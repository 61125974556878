import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  userDetails: any;
  IsUserLogin: boolean = false;
  constructor(private authService: AuthService,
    private dataService: DataService,
    private router: Router) {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/login' || val.url == '/' || val.url === '/forgot-password'
          || val.url === '/award-list' || val.url === '/thank-you' || val.url === '/sponsors-list'
          || val.url === '/media-gallery' || val.url === '/our-jury' || val.url === '/assessor-appliaction' || val.url === '/our-assessors' || val.url === '/faq' || val.url === '/assessor-appliaction' || val.url.match('/award-details') || val.url.match('/assessor-register') || val.url.match('/company-register') || val.url.match('/view-all-media')
          || val.url.match('/active-account') || val.url.match('/reset-password') || val.url.match('/jury-appliaction')
          || val.url.match('/award-framework') || val.url.match('/testimonials') || (val.url.match('/contact-us')) || (val.url.match('/award-winner')) || (val.url.match('/terms-and-condition')) || (val.url.match('/privacy-policy')) || (val.url.match('/cookies-policy'))) {
          this.IsUserLogin = false;
        }
        else {
          this.IsUserLogin = true;
        }
      }
    });
  }

  ngOnInit() {
  }

  positionAwardScroll(dropDownName) {
    console.log(dropDownName)
    this.dataService.dropdownScroll(dropDownName);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { DashboardService } from '../../services/dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-award-statistics',
  templateUrl: './award-statistics.component.html',
  styleUrls: ['./award-statistics.component.scss']
})
export class AwardStatisticsComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  awardList: any = [];
  lazyLoad: boolean = false;
  award_image_path: any;
  companymanagementListArray: any = [];
  permissionMsg: any;
  permissionStatus: boolean = false;
  infoType: any;
  closeResult: string;
  singleAwardDetails: any;

  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  //   title: {
  //     display: true,
  //     text: 'CURRENT COPANY RATING OUT OF 5',
  //     fontColor: 'black',  // chart title color (can be hexadecimal too)
  //   },
  //   scales: {
  //     xAxes: [{
  //       barThickness: 20,
  //       maxBarThickness: 30
  //     }],
  //     yAxes: [{
  //       scaleLabel: {
  //         display: true,
  //         labelString: 'Total Rating'
  //       },
  //       gridLines: {
  //         drawBorder: false,
  //       },
  //       ticks: {
  //         fontFamily: "'Open Sans Bold', sans-serif",
  //         fontSize: 11,
  //         min: 0,
  //         max: 2.5
  //       }
  //     }]
  //   },
  // };
  // public barChartLabels = ['Ajesh Company', 'Dubai Investors', 'Test Company', 'billabong','Aone', 'Sample Company', 'T.T', 'sillico','ABD','R4T','Patriot Rent A Car','Nithin Company'];
  // public barChartType: ChartType = 'bar';
  // public barChartLegend = false;
  // public barChartPlugins = [];

  // public barChartData: ChartDataSets[] = [
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series B' },
  //   { data: [0.0, 0.0, 2.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series C' },
  //   { data: [0.0, 0.0, 0.0, 1.9, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series D' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series E' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series F' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.4, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series G' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.6, 0.0, 0.0, 0.0, 0.0], label: 'Series H' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series I' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.8, 0.0, 0.0], label: 'Series J' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5, 0.0], label: 'Series K' },
  //   { data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0], label: 'Series L' },
  // ];
  //  public barChartColors = [
  //   { backgroundColor: '' },
  //   { backgroundColor: '#26d033' },
  //   { backgroundColor: '#ef7003' },
  //   { backgroundColor: '#e4f309' },
  //   { backgroundColor: '#0abd83' },
  //   { backgroundColor: '#e812a1' },
  //   { backgroundColor: '#58c4fd' },
  //   { backgroundColor: '#068ed6' },
  //   { backgroundColor: '#ef7003' },
  //   { backgroundColor: '#e4f309' },
  //   { backgroundColor: '#0abd83' },
  // ]

  // public barChartOptions1: ChartOptions = {
  //   responsive: true,
  // };
  // public barChartLabels1 = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  // public barChartType1: ChartType = 'doughnut';
  // public barChartLegend1 = true;
  // public barChartPlugins1 = [];

  // public barChartData1: ChartDataSets[] = [
  //   { data: [65, 31], label: 'Series A' },
  //   // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  // ];
  // public barChartColors1 = [
  //   // { backgroundColor: '#26d033' },
  //   { backgroundColor: '#ef7003' }
  // ]
  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private dashboardService: DashboardService,
    private modalService: NgbModal) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/award-statistics')
          this.leftbarselected = 'Award Statistics';
        this.dataService.leftPanelSelection('award-statistics');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-statistics`]);
    }
    this.dashboardStaticsDetails();
    this.companymanagementList();
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  dashboardStaticsDetails() {
    this.lazyLoad = true;
    this.dashboardService.staticsDetails(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardList = res.all_awards;
        this.award_image_path = res.award_image_path;
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong');
      })
  }
  statisticsInfo(content, awardItem, type, className) {
    if (type == 'assessor') {
      this.infoType = 'assessor';
    }
    else if (type == 'jury') {
      this.infoType = 'jury';
    }
    else if (type == 'company') {
      this.infoType = 'company';
    }
    this.singleAwardDetails = awardItem;
    let modalClassName = className;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}

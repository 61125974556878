import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { JudgeService } from '../../services/judge.service';


@Component({
  selector: 'app-company-award-details',
  templateUrl: './company-award-details.component.html',
  styleUrls: ['./company-award-details.component.scss']
})
export class CompanyAwardDetailsComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  awardId: any;
  awardYearId: any;
  award_details: any = [];
  lazyLoad: boolean = false;
  award_image_path: any;
  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private judgeService: JudgeService) {
      this.activatedRoute.params.subscribe(params => {
        this.awardId = params.id;
        this.awardYearId = params.id1;
      }); 
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd == true) {
          if (val.url.match('/company-award-details'))
            this.leftbarselected = 'Award Details';
          this.dataService.leftPanelSelection('company-award-apply');
        }
      });
      this.user_token = localStorage.getItem('user_token');
      this.api_token = localStorage.getItem('api_token');
    }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-award-details/`+ this.awardId+'/'+ this.awardYearId]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.Awarddetails();
  }

  Awarddetails() {
    this.lazyLoad = true;
    this.authService.awardDetails(this.user_token, this.awardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.lazyLoad = false;
        console.log(res);
        this.award_details =  res.awardDetails.filter(x => x.year_id === Number(this.awardYearId));
        this.award_image_path = res.award_image_path;
      }

    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      })
  }
  openDocumentDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to apply for this award?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.applyAward();
      }
    });
  }
  applyAward(){
    let data = {
      award_year_id: this.awardYearId
    }
    this.judgeService.compnayAwardApply(this.api_token,data).subscribe((res: any)=>{
      console.log(res);
      if(res.has_error == 0){
        this.messagesService.showSuccess(res.msg);
        setTimeout(() => {
          this.messagesService.clear();
          this.router.navigate([`/company-dashboard`]);
        }, 2000);
      }
      if(res.has_error == 1){
        this.messagesService.showError(res.error);
      }
    })
  }
}

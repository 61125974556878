import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { appConfig } from '../../app.config';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  userDetails: any;
  IsUserLogin: boolean = false;
  aciveLineId: any;
  siteLogoLink: any;
  blinkShow: boolean = false;
  api_token: any;
  userMenuList: any = [];
  menuList: any = [];
  permissionMenuList: any = [];
  leftPanelShow: boolean = false;

  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';
  constructor(private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService) {
    this.siteLogoLink = appConfig.siteLogoLink;

    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/login' || val.url == '/' || val.url === '/forgot-password'
          || val.url === '/award-list' || val.url === '/thank-you' || val.url === '/sponsors-list'
          || val.url === '/media-gallery' || val.url === '/our-jury' || val.url === '/assessor-appliaction' || val.url === '/our-assessors' || val.url === '/faq' || val.url === '/assessor-appliaction' || val.url.match('/award-details') || val.url.match('/assessor-register') || val.url.match('/company-register') || val.url.match('/view-all-media') || val.url.match('/active-account') || val.url.match('/reset-password') || val.url.match('/jury-appliaction') || val.url.match('/award-framework') || val.url.match('/testimonials') || (val.url.match('/contact-us')) || (val.url.match('/award-winner')) || (val.url.match('/terms-and-condition')) || (val.url.match('/privacy-policy')) || (val.url.match('/cookies-policy'))) {
          this.IsUserLogin = false;
        }
        else {
          this.IsUserLogin = true;
          this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
          this.api_token = localStorage.getItem('api_token');
          if(this.userDetails.user_type == 2) {
          this.permissionMenu(this.api_token);
          }
        }
      }
    });
  }

  ngOnInit() {
    this.dataService.leftbarselection.subscribe((res: any) => {
      this.aciveLineId = res;
      if (this.aciveLineId != '') {
        setTimeout(() => {
          let parentEl = document.getElementById(this.aciveLineId).parentNode.parentNode;
          parentEl.querySelectorAll('li').forEach((item) => {
            item.querySelector('a').classList.remove('active');
          });
          document.getElementById(this.aciveLineId).className += ' active';
        }, 200);

      }
    })
    this.dataService.blinkData.subscribe((res: any) => {
      // console.log(res)
      if (res != '') {
        setTimeout(() => {
          if (res.blink_status == 'Y') {
            this.blinkShow = true;
          }
          else if (res.blink_status == 'N') {
            this.blinkShow = false;
          }

        }, 200);
      }
    })
  //   if(this.IsUserLogin = true){
  //   setTimeout(() => {
  //     this.permissionMenu(this.api_token);
  //   }, 200);
  // }

  }
  
  logout() {
    this.IsUserLogin = false;
    this.dataService.userLoggenInStatus(this.IsUserLogin);
    localStorage.removeItem('userDetails');
    localStorage.removeItem('eachJuryDetails');
    localStorage.removeItem('eachOfficeDetails');
    localStorage.removeItem('uploadPath');
    localStorage.removeItem('api_token');
    this.router.navigate(['/login']);
  }
  lineActive(id) {
    this.aciveLineId = id;
    let parentEl = document.getElementById(this.aciveLineId).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('a').classList.remove('active');
    });
    document.getElementById(this.aciveLineId).className += ' active';
  }
  permissionMenu(api_token) {
    this.dashboardService.menuDetails(api_token).subscribe((res: any) => {
      this.menuList = res.menus;
      this.userMenuList = res.user_menus;
      this.menuList.map((item) => {
        if (item.menu_name == 'Award Office Management') {
          item['menu_id'] = item.id;
          item['id'] = 'award_office_mgmt';
        }
        if (item.menu_name == 'Award Office Group Management') {
          item['menu_id'] = item.id;
          item['id'] = 'award_office_group_mgmt';
        }
        if (item.menu_name == 'Award Management') {
          item['menu_id'] = item.id;
          item['id'] = 'award_management';
        }
        if (item.menu_name == 'Criteria Management') {
          item['menu_id'] = item.id;
          item['id'] = 'criteria_management';
        }
        if (item.menu_name == 'Assessor Management') {
          item['menu_id'] = item.id;
          item['id'] = 'assessor_management';
        }
        if (item.menu_name == 'Jury Management') {
          item['menu_id'] = item.id;
          item['id'] = 'jury_management';
        }
        if (item.menu_name == 'Company Management') {
          item['menu_id'] = item.id;
          item['id'] = 'company_management';
        }
        if (item.menu_name == 'Company Documents') {
          item['menu_id'] = item.id;
          item['id'] = 'company_documents';
        }
        if (item.menu_name == 'Assessor Group') {
          item['menu_id'] = item.id;
          item['id'] = 'assessor_group';
        }
        if (item.menu_name == 'Jury Group') {
          item['menu_id'] = item.id;
          item['id'] = 'jury_group';
        }
        if (item.menu_name == 'Assigned Assessor Group List') {
          item['menu_id'] = item.id;
          item['id'] = 'assign_assessor_group';
        }
        if (item.menu_name == 'Assigned Jury Group List') {
          item['menu_id'] = item.id;
          item['id'] = 'assign_jury_group';
        }
      })
      // console.log(this.menuList);
      // console.log(this.userMenuList);
      this.permissionMenuList = [];
      if (this.userMenuList.length > 0) {
        this.menuList.filter(o => this.userMenuList.find(o2 => o.menu_id === o2)).map((item) => {
          this.permissionMenuList.push(item.id)
        });
        console.log(this.menuList.filter(o => this.userMenuList.find(o2 => o.menu_id === o2)));
        // console.log(this.permissionMenuList);
      }
    },
      err => {

      })
  }
  openDashboardLeftPanel(){
    this.leftPanelShow = !this.leftPanelShow;
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JuryRegisterComponent } from './jury-register.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesService } from '../services/messages.service';
import { ClickOutsideModule } from 'ng-click-outside';

// intro routing
export const routes: Routes = [
  { path: '', component: JuryRegisterComponent },
];
@NgModule({
  declarations: [JuryRegisterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule
  ],
  providers:[MessagesService]
})
export class JuryRegisterModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class JudgeService {
  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  constructor(private http: HttpClient) { }

  // update passoword
  updatePaasword(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/change-password?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // update profile
  updateProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/update-profile?api_token=${api_token}`, $obj);
  }
  // get critera list
  getCritera(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/get-child-criteria?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // add document
  addDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/upload-company-document?api_token=${api_token}`, $obj);
  }
  // add general document
  addGeneralDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-company-general-document?api_token=${api_token}`, $obj);
  }
  // document list
  documentlist(api_token, searchYear, searchAward) {
    if (searchYear == undefined) {
      searchYear = '';
    }
    if (searchAward == undefined) {
      searchAward = '';
    }
    return this.http.get(`${appConfig.apiUrl}/company-documents?api_token=${api_token}&searchYear=${searchYear}&searchAward=${searchAward}`);
  }
  // general document list
  generalDocumentlist(api_token, searchYear, searchAward) {
    if (searchYear == undefined) {
      searchYear = '';
    }
    if (searchAward == undefined) {
      searchAward = '';
    }
    return this.http.get(`${appConfig.apiUrl}/company-general-document?api_token=${api_token}&searchYear=${searchYear}&searchAward=${searchAward}`);
  }
  // delete company document
  deleteCompanyDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/delete-company-document?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // delete company general doc
  deleteCompanyGeneralDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/delete-company-general-doc?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // mangement award list
  awardmanagementlist(api_token) {
    return this.http.get(`${appConfig.apiUrl}/mangement-award-list?api_token=${api_token}`);
  }
  // send approve
  assesmentStatusChange(api_token, $obj) {
    console.log($obj);
    return this.http.post(`${appConfig.apiUrl}/assesment-status-change?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // show final award score
  showFinalAwardScore(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/show-final-award-score?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // all-assesment-status-change
  allAssesmentStatusChange(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/all-assesment-status-change?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // all-assesment-status-change-office
  allAssesmentStatusChangeOffice(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/all-assesment-status-change-office?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // submit final report
  SubmitFinalreport(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/submit-final-report?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // send message
  sendComment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/send-message?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // add judge comment
  addJudgeComment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-judge-comment?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // add judge comment mul
  addJudgeCommentMul(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-judge-comment-mul?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // company-document-to-office
  compnayAwardApply(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/applyto-another-award?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // award office filter company list
  searchCompanylist(api_token, searchYear, searchAward, searchCompany) {
    if (searchYear == undefined) {
      searchYear = '';
    }
    if (searchAward == undefined) {
      searchAward = '';
    }
    if (searchCompany == undefined) {
      searchCompany = '';
    }
    return this.http.get(`${appConfig.apiUrl}/company-document-list?api_token=${api_token}&searchYear=${searchYear}&searchAward=${searchAward}&searchCompany=${searchCompany}`, { headers: this.headers });
  }
  // award office filter general company list
  searchGeneralCompanylist(api_token, searchYear, searchAward, searchCompany) {
    if (searchYear == undefined) {
      searchYear = '';
    }
    if (searchAward == undefined) {
      searchAward = '';
    }
    if (searchCompany == undefined) {
      searchCompany = '';
    }
    return this.http.get(`${appConfig.apiUrl}/company-general-document-list?api_token=${api_token}&searchYear=${searchYear}&searchAward=${searchAward}&searchCompany=${searchCompany}`, { headers: this.headers });
  }
  // payment-list
  paymentlist(api_token) {
    return this.http.get(`${appConfig.apiUrl}/payment-list?api_token=${api_token}`, { headers: this.headers });
  }
  // participated-award-list
  participatedAwardlist(api_token) {
    return this.http.get(`${appConfig.apiUrl}/company-participated-award?api_token=${api_token}`, { headers: this.headers });
  }
  // download-invoice
  downloadInvoice(api_token, id) {
    return this.http.get(`${appConfig.apiUrl}/download-invoice?api_token=${api_token}&id=${id}`, { headers: this.headers });
  }
  // send-submission
  sendSubmission(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/send-submission?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // share-comment-to-member
  leaderCommentShare(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/share-comment-to-member?api_token=${api_token}`, $obj, { headers: this.headers });
  }
}

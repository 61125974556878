import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftPanelComponent } from './left-panel.component';
import { RouterModule, Routes } from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: LeftPanelComponent },
];

@NgModule({
  declarations: [LeftPanelComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatTooltipModule,
    NgScrollbarModule
  ],
  exports: [LeftPanelComponent]
})
export class LeftPanelModule { }

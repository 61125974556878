import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from "@angular/router";
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';


declare var $: any;

@Component({
  selector: 'app-award-fremework',
  templateUrl: './award-fremework.component.html',
  styleUrls: ['./award-fremework.component.scss']
})
export class AwardFremeworkComponent implements OnInit {
  user_token: any;
  awardFrameworkDetails: any;
  award_ppt_path: any;
  pdfUrl: any;

  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  awardFrameworkFileExtension: any;
  pptUrl: any;
  awardId: any;
  award_details: any;
  awardYearId: any;
  award_image_path: any;
  constructor(private dataService: DataService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute) {
      this.activatedRoute.params.subscribe(params => {
        this.awardId = params.id;
        this.awardYearId = params.id1;
      });
      console.log(this.awardId);
     }

  ngOnInit() {
    window.scroll(0, 0);
    let that = this;
    // $(document).ready(function () {
    //   var url = '/assets/images/dummy.pdf';
    //   $("#pdfViewer").flipBook({
    //     pdfUrl: url,
    //     skin: 'dark',
    //     printMenu: false,
    //     btnPrint: { enabled: false },
    //     btnShare: { enabled: false },
    //     zoomStep: 2,
    //     btnDownloadPages: { enabled: false },
    //     btnDownloadPdf: { enabled: false },
    //     btnColor: 'rgb(33,181,212)',
    //     btnSound: { enabled: true },
    //     btnSearch: {
    //       enabled: true,
    //       title: "Search",
    //       icon: "fa fa-search"
    //     },
    //     viewMode: 'swipe',
    //     singlePageMode: true,
    //     backgroundTransparent: true
    //   });
    // })
    this.getuserToken();
  }
  // positionScroll(name) {
  //   localStorage.setItem('framaework_name',name);
  //   this.router.navigate(['/']);
  // }
  positionScroll(dropDownName) {
    // if (this.currenPageUrl == '/') {
    this.dataService.dropdownScroll(dropDownName);
    this.router.navigate(['/']);
    // }
    // if ((this.currenPageUrl == '/login') || (this.currenPageUrl === '/forgot-password')|| (this.currenPageUrl === '/award-list')|| (this.currenPageUrl === '/thank-you')|| (this.currenPageUrl === '/sponsors-list')|| (this.currenPageUrl === '/media-gallery')|| (this.currenPageUrl === '/our-juries')|| (this.currenPageUrl === '/assessor-appliaction')|| (this.currenPageUrl === '/our-assessor')|| (this.currenPageUrl === '/faq') || (this.currenPageUrl === '/jury-appliaction')|| (this.currenPageUrl === '/award-framework') || (this.currenPageUrl === '/testimonials') || (this.currenPageUrl.match('/award-details')) || (this.currenPageUrl.match('/assessor-register'))|| (this.currenPageUrl.match('/company-register')) || (this.currenPageUrl.match('/view-all-media')) || (this.currenPageUrl.match('/reset-password'))) {
    //   this.router.navigate(['/']);
    //   this.dataService.dropdownScroll(dropDownName);
    // }
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token);
        if((this.awardId == null) || (this.awardId == undefined)) {
          this.getAwardFramework(this.user_token);
        }
        if((this.awardId != null) || (this.awardId != undefined)) {
          this.getAwardDetailsPresentation(this.user_token);
        }
      }
    },
      err => {

      });
  }
  getAwardFramework(user_token) {
    this.authService.awardFramework(user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.awardFrameworkDetails = res.framework_data;
        this.award_ppt_path = res.file_path;
        if(this.awardFrameworkDetails.file != null){
          this.awardFrameworkFileExtension = this.awardFrameworkDetails.file.split('.')[1];
        }
        console.log(this.awardFrameworkFileExtension);
        if(this.awardFrameworkFileExtension == 'pdf') {
          this.pdfUrl = this.award_ppt_path + this.awardFrameworkDetails.file;
          console.log(this.pdfUrl);
        }
        if((this.awardFrameworkFileExtension == 'ppt') || (this.awardFrameworkFileExtension == 'pptx')){
          this.pptUrl = this.award_ppt_path + this.awardFrameworkDetails.file;
          console.log(this.pptUrl);
        }
        // if((this.awardFrameworkFileExtension == 'pptx')){
        //   this.pptUrl = this.award_ppt_path + this.awardFrameworkDetails.file;
        //   console.log(this.pptUrl);
        // }
      }
    },
      err => {

      });
  }
  getAwardDetailsPresentation(user_token) {
    this.authService.awardDetails(user_token, this.awardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        console.log(res);
        this.award_details =  res.awardDetails.filter(x => x.year_id === Number(this.awardYearId));
        this.award_ppt_path = res.ppt_path;
        this.award_image_path = res.award_image_path;
        if(this.award_details[0].ppt != null){
          this.awardFrameworkFileExtension = this.award_details[0].ppt.split('.')[1];
        }
        console.log(this.awardFrameworkFileExtension);
        if(this.awardFrameworkFileExtension == 'pdf') {
          this.pdfUrl = this.award_ppt_path + this.award_details[0].ppt;
          console.log(this.pdfUrl);
        }
        if((this.awardFrameworkFileExtension == 'ppt') || (this.awardFrameworkFileExtension == 'pptx')){
          this.pptUrl = this.award_ppt_path + this.award_details[0].ppt;
          console.log(this.pptUrl);
        }
        // if((this.awardFrameworkFileExtension == 'pptx')){
        //   this.pptUrl = this.award_ppt_path + this.award_details[0].ppt;
        //   console.log(this.pptUrl);
        // }
      }

    },
      err => {
      })
  }
  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnersComponent } from './partners.component';
import { WinnerService } from 'src/app/services/winner.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MessagesService } from '../../services/messages.service';

@NgModule({
  declarations: [PartnersComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  providers: [
    WinnerService,
    MessagesService
  ],
  exports: [PartnersComponent]
})
export class PartnersModule { }

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { appConfig } from '../app.config';
@Component({
  selector: 'app-jury-register',
  templateUrl: './jury-register.component.html',
  styleUrls: ['./jury-register.component.scss']
})
export class JuryRegisterComponent implements OnInit {
  formOneShow: boolean = true;
  formTwoShow: boolean = false;
  formThreeShow: boolean = false;
  user_token: any;
  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  stepThreeForm: FormGroup;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  NamePrefixList: any = [];
  genderList: any = [];
  allCountryList: any = [];
  QualificationList: any = [];
  YearsofWorkExperienceList: any = [];
  IndustryList: any = [];
  ProfessionalUseLanguageList: any = [];
  cityListArray: any = [];
  Profile_Cv_Show: any;
  Profile_pic_Show: any;
  ProfessionalPicErr: any;
  ProfessionalProfileErr: any;
  mobileCountryCodeeErr: string;
  WhatsAppCountryErr: string;
  PrefixNameErr: string;
  emailProsessingtext: boolean = false;
  webSitePatternErr: boolean = false;
  uniquePersonalEmailShow: boolean = false;
  uniquePersonalEmailVerified: boolean = false;
  uniquePersonalEmailErr: any;
  errorMessageValue: any;
  processingText: boolean = false;
  aboutusList: any = [];
  awardList: any = [];
  juryAwardIdArray: any = [];
  showOpenAward: boolean = false;
  allAwardList: any = [];
  allAwardSelected: any = [];
  allAwardSelectedString: any = "";
  showAwardborder: boolean = false;
  awardErr: any;
  imageResolutionText: any;
  fileTypeText: any;
  executivePictureErr: any;
  executive_picture_show: any;
  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private messagesService: MessagesService,
    private router: Router) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
    this.getuserToken();
    this.juryDetails();
    this.callingCodes();
    this.aboutusList.push('Award website', 'Google Search', 'Referred by someone', 'Social Media', 'Others')
    this.stepOneForm = this.formBuilder.group({
      namePrefix: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      employer: ['', Validators.required],
      job_title: ['', Validators.required],
      Country_code: ['', Validators.required],
      mobile_no: ['', Validators.required],
      WhatsApp_Country_code: ['', Validators.required],
      WhatsApp_mobile_no: ['', Validators.required],
      workEmail: ['', [Validators.required, Validators.email]],
      personalEmail: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.stepTwoForm = this.formBuilder.group({
      qualification: ['', Validators.required],
      yearsofWorkExperience: ['', Validators.required],
      industry: ['', Validators.required],
      professional_language: ['', Validators.required],
      linkedIn_profile_link: ['', Validators.required],
      about_us: ['', Validators.required]
    });
    this.stepThreeForm = this.formBuilder.group({
      interest_description: ['', Validators.required],
      experience_description: ['', Validators.required],
      confict_description: ['', Validators.required],
      recomended_email: [''],
      notes: [''],
      executive_job_title: ['', Validators.required],
      executive_name: ['', Validators.required],
      executive_summary: ['', Validators.required],
    });
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  juryDetails() {
    this.authService.juryDetails(this.user_token).subscribe((res: any) => {
      console.log(res);
      this.imageResolutionText = res.jury_image_text;
      this.awardList = res.award_data;
      this.NamePrefixList = res.NamePrefixs;
      this.genderList = res.genders;
      this.allCountryList = res.all_country;
      this.QualificationList = res.Qualifications;
      this.YearsofWorkExperienceList = res.YearsofWorkExperiences;
      this.IndustryList = res.Industries;
      this.ProfessionalUseLanguageList = res.ProfessionalUseLanguages;
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  onClickedOutsideAward(e: Event) {
    this.showOpenAward = false;
  }
  openClosePopup() {
    this.showOpenAward = !this.showOpenAward;
  }
  checkAward(item) {
    console.log(item);
    if (!this.allAwardSelected.some((allAwardSelecteditem) => allAwardSelecteditem.year_id == item.year_id)) {
      this.juryAwardIdArray.push(item.year_id);
      this.allAwardSelected.push(item);
      if (this.allAwardSelected.length > 0) {
        this.awardErr = '';
        this.showAwardborder = false;
      }
    }
    else {
      const index: number = this.allAwardSelected.indexOf(item);
      if (index !== -1) {
        this.allAwardSelected.splice(index, 1);
        this.juryAwardIdArray.splice(index, 1);
        if (this.allAwardSelected.length == 0) {
          this.awardErr = 'Award is required';
          this.showAwardborder = true;
        }
      }
    }
    console.log(this.juryAwardIdArray);
    this.allAwardSelectedString = "";
    if (this.allAwardSelected.length > 3) {
      this.allAwardSelectedString = this.allAwardSelected.length + " items selected";

    }
    else {
      for (let itemlist of this.allAwardSelected) {
        this.allAwardSelectedString += itemlist.title + ',';

      }
    }
  }

  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      // console.log(res);
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
      // console.log(this.countryCallingCodes);
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  // get city list
  cityList(NationalityId) {
    let data = {
      nationalities_id: NationalityId
    }
    this.authService.getCityList(data).subscribe((response: any) => {
      this.cityListArray = response.data;
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    });
  }
  // convenience getter for easy access to form fields
  get f1() { return this.stepOneForm.controls; }

  get f2() { return this.stepTwoForm.controls; }

  get f3() { return this.stepThreeForm.controls; }

  onChangeMobileCode(event) {
    if (event == '') {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.mobileCountryCodeeErr = '';
    }
  }

  onChangeWhatappCode(event) {
    if (event == '') {
      this.WhatsAppCountryErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.WhatsAppCountryErr = '';
    }
  }
  onChangePrefix(event) {
    if (event == '') {
      this.PrefixNameErr = 'Jury Prefix Name is required';
    }
    if (event != '') {
      this.PrefixNameErr = '';
    }
  }
  uniquePersonalEmailCheck() {
    if (this.stepOneForm.value.personalEmail != '') {
      let data = {
        email: this.stepOneForm.value.personalEmail
      }
      this.emailProsessingtext = true;
      this.authService.uniqueEmail(data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.uniquePersonalEmailShow = true;
          this.emailProsessingtext = false;
          this.uniquePersonalEmailVerified = false;
          this.uniquePersonalEmailErr = '';
        }
        else if (res.has_error == 1) {
          this.uniquePersonalEmailShow = false;
          this.emailProsessingtext = false;
          window.scroll({
            top: 800,
            left: 0,
            behavior: 'smooth'
          });
          this.uniquePersonalEmailVerified = true;
          this.uniquePersonalEmailErr = 'Personal Email is already registered';
        }
      }, err => {
        this.uniquePersonalEmailErr = 'Something went wrong.';
        this.uniquePersonalEmailShow = false;
        this.emailProsessingtext = false;
        this.uniquePersonalEmailVerified = false;
      })
    }
  }

  onKey(event: any) { // without type info
    if (event.target.value == '') {
      this.uniquePersonalEmailVerified = false;
    }
  }

  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  onChangeNationality($event) {
    // this.cityListArray = [];
    // this.stepOneForm.get('city').reset();
    // this.cityList($event);
  }
  onSelectFileProfilepic(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      this.Profile_pic_Show = event.target.files[0];
      if (this.Profile_pic_Show != '') {
        this.ProfessionalPicErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => {
      }
    }
  }

  onSelectFileProfileCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.Profile_Cv_Show = event.target.files[0];
      if (this.Profile_Cv_Show != '') {
        this.ProfessionalProfileErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {

      }
    }
  }

  onSelectFileExecutivePicture(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      this.executive_picture_show = event.target.files[0];
      if (this.executive_picture_show != '') {
        this.executivePictureErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => {
      }
    }
  }

  formOneSubmit() {
    this.submitted1 = true;
    if ((this.stepOneForm.value.address == '') || (this.stepOneForm.value.city == '')
      || (this.stepOneForm.value.zip_code == '') || (this.stepOneForm.value.country == '')) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.workEmail == '') || (this.stepOneForm.value.personalEmail == '')) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.WhatsApp_Country_code == '') && (this.stepOneForm.value.WhatsApp_mobile_no != '')) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    if ((this.stepOneForm.value.Country_code == '') && (this.stepOneForm.value.mobile_no != '')) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if ((this.stepOneForm.value.employer == '') || (this.stepOneForm.value.job_title == '')
      && (this.stepOneForm.value.gender != '') && (this.stepOneForm.value.nationality != '')) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.namePrefix == '') && (this.stepOneForm.value.firstName != '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
      this.PrefixNameErr = 'Jury Prefix Name is required';
    }
    if ((this.stepOneForm.value.firstName == '') || (this.stepOneForm.value.lastName == '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.juryAwardIdArray.length == 0) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
      this.showAwardborder = true;
      this.awardErr = 'Award is required';
    }
    if (this.stepOneForm.invalid) {
      return;
    }
    if (this.stepOneForm.value.personalEmail != '') {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.stepOneForm.value.personalEmail != '') {
      this.uniquePersonalEmailCheck();
    }
    setTimeout(() => {
      if (this.uniquePersonalEmailShow == true) {
        if (this.juryAwardIdArray.length > 0) {
          this.formOneShow = false;
          this.formTwoShow = true;
          this.formThreeShow = false;
          window.scroll({
            top: 200,
            left: 0,
            behavior: 'smooth'
          });
        }
        else if (this.juryAwardIdArray.length == 0) {
          window.scroll({
            top: 800,
            left: 0,
            behavior: 'smooth'
          });
          this.showAwardborder = true;
          this.awardErr = 'Award is required';
        }
      }
    }, 1500);
    console.log(this.stepOneForm.value)
  }
  formTwoSubmit() {
    this.submitted2 = true;
    if ((this.stepOneForm.value.qualification == '') || (this.stepOneForm.value.yearsofWorkExperience == '')
      || (this.stepOneForm.value.industry == '') || (this.stepOneForm.value.professional_language == '')
      || (this.stepOneForm.value.linkedIn_profile_link == '')) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    // stop here if form is invalid
    if (this.stepTwoForm.invalid) {
      return;
    }

    if (this.webSitePatternErr == true) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.webSitePatternErr == false) {
      this.formOneShow = false;
      this.formTwoShow = false;
      this.formThreeShow = true;
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
    }
    console.log(this.stepTwoForm.value)
  }
  formThreeSubmit() {
    this.submitted3 = true;
    // if ((this.stepThreeForm.value.notes == '') && ((this.stepThreeForm.value.recomended_email == ''))) {
    //   window.scroll({
    //     top: 1000,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }
    if (this.stepThreeForm.value.confict_description == '') {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.Profile_pic_Show == null) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.ProfessionalPicErr = 'Professional Photo is required';
    }
    else {
      this.ProfessionalPicErr = '';
    }
    if (this.Profile_Cv_Show == null) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.ProfessionalProfileErr = 'Professional Profile/ CV is required';
    }
    else {
      this.ProfessionalProfileErr = '';
    }
    if (this.executive_picture_show == null) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.executivePictureErr = 'Executive Picture is required';
    }
    else {
      this.executivePictureErr = '';
    }
    if (this.stepThreeForm.value.experience_description == '') {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.stepThreeForm.value.interest_description == '') {
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
    }
    // stop here if form is invalid
    if (this.stepThreeForm.invalid) {
      return;
    }

    if ((this.Profile_pic_Show != null) && (this.Profile_Cv_Show != null) && (this.executive_picture_show != null)) {

      this.stepOneForm.value.namePrefix = Number(this.stepOneForm.value.namePrefix);
      this.stepOneForm.value.gender = Number(this.stepOneForm.value.gender);
      this.stepOneForm.value.nationality = Number(this.stepOneForm.value.nationality);
      this.stepOneForm.value.Country_code = parseInt(this.stepOneForm.value.Country_code);
      this.stepOneForm.value.WhatsApp_Country_code = parseInt(this.stepOneForm.value.WhatsApp_Country_code);
      this.stepOneForm.value.country = Number(this.stepOneForm.value.country);

      this.stepTwoForm.value.qualification = Number(this.stepTwoForm.value.qualification);
      this.stepTwoForm.value.yearsofWorkExperience = Number(this.stepTwoForm.value.yearsofWorkExperience);
      this.stepTwoForm.value.industry = Number(this.stepTwoForm.value.industry);
      this.stepTwoForm.value.professional_language = Number(this.stepTwoForm.value.professional_language);

      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('award_id', this.juryAwardIdArray),
        formData.append('NamePrefix_id', this.stepOneForm.value.namePrefix),
        formData.append('first_name', this.stepOneForm.value.firstName),
        formData.append('last_name', this.stepOneForm.value.lastName),
        formData.append('email', this.stepOneForm.value.personalEmail),
        formData.append('Gender_id', this.stepOneForm.value.gender),
        formData.append('nationalities_id', this.stepOneForm.value.nationality),
        formData.append('Current_Employer', this.stepOneForm.value.employer),
        formData.append('Job_Title', this.stepOneForm.value.job_title),
        formData.append('address', this.stepOneForm.value.address),
        formData.append('Country_code', this.stepOneForm.value.Country_code),
        formData.append('mobile_no', this.stepOneForm.value.mobile_no),
        formData.append('WhatsApp_Country_code', this.stepOneForm.value.WhatsApp_Country_code),
        formData.append('WhatsApp_mobile_no', this.stepOneForm.value.WhatsApp_mobile_no),
        formData.append('Work_E_mail', this.stepOneForm.value.workEmail),
        formData.append('cityof_residences_id', this.stepOneForm.value.city),
        formData.append('country_id', this.stepOneForm.value.country),
        formData.append('zip_code', this.stepOneForm.value.zip_code),

        formData.append('industries_id', this.stepTwoForm.value.industry),
        formData.append('qualifications_id', this.stepTwoForm.value.qualification),
        formData.append('professional_language_id', this.stepTwoForm.value.professional_language),
        formData.append('yearsof_work_experiences_id', this.stepTwoForm.value.yearsofWorkExperience),
        formData.append('LinkedIn_Profile_Link', this.stepTwoForm.value.linkedIn_profile_link),
        formData.append('how_did_you_hear_about', this.stepTwoForm.value.about_us),

        formData.append('interest_description', this.stepThreeForm.value.interest_description),
        formData.append('experience_description', this.stepThreeForm.value.experience_description),
        formData.append('confict_description', this.stepThreeForm.value.confict_description),
        formData.append('recomended_email', this.stepThreeForm.value.recomended_email ? this.stepThreeForm.value.recomended_email : ''),
        formData.append('notes', this.stepThreeForm.value.notes ? this.stepThreeForm.value.notes : ''),
        formData.append('Profile_CV', this.Profile_Cv_Show),
        formData.append('Profile_pic', this.Profile_pic_Show),


        formData.append('executive_job_title', this.stepThreeForm.value.executive_job_title),
        formData.append('executive_name', this.stepThreeForm.value.executive_name),
        formData.append('executive_picture', this.executive_picture_show),
        formData.append('executive_summary', this.stepThreeForm.value.executive_summary),



        console.log(this.stepTwoForm.value)
      this.processingText = true;
      this.authService.juryRegistration(formData).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingText = false;
          this.messagesService.showSuccess('Registration is successfully done.Please check your email for an account activation link.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 800);
          this.router.navigate(['/thank-you']);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          window.scroll({
            top: 400,
            left: 0,
            behavior: 'smooth'
          });
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 800);
        }
      },
        error => {
          this.processingText = false;
          window.scroll({
            top: 400,
            left: 0,
            behavior: 'smooth'
          });
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 800);
        })
    }
  }
}

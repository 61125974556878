import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CaptchaModule} from 'primeng/captcha';
// intro routing
export const routes: Routes = [
  { path: '', component: ForgotPasswordComponent },
];

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CaptchaModule
  ]
})
export class ForgotPasswordModule { }

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService,
        private messagesService: MessagesService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('api_token');
        // console.log(req)
        if (token) {
            if(req.url != 'https://restcountries.eu/rest/v2/all'){
                req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
        }
        }

        return next.handle(req).pipe(tap((event: any) => { }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                console.log('API ERROR', error);
                if (error.status == 504 || error.status == 401) { /* in chrome we get 0 as error status code and for other browsers we get right error status code that is 401 */
                    console.error('Token is expired');
                    localStorage.removeItem('userDetails');
                    localStorage.removeItem('api_token');
                    this.router.navigate(['/login']);
                    this.messagesService.showError('Your Token is expired,Please Login again')
                    setTimeout(() => {
                        this.messagesService.clear();
                    }, 3000);
                }
            }
        }));
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  //   private mutipartheaders = new HttpHeaders().set(
  //     'enctype', 'multipart/form-data'
  // );

  constructor(private http: HttpClient) {
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem('api_token')) {
      return true;
    }
    return false;
  }

  // get user token
  getUserToken($obj) {
    return this.http.post(`${appConfig.apiUrl}/get-user-token`, $obj, { headers: this.headers });
  }

  //  award-details
  awardDetails(user_token, awrad_id) {
    return this.http.get(`${appConfig.apiUrl}/award-details?user_token=${user_token}&award_id=${awrad_id}`, { headers: this.headers })
  }

  // get city list
  getCityList($obj) {
    // return this.http.get(`${appConfig.apiUrl}/get-city?nationalities_id=${nationalities_id}`, { headers: this.headers });
    return this.http.post(`${appConfig.apiUrl}/get-city`, $obj, { headers: this.headers });
  }
  // assessor registration details
  assessorDetails($obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-registration`, $obj, { headers: this.headers });
  }
  // unique email check
  uniqueEmail(email) {
    return this.http.post(`${appConfig.apiUrl}/unique-email`, email, { headers: this.headers });
  }

  // Assessor Register
  assessorRegister($obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-registration-process`, $obj);
  }
  // country code list
  countryCodeList() {
    return this.http.get('https://restcountries.eu/rest/v2/all')
  }
  // company details
  companyDetails($obj) {
    return this.http.post(`${appConfig.apiUrl}/company-registration`, $obj, { headers: this.headers });
  }
  // company register
  companyRegister($obj) {
    return this.http.post(`${appConfig.apiUrl}/company-registration-process`, $obj);
  }
  sponsorsList(user_token) {
    return this.http.get(`${appConfig.apiUrl}/sponser-list?user_token=${user_token}`, { headers: this.headers })
  }
  getCategorySponsers(user_token, awrad_id) {
    return this.http.get(`${appConfig.apiUrl}/get-category-sponsers?user_token=${user_token}&award_id=${awrad_id}`, { headers: this.headers })
  }
  sponsersDetails(user_token, awrad_id) {
    return this.http.get(`${appConfig.apiUrl}/sponsers-registration?user_token=${user_token}&award_id=${awrad_id}`, { headers: this.headers })
  }
  // sponsers register
  sponsersRegister($obj) {
    return this.http.post(`${appConfig.apiUrl}/sponsers-registration-process`, $obj);
  }
  // get general category sponsers
  generalCategorySponsers(user_token) {
    return this.http.get(`${appConfig.apiUrl}/get-general-category-sponsers?user_token=${user_token}`, { headers: this.headers })
  }
  // all award list
  allAwardList(user_token) {
    return this.http.get(`${appConfig.apiUrl}/award-list?user_token=${user_token}`, { headers: this.headers })
  }

  getCategorySponsersType(user_token, awrad_id) {
    return this.http.get(`${appConfig.apiUrl}/get-category-sponsers?user_token=${user_token}&award_id=${awrad_id}`, { headers: this.headers })
  }

  // sponsers description
  sponserDescription(user_token) {
    return this.http.get(`${appConfig.apiUrl}/cms?slug=sponser_cms`)
  }
  // sponsers description
  accountType(user_token) {
    return this.http.get(`${appConfig.apiUrl}/get-user-types?user_token=${user_token}`, { headers: this.headers })
  }
  // login
  login($obj) {
    return this.http.post(`${appConfig.apiUrl}/login`, $obj, { headers: this.headers });
  }
  // forgot password
  forgotPassword($obj) {
    return this.http.post(`${appConfig.apiUrl}/forgot-password`, $obj);
  }
  // reset password
  resetPassword($obj) {
    return this.http.post(`${appConfig.apiUrl}/reset-password`, $obj);
  }
  // award list for user
  userAwardList(user_token) {
    return this.http.get(`${appConfig.apiUrl}/award-list-for-user?user_token=${user_token}`, { headers: this.headers })
  }
  // jury details
  juryDetails(user_token) {
    return this.http.get(`${appConfig.apiUrl}/jury-registration?user_token=${user_token}`, { headers: this.headers })
  }
  // jury registration process
  juryRegistration($obj) {
    return this.http.post(`${appConfig.apiUrl}/jury-registration-process`, $obj);
  }
  // banners
  getBannerData(user_token) {
    return this.http.get(`${appConfig.apiUrl}/banners?user_token=${user_token}`, { headers: this.headers })
  }
  // contact-us
  contactUs($obj) {
    return this.http.post(`${appConfig.apiUrl}/contact-us`, $obj);
  }
  // award-framework
  awardFramework(user_token) {
    return this.http.get(`${appConfig.apiUrl}/award-framework?user_token=${user_token}`);
  }
}

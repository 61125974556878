import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AwardOfficeDashboardComponent } from './award-office-dashboard.component';
import { AwardOfficeProfileModule } from './award-office-profile/award-office-profile.module';
import { AwardManagementModule } from './award-management/award-management.module';
import { MessagesService } from '../services/messages.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../services/excel.service';
import { DashboardService } from '../services/dashboard.service';
import { DataService } from '../services/data.service';
import { DatePipe } from '@angular/common'
import { OfficeArchiveModule } from './office-archive/office-archive.module';
import { OfficeCompanyDocumentsModule } from './office-company-documents/office-company-documents.module';
import { AwardOfficeMgmtModule } from './award-office-mgmt/award-office-mgmt.module';
import { OfficeCrteriaManagemnetModule } from './office-crteria-managemnet/office-crteria-managemnet.module';
import { AssessorManagementModule } from './assessor-management/assessor-management.module';
import { AssessorGroupModule } from './assessor-group/assessor-group.module';
import { CompanyManagementModule } from './company-management/company-management.module';
import { JuryGroupModule } from './jury-group/jury-group.module';
import { JuryManagementModule } from './jury-management/jury-management.module';
import { OfficeGroupMgmtModule } from './office-group-mgmt/office-group-mgmt.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/_helpers/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ChartsModule } from 'ng2-charts';
import { CalendarModule } from 'primeng/calendar';

// intro routing
export const routes: Routes = [
  { path: '', component: AwardOfficeDashboardComponent },
];

@NgModule({
  declarations: [AwardOfficeDashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    AwardOfficeProfileModule,
    AwardManagementModule,
    OfficeArchiveModule,
    OfficeCompanyDocumentsModule,
    AwardOfficeMgmtModule,
    OfficeCrteriaManagemnetModule,
    AssessorGroupModule,
    AssessorManagementModule,
    CompanyManagementModule,
    JuryGroupModule,
    JuryManagementModule,
    OfficeGroupMgmtModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgScrollbarModule,
    ChartsModule,
    CalendarModule
  ],
  providers: [DashboardService, MessagesService, DataService,ExcelService,DatePipe]
})
export class AwardOfficeDashboardModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { appConfig } from '../app.config';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  awardId: any;
  awardYearId: any;
  award_details: any = [];
  user_token: any;
  award_image_path: any;
  constructor(private router: Router,
    private authService: AuthService,
    private messagesService: MessagesService) {
    this.user_token = localStorage.getItem('user_token');
    this.awardId = localStorage.getItem('awardId');
    this.awardYearId = localStorage.getItem('award_year_Id');
  }

  ngOnInit() {
    // if (this.awardId == null) {
    //   this.router.navigate(['/award-list']);
    // }
    // else if (this.awardId != null) {
    this.getuserToken();
    window.scrollTo(0, 0);
    if (this.awardId != null) {
      this.AwardDetails();
    }
    // }
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }
  AwardDetails() {
    this.authService.awardDetails(this.user_token, this.awardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        console.log(res);
        console.log(this.awardYearId);
        this.award_details =  res.awardDetails.filter(x => x.year_id === Number(this.awardYearId));
        // this.award_details = res.awardDetails;
        this.award_image_path = res.award_image_path;
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      })
  }
}

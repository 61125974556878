import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { MessagesService } from '../services/messages.service';
import { DataService } from '../services/data.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExcelService } from '../services/excel.service';
import { saveAs as fileSaverSave } from 'file-saver'
import { Observable, Observer } from 'rxjs';
import { appConfig } from '../app.config';

@Component({
  selector: 'app-assessor-dashboard',
  templateUrl: './assessor-dashboard.component.html',
  styleUrls: ['./assessor-dashboard.component.scss']
})
export class AssessorDashboardComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  user_token: any;
  api_token: any;
  userDetails: any;
  leftbarselected: any;
  assessorCompanyDetails: any = [];
  closeResult: string;
  singleAssessorDetails: any;
  company_logo_path: any;
  licenseDownloadLink: any;
  companyDocumentDownloadLink: any;
  ActivityLogData: any = [];
  assessorDocsData: any = [];
  siteVistRequestForm: FormGroup;
  sendApprovalForm: FormGroup;
  uploadDocumentForm: FormGroup;
  awardSearchForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  processingText1: boolean = false;
  processingText2: boolean = false;
  processingText3: boolean = false;
  errMsg1: any;
  errMsg2: any;
  errMsg3: any;
  document_Show: any;
  documentErr: any;
  DocumentList: any = [];
  documentPath: any;
  currentYear: any;
  yearList: any = [];
  awardListArray: any = [];
  searchAwardList: any = [];
  award_name: any;
  award_search_year: any;
  filteredAwardArray: any = [];
  lazyLoad: boolean = false;
  showDocument: boolean = false;
  displayDocument: any;
  documentType: any;
  group_members_id: any = [];
  shareAllMsg: any;
  shareAllErrMsg: any;
  shareAllProcessing: boolean = false;
  base64Image: any;
  allShareData: any = [];
  selectedMembersArray: any = [];
  selectedMembersErr: any;
  selectedTeamMembersArray: any = [];
  memberLoad: boolean = false;
  getShareDataResponse: any;
  allShareStatusShow: boolean = false;
  archiveProcessing: boolean = false;
  juryUploadDocumentLink: any;
  companyGeneralDocumentDownloadLink: any;
  criteriaName: any;
  allEvidenceDocumentData: any = [];
  allSubmissionDocumentData: any = [];
  evidencedocumentDocs: any = [];
  submissiondocumentDocs: any = [];
  allEvidenceDocumentStatusShow: boolean = false;
  allSubmissionDocumentStatusShow: boolean = false;
  submissionDocumentDownloadPath: any;
  evidenceDocumentDownloadPath: any;
  search_award: any;

  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private authService: AuthService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private excelService: ExcelService) {
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/assesor-dashboard')
          this.leftbarselected = 'dashboard';
        this.dataService.leftPanelSelection('dashboard');
      }
    });
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }

    var d = new Date();
    this.currentYear = d.getFullYear();
    for (let i = this.currentYear; i > this.currentYear - 6; i--) {
      this.yearList.push(i);
    }
    this.siteVistRequestForm = this.formBuilder.group({
      date: ['', Validators.required],
      time: ['']
    });
    this.sendApprovalForm = this.formBuilder.group({
      comments: ['', Validators.required]
    });
    this.uploadDocumentForm = this.formBuilder.group({
      document_name: ['', Validators.required]
    });
    this.getDashboardDeails();
    this.userAwardlist();
    // this.getBase64ImageFromUrl().then((res: any)=>{
    //   console.log(res);

    // }).catch(error =>
    //   console.error(error)
    // );
    // this.getBase64Image('http://dev4.technoxponent.com/innovationaward/public/assets/upload/evidence/1585141756nh93Q8FvjP.png',function(data){
    //   console.log('ddd',data)
    // });
  }

  // convenience getter for easy access to form fields
  get f1() { return this.siteVistRequestForm.controls; }

  // convenience getter for easy access to form fields
  get f2() { return this.sendApprovalForm.controls; }

  // convenience getter for easy access to form fields
  get f3() { return this.uploadDocumentForm.controls; }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  getUserDetails() {
    this.dashboardService.getUserDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.userDetails = res;
        this.dataService.userData(res);
      }
    }, err => {
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  getDashboardDeails() {
    this.lazyLoad = true;
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.assessorCompanyDetails = res.assessor_company_details;
        // this.licenseDownloadLink = res.business_license_path;
        // this.companyDocumentDownloadLink = res.document_path;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        this.companyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';

        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';

        this.company_logo_path = res.logo_path;
        this.assessorCompanyDetails.map((item) => {
          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
        })
        console.log(this.assessorCompanyDetails);
      }
    }, err => {
      this.lazyLoad = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  // select compnay logo
  onSelectDocument(event) {
    if (event.target.files && event.target.files[0]) {
      this.document_Show = event.target.files[0];
      if (this.document_Show != '') {
        this.documentErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
      }
    }
  }
  open(content, assessorItem, className) {
    let modalClassName = className;
    console.log(modalClassName)
    this.criteriaName = '';
    this.submitted1 = false;
    this.submitted2 = false;
    this.submitted3 = false;
    this.siteVistRequestForm.get('date').reset();
    this.sendApprovalForm.get('comments').reset();
    this.uploadDocumentForm.get('document_name').reset();
    this.singleAssessorDetails = assessorItem;
    let award_Id = this.singleAssessorDetails.award_year_id;
    if (this.singleAssessorDetails.company_name.company_general_documents.length > 0) {
      this.singleAssessorDetails.company_name.company_general_documents = this.singleAssessorDetails.company_name.company_general_documents.filter(x => x.award_year_id === award_Id);
    }

    if (this.singleAssessorDetails.company_name.company_documents.length > 0) {
      this.singleAssessorDetails.company_name.company_documents = this.singleAssessorDetails.company_name.company_documents.filter(x => x.award_year_id === award_Id);
    }
    // console.log(this.singleAssessorDetails.company_name.company_documents);
    // console.log(this.singleAssessorDetails.company_name.company_general_documents);
    console.log(this.singleAssessorDetails);
    // company_documents
    this.assessorDocsData = assessorItem.assessor_docs;
    this.getShareStatus(this.singleAssessorDetails);
    this.document_Show = null;
    this.documentErr = '';
    this.showDocument = false;
    this.ActivityLogData = assessorItem.ActivityLog_datas;
    this.selectedTeamMembersArray = [];
    this.allShareData = [];
    this.allShareStatusShow = false;
    this.getUploadedDocument();
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder' , centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  formOneSubmit() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.siteVistRequestForm.invalid) {
      return;
    }
    let latest_date = this.datepipe.transform(this.siteVistRequestForm.value.date, 'yyyy-MM-dd');
    let latest_time = this.datepipe.transform(this.siteVistRequestForm.value.time, 'h:mm a');
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleAssessorDetails.award_id,
      'company_id': this.singleAssessorDetails.company_id,
      'group_id': this.singleAssessorDetails.group_id,
      'site_visit_req_date': latest_date,
      'visit_time': latest_time,
      'award_year': this.singleAssessorDetails.award_year_details.year
    }

    console.log(data)
    if (this.user_token != null) {
      this.errMsg1 = '';
      this.processingText1 = true;
      this.dashboardService.siteVisitrequest(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingText1 = false;
          this.siteVistRequestForm.reset();
          this.modalService.dismissAll();
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        }
      },
        err => {
          this.processingText1 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg1 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2000);
          }
        })
    }
    else if (this.user_token == null) {
      this.errMsg1 = 'User token is missing,Please go to the home page';
      setTimeout(() => {
        this.errMsg1 = '';
      }, 2000);
    }
  }
  formTwoSubmit() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.sendApprovalForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleAssessorDetails.award_id,
      'company_id': this.singleAssessorDetails.company_id,
      'Comments': this.sendApprovalForm.value.comments,
      'award_year': this.singleAssessorDetails.award_year_details.year,
      'group_id': this.singleAssessorDetails.group_id
    }
    if (this.user_token != null) {
      this.errMsg2 = '';
      this.processingText2 = true;
      this.dashboardService.sendApproval(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingText2 = false;
          this.sendApprovalForm.reset();
          this.modalService.dismissAll();
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg2 = res.msg;
          setTimeout(() => {
            this.errMsg2 = '';
          }, 2500);
        }
      },
        err => {
          this.processingText2 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg2 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg2 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
    else if (this.user_token == null) {
      this.errMsg2 = 'User token is missing,Please go to the home page';
      setTimeout(() => {
        this.errMsg2 = '';
      }, 2000);
    }
  }
  formThreeSubmit() {
    this.submitted3 = true;
    if (this.document_Show == null) {
      this.documentErr = 'Document is required';
    }
    else {
      this.documentErr = '';
    }
    // stop here if form is invalid
    if (this.uploadDocumentForm.invalid) {
      return;
    }
    if (this.document_Show != null) {
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('award_id', this.singleAssessorDetails.award_id),
        formData.append('company_id', this.singleAssessorDetails.company_id),
        formData.append('group_id', this.singleAssessorDetails.group_id),
        formData.append('document_name', this.uploadDocumentForm.value.document_name),
        formData.append('document', this.document_Show)
      if (this.user_token != null) {
        this.errMsg3 = '';
        this.processingText3 = true;
        this.dashboardService.uploadDocument(this.api_token, formData).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.processingText3 = false;
            this.DocumentList = res.document_list;
            this.documentPath = res.path;
            var scrollModal = document.querySelector(".modal.show");
            scrollModal.scrollTop = 400;
            this.submitted3 = false;
            this.document_Show = null;
            this.documentErr = '';
            this.uploadDocumentForm.reset();
            // this.modalService.dismissAll();
            this.errMsg3 = res.msg;
            setTimeout(() => {
              this.errMsg3 = '';
            }, 2000);
          }

        },
          err => {
            this.processingText3 = false;
            if (err.error.message == "Unauthenticated.") {
              this.modalService.dismissAll();
            }
            if (err.error.message != "Unauthenticated.") {
              this.errMsg3 = 'Something went wrong.';
              setTimeout(() => {
                this.errMsg3 = '';
              }, 2000);
            }
          }
        )
      }
      else if (this.user_token == null) {
        this.errMsg3 = 'User token is missing,Please go to the home page';
        setTimeout(() => {
          this.errMsg3 = '';
        }, 2000);
      }
    }
  }
  getUploadedDocument() {
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleAssessorDetails.award_id,
      'company_id': this.singleAssessorDetails.company_id,
      'group_id': this.singleAssessorDetails.group_id,
    }
    this.dashboardService.getDocument(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        // this.documentPath = res.path;
        this.documentPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';
        this.DocumentList = res.document_list;
      }
    },
      err => {
        if (err.error.message == "Unauthenticated.") {
          this.modalService.dismissAll();
        }
        if (err.error.message != "Unauthenticated.") {
          this.messagesService.showError('Something went wrong.');
        }
      })
  }
  deleteUploadedDocument(item) {
    let data = {
      'user_token': this.user_token,
      'award_id': item.award_id,
      'company_id': item.company_id,
      'group_id': item.group_id,
      'document_id': item.id
    }
    if (this.user_token != null) {
      this.dashboardService.deleteDocument(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          var scrollModal = document.querySelector(".modal.show");
          scrollModal.scrollTop = 400;
          this.documentPath = res.path;
          this.DocumentList = res.document_list;
          this.errMsg2 = 'Document Deleted successfully.';
          setTimeout(() => {
            this.errMsg2 = '';
          }, 2000);
        }
      },
        err => {
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg2 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg2 = '';
            }, 2000);
          }
        })
    }
    else if (this.user_token == null) {
      this.errMsg2 = 'User token is missing,Please go to the home page';
      setTimeout(() => {
        this.errMsg2 = '';
      }, 2000);
    }
  }
  userAwardlist() {
    this.authService.userAwardList(this.user_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardListArray = res.award_data;
        // this.awardListArray = res.award_data.filter(x => x.status == "Y");
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  onChange(value) {
    this.award_search_year = value;
  }
  onChangeAward(value) {
    this.search_award = value;
    console.log(this.search_award)
  }
  awardSearchSubmit() {
    // this.awardListArray.filter(x => x.title === this.award_name).map((item) => {
    //   if (!this.searchAwardList.some((item) => item.title == this.award_name)) {
    //     this.searchAwardList.push(item);
    //     this.filteredAwardArray.push(item.id);
    //   }
    // })
    if(this.search_award != ''){
      this.search_award = Number(this.search_award);
      this.filteredAwardArray = [];
      this.filteredAwardArray.push(this.search_award);
    }
    if(this.search_award == ''){
      this.filteredAwardArray = [];
    }
    this.award_name = '';
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'searchAward': this.filteredAwardArray.length > 0 ? this.filteredAwardArray : [],
      // 'searchAward': this.search_award ? this.search_award : '',
      'searchYear': this.award_search_year ? this.award_search_year : '',
    }
    this.dashboardService.awardFilteration(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.assessorCompanyDetails = res.assessor_company_details;
        this.assessorCompanyDetails.map((item) => {
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
          })
        })
        console.log(this.assessorCompanyDetails);
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.awardSearchSubmit();
    }
  }
  deleteAward(index) {
    this.searchAwardList.splice(index, 1);
    this.filteredAwardArray.splice(index, 1);
  }
  goToAssesmentPoint(assessorItem) {
    this.router.navigate(['assesor-assignment', assessorItem.company_id, assessorItem.award_id, assessorItem.group_id,assessorItem.award_year_id]);
  }
  goToFinalAssesmentPoint(assessorItem) {
    this.router.navigate(['final-assesment', assessorItem.company_id, assessorItem.award_id, assessorItem.group_id, assessorItem.award_year_id]);
  }
  openDialog(data) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteUploadedDocument(data);
        // const a = document.createElement('a');
        // a.click();
        // a.remove();
      }
    });
  }
  viewDocument(documentItem) {
    this.documentType = documentItem.split('.').pop();

    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png')) {
      this.showDocument = true;
      this.displayDocument = this.documentPath + documentItem;
    }
    else if (this.documentType == 'pdf') {
      this.showDocument = true;
      this.displayDocument = this.documentPath + documentItem;
      this.displayDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentPath + documentItem);
    }
    else if ((this.documentType == 'docx') && (this.documentType == 'ppt') && (this.documentType == 'pptx')) {
      this.showDocument = false;
      this.displayDocument = this.documentPath + documentItem;
      const downloadLink = document.createElement("a");
      const fileName = documentItem;

      downloadLink.href = this.displayDocument;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  newDocumentUpload() {
    this.showDocument = false;
  }
  exportAsXLSX(singleAssessorDetails): void {
    let data = [{
      'Name': singleAssessorDetails.company_name.company_name,
      'Type': singleAssessorDetails.company_name.industry.trans_data,
      'Year in UAE': singleAssessorDetails.company_name.year_in_uae.title,
      'Email': singleAssessorDetails.company_name.user.email,
      'Address': singleAssessorDetails.company_name.head_office_addr,
      'Mobile': singleAssessorDetails.company_name.mob_phone,
      'PB NO': singleAssessorDetails.company_name.po_box,
      'Number of Branch': singleAssessorDetails.company_name.branch.title,
      'Location': singleAssessorDetails.company_name.user.location.trans_data,
      'Website': singleAssessorDetails.company_name.website,
      'CEO/GM': singleAssessorDetails.company_name.ceo_prefix + singleAssessorDetails.company_name.ceo_firstname + singleAssessorDetails.company_name.ceo_lastname,
      'Representative': singleAssessorDetails.company_name.representative_prefix +
        singleAssessorDetails.company_name.representative_firstname +
        singleAssessorDetails.company_name.representative_lastname,

      'Registration Date and Time': this.datepipe.transform(singleAssessorDetails.company_name.user.created_at, 'dd MMM yy h:mm a')
    }]

    this.excelService.exportAsExcelFile(data, singleAssessorDetails.company_name.company_name);
  }
  getShareStatus(singleAssessorDetails) {
    this.group_members_id = [];
    singleAssessorDetails.group_members.map((item) => {
      this.group_members_id.push(item.assessor_id);
    })
    let data = {
      user_token: this.user_token,
      award_id: singleAssessorDetails.award_id,
      company_id: singleAssessorDetails.company_id,
      group_id: singleAssessorDetails.group_id,
      team_member_ids: this.group_members_id,
    }
    this.memberLoad = true;
    this.dashboardService.getShareStatus(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.getShareDataResponse = res;
        if (res.allMemData.filter(x => x.shareAllStatus == 'Y').length == res.allMemData.length) {
          this.allShareStatusShow = true
        }
        else {
          this.allShareStatusShow = false;
        }
        res.allMemData.map((item) => {
          if (this.selectedTeamMembersArray.indexOf(item.member_id) == -1) {
            if (item.shareAllStatus == 'Y') {
              this.selectedTeamMembersArray.push({
                "member_id": item.member_id,
                "status": "Y"
              });
              this.allShareData.push(item.member_id);
            }
            if (item.shareAllStatus == 'N') {
              this.selectedTeamMembersArray.push({
                "member_id": item.member_id,
                "status": "N"
              });
            }
          }
        })
        this.memberLoad = false;
        console.log(this.selectedTeamMembersArray)
        console.log(this.allShareData)
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.memberLoad = false;
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.memberLoad = false;
        this.shareAllMsg = '';
      }
    })
  }
  shareAll() {
    this.group_members_id = [];
    console.log(this.singleAssessorDetails);
    this.singleAssessorDetails.group_members.map((item) => {
      this.group_members_id.push(item.assessor_id);
    })
    let data = {
      user_token: this.user_token,
      team_member_ids: this.selectedTeamMembersArray,
      award_id: this.singleAssessorDetails.award_id,
      company_id: this.singleAssessorDetails.company_id,
      group_id: this.singleAssessorDetails.group_id,
      share_status: 'Y'
    }
    console.log(this.group_members_id);
    this.shareAllProcessing = true;
    this.dashboardService.shareAllScorePpoint(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.shareAllProcessing = false;
        this.shareAllMsg = 'Shared with all group member successfully';
        setTimeout(() => {
          this.shareAllMsg = '';
          this.modalService.dismissAll();
        }, 2500);
      }
    }, err => {
      this.shareAllProcessing = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.shareAllMsg = '';
        this.shareAllErrMsg = '';
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.shareAllMsg = '';
        this.shareAllErrMsg = '';
        this.shareAllErrMsg = 'Something went wrong.';
      }
    })
  }
  memberValue($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.selectedTeamMembersArray.indexOf(Item.assessor_id) == -1) {
        this.selectedTeamMembersArray.map((memberItem) => {
          if (memberItem.member_id == Item.assessor_id) {
            memberItem.status = 'Y';
          }
        })
      }
      if (this.selectedTeamMembersArray.filter(x => x.status == 'Y').length == this.selectedTeamMembersArray.length) {
        this.allShareStatusShow = true;
      }
      console.log(this.allShareStatusShow)
      if (this.selectedTeamMembersArray.length > 0) {
        this.selectedMembersErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.selectedTeamMembersArray.map((memberItem) => {
        if (memberItem.member_id == Item.assessor_id) {
          memberItem.status = 'N'
          this.allShareStatusShow = false;
        }
      })
      // this.allShareData.push(item.member_id);
      // this.allShareData.splice(this.allShareData.indexOf($event.srcElement.value), 1);
      if (this.selectedTeamMembersArray.length == 0) {
        this.selectedMembersErr = 'Please select one member';
      }
    }
    console.log(this.selectedTeamMembersArray)
  }
  AllmemberValue($event) {
    this.selectedTeamMembersArray = [];
    this.allShareData = [];
    if ($event.srcElement.checked == true) {
      this.getShareDataResponse.allMemData.map((item) => {
        if (this.selectedTeamMembersArray.indexOf(item.member_id) == -1) {
          this.selectedTeamMembersArray.push({
            "member_id": item.member_id,
            "status": "Y"
          });
          this.allShareData.push(item.member_id);
        }
      });
    }
    else if ($event.srcElement.checked == false) {
      this.allShareData = [];
      this.getShareDataResponse.allMemData.map((item) => {
        // if (this.selectedTeamMembersArray.indexOf(item.member_id) == -1) {
        this.selectedTeamMembersArray.push({
          "member_id": item.member_id,
          "status": "N"
        });
        // }
      });
    }
    console.log(this.allShareData);
    console.log(this.selectedTeamMembersArray);

    // this.propertyIdList = [];
    // for (var i = 0; i < this.propertyList.length; i++) {
    //   this.propertyList[i]['selected'] = this.selectedAll;
    //   this.propertyIdList.push(this.propertyList[i]['id']);
    // }
    // console.log(this.selectedAll);
    // if (!this.selectedAll) {
    //   this.propertyIdList = [];
    //   this.disableDelete = true;
    //   console.log("disableDelete", this.propertyList);
    //   console.log("propertyIdList", this.propertyIdList);
    // } else {
    //   this.disableDelete = false;
    //   console.log("disableDelete", this.propertyList);
    //   console.log("propertyIdList", this.propertyIdList);

    // }
  }
  openDialogArchive(assessorItem) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to archive this assessment?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.makeArchive(assessorItem);
      }
    });
  }
  makeArchive(assessorItem) {
    let data = {
      group_id: assessorItem.group_id,
      award_id: assessorItem.award_id,
      company_id: assessorItem.company_id,
      award_year_id: assessorItem.award_year_id
    }
    this.archiveProcessing = true;
    this.dashboardService.createArchive(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.dashboardDeails();
        // this.assessorCompanyDetails.map((item) => {
        //   if (item.company_id == assessorItem.company_id) {
        //     item.is_archive = 'Y';
        //   }
        // })
        this.archiveProcessing = false;
        this.messagesService.showSuccess(res.msg);
        setTimeout(() => {
          this.messagesService.clear();
          this.modalService.dismissAll();
        }, 2500);
      }
    }, err => {
      this.archiveProcessing = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  dashboardDeails() {
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.assessorCompanyDetails = res.assessor_company_details;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        this.companyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';

        this.company_logo_path = res.logo_path;
        this.assessorCompanyDetails.map((item) => {
          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
        })
        console.log(this.assessorCompanyDetails);
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }

  singleEvidenceDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.singleAssessorDetails.company_name.company_documents.map((docItem) => {
        if (this.allEvidenceDocumentData.indexOf(docItem.id) == -1) {
          if (docItem.id == Item.id) {
            this.allEvidenceDocumentData.push(docItem.id);
            docItem.checked = true;
            this.evidencedocumentDocs.push('assets/upload/category_documents/' + docItem.doc_name);
          }
        }
      })
      let documentPath = this.evidencedocumentDocs.join('--');
      this.evidenceDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      if (this.singleAssessorDetails.company_name.company_documents.length === this.evidencedocumentDocs.length) {
        this.allEvidenceDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allEvidenceDocumentData.findIndex(itm => itm === Item.id);

      if (removeIndex !== -1) {
        this.allEvidenceDocumentData.splice(removeIndex, 1);
        this.evidencedocumentDocs.splice(removeIndex, 1);
      }
      if (this.evidencedocumentDocs.length > 0) {
        let documentPath = this.evidencedocumentDocs.join('--');
        this.submissionDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      }
      if (this.singleAssessorDetails.company_name.company_documents.length !== this.evidencedocumentDocs.length) {
        this.allEvidenceDocumentStatusShow = false;
      }
    }
  }

  SelectAllEvidenceDocumentValue($event) {
    this.allEvidenceDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.evidencedocumentDocs = [];
      this.singleAssessorDetails.company_name.company_documents.map((item) => {
        if (this.allEvidenceDocumentData.indexOf(item.id) == -1) {
          this.allEvidenceDocumentData.push(item.id);
          item.checked = true;
          this.evidencedocumentDocs.push('assets/upload/category_documents/' + item.doc_name);
        }
      });
      this.allEvidenceDocumentStatusShow = true;
      let documentPath = this.evidencedocumentDocs.join('--');
      this.evidenceDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
    }
    else if ($event.srcElement.checked == false) {
      this.allEvidenceDocumentData = [];
      this.evidencedocumentDocs = [];
      this.singleAssessorDetails.company_name.company_documents.map((item) => {
        item.checked = false;
      });
      this.allEvidenceDocumentStatusShow = false;
    }
    console.log(this.evidencedocumentDocs)
    console.log(this.allEvidenceDocumentData)
  }

  singleSubmissionDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.singleAssessorDetails.company_name.company_general_documents.map((docItem) => {
        if (this.allSubmissionDocumentData.indexOf(docItem.id) == -1) {
          if (docItem.id == Item.id) {
            this.allSubmissionDocumentData.push(docItem.id);
            docItem.checked = true;
            this.submissiondocumentDocs.push('assets/upload/comp_general_doc/' + docItem.document_name);
          }
        }
      })
      let documentPath = this.submissiondocumentDocs.join('--');
      this.submissionDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      if (this.singleAssessorDetails.company_name.company_general_documents.length === this.submissiondocumentDocs.length) {
        this.allSubmissionDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allSubmissionDocumentData.findIndex(itm => itm === Item.id);

      if (removeIndex !== -1) {
        this.allSubmissionDocumentData.splice(removeIndex, 1);
        this.submissiondocumentDocs.splice(removeIndex, 1);
      }
      if (this.submissiondocumentDocs.length > 0) {
        let documentPath = this.submissiondocumentDocs.join('--');
        this.submissionDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      }
      if (this.singleAssessorDetails.company_name.company_general_documents.length !== this.submissiondocumentDocs.length) {
        this.allSubmissionDocumentStatusShow = false;
      }
    }
  }

  SelectAllSubmissionDocumentValue($event) {
    this.allSubmissionDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.submissiondocumentDocs = [];
      this.singleAssessorDetails.company_name.company_general_documents.map((item) => {
        if (this.allSubmissionDocumentData.indexOf(item.id) == -1) {
          this.allSubmissionDocumentData.push(item.id);
          item.checked = true;
          this.submissiondocumentDocs.push('assets/upload/comp_general_doc/' + item.document_name);
        }
      });
      this.allSubmissionDocumentStatusShow = true;
      let documentPath = this.submissiondocumentDocs.join('--');
      this.submissionDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
    }
    else if ($event.srcElement.checked == false) {
      this.allSubmissionDocumentData = [];
      this.submissiondocumentDocs = [];
      this.singleAssessorDetails.company_name.company_general_documents.map((item) => {
        item.checked = false;
      });
      this.allSubmissionDocumentStatusShow = false;
    }
    console.log(this.submissiondocumentDocs)
    console.log(this.allSubmissionDocumentData)
  }
  // downloadLicense(file) {
  //   this.documentType = file.split('.').pop();
  //   let filename = file;
  //   let format = this.documentType;
  //   console.log
  //   this.toDataURL(this.licenseDownloadLink + file, function (dataUrl) {
  //     // console.log(dataUrl);
  //     // this.dataURLtoFile(dataUrl);
  //     //  filename = 'haha.png',
  //     //  format = 'png'
  //     const arr = dataUrl.split(',');
  //     const mime = arr[0].match(/:(.*?);/)[1];
  //     const bstr = atob(arr[1]);
  //     let n = bstr.length;
  //     const u8arr = new Uint8Array(n);
  //     while (n--) {
  //       u8arr[n] = bstr.charCodeAt(n);
  //     }
  //     fileSaverSave(new File([u8arr], filename, { type: format }));
  //   })
  // }
  // async getBase64ImageFromUrl() {
  //   let imageUrl = 'http://dev4.technoxponent.com/innovationaward/public/assets/upload/evidence/1585141756nh93Q8FvjP.png';
  //    var res = await fetch(imageUrl);
  //    var blob = await res.blob();

  //    return new Promise((resolve, reject) => {
  //      var reader = new FileReader();
  //      reader.addEventListener("load", function () {
  //        console.log(reader);
  //        resolve(reader.result);
  //      }, false);

  //      reader.onerror = () => {
  //        return reject(this);
  //      };
  //      reader.readAsDataURL(blob);
  //    })
  //  }
  //  toDataURL(url, callback) {
  //    var xhr = new XMLHttpRequest();
  //    xhr.onload = function () {
  //      var reader = new FileReader();
  //      reader.onloadend = function () {
  //        callback(reader.result);
  //      }
  //      reader.readAsDataURL(xhr.response);
  //    };
  //    xhr.open('GET', url);
  //    xhr.responseType = 'blob';
  //    xhr.send();
  //  }
  // dataURLtoFile(dataurl) {
  //   let filename = 'haha.png';
  //   let format = 'png'
  //   const arr = dataurl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   fileSaverSave(new File([u8arr], filename, { type: format }));
  // }
}

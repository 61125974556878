import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JuryManagementComponent } from './jury-management.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesService } from '../../services/messages.service';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: JuryManagementComponent },
];
@NgModule({
  declarations: [JuryManagementComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule
  ],providers:[MessagesService]
})
export class JuryManagementModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { appConfig } from '../app.config';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  user_token: any;
  forgotPasswordForm: FormGroup;
  submitted = false;
  processingText: boolean = false;
  recapChaResponse: any;
  capchaErr: any;
  recaptcha: any;
  lazyLoad: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private messagesService: MessagesService) { }

  ngOnInit() {
    this.getuserToken();
    this.user_token = localStorage.getItem('user_token');
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.lazyLoad = true;
    setTimeout(() => {
      this.recaptcha = (window as any).grecaptcha;
    console.log(this.recaptcha)    
    this.lazyLoad = false;
    }, 1500);

    //Site-key => 6LepCtwUAAAAAOM7u-7sJWlvw8MTybv1_AmZ4gHB
    //Secret-key => 6LepCtwUAAAAAGma9gqQGK0QgYp9bcHdS0h7O-J0
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  showResponse(response) {
    console.log(response);
    this.recapChaResponse = response.response;
    console.log(this.recapChaResponse);
    if(this.recapChaResponse != undefined){
      this.capchaErr ='';
    }
    //call to a backend to verify against recaptcha with private key
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotPasswordForm.controls; }

  forgotPasswordFormSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      email: this.forgotPasswordForm.value.email
    }
    if (this.recapChaResponse != undefined) {
      this.processingText = true;
      this.authService.forgotPassword(data).subscribe((res: any) => {
        console.log(res)
        if (res.has_error == 0) {
          this.processingText = false;
          console.log(res.user[0].api_token);
          localStorage.setItem('api_token', res.user[0].api_token);
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          this.messagesService.showError('Invalid email');
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }, err => {
        this.processingText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 2000);
      })
    }
    else if (this.recapChaResponse == undefined) {
      this.capchaErr = 'Please validate the capcha';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/_helpers/_guards/custom.validator';
import { JudgeService } from '../../services/judge.service';
import { MessagesService } from '../../services/messages.service';
import { AuthService } from '../../services/auth.service';
import { appConfig } from '../../app.config';


@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  show_personal_information: boolean = false;
  show_professional_information: boolean = false;
  show_change_password: boolean = false;
  leftbarselected: any;
  user_token: any;
  api_token: any;
  userDetails: any;
  profileImagePath: any;
  businessLicensePath: any;
  updatePasswordForm: FormGroup;
  profileUpdateOneForm: FormGroup;
  profileUpdateTwoForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  upperCaseNewErrMsg: any;
  NumberNewErrMsg: any;
  business_license: any;
  company_logo: any;
  display_profile_photo: any;
  processingPasswordText: boolean;
  awardDetails: any =[];
  allCountryList: any = [];
  no_of_staff_List: any = [];
  years_in_uae_list: any = [];
  branchesList: any = [];
  NationalitiesList: any = [];
  sectorsList: any = [];
  IndustriesList: any = [];
  NamePrefixsList: any = [];
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  webSitePatternErr: boolean;
  BusinessLicense_Show: null;
  CompanyLogo_Show: null;
  processingOneText: boolean;
  processingTwoText: boolean;
  award_id: any;
  errorMessageValue: any;
  lazyLoad: boolean = false;
  loadingCount: number = 0;
  awardCountry: any = [];
  fileTypeText: any;
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService,
    private authService: AuthService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/company-profile')
          this.leftbarselected = 'profile';
        this.dataService.leftPanelSelection('company_profile');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-profile`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.updatePasswordForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });
    this.profileUpdateOneForm = this.formBuilder.group({
      company_name: ['', [Validators.required]],
      industry: ['', [Validators.required]],
      sector: ['', [Validators.required]],
      no_of_staff: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      years_in_uae: ['', [Validators.required]],
      work_email: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      contact_Country_code: ['', [Validators.required]],
      website: ['', [Validators.required]],
    });
    this.profileUpdateTwoForm = this.formBuilder.group({
      ceo_prefix: ['', [Validators.required]],
      ceo_first_name: ['', [Validators.required]],
      ceo_last_name: ['', [Validators.required]],
      authorized_representative_prefix: ['', [Validators.required]],
      authorized_representative: ['', [Validators.required]],
      representative_job_title: ['', [Validators.required]],
      mobile_Country_code: ['', [Validators.required]],
      mobile_number: ['', [Validators.required]],
      email: ['', [Validators.required]],
      company_profile_description: ['', [Validators.required]],
      head_office_address: ['', [Validators.required]],
    });
    this.getUserDetails();
    this.callingCodes();
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
  }

  get f() { return this.updatePasswordForm.controls; }

  get f1() { return this.profileUpdateOneForm.controls; }
  get f2() { return this.profileUpdateTwoForm.controls; }

  openshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  closeshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  openshow_professional_information() {
    this.show_professional_information = !this.show_professional_information;
  }
  closeshow_professional_information() {
    this.show_professional_information = !this.show_professional_information;
  }
  openshow_change_password() {
    this.show_change_password = !this.show_change_password;
  }
  closeshow_change_password() {
    this.show_change_password = !this.show_change_password;
    this.submitted1 = false;
    this.updatePasswordForm.reset();
    this.upperCaseNewErrMsg = '';
    this.NumberNewErrMsg = '';
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  onChangeContactNumberCode(event) {
    if (event == '') {
      // this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      // this.mobileCountryCodeeErr = '';
    }
  }
  getUserDetails() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.getUserDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      this.lazyLoad = false;
      this.loadingCount = 1;
      if (this.loadingCount == 1) {
        this.dataService.userdetails(res);
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.userDetails.name = res.user_details.company_name;
        this.userDetails.company.company_logo = res.user_details.company_logo;
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
      }
      this.userDetails = res.user_details;
      this.award_id = res.award_id;
      this.awardDetails = res.awardUser;
      this.allCountryList = res.all_country;
      this.no_of_staff_List = res.no_of_staff;
      this.years_in_uae_list = res.years_in_uae;
      this.branchesList = res.branches;
      this.NationalitiesList = res.Nationalities;
      this.sectorsList = res.sectors;
      this.IndustriesList = res.Industries;
      this.NamePrefixsList = res.NamePrefixs;
      this.profileImagePath = res.imagePath;

      this.profileUpdateOneForm.patchValue({
        company_name: this.userDetails.company_name,
        industry: this.userDetails.industry_id,
        sector: this.userDetails.sector_id,
        no_of_staff: this.userDetails.no_of_staff,
        branch: this.userDetails.no_of_branch,
        country: this.userDetails.user.country_id,
        city: this.userDetails.user.city,
        postal_code: this.userDetails.po_box,
        years_in_uae: this.userDetails.years_in_uae,
        work_email: this.userDetails.Work_E_mail,
        contact_number: this.userDetails.user.phone,
        contact_Country_code: this.userDetails.country_code,
        website: this.userDetails.website,
      });
      this.profileUpdateTwoForm.patchValue({
        ceo_prefix: this.userDetails.ceo_prefix,
        ceo_first_name: this.userDetails.ceo_firstname,
        ceo_last_name: this.userDetails.ceo_lastname,
        authorized_representative_prefix: this.userDetails.representative_prefix,
        authorized_representative: this.userDetails.representative_firstname,
        representative_job_title: this.userDetails.job_title,
        mobile_Country_code: this.userDetails.mob_country_code,
        mobile_number: this.userDetails.mob_phone,
        email: this.userDetails.user.email,
        company_profile_description: this.userDetails.description,
        head_office_address: this.userDetails.head_office_addr
      });
      // this.awardDetails.award_country = this.allCountryList.find(x => x.nationalities_id == this.awardDetails.award_details.country_id).trans_data;
      console.log(this.awardDetails)
      this.awardDetails.map((item)=>{
        this.allCountryList.map((countryitem)=>{
          if(item.award_details.country_id == countryitem.nationalities_id){
            // console.log(countryitem)
            this.awardCountry.push(countryitem)
          }
        })
      })
      
      this.awardCountry = this.awardCountry.filter((thing, index, self) => index === self.findIndex((t) => ( t.nationalities_id === thing.nationalities_id)) )
      console.log(this.awardCountry);

      this.business_license = this.userDetails.business_license;
      this.company_logo = this.userDetails.company_logo;
      this.display_profile_photo = this.userDetails.company_logo != null ? this.profileImagePath + this.userDetails.company_logo : 'assets/images/default-user.png';
      // this.businessLicensePath = res.business_license_path;
      this.businessLicensePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
      this.userDetails.industry_name = this.IndustriesList.find((x) => x.industries_id === this.userDetails.industry_id).trans_data;
      this.userDetails.sector_name = this.sectorsList.find((x) => x.sector_id === this.userDetails.sector_id).trans_data;

      this.userDetails.no_of_staff_name = this.no_of_staff_List.find((x) => x.id === this.userDetails.no_of_staff).title;
      this.userDetails.branch_name = this.branchesList.find((x) => x.id === this.userDetails.no_of_branch).title;
      this.userDetails.years_in_uae_name = this.years_in_uae_list.find((x) => x.id === this.userDetails.years_in_uae).title;
      this.userDetails.country_name = this.allCountryList.find((x) => x.nationalities_id === this.userDetails.user.country_id).trans_data;

      this.userDetails.ceo_prefix_name = this.NamePrefixsList.find((x) => x.NamePrefix_id === this.userDetails.ceo_prefix).Prefix_name;
      this.userDetails.representative_prefix_name = this.NamePrefixsList.find((x) => x.NamePrefix_id === this.userDetails.representative_prefix).Prefix_name;
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.')
      })
  }
  onSelectBusinessLicense(event) {
    if (event.target.files && event.target.files[0]) {
      this.BusinessLicense_Show = event.target.files[0];
      // console.log(this.profile_CV)
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  onSelectCompanyLogo(event) {
    if (event.target.files && event.target.files[0]) {
      this.CompanyLogo_Show = event.target.files[0];
      console.log(this.CompanyLogo_Show);
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
        // console.log(this.display_profile_photo)
      }
    }
  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onKeyNewPassword(event: any) { // without type info
    let upperCaseCharacters = /[A-Z]+/;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/;
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (event.target.value != '') {
      this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';
      this.NumberNewErrMsg = 'New Password must contain at least one number';
      if ((upperCaseCharacters.test(event.target.value) === false)) {
        this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';

      }
      if ((upperCaseCharacters.test(event.target.value) === true)) {
        this.upperCaseNewErrMsg = '';
      }
      if ((numberCharacters.test(event.target.value) === false)) {
        this.NumberNewErrMsg = 'New Password must contain at least one number';
      }
      if ((numberCharacters.test(event.target.value) === true)) {
        this.NumberNewErrMsg = '';
      }
    }
    else if (event.target.value == '') {
      this.NumberNewErrMsg = '';
      this.upperCaseNewErrMsg = '';
    }
  }
  updatepassword() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      current_password: this.updatePasswordForm.value.current_password,
      new_password: this.updatePasswordForm.value.new_password,
    }
    if ((this.NumberNewErrMsg == '') && (this.upperCaseNewErrMsg == '')) {
      this.processingPasswordText = true;
      this.judgeService.updatePaasword(this.api_token, data).subscribe((res: any) => {
        console.log(res)
        if (res.has_error == 0) {
          this.processingPasswordText = false;
          this.messagesService.showSuccess(res.msg);
          this.submitted1 = false;
          console.log(this.updatePasswordForm.value);
          this.show_change_password = false;
          this.updatePasswordForm.reset();
          this.upperCaseNewErrMsg = '';
          this.NumberNewErrMsg = '';
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingPasswordText = false;
        }
      },
        err => {
          this.processingPasswordText = false;
          this.messagesService.showError('Something went wrong.');
        });
    }
  }
  updateprofileOne() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.profileUpdateOneForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.award_id),
      formData.append('company_name', this.profileUpdateOneForm.value.company_name),
      formData.append('industry_id', this.profileUpdateOneForm.value.industry),
      formData.append('sector_id', this.profileUpdateOneForm.value.sector),
      formData.append('city', this.profileUpdateOneForm.value.city),
      formData.append('country_id', this.profileUpdateOneForm.value.country),
      formData.append('no_of_staff', this.profileUpdateOneForm.value.no_of_staff),
      formData.append('po_box', this.profileUpdateOneForm.value.postal_code),
      formData.append('years_in_uae', this.profileUpdateOneForm.value.years_in_uae),
      formData.append('website', this.profileUpdateOneForm.value.website),
      formData.append('no_of_branch', this.profileUpdateOneForm.value.branch),
      formData.append('phone', this.profileUpdateOneForm.value.contact_number),
      formData.append('country_code', this.profileUpdateOneForm.value.contact_Country_code),
      formData.append('Work_E_mail', this.profileUpdateOneForm.value.work_email)
    formData.append('email', this.profileUpdateTwoForm.value.email)
    if (this.CompanyLogo_Show != null) {
      formData.append('company_logo', this.CompanyLogo_Show)
    }
    if (this.CompanyLogo_Show == null) {
      formData.append('company_logo', this.company_logo)
    }
    this.processingOneText = true;
    this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.processingOneText = false;
        this.getUserDetails();
        this.show_personal_information = false;
        this.CompanyLogo_Show = null;
        this.messagesService.showSuccess('Profile Updated successfully.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
      else if (res.has_error == 1) {
        this.processingOneText = false;
        for (let [key, value] of Object.entries(res.error)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingOneText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
  }
  updateprofileTwo() {
    this.submitted3 = true;
    // stop here if form is invalid
    if (this.profileUpdateTwoForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.award_id),
      formData.append('ceo_prefix', this.profileUpdateTwoForm.value.ceo_prefix),
      formData.append('ceo_firstname', this.profileUpdateTwoForm.value.ceo_first_name),
      formData.append('ceo_lastname', this.profileUpdateTwoForm.value.ceo_last_name),
      formData.append('representative_prefix', this.profileUpdateTwoForm.value.authorized_representative_prefix),
      formData.append('representative_firstname', this.profileUpdateTwoForm.value.authorized_representative),
      formData.append('job_title', this.profileUpdateTwoForm.value.representative_job_title),
      formData.append('mob_country_code', this.profileUpdateTwoForm.value.mobile_Country_code),
      formData.append('mob_phone', this.profileUpdateTwoForm.value.mobile_number),
      formData.append('email', this.profileUpdateTwoForm.value.email)
      if (this.BusinessLicense_Show != null) {
        formData.append('business_license', this.BusinessLicense_Show)
      }
      if (this.BusinessLicense_Show == null) {
        formData.append('business_license', this.business_license)
      }
      formData.append('description', this.profileUpdateTwoForm.value.company_profile_description),
      formData.append('head_office_addr', this.profileUpdateTwoForm.value.head_office_address)
      this.processingTwoText = true;
      this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.processingTwoText = false;
        this.show_professional_information = false;
        this.BusinessLicense_Show = null;
        this.getUserDetails();
        this.messagesService.showSuccess('Profile Updated successfully.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
      else if (res.has_error == 1) {
        this.processingTwoText = false;
        for (let [key, value] of Object.entries(res.error)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingTwoText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
  }
}

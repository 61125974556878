import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { DataService } from '../../services/data.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-assignment-point',
  templateUrl: './assignment-point.component.html',
  styleUrls: ['./assignment-point.component.scss']
})
export class AssignmentPointComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  userDetails: any;
  api_token: any;
  user_token: any;
  criteriaList: any = [];
  subcriteriaList: any = [];
  groupMembers: any = [];
  subcriteriaTitle: any;
  assignmentDetails: any;
  breadcrumbSelected: any;
  IdParams: any;
  mainCriteraNumber: number = 1;
  CriteraNumber: any;
  strength: any;
  comment: any;
  selectPointType: any;
  selectPoint: any;
  criteraPoint: any;
  selectIndex: number = 0;
  critera_id: any;
  ScorePoint: any;
  awardMeasurmentsList: any = [];
  pointMeasurmentsList: any = [];
  IsGroupMember: boolean = true;
  share_status: any;
  IsMyAssesmentData: boolean = false;
  AssesmentSelectedIndex: any;
  lazyLoad: boolean = false;
  showOthersData: boolean = false;
  processingtext: boolean = false;
  closeResult: string;
  criteriatype_name_Array: any = [];
  thirdLebelStrength: any = [];
  thirdLebelComment: any = [];
  thirdLebelPointType: any = [];
  thirdLebelAllPoint: any = [];
  thirdLebelAllAssesorComment: any = [];
  processingThirdLebeltext: boolean = false;
  errorMessageValue: any;
  criteraNumber: any;
  assessor_comment: any;
  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog) {

      this.activatedRoute.params.subscribe(params => {
        this.IdParams = params;
      });
      console.log(this.IdParams)
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url.match('/assesor-assignment'))
          this.breadcrumbSelected = 'Add';
        this.dataService.leftPanelSelection('dashboard');
      }
    });
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-assignment/`+this.IdParams.id+'/'+this.IdParams.id2+'/'+this.IdParams.id3 + '/' + this.IdParams.id4]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getScoreBoarddetails();
    this.addEditor();
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  // get score details
  getScoreBoarddetails() {
    let data = {
      user_token: this.user_token,
      'company_id': this.IdParams.id,
      'award_id': this.IdParams.id2,
      'group_id': this.IdParams.id3,
      'award_year_id': this.IdParams.id4
    }

    console.log(data)
    this.lazyLoad = true;
    this.dashboardService.getAwardScore(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.assignmentDetails = res;
        this.criteriaList = res.criteria;
        this.subcriteriaList = res.get_all_criteria;
        this.groupMembers = res.group_members;
        this.criteriatype_name_Array = res.criteriatype;
        this.subcriteriaTitle = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].title;
        this.ScorePoint = this.subcriteriaList[0];
        this.critera_id = this.subcriteriaList[0].id;
        this.criteraPoint = this.subcriteriaList[0].point;
        // for (let i = 0; i < this.criteriaList.length; i++) {
        //   this.criteriaList[i].each_point = this.criteriaList[i].each_point.toFixed(2);
        // }
        for (let i = 0; i < this.subcriteriaList.length; i++) {
          if (this.subcriteriaList[i].hasOwnProperty('score_data')) {
            if (this.subcriteriaList[i].score_data.length > 0) {
              // console.log('score_data > length')
              this.subcriteriaList[i]['total_point'] = this.subcriteriaList[i].score_data[0].point_percent;
            }
          }
          else if (!this.subcriteriaList[i].hasOwnProperty('score_data')) {
            this.subcriteriaList[i]['total_point'] = 0;
            for (let j = 0; j < this.criteriatype_name_Array.length; j++) {
              if (this.subcriteriaList[i].hasOwnProperty(j + 1)) {
                if (parseInt(this.subcriteriaList[i][j + 1].score_data[0].criteria_id) == this.subcriteriaList[i].id) {
                  this.subcriteriaList[i]['total_point'] += parseFloat(this.subcriteriaList[i][j + 1].score_data[0].point_percent)
                  // this.subcriteriaList[i]['total_point'] = Number(this.subcriteriaList[i]['total_point'])
                  // this.subcriteriaList[i]['total_point'] = Math.round((this.subcriteriaList[i]['total_point'] + Number.EPSILON) * 100) / 100
                  // console.log('score_data {}')
                }
              }
              if (!this.subcriteriaList[i].hasOwnProperty(j + 1)) {
                this.subcriteriaList[i]['total_point'] = 0
                // console.log('score_data < length')
              }
            }
          }
        }

        if (this.subcriteriaList[0].hasOwnProperty('score_data')) {
          this.showOthersData = true;
          this.IsMyAssesmentData = true;
          this.selectPointType = this.subcriteriaList[0].score_data[0].point_type;
          this.strength = this.subcriteriaList[0].score_data[0].strength;
          this.comment = this.subcriteriaList[0].score_data[0].comment;
          this.selectPoint = this.subcriteriaList[0].score_data[0].allopoint;
          this.assessor_comment = this.subcriteriaList[0].score_data[0].assessor_comment;
          if (this.subcriteriaList[0].score_data[0].allopoint == '0') {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('0')
          }
          else if ((this.subcriteriaList[0].score_data[0].allopoint == '5') || (this.subcriteriaList[0].score_data[0].allopoint == '10') || (this.subcriteriaList[0].score_data[0].allopoint == '15') || (this.subcriteriaList[0].score_data[0].allopoint == '20') || (this.subcriteriaList[0].score_data[0].allopoint == '25')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '30') || (this.subcriteriaList[0].score_data[0].allopoint == '35') || (this.subcriteriaList[0].score_data[0].allopoint == '40') || (this.subcriteriaList[0].score_data[0].allopoint == '45') || (this.subcriteriaList[0].score_data[0].allopoint == '50')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '55') || (this.subcriteriaList[0].score_data[0].allopoint == '60') || (this.subcriteriaList[0].score_data[0].allopoint == '65') || (this.subcriteriaList[0].score_data[0].allopoint == '70') || (this.subcriteriaList[0].score_data[0].allopoint == '75')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '80') || (this.subcriteriaList[0].score_data[0].allopoint == '85') || (this.subcriteriaList[0].score_data[0].allopoint == '90') || (this.subcriteriaList[0].score_data[0].allopoint == '95') || (this.subcriteriaList[0].score_data[0].allopoint == '100')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
          }
        }
        else if (!this.subcriteriaList[0].hasOwnProperty('score_data')) {
          this.showOthersData = false;
          this.IsMyAssesmentData = true;
          this.selectPointType = '';
          this.strength = '';
          this.comment = '';
          this.selectPoint = '';
          this.assessor_comment = '';
        }
        for (let i = 0; i < this.criteriaList.length; i++) {
          if (i == 0) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            var key = Object.keys(this.assignmentDetails.award_measurments)[i];
            this.awardMeasurmentsList = this.assignmentDetails.award_measurments[key];
            this.pointMeasurmentsList.push('0')
          }
          if (i != 0) {
            if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
              this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            }
            else {
              this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
            }
          }
        }
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + this.subcriteriaList[0].hire_order;

        if (this.subcriteriaList[0].shared_data_list.length > 0) {
          this.groupMembers.map((x) => {
            x['view_status'] = '';
            if (x && x.view_status) {
              x.view_status = '';
            }
          })
          this.subcriteriaList[0].shared_data_list.map((item) => {
            this.groupMembers.map((x) => {
              if (item.from_user_id == x.assessor_id) {
                x.view_status = item.status;
              }
            })
          })
        }
        if (this.subcriteriaList[0].shared_data_list.length == 0) {
          this.groupMembers.map((x) => {
            x['view_status'] = '';
            if (x && x.view_status) {
              x.view_status = '';
            }
          })
        }
        // console.log(this.groupMembers)
        this.groupMembers.map((item) => {
          let group_members_key = Object.keys(item);
          var x = group_members_key.filter(x => Number(x) === this.critera_id).toString();
          // console.log(x)
          // console.log(item[x][0]);
          if (x && item[x][0]) {
            if (item[x][0].status == "Y") {
              item.accessors_name['share_button_name'] = 'Unshare';
            }
            else if (item[x][0].status == "N") {
              item.accessors_name['share_button_name'] = 'Share';
            }
          }
          else {
            item.accessors_name['share_button_name'] = 'Share';
          }
        })
        // console.log(this.groupMembers);
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      })
  }

  lineActive(subcriteria, criteraNumber, index) {
    this.criteraNumber = criteraNumber;
    if (this.criteraNumber != 3) {
      this.AssesmentSelectedIndex = null;
      this.IsMyAssesmentData = true;
      this.IsGroupMember = true;
      this.selectIndex = index;
      this.ScorePoint = subcriteria;
      console.log(this.ScorePoint)
      if (this.ScorePoint.shared_data_list.length > 0) {
        this.groupMembers.map((x) => {
          x['view_status'] = '';
          if (x && x.view_status) {
            x.view_status = '';
          }
        })
        this.ScorePoint.shared_data_list.map((item) => {
          this.groupMembers.map((x) => {
            if (item.from_user_id == x.assessor_id) {
              x.view_status = item.status;
            }
          })
        })
      }
      if (this.ScorePoint.shared_data_list.length == 0) {
        this.groupMembers.map((x) => {
          x['view_status'] = '';
          if (x && x.view_status) {
            x.view_status = '';
          }
        })
      }
      console.log(this.groupMembers);
      this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      this.subcriteriaTitle = this.ScorePoint.title;
      var key = Object.keys(this.assignmentDetails.award_measurments)[criteraNumber - 1];
      this.selectPointType = "";
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.assignmentDetails.award_measurments[key];
      this.selectPoint = "";
      this.pointMeasurmentsList = [];
      this.pointMeasurmentsList.push('0')
      this.critera_id = this.ScorePoint.id;
      this.criteraPoint = this.ScorePoint.point;
      this.groupMembers.map((item) => {
        let group_members_key = Object.keys(item);
        var x = group_members_key.filter(x => Number(x) === this.critera_id).toString();
        // console.log(x)
        // console.log(item[x][0]);
        if (x && item[x][0]) {
          if (item[x][0].status == "Y") {
            item.accessors_name['share_button_name'] = 'Unshare';
          }
          else if (item[x][0].status == "N") {
            item.accessors_name['share_button_name'] = 'Share';
          }
        }
        else {
          item.accessors_name['share_button_name'] = 'Share';
        }
      });

      if (this.ScorePoint.hasOwnProperty('score_data')) {
        this.showOthersData = true;
        this.selectPointType = this.ScorePoint.score_data[0].point_type;
        this.strength = this.ScorePoint.score_data[0].strength;
        this.comment = this.ScorePoint.score_data[0].comment;
        this.selectPoint = this.ScorePoint.score_data[0].allopoint;
        this.assessor_comment = this.ScorePoint.score_data[0].assessor_comment;
        if (this.ScorePoint.score_data[0].allopoint == '0') {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('0')
        }
        else if ((this.ScorePoint.score_data[0].allopoint == '5') || (this.ScorePoint.score_data[0].allopoint == '10') || (this.ScorePoint.score_data[0].allopoint == '15') || (this.ScorePoint.score_data[0].allopoint == '20') || (this.ScorePoint.score_data[0].allopoint == '25')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '30') || (this.ScorePoint.score_data[0].allopoint == '35') || (this.ScorePoint.score_data[0].allopoint == '40') || (this.ScorePoint.score_data[0].allopoint == '45') || (this.ScorePoint.score_data[0].allopoint == '50')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '55') || (this.ScorePoint.score_data[0].allopoint == '60') || (this.ScorePoint.score_data[0].allopoint == '65') || (this.ScorePoint.score_data[0].allopoint == '70') || (this.ScorePoint.score_data[0].allopoint == '75')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '80') || (this.ScorePoint.score_data[0].allopoint == '85') || (this.ScorePoint.score_data[0].allopoint == '90') || (this.ScorePoint.score_data[0].allopoint == '95') || (this.ScorePoint.score_data[0].allopoint == '100')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
        }
      }
      else if (!this.ScorePoint.hasOwnProperty('score_data')) {
        this.showOthersData = false;
        this.selectPointType = '';
        this.strength = '';
        this.comment = '';
        this.selectPoint = '';
        this.assessor_comment = '';
      }
    }
    if (this.criteraNumber == 3) {
      this.AssesmentSelectedIndex = null;
      this.IsMyAssesmentData = true;
      this.IsGroupMember = true;
      this.selectIndex = index;
      this.ScorePoint = subcriteria;
      console.log(this.ScorePoint);
      this.critera_id = this.ScorePoint.id;
      this.criteraPoint = this.ScorePoint.point;

      if (this.ScorePoint.shared_data_list.length > 0) {
        this.groupMembers.map((x) => {
          x['view_status'] = '';
          if (x && x.view_status) {
            x.view_status = '';
          }
        })
        this.ScorePoint.shared_data_list.map((item) => {
          this.groupMembers.map((x) => {
            if (item.from_user_id == x.assessor_id) {
              x.view_status = item.status;
            }
          })
        })
      }
      if (this.ScorePoint.shared_data_list.length == 0) {
        this.groupMembers.map((x) => {
          x['view_status'] = '';
          if (x && x.view_status) {
            x.view_status = '';
          }
        })
      }
      this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      this.subcriteriaTitle = this.ScorePoint.title;
      this.criteraPoint = this.ScorePoint.point;
      var key = Object.keys(this.assignmentDetails.award_measurments)[criteraNumber - 1];
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.assignmentDetails.award_measurments[key];
      this.groupMembers.map((item) => {
        let group_members_key = Object.keys(item);
        var x = group_members_key.filter(x => Number(x) === this.critera_id).toString();
        // console.log(x)
        // console.log(item[x][0]);
        if (x && item[x][0]) {
          if (item[x][0].status == "Y") {
            item.accessors_name['share_button_name'] = 'Unshare';
          }
          else if (item[x][0].status == "N") {
            item.accessors_name['share_button_name'] = 'Share';
          }
        }
        else {
          item.accessors_name['share_button_name'] = 'Share';
        }
      });
      for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

        if (!this.ScorePoint.hasOwnProperty('score_data')) {
          if (this.ScorePoint.hasOwnProperty(i)) {
            if (this.criteriatype_name_Array[i - 1].criteria_type_id == this.ScorePoint[i].score_data[0].criteria_type_id) {
              this.criteriatype_name_Array[i - 1]['ScorePoint'] = this.ScorePoint[i].score_data[0];

              this.showOthersData = true;
              if (this.ScorePoint[i].score_data[0].allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '5') || (this.ScorePoint[i].score_data[0].allopoint == '10') || (this.ScorePoint[i].score_data[0].allopoint == '15') || (this.ScorePoint[i].score_data[0].allopoint == '20') || (this.ScorePoint[i].score_data[0].allopoint == '25')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '30') || (this.ScorePoint[i].score_data[0].allopoint == '35') || (this.ScorePoint[i].score_data[0].allopoint == '40') || (this.ScorePoint[i].score_data[0].allopoint == '45') || (this.ScorePoint[i].score_data[0].allopoint == '50')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '55') || (this.ScorePoint[i].score_data[0].allopoint == '60') || (this.ScorePoint[i].score_data[0].allopoint == '65') || (this.ScorePoint[i].score_data[0].allopoint == '70') || (this.ScorePoint[i].score_data[0].allopoint == '75')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '80') || (this.ScorePoint[i].score_data[0].allopoint == '85') || (this.ScorePoint[i].score_data[0].allopoint == '90') || (this.ScorePoint[i].score_data[0].allopoint == '95') || (this.ScorePoint[i].score_data[0].allopoint == '100')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
              // console.log(this.criteriatype_name_Array[i - 1]['ScorePoint']);
            }
          }
          if (!this.ScorePoint.hasOwnProperty(i)) {
            this.showOthersData = false;
            let blankdata = {
              'allopoint': '0',
              'point_type': '0',
              'strength': '',
              'comment': '',
              'assessor_comment': ''
            }
            if (this.criteriatype_name_Array[i - 1] != undefined) {
              this.criteriatype_name_Array[i - 1]['ScorePoint'] = blankdata;
              if (blankdata.allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
            }
          }
        }
      }
      // console.log(this.criteriatype_name_Array);
    }
  }
  onChange($event) {
    console.log($event)
    this.pointMeasurmentsList = [];
    if ($event == '0') {
      this.pointMeasurmentsList.push('0')
    }
    else if ($event == '25') {
      this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
    }
    else if ($event == '50') {
      this.pointMeasurmentsList.push('30', '35', '40', '45', '50')
    }
    else if ($event == '75') {
      this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
    }
    else if ($event == '100') {
      this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
    }
  }
  onChangeThirdLebel($event, index1) {
    this.criteriatype_name_Array.forEach((element, index) => {
      if (index == index1) {
        console.log($event)
        this.pointMeasurmentsList = [];
        if ($event == '0') {
          this.pointMeasurmentsList.push('0');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '25') {
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '50') {
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '75') {
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '100') {
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
      }
    });
  }
  scoreBoarddetails() {
    let data = {
      user_token: this.user_token,
      'company_id': this.IdParams.id,
      'award_id': this.IdParams.id2,
      'group_id': this.IdParams.id3,
      'award_year_id': this.IdParams.id4
    }
    console.log(data)
    this.dashboardService.getAwardScore(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.assignmentDetails = res;
        this.groupMembers = res.group_members;
        this.criteriaList = this.assignmentDetails.criteria;
        this.mainCriteraNumber = 1;
        for (let i = 0; i < this.criteriaList.length; i++) {
          if (i == 0) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            var key = Object.keys(this.assignmentDetails.award_measurments)[i];
            this.awardMeasurmentsList = this.assignmentDetails.award_measurments[key];
            // this.pointMeasurmentsList.push('0')
          }
          if (i != 0) {
            if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
              this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            }
            else {
              this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
            }
          }
        }
        if (this.ScorePoint.shared_data_list.length > 0) {
          this.groupMembers.map((x) => {
            x['view_status'] = '';
            if (x && x.view_status) {
              x.view_status = '';
            }
          })
          this.ScorePoint.shared_data_list.map((item) => {
            this.groupMembers.map((x) => {
              if (item.from_user_id == x.assessor_id) {
                x.view_status = item.status;
              }
            })
          })
        }
        if (this.ScorePoint.shared_data_list.length == 0) {
          this.groupMembers.map((x) => {
            x['view_status'] = '';
            if (x && x.view_status) {
              x.view_status = '';
            }
          })
        }

        this.groupMembers.map((item) => {
          let group_members_key = Object.keys(item);
          var x = group_members_key.filter(x => Number(x) === this.critera_id).toString();
          // console.log(x)
          // console.log(item[x][0]);
          if (x && item[x][0]) {
            if (item[x][0].status == "Y") {
              item.accessors_name['share_button_name'] = 'Unshare';
            }
            else if (item[x][0].status == "N") {
              item.accessors_name['share_button_name'] = 'Share';
            }
          }
          else {
            item.accessors_name['share_button_name'] = 'Share';
          }
        });
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  // add score point
  addScorepoint() {
    if (this.criteraNumber != 3) {
      if ((this.assessor_comment != '') || (this.assessor_comment != null) && (this.strength != '') && (this.comment != '') 
      && (this.selectPointType != '') && (this.selectPoint != '')) {
        let data = {
          'user_token': this.user_token,
          'api_token': this.api_token,
          'award_id': this.IdParams.id2,
          'criteria_id': this.critera_id,
          'company_id': this.IdParams.id,
          'group_id': this.IdParams.id3,
          'point_type': this.selectPointType,
          'allopoint': this.selectPoint ? this.selectPoint : '',
          'criteria_point': this.criteraPoint,
          'strength': this.strength ? this.strength : '',
          'comment': this.comment ? this.comment : '',
          'assessor_comment': this.assessor_comment ? this.assessor_comment : '',
          'award_year_id': this.IdParams.id4
        }
        console.log(data)
        this.processingtext = true;
        this.dashboardService.addScorePoint(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.processingtext = false;
            this.showOthersData = true;
            console.log(this.ScorePoint);
            this.scoreBoarddetails();
            this.messagesService.showSuccess('Score Data Added successfully.');
            this.subcriteriaList.map((item) => {
              if (item.id == this.critera_id) {
                item['score_data'] = [];
                item.score_data.push(res.score_point);
                item['total_point'] = item.score_data[0].point_percent;
                // item['total_point'] = Number(item['total_point'])
                // item['total_point'] = Math.round((item['total_point'] + Number.EPSILON) * 100) / 100
              }
            })
          }
          if (res.has_error == 1) {
            this.processingtext = false;
            for (let [key, value] of Object.entries(res.msg)) {
              this.messagesService.showError(value);
              setTimeout(() => {
                this.messagesService.clear();
              }, 1500);
            }
          }
        },
          err => {
            this.processingtext = false;
            this.messagesService.showError('Something went wrong.');
            console.log(err);
          })
      }
      else if ((this.assessor_comment == '') ||  (this.strength == '') || (this.comment == '') || (this.selectPointType == '') || (this.selectPoint == '')) {
        this.messagesService.showError('Please Fillup All the Credentials');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1500);
      }
    }
    else if (this.criteraNumber == 3) {
      this.addThirdLebelScorePoint();
    }
  }
  // add third score point
  addThirdLebelScorePoint() {
    this.thirdLebelStrength = [];
    this.thirdLebelComment = [];
    this.thirdLebelAllPoint = [],
      this.thirdLebelPointType = [],
      this.thirdLebelAllAssesorComment = []
    for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

      if (this.criteriatype_name_Array[i - 1] != undefined) {

        this.thirdLebelStrength.push(this.criteriatype_name_Array[i - 1].ScorePoint.strength ? this.criteriatype_name_Array[i - 1].ScorePoint.strength : '');

        this.thirdLebelComment.push(this.criteriatype_name_Array[i - 1].ScorePoint.comment ? this.criteriatype_name_Array[i - 1].ScorePoint.comment : '');

        this.thirdLebelPointType.push(this.criteriatype_name_Array[i - 1].ScorePoint.point_type ? this.criteriatype_name_Array[i - 1].ScorePoint.point_type : '');

        this.thirdLebelAllPoint.push(this.criteriatype_name_Array[i - 1].ScorePoint.allopoint ? this.criteriatype_name_Array[i - 1].ScorePoint.allopoint : '');

        this.thirdLebelAllAssesorComment.push(this.criteriatype_name_Array[i - 1].ScorePoint.assessor_comment ? this.criteriatype_name_Array[i - 1].ScorePoint.assessor_comment : '');
        
      }
    }

    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'award_id': this.IdParams.id2,
      'criteria_id': this.critera_id,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'point_type': this.thirdLebelPointType,
      'allopoint': this.thirdLebelAllPoint,
      'criteria_point': this.criteraPoint,
      'strength': this.thirdLebelStrength,
      'comment': this.thirdLebelComment,
      'assessor_comment': this.thirdLebelAllAssesorComment,
      'award_year_id': this.IdParams.id4
    }
    console.log(data);
    this.processingThirdLebeltext = true;
    this.dashboardService.addThirdLebelScorePoint(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.processingThirdLebeltext = false;
        this.showOthersData = true;
        this.scoreBoarddetails();
        this.messagesService.showSuccess('Score Data Updated successfully.');
        this.subcriteriaList.map((item) => {
          if (item.id == this.critera_id) {
            item['total_point'] = 0;
            for (let i = 1; i <= this.criteriatype_name_Array.length; i++) {
              if (!item.hasOwnProperty('score_data')) {
                if (item.hasOwnProperty(i)) {
                  item[i].score_data[0] = res.score_point[i - 1];
                  item['total_point'] += parseFloat(item[i].score_data[0].point_percent);
                  // item['total_point'] = Number(item['total_point'])
                  // item['total_point'] = Math.round((item['total_point'] + Number.EPSILON) * 100) / 100
                }
                if (!item.hasOwnProperty(i)) {
                  item[i] = {
                    'score_data': []
                  }
                  item[i].score_data[0] = res.score_point[i - 1];
                  item['total_point'] += parseFloat(item[i].score_data[0].point_percent);
                  // item['total_point'] = Number(item['total_point'])
                  // item['total_point'] = Math.round((item['total_point'] + Number.EPSILON) * 100) / 100
                }
              }

            }
          }
        })
        console.log(this.subcriteriaList)
      }
      if (res.has_error == 1) {
        this.processingThirdLebeltext = false;
        for (let [key, value] of Object.entries(res.msg)) {
          // console.log(`${key}: ${value}`);
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
      }
    },
      err => {
        this.processingThirdLebeltext = false;
        this.messagesService.showError('Something went wrong.');
        console.log(err);
      })
  }
  // view share dData
  viewSharedData(member, index) {
    if (this.showOthersData == true) {
      if ((this.AssesmentSelectedIndex == null) || (this.AssesmentSelectedIndex == undefined)) {
        this.IsMyAssesmentData = false;
      }
      this.AssesmentSelectedIndex = index;
      var key = Object.keys(member);
      var x = key.filter(x => Number(x) === this.critera_id).toString();
      // console.log(member);
      // console.log(member[x]);
      // console.log(member[x].details_data[0]);
      let data = {
        'user_token': this.user_token,
        'api_token': this.api_token,
        'criteria_id': this.critera_id,
        'company_id': this.IdParams.id,
        'user_id': member.accessors_name.id
      }
      this.dashboardService.showSharedData(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          if (res.shareData != null) {
            if (res.shareData.status == 'N') {
              this.messagesService.showError('No shared Data Found');
            }
            if (this.criteraNumber != 3) {
              if (res.shareData.status == 'Y') {
                this.IsGroupMember = false;
                window.scroll({
                  top: 600,
                  left: 0,
                  behavior: 'smooth'
                });
                // console.log(member);
                // console.log(member[x]);
                // console.log(member[x].details_data[0]);
                this.selectPointType = member[x].details_data[0].point_type;
                this.selectPoint = member[x].details_data[0].allopoint;
                this.strength = member[x].details_data[0].strength;
                this.comment = member[x].details_data[0].comment;
                this.assessor_comment = member[x].details_data[0].assessor_comment;

                if (member[x].details_data[0].allopoint == '0') {
                  this.pointMeasurmentsList = [];
                  this.pointMeasurmentsList.push('0')
                }
                else if ((member[x].details_data[0].allopoint == '5') || (member[x].details_data[0].allopoint == '10') || (member[x].details_data[0].allopoint == '15') || (member[x].details_data[0].allopoint == '20') || (member[x].details_data[0].allopoint == '25')) {
                  this.pointMeasurmentsList = [];
                  this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
                }

                else if ((member[x].details_data[0].allopoint == '30') || (member[x].details_data[0].allopoint == '35') || (member[x].details_data[0].allopoint == '40') || (member[x].details_data[0].allopoint == '45') || (member[x].details_data[0].allopoint == '50')) {
                  this.pointMeasurmentsList = [];
                  this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
                }

                else if ((member[x].details_data[0].allopoint == '55') || (member[x].details_data[0].allopoint == '60') || (member[x].details_data[0].allopoint == '65') || (member[x].details_data[0].allopoint == '70') || (member[x].details_data[0].allopoint == '75')) {
                  this.pointMeasurmentsList = [];
                  this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
                }

                else if ((member[x].details_data[0].allopoint == '80') || (member[x].details_data[0].allopoint == '85') || (member[x].details_data[0].allopoint == '90') || (member[x].details_data[0].allopoint == '95') || (member[x].details_data[0].allopoint == '100')) {
                  this.pointMeasurmentsList = [];
                  this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
                }

              }
            }
            if (this.criteraNumber == 3) {
              if (res.shareData.status == 'Y') {
                this.IsGroupMember = false;
                window.scroll({
                  top: 600,
                  left: 0,
                  behavior: 'smooth'
                })
                for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

                  if (!this.ScorePoint.hasOwnProperty('score_data')) {
                    if (this.ScorePoint.hasOwnProperty(i)) {
                      if (this.criteriatype_name_Array[i - 1].criteria_type_id == this.ScorePoint[i].score_data[0].criteria_type_id) {
                        // console.log(this.assignmentDetails.group_members_mul);
                        let x = this.critera_id.toString();
                        this.criteriatype_name_Array[i - 1]['ScorePoint'] = this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i];


                        if (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '0') {
                          this.pointMeasurmentsList = [];
                          this.pointMeasurmentsList.push('0');
                          this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
                        }

                        else if ((this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '5') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '10') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '15') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '20') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '25')) {
                          this.pointMeasurmentsList = [];
                          this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
                          this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
                        }

                        else if ((this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '30') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '35') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '40') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '45') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '50')) {
                          this.pointMeasurmentsList = [];
                          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
                          this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
                        }

                        else if ((this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '55') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '60') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '65') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '70') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '75')) {
                          this.pointMeasurmentsList = [];
                          this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
                          this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
                        }

                        else if ((this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '80') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '85') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '90') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '95') || (this.assignmentDetails.group_members_mul[this.AssesmentSelectedIndex][x].details_data[i].allopoint == '100')) {
                          this.pointMeasurmentsList = [];
                          this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
                          this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
                        }
                      }
                    }
                    // if (!this.ScorePoint.hasOwnProperty(i)) {
                    //   this.showOthersData = false;
                    //   let blankdata = {
                    //     'allopoint': '0',
                    //     'point_type': '0',
                    //     'strength': '',
                    //     'comment': '',
                    //   }
                    //   if (this.criteriatype_name_Array[i - 1] != undefined) {
                    //     this.criteriatype_name_Array[i - 1]['ScorePoint'] = blankdata;
                    //     if (blankdata.allopoint == '0') {
                    //       this.pointMeasurmentsList = [];
                    //       this.pointMeasurmentsList.push('0');
                    //       this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
                    //     }
                    //   }
                    // }
                  }
                }
                console.log(this.criteriatype_name_Array)
              }
            }
          }
          else if (res.shareData == null) {
            this.messagesService.showError('No shared Data Found');
            setTimeout(() => {
              this.messagesService.clear();
            }, 1500);
          }
        }
        if (res.has_error == 1) {
          if (res.shareData == null) {
            this.messagesService.showError('No shared Data Found');
            setTimeout(() => {
              this.messagesService.clear();
            }, 1500);
          }
        }
      },
        err => {
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 1500);
        })
    }
    else if (this.showOthersData == false) {
      this.messagesService.showError('Please Fillup your own data First');
      setTimeout(() => {
        this.messagesService.clear();
      }, 1500);
    }
  }
  // view my assesment
  viewMyassesment() {
    window.scroll({
      top: 600,
      left: 0,
      behavior: 'smooth'
    });
    this.IsGroupMember = true;
    this.IsMyAssesmentData = true;
    this.AssesmentSelectedIndex = null;
    // console.log(this.ScorePoint)
    if (this.criteraNumber != 3) {
      if ((this.ScorePoint == null) || (this.ScorePoint == undefined)) {
        if (this.subcriteriaList[0].hasOwnProperty('score_data')) {
          this.selectPointType = this.subcriteriaList[0].score_data[0].point_type;
          this.strength = this.subcriteriaList[0].score_data[0].strength;
          this.comment = this.subcriteriaList[0].score_data[0].comment;
          this.selectPoint = this.subcriteriaList[0].score_data[0].allopoint;
          this.assessor_comment = this.subcriteriaList[0].score_data[0].assessor_comment;
          if (this.subcriteriaList[0].score_data[0].allopoint == '0') {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('0')
          }
          else if ((this.subcriteriaList[0].score_data[0].allopoint == '5') || (this.subcriteriaList[0].score_data[0].allopoint == '10') || (this.subcriteriaList[0].score_data[0].allopoint == '15') || (this.subcriteriaList[0].score_data[0].allopoint == '20') || (this.subcriteriaList[0].score_data[0].allopoint == '25')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '30') || (this.subcriteriaList[0].score_data[0].allopoint == '35') || (this.subcriteriaList[0].score_data[0].allopoint == '40') || (this.subcriteriaList[0].score_data[0].allopoint == '45') || (this.subcriteriaList[0].score_data[0].allopoint == '50')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '55') || (this.subcriteriaList[0].score_data[0].allopoint == '60') || (this.subcriteriaList[0].score_data[0].allopoint == '65') || (this.subcriteriaList[0].score_data[0].allopoint == '70') || (this.subcriteriaList[0].score_data[0].allopoint == '75')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '80') || (this.subcriteriaList[0].score_data[0].allopoint == '85') || (this.subcriteriaList[0].score_data[0].allopoint == '90') || (this.subcriteriaList[0].score_data[0].allopoint == '95') || (this.subcriteriaList[0].score_data[0].allopoint == '100')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
          }
        }
        else if (!this.subcriteriaList[0].hasOwnProperty('score_data')) {
          this.selectPointType = '';
          this.strength = '';
          this.comment = '';
          this.selectPoint = '';
          this.assessor_comment = '';
        }
      }
      if ((this.ScorePoint != null) || (this.ScorePoint != undefined)) {

        if (this.ScorePoint.hasOwnProperty('score_data')) {
          this.selectPointType = this.ScorePoint.score_data[0].point_type;
          this.strength = this.ScorePoint.score_data[0].strength;
          this.comment = this.ScorePoint.score_data[0].comment;
          this.selectPoint = this.ScorePoint.score_data[0].allopoint;
          this.assessor_comment = this.ScorePoint.score_data[0].assessor_comment
          if (this.ScorePoint.score_data[0].allopoint == '0') {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('0')
          }
          else if ((this.ScorePoint.score_data[0].allopoint == '5') || (this.ScorePoint.score_data[0].allopoint == '10') || (this.ScorePoint.score_data[0].allopoint == '15') || (this.ScorePoint.score_data[0].allopoint == '20') || (this.ScorePoint.score_data[0].allopoint == '25')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
          }

          else if ((this.ScorePoint.score_data[0].allopoint == '30') || (this.ScorePoint.score_data[0].allopoint == '35') || (this.ScorePoint.score_data[0].allopoint == '40') || (this.ScorePoint.score_data[0].allopoint == '45') || (this.ScorePoint.score_data[0].allopoint == '50')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          }

          else if ((this.ScorePoint.score_data[0].allopoint == '55') || (this.ScorePoint.score_data[0].allopoint == '60') || (this.ScorePoint.score_data[0].allopoint == '65') || (this.ScorePoint.score_data[0].allopoint == '70') || (this.ScorePoint.score_data[0].allopoint == '75')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
          }

          else if ((this.ScorePoint.score_data[0].allopoint == '80') || (this.ScorePoint.score_data[0].allopoint == '85') || (this.ScorePoint.score_data[0].allopoint == '90') || (this.ScorePoint.score_data[0].allopoint == '95') || (this.ScorePoint.score_data[0].allopoint == '100')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
          }
        }
        else if (!this.ScorePoint.hasOwnProperty('score_data')) {
          this.selectPointType = '';
          this.strength = '';
          this.comment = '';
          this.selectPoint = '';
          this.assessor_comment = '';
        }
      }
    }
    else if (this.criteraNumber == 3) {
      for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

        if (!this.ScorePoint.hasOwnProperty('score_data')) {
          if (this.ScorePoint.hasOwnProperty(i)) {
            if (this.criteriatype_name_Array[i - 1].criteria_type_id == this.ScorePoint[i].score_data[0].criteria_type_id) {
              this.criteriatype_name_Array[i - 1]['ScorePoint'] = this.ScorePoint[i].score_data[0];

              this.showOthersData = true;
              if (this.ScorePoint[i].score_data[0].allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '5') || (this.ScorePoint[i].score_data[0].allopoint == '10') || (this.ScorePoint[i].score_data[0].allopoint == '15') || (this.ScorePoint[i].score_data[0].allopoint == '20') || (this.ScorePoint[i].score_data[0].allopoint == '25')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '30') || (this.ScorePoint[i].score_data[0].allopoint == '35') || (this.ScorePoint[i].score_data[0].allopoint == '40') || (this.ScorePoint[i].score_data[0].allopoint == '45') || (this.ScorePoint[i].score_data[0].allopoint == '50')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '55') || (this.ScorePoint[i].score_data[0].allopoint == '60') || (this.ScorePoint[i].score_data[0].allopoint == '65') || (this.ScorePoint[i].score_data[0].allopoint == '70') || (this.ScorePoint[i].score_data[0].allopoint == '75')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '80') || (this.ScorePoint[i].score_data[0].allopoint == '85') || (this.ScorePoint[i].score_data[0].allopoint == '90') || (this.ScorePoint[i].score_data[0].allopoint == '95') || (this.ScorePoint[i].score_data[0].allopoint == '100')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
              // console.log(this.criteriatype_name_Array[i - 1]['ScorePoint']);
            }
          }
          if (!this.ScorePoint.hasOwnProperty(i)) {
            this.showOthersData = false;
            let blankdata = {
              'allopoint': '0',
              'point_type': '0',
              'strength': '',
              'comment': '',
            }
            if (this.criteriatype_name_Array[i - 1] != undefined) {
              this.criteriatype_name_Array[i - 1]['ScorePoint'] = blankdata;
              if (blankdata.allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
            }
          }
        }
      }
    }
  }
  // share score point
  shareScorepoint(member) {
    console.log(member)
    if (this.showOthersData == true) {
      var key = Object.keys(member);
      var x = key.filter(x => Number(x) === this.critera_id).toString();
      if (x && member[x][0]) {
        if (member[x][0].status == 'Y') {
          this.share_status = 'N';
        }
        else if (member[x][0].status == 'N') {
          this.share_status = 'Y';
        }
      }
      else if (x && !member[x][0]) {
        this.share_status = 'Y';
      }
      // console.log(this.share_status)
      let data = {
        'user_token': this.user_token,
        'api_token': this.api_token,
        'award_id': this.IdParams.id2,
        'criteria_id': this.critera_id,
        'company_id': this.IdParams.id,
        'team_member_id': member.accessors_name.id,
        'group_id': this.IdParams.id3,
        'share_status': this.share_status
      }
      console.log(data.share_status)

      this.dashboardService.shareScorePoint(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.messagesService.showSuccess(res.msg);
          if (res.msg == "Un-Shared successfully.") {
            // this.assignmentDetails.group_members
            this.groupMembers.map((item) => {
              if (item.assessor_id == member.assessor_id) {
                let group_members_key = Object.keys(item);
                var x = group_members_key.filter(x => Number(x) === this.critera_id).toString();
                if (x && item[x][0]) {
                  item[x][0].status = this.share_status;
                  if (item[x][0].status == "Y") {
                    item.accessors_name['share_button_name'] = 'Unshare';
                  }
                  else if (item[x][0].status == "N") {
                    item.accessors_name['share_button_name'] = 'Share';
                  }
                }
                else if (x && !item[x][0]) {
                  item[x]['0'] = {};
                  item[x][0]['status'] = this.share_status
                  console.log(item[x]);
                  if (item[x][0].status == "Y") {
                    item.accessors_name['share_button_name'] = 'Unshare';
                  }
                  else if (item[x][0].status == "N") {
                    item.accessors_name['share_button_name'] = 'Share';
                  }
                }
              }
            });
            console.log(this.groupMembers)

          }
          else if (res.msg == "Shared successfully.") {
            // this.assignmentDetails.group_members
            this.groupMembers.map((item) => {
              if (item.assessor_id == member.assessor_id) {
                let group_members_key = Object.keys(item);
                var x = group_members_key.filter(x => Number(x) === this.critera_id).toString();
                if (x && item[x][0]) {
                  item[x][0].status = this.share_status;
                  if (item[x][0].status == "Y") {
                    item.accessors_name['share_button_name'] = 'Unshare';
                  }
                  else if (item[x][0].status == "N") {
                    item.accessors_name['share_button_name'] = 'Share';
                  }
                }
                else if (x && !item[x][0]) {
                  item[x]['0'] = {};
                  item[x][0]['status'] = this.share_status
                  console.log(item[x]);
                  if (item[x][0].status == "Y") {
                    item.accessors_name['share_button_name'] = 'Unshare';
                  }
                  else if (item[x][0].status == "N") {
                    item.accessors_name['share_button_name'] = 'Share';
                  }
                }
              }
            });
            console.log(this.groupMembers)

          }
          // console.log(this.assignmentDetails)
        }
        if (res.has_error == 1) {
          for (let [key, value] of Object.entries(res.msg)) {
            this.messagesService.showError(value + 'Please Select one Member');
          }
        }
      },
        err => {
          this.messagesService.showError('Something went wrong.');
        })
    }
    // else if (this.showOthersData == false) {
    //   this.messagesService.showError('Please Fillup your own data First');
    //   setTimeout(() => {
    //     this.messagesService.clear();
    //   }, 1500);
    // }
  }
  openDialog(member) {
    if (this.showOthersData == true) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to proceed?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        },
        panelClass: 'custom-modalbox'
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.shareScorepoint(member);
        }
      });
    }
    else if (this.showOthersData == false) {
      this.messagesService.showError('Please Fillup your own data First');
      setTimeout(() => {
        this.messagesService.clear();
      }, 1500);
    }
  }
  addEditor(){
    ClassicEditor.create( document.querySelector( '#editor' ), {
      resize_enabled: false,
      // toolbar: {
      // items: ['bold','italic','underline','|','strikethrough','subscript','superscript','|','bulletedList','numberedList','|','blockQuote','codeBlock','code','horizontalLine']
      // },
      language: 'en-gb',
      licenseKey: '',
      
      } )
      .then( editor => {
        console.log(editor)
      } )
      .catch( error => {
      console.error( error );
      } );
  }
}

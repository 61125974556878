import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessorManagementComponent } from './assessor-management.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesService } from '../../services/messages.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
// intro routing
export const routes: Routes = [
  { path: '', component: AssessorManagementComponent },
];

@NgModule({
  declarations: [AssessorManagementComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    AngularMultiSelectModule
  ],providers:[MessagesService]
})
export class AssessorManagementModule { }

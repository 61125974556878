import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllParticipatedAwardComponent } from './all-participated-award.component';
import { RouterModule, Routes } from '@angular/router';
import { MessagesService } from '../../services/messages.service';

// intro routing
export const routes: Routes = [
  { path: '', component: AllParticipatedAwardComponent },
];
@NgModule({
  declarations: [AllParticipatedAwardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],providers: [MessagesService]
})
export class AllParticipatedAwardModule { }

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JudgeService } from '../../services/judge.service';

@Component({
  selector: 'app-jury-group',
  templateUrl: './jury-group.component.html',
  styleUrls: ['./jury-group.component.scss']
})
export class JuryGroupComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  showAddForm: boolean = false;
  juryGroupDetails: any = [];
  lazyLoad: boolean = false;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  companymanagementListArray: any = [];
  groupCreationDatalist: any = [];
  juryGroupForm: FormGroup;
  submitted: boolean = false;
  formAction: any;
  processignText: boolean = false;
  loadingNumber: number = 0;
  singleJuryGroupDeatils: any;
  allSelectedMemberData: any = [];
  memberErr: string;
  awardListArray: any = [];
  JuryErrMsg: boolean = false;
  permissionMsg: any;
  permissionStatus: boolean = false;
  settings: {};
  settingsLeader = {};
  allSelectedMemberId: any = [];
  selectedTeamLeaderData: any = [];
  TeamLeaderErr: string;

  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/jury-group')
          this.leftbarselected = 'Jury Group';
        this.dataService.leftPanelSelection('jury_group');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/jury-group`]);
    }
    this.JuryGrouplist();
    this.companymanagementList();

    this.juryGroupForm = this.formBuilder.group({
      group_name: ['', [Validators.required]],
      // teamleader: ['', [Validators.required]],
      award_year_id: ['', [Validators.required]]
    });
    this.settings = {
      singleSelection: false,
      text: "Select Jury",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settingsLeader = {
      singleSelection: true,
      text: "Select TeamLeader",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      classes: "myclass custom-class-example"
    };
  }

  get f() { return this.juryGroupForm.controls; }

  JuryGrouplist() {
    if (this.loadingNumber == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.juryGrouplist(this.api_token).subscribe((res: any) => {
      // if (res.has_error == 0) {
      this.loadingNumber = 1;
      this.juryGroupDetails = res.jury_group_list;
      this.lazyLoad = false;
      this.permissionStatus = false;
      this.permissionMsg = '';
      // }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }

  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  awardManagementList() {
    this.judgeService.awardmanagementlist(this.api_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.awardListArray = res.award_data;
        this.awardListArray = res.award_data.filter((thing, index, self) => index === self.findIndex((t) => (t.year_id === thing.year_id)))
        // console.log(this.awardListArray);
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }

  onChangeAward(value) {
    this.allSelectedMemberData = [];
    this.allSelectedMemberId = [];
    this.selectedTeamLeaderData = [],
    this.allSelectedMemberId = [];

    // this.juryGroupForm.get('teamleader').reset();
    this.submitted = false;
    this.groupCreationlist(value);
  }

  groupCreationlist(year_id) {
    this.dashboardService.groupCreationList(this.api_token, year_id).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.JuryErrMsg = true;
        this.groupCreationDatalist = res.jury_list;
        this.groupCreationDatalist.map((item) => {
          item['itemName'] = item.name;
        });
        console.log('groupCreationDatalist', this.groupCreationDatalist);
      }
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }

  onItemSelect(item: any) {
    console.log(item);
    this.selectedTeamLeaderData = [];
    // console.log(this.allSelectedMemberData);
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Assessor';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    this.selectedTeamLeaderData = [];
    // console.log(this.allSelectedMemberData);
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Assessor';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
  }
  onSelectAll(items: any) {
    console.log(items);
    this.allSelectedMemberData = [];
    this.selectedTeamLeaderData = [];
    this.allSelectedMemberData = items;
    // console.log('allSelectedMemberData',this.allSelectedMemberData);
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Jury';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
  }
  onDeSelectAll(items: any) {
    console.log(items);
    this.allSelectedMemberData = [];
    this.selectedTeamLeaderData = [];
    // console.log('allSelectedMemberData',this.allSelectedMemberData);
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Jury';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
  }

  onItemSelectTeamLeader(item: any) {
    console.log(item);
    // console.log(this.selectedTeamLeaderData);
    if(this.selectedTeamLeaderData.length == 0){
      this.TeamLeaderErr = 'Please Select One Team Leader';
    }
    if(this.allSelectedMemberData.length > 0){
      this.TeamLeaderErr = '';
    }
  }
  OnItemDeSelectTeamLeader(item: any) {
    console.log(item);
    // console.log(this.selectedTeamLeaderData);
    if(this.selectedTeamLeaderData.length == 0){
      this.TeamLeaderErr = 'Please Select One Team Leader';
    }
    if(this.allSelectedMemberData.length > 0){
      this.TeamLeaderErr = '';
    }
  }

  selectJury($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.allSelectedMemberData.indexOf(Item.id) == -1) {
        this.allSelectedMemberData.push(Item.id);
      }
      if (this.allSelectedMemberData.length > 0) {
        this.memberErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.allSelectedMemberData.splice(this.allSelectedMemberData.indexOf($event.srcElement.value * 1), 1);
      if (this.allSelectedMemberData.length == 0) {
        this.memberErr = 'Please Select One Assessor';
      }
    }
    console.log(this.allSelectedMemberData);
  }

  addJurygroup() {
    this.submitted = true;

    if (this.juryGroupForm.invalid) {
      return;
    }

    this.allSelectedMemberId = [];
    for(let item of this.allSelectedMemberData){
      this.allSelectedMemberId.push(item.id)
    }
    if (this.formAction == 'add') {
      if ((this.allSelectedMemberData.length > 0) && (this.selectedTeamLeaderData.length > 0)) {
      let data = {
        group_name: this.juryGroupForm.value.group_name,
        teamleader: this.selectedTeamLeaderData[0].id,
        award_year_id: this.juryGroupForm.value.award_year_id,
        judgeInGroup: this.allSelectedMemberId
      }
      console.log(data);
      // if (this.allSelectedMemberData.length > 0) {
        this.memberErr = '';
        this.TeamLeaderErr = '';
        this.processignText = true;
        this.dashboardService.addJuryGroup(this.api_token, data).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processignText = false;
            this.messagesService.showSuccess(res.msg);
            setTimeout(() => {
              this.messagesService.clear();
            }, 2000);
            this.JuryGrouplist();
            this.closeJuryGroupForm();
          }
          if (res.has_error == 1) {
            this.processignText = false;
            this.messagesService.showError(res.msg);
            setTimeout(() => {
              this.messagesService.clear();
            }, 2000);
          }
        }, err => {
          this.processignText = false;
          this.messagesService.showError('Something went wrong.')
        })
      }
      if (this.allSelectedMemberData.length == 0) {
        this.memberErr = 'Please Select One Jury';
      }
      if (this.selectedTeamLeaderData.length == 0) {
        this.TeamLeaderErr = 'Please Select One Team Leader';
      }
    }
    if (this.formAction == 'edit') {
      if ((this.allSelectedMemberData.length > 0) && (this.selectedTeamLeaderData.length > 0)) {
      let data = {
        group_name: this.juryGroupForm.value.group_name,
        teamleader: this.selectedTeamLeaderData[0].id,
        award_year_id: this.juryGroupForm.value.award_year_id,
        judgeInGroup: this.allSelectedMemberId,
        group_id: this.singleJuryGroupDeatils.id
      }
      console.log(data);
      // if (this.allSelectedMemberData.length > 0) {
        this.memberErr = '';
        this.TeamLeaderErr = '';
        this.processignText = true;
        this.dashboardService.editJuryGroup(this.api_token, data).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.messagesService.showSuccess(res.msg);
            setTimeout(() => {
              this.messagesService.clear();
            }, 2000);
            this.processignText = false;
            this.JuryGrouplist();
            this.closeJuryGroupForm();
          }
          if (res.has_error == 1) {
            this.processignText = false;
            this.messagesService.showError(res.msg);
            setTimeout(() => {
              this.messagesService.clear();
            }, 2000);
          }
        }, err => {
          this.processignText = false;
          this.messagesService.showError('Something went wrong.')
        })
      }
      if (this.allSelectedMemberData.length == 0) {
        this.memberErr = 'Please Select One Jury';
      }
      if (this.selectedTeamLeaderData.length == 0) {
        this.TeamLeaderErr = 'Please Select One Team Leader';
      }
    }
  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }
  openJuryGroupForm(action, groupItem) {
    this.showAddForm = true;
    this.formAction = action;
    this.panelExpanded = false;
    this.awardManagementList();
    if ((this.formAction == 'add') && (groupItem == null)) {
      this.singleJuryGroupDeatils = null;
      this.juryGroupForm.reset();
      this.allSelectedMemberData = [];
      this.groupCreationDatalist = [];
      this.allSelectedMemberId = [];
      this.selectedTeamLeaderData = [],
      this.allSelectedMemberId = [];
    }
    if ((this.formAction == 'edit') && (groupItem != null)) {
      this.singleJuryGroupDeatils = groupItem;
      console.log(this.singleJuryGroupDeatils);

      this.juryGroupForm.patchValue({
        group_name: this.singleJuryGroupDeatils.group_name,
        // teamleader: this.singleJuryGroupDeatils.teamleader,
        award_year_id: this.singleJuryGroupDeatils.award_year_id,
      });
      this.onChangeAward(this.singleJuryGroupDeatils.award_year_id);
      this.allSelectedMemberData = [];
      this.selectedTeamLeaderData = [];

      this.singleJuryGroupDeatils.judge_in_groups.map((item1) => {
        item1['itemName'] = item1.judge_name.name;
        item1['activity_logs_id'] = item1.judge_name.activity_logs_id,
        item1['address'] = item1.judge_name.address,
        item1['api_token'] = item1.judge_name.api_token,
        item1['award_id'] = item1.judge_name.award_id,
        item1['city'] = item1.judge_name.city,
        item1['country_id'] = item1.judge_name.country_id,
        item1['created_at'] = item1.judge_name.created_at,
        item1['deleted_at'] = item1.judge_name.deleted_at,
        item1['email'] = item1.judge_name.email,
        item1['first_name'] = item1.judge_name.first_name,
        item1['forget_password_link_status'] = item1.judge_name.forget_password_link_status,
        item1['gender'] = item1.judge_name.gender,
        item1['group_ids'] = item1.judge_name.group_ids,
        item1['id'] = item1.judge_id,
        item1['is_active'] = item1.judge_name.is_active,
        item1['jury'] = {}
        item1['language_id'] = item1.judge_name.language_id,
        item1['last_name'] = item1.judge_name.last_name,
        item1['name'] = item1.name,
        item1['office_company'] = item1.judge_name.office_company,
        item1['parent_id'] = item1.judge_name.parent_id,
        item1['phone'] = item1.judge_name.phone,
        item1['phone_number_code'] = item1.judge_name.phone_number_code,
        item1['profile_picture'] = item1.judge_name.profile_picture,
        item1['updated_at'] = item1.judge_name.updated_at,
        item1['updated_by'] = item1.judge_name.updated_by,
        item1['user_name'] = item1.judge_name.user_name,
        item1['user_type'] = item1.judge_name.user_type,
        item1['view_on_dashboard'] = item1.view_on_dashboard,
        this.allSelectedMemberData.push(item1);
        if (item1.is_team_leader == 'Y') {
          this.selectedTeamLeaderData.push(item1)
          }
      });
      // this.singleJuryGroupDeatils.judge_in_groups.map((item) => {
      //   this.allSelectedMemberData.push(item.judge_id);
      //   if (item.is_team_leader == 'Y') {
      //     this.juryGroupForm.patchValue({
      //       teamleader: item.judge_id
      //     });
      //   }
      // })
      console.log(this.allSelectedMemberData);
      console.log(this.selectedTeamLeaderData);
    }
  }

  closeJuryGroupForm() {
    this.showAddForm = false;
    this.submitted = false;
  }
}

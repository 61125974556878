import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JudgeDashboardComponent } from './judge-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { JudgeProfileModule } from './judge-profile/judge-profile.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../services/excel.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/_helpers/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: JudgeDashboardComponent },
];

@NgModule({
  declarations: [JudgeDashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    JudgeProfileModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgScrollbarModule
  ],
  providers: [DatePipe,ExcelService]
  // ,FilterlistPipe,
    // NamePipe
})
export class JudgeDashboardModule { }

import { Component, OnInit } from '@angular/core';
import { WinnerService } from '../services/winner.service';
import { appConfig } from '../app.config';
import { Router } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-award-list',
  templateUrl: './award-list.component.html',
  styleUrls: ['./award-list.component.scss']
})
export class AwardListComponent implements OnInit {
  awardimageIcon: any;
  awardListArray: any = [];
  pageNumber: number = 1;
  totalNumber: number = 1;
  user_token: any;
  thumImagePath: any;
  lazyLoad: boolean = false;
  award_image_path: any;
  constructor(private winnerService: WinnerService,
    private router: Router,
    private messagesService: MessagesService,
    private authService: AuthService) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.getuserToken();
    // this.thumImagePath = appConfig.thumb_path;
    this.awardlist();
  }
  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }
  // award list
  awardlist() {
    this.lazyLoad = true;
    let per_page = 6;
    let page_no = this.pageNumber;
    this.winnerService.awardList(this.user_token, per_page, page_no).subscribe((response: any) => {
      if (response.has_error == 0) {
        this.lazyLoad = false;
        this.award_image_path = response.award_image_path;
        this.awardimageIcon = response.image_path;
        this.totalNumber = response.total_no_of_award;

        response.award_data.map((item) => {
          this.awardListArray.push(item);
        })
      }
    }, error => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800)
      // console.error(error);
    });
  }
  // load more awards
  loadMoreAwards() {
    // this.awardlist();
    let per_page = 6;
    console.log(this.totalNumber);
    this.pageNumber++;
    let skip = this.pageNumber;
    this.winnerService.awardList(this.user_token, per_page, skip).subscribe((response: any) => {
      // console.log('response', response);
      if (response.has_error == 0) {
        this.awardimageIcon = response.image_path;
        response.award_data.map((item) => {
          this.awardListArray.push(item);
        })
      }
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800)
      // console.error(error);
    });
  }

  scrollDown() {
    // window.scrollTo(0, document.body.scrollHeight);
    window.scroll({
      top: 800,
      left: 0,
      behavior: 'smooth'
    });
  }
  onScroll() {
    console.log('scrolled!!');
    this.loadMoreAwards();
  }
  goToAwardDetails(awardItem){
    // localStorage.setItem('award_yearId', awardItem.year_id);
    this.router.navigate([`/award-details/`+awardItem.id+'/'+awardItem.year_id]);
  }
}

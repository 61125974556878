import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/_helpers/_guards/custom.validator';
import { JudgeService } from '../../services/judge.service';
import { AuthService } from '../../services/auth.service';
import { appConfig } from 'src/app/app.config';


@Component({
  selector: 'app-judge-profile',
  templateUrl: './judge-profile.component.html',
  styleUrls: ['./judge-profile.component.scss']
})
export class JudgeProfileComponent implements OnInit {
  leftbarselected; any;
  show_personal_information: boolean = false;
  show_professional_information: boolean = false;
  show_industry_information: boolean = false;
  show_change_password: boolean = false;
  user_token: any;
  api_token: any;
  userDetails: any;
  profileUpdateOneForm: FormGroup;
  profileUpdateTwoForm: FormGroup;
  profileUpdateThreeForm: FormGroup;
  updatePasswordForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  submitted4: boolean = false;
  profileImagePath: any;
  gendersList: any = [];
  QualificationsList: any = [];
  AllCountryList: any = [];
  nationalityList: any = [];
  aboutusList: any = [];
  IndustryList: any = [];
  YearsofWorkExperienceList: any = [];
  ProfessionalUseLanguagesList: any = [];
  upperCaseNewErrMsg: string;
  NumberNewErrMsg: string;
  processingPasswordText: boolean = false;
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  selectedExternalAwardsParticipates: any = [];
  selectedInnovationHandsonExperiences: any = [];
  selectedAreasofExpertises: any = [];
  namePrefixsList: any = [];
  ExternalAwardsParticipatedErr: any;
  HandsonExperiencesErr: any;
  AreasofExpertisesErr: any;
  lazyLoad: boolean = false;
  webSitePatternErr: any;
  processingOneText: boolean = false;
  processingTwoText: boolean = false;
  processingThreeText: boolean = false;
  award_id: any;
  errorMessageValue: any;
  loadingCount: number = 0;
  PrefixNameErr: any;
  juryAwardIdArray: any = [];
  display_profile_photo: any;
  profile_photo: any;
  profile_photo_show: null;
  profile_CV: any;
  Profile_Cv_Show: null;
  ProfessionalCVErr: any;
  ProfessionalPicErr: any;
  profileCVPath: any;
  fileTypeText: any;
  
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService,
    private authService: AuthService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/jury-profile')
          this.leftbarselected = 'Profile';
        this.dataService.leftPanelSelection('judge-profile');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    this.aboutusList.push('Award website', 'Google Search', 'Referred by someone', 'Social Media', 'Others')
    if (userInfo.user_type == '7') {
      this.router.navigate([`/jury-profile`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.updatePasswordForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });
    this.profileUpdateOneForm = this.formBuilder.group({
      namePrefix: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      employer: ['', Validators.required],
      job_title: ['', Validators.required],
      Country_code: ['', Validators.required],
      mobile_no: ['', Validators.required],
      WhatsApp_Country_code: ['', Validators.required],
      WhatsApp_mobile_no: ['', Validators.required],
      workEmail: ['', [Validators.required, Validators.email]],
      personalEmail: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      country: ['', Validators.required],
    });

    this.profileUpdateTwoForm = this.formBuilder.group({
      qualification: ['', Validators.required],
      yearsofWorkExperience: ['', Validators.required],
      industry: ['', Validators.required],
      professional_language: ['', Validators.required],
      linkedIn_profile_link: ['', Validators.required],
      about_us: ['', Validators.required]
    });
    this.profileUpdateThreeForm = this.formBuilder.group({
      interest_description: ['', Validators.required],
      experience_description: ['', Validators.required],
      confict_description: ['', Validators.required],
      recomended_email: [''],
      notes: ['']
    });

    this.getUserDetails();
    this.callingCodes();
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
  }

  get f() { return this.updatePasswordForm.controls; }

  get f1() { return this.profileUpdateOneForm.controls; }
  get f2() { return this.profileUpdateTwoForm.controls; }
  get f3() { return this.profileUpdateThreeForm.controls; }
  openshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  closeshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  openshow_professional_information() {
    this.show_professional_information = !this.show_professional_information;
  }
  closeshow_professional_information() {
    this.show_professional_information = !this.show_professional_information;
  }
  openshow_industry_information() {
    this.show_industry_information = !this.show_industry_information;
  }
  closeshow_industry_information() {
    this.show_industry_information = !this.show_industry_information;
  }
  openshow_change_password() {
    this.show_change_password = !this.show_change_password;
  }
  closeshow_change_password() {
    this.show_change_password = !this.show_change_password;
    this.submitted1 = false;
    this.updatePasswordForm.reset();
    this.upperCaseNewErrMsg = '';
    this.NumberNewErrMsg = '';
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  getUserDetails() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.getUserDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      this.lazyLoad = false;
      this.loadingCount = 1;
      if (this.loadingCount == 1) {
        this.dataService.userdetails(res);
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.userDetails.name = res.user_details.user.name;
        this.userDetails.profile_picture = res.user_details.user.profile_picture;
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
      }
      this.userDetails = res.user_details;
      // this.profileCVPath = res.cv_path;
      this.profileCVPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/Profile_CV/';
      this.juryAwardIdArray = [];
      res.awardUser.map((item) => {
        this.juryAwardIdArray.push(item.award_id)
      })

      this.namePrefixsList = res.NamePrefixs;
      this.gendersList = res.genders;
      this.QualificationsList = res.Qualifications;
      this.nationalityList = res.Nationalities
      this.AllCountryList = res.all_country;
      this.YearsofWorkExperienceList = res.YearsofWorkExperiences;
      this.ProfessionalUseLanguagesList = res.ProfessionalUseLanguages;
      this.IndustryList = res.Industries;
      this.profileImagePath = res.imagePath;
      this.display_profile_photo = this.userDetails.user.profile_picture != null ? this.profileImagePath + this.userDetails.user.profile_picture : 'assets/images/default-user.png';
      this.profile_photo = this.userDetails.user.profile_picture;
      this.profile_CV = this.userDetails.Profile_CV;

      this.userDetails.gender_name = this.gendersList.find(x => x.gender_id == this.userDetails.Gender_id).trans_data;
      this.userDetails.nationalities_name = this.AllCountryList.find((x) => x.nationalities_id === this.userDetails.nationalities_id).trans_data;
      this.userDetails.country_name = this.AllCountryList.find((x) => x.nationalities_id === this.userDetails.user.country_id).trans_data;

      this.userDetails.qualifications_name = this.QualificationsList.find((x) => x.qualifications_id === this.userDetails.qualifications_id).trans_data;
      this.userDetails.years_of_work_experience_name = this.YearsofWorkExperienceList.find((x) => x.yearsof_work_experiences_id === this.userDetails.yearsof_work_experiences_id).trans_data;

      this.userDetails.Professional_language = this.ProfessionalUseLanguagesList.find((x) => x.professional_language_id === this.userDetails.professional_language_id).trans_data;

      this.userDetails.industry = this.IndustryList.find((x) => x.industries_id === this.userDetails.industries_id).trans_data;
      this.profileUpdateOneForm.patchValue({
        namePrefix: this.userDetails.NamePrefix_id,
        firstName: this.userDetails.user.first_name,
        lastName: this.userDetails.user.last_name,
        gender: this.userDetails.Gender_id,
        nationality: this.userDetails.nationalities_id,
        employer: this.userDetails.Current_Employer,
        job_title: this.userDetails.Job_Title,
        Country_code: this.userDetails.Country_code,
        mobile_no: this.userDetails.mobile_no,
        WhatsApp_Country_code: this.userDetails.WhatsApp_Country_code,
        WhatsApp_mobile_no: this.userDetails.WhatsApp_mobile_no,
        workEmail: this.userDetails.Work_E_mail,
        personalEmail: this.userDetails.user.email,
        address: this.userDetails.user.address,
        city: this.userDetails.cityof_residences_id,
        zip_code: this.userDetails.zip_code,
        country: this.userDetails.user.country_id,
      });

      this.profileUpdateTwoForm.patchValue({
        qualification: this.userDetails.qualifications_id,
        yearsofWorkExperience: this.userDetails.yearsof_work_experiences_id,
        industry: this.userDetails.industries_id,
        professional_language: this.userDetails.professional_language_id,
        linkedIn_profile_link: this.userDetails.LinkedIn_Profile_Link,
        about_us: this.userDetails.how_did_you_hear_about
      });
      this.profileUpdateThreeForm.patchValue({
        interest_description: this.userDetails.interest_description,
        experience_description: this.userDetails.experience_description,
        confict_description: this.userDetails.confict_description,
        recomended_email: this.userDetails.recomended_email,
        notes: this.userDetails.notes
      });
      // this.display_profile_photo = this.userDetails.user.profile_picture != null ? this.profileImagePath + this.userDetails.user.profile_picture : 'assets/images/default-user.png';
      this.dataService.userData(res);

    }, err => {
      this.lazyLoad = false;
      if (err.error.message == "Unauthenticated.") {
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  onChangeMobileCode(event) {
    if (event == '') {
      // this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      // this.mobileCountryCodeeErr = '';
    }
  }
  onChangeWhatappCode(event) {
    if (event == '') {
      // this.WhatsAppCountryErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      // this.WhatsAppCountryErr = '';
    }
  }
  onChangePrefix(event) {
    if (event == '') {
      this.PrefixNameErr = 'Jury Prefix Name is required';
    }
    if (event != '') {
      this.PrefixNameErr = '';
    }
  }
  onKeyNewPassword(event: any) { // without type info
    let upperCaseCharacters = /[A-Z]+/;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/;
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (event.target.value != '') {
      this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';
      this.NumberNewErrMsg = 'New Password must contain at least one number';
      if ((upperCaseCharacters.test(event.target.value) === false)) {
        this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';

      }
      if ((upperCaseCharacters.test(event.target.value) === true)) {
        this.upperCaseNewErrMsg = '';
      }
      if ((numberCharacters.test(event.target.value) === false)) {
        this.NumberNewErrMsg = 'New Password must contain at least one number';
      }
      if ((numberCharacters.test(event.target.value) === true)) {
        this.NumberNewErrMsg = '';
      }
    }
    else if (event.target.value == '') {
      this.NumberNewErrMsg = '';
      this.upperCaseNewErrMsg = '';
    }
  }
  onSelectCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.Profile_Cv_Show = event.target.files[0];
      if (this.Profile_Cv_Show == null) {
        this.ProfessionalCVErr = 'please select a document';
      }
      if (this.Profile_Cv_Show != null) {
        this.ProfessionalCVErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  onSelectPhoto(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_photo_show = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
      }
    }
  }
  updatepassword() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      current_password: this.updatePasswordForm.value.current_password,
      new_password: this.updatePasswordForm.value.new_password,
    }
    if ((this.NumberNewErrMsg == '') && (this.upperCaseNewErrMsg == '')) {
      this.processingPasswordText = true;
      this.judgeService.updatePaasword(this.api_token, data).subscribe((res: any) => {
        console.log(res)
        if (res.has_error == 0) {
          this.processingPasswordText = false;
          this.messagesService.showSuccess(res.msg);
          this.submitted1 = false;
          console.log(this.updatePasswordForm.value);
          this.show_change_password = false;
          this.updatePasswordForm.reset();
          this.upperCaseNewErrMsg = '';
          this.NumberNewErrMsg = '';
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingPasswordText = false;
        }
      },
        err => {
          this.processingPasswordText = false;
          this.messagesService.showError('Something went wrong.');
        });
    }
  }
  updateprofileOne() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.profileUpdateOneForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.juryAwardIdArray),
      formData.append('NamePrefix_id', this.profileUpdateOneForm.value.namePrefix),
      formData.append('first_name', this.profileUpdateOneForm.value.firstName),
      formData.append('last_name', this.profileUpdateOneForm.value.lastName),
      formData.append('email', this.profileUpdateOneForm.value.personalEmail),
      formData.append('Gender_id', this.profileUpdateOneForm.value.gender),
      formData.append('nationalities_id', this.profileUpdateOneForm.value.nationality),
      formData.append('Current_Employer', this.profileUpdateOneForm.value.employer),
      formData.append('Job_Title', this.profileUpdateOneForm.value.job_title),
      formData.append('address', this.profileUpdateOneForm.value.address),
      formData.append('Country_code', this.profileUpdateOneForm.value.Country_code),
      formData.append('mobile_no', this.profileUpdateOneForm.value.mobile_no),
      formData.append('WhatsApp_Country_code', this.profileUpdateOneForm.value.WhatsApp_Country_code),
      formData.append('WhatsApp_mobile_no', this.profileUpdateOneForm.value.WhatsApp_mobile_no),
      formData.append('Work_E_mail', this.profileUpdateOneForm.value.workEmail),
      formData.append('cityof_residences_id', this.profileUpdateOneForm.value.city),
      formData.append('country_id', this.profileUpdateOneForm.value.country),
      formData.append('zip_code', this.profileUpdateOneForm.value.zip_code)

    if (this.profile_photo_show != null) {
      formData.append('Profile_pic', this.profile_photo_show)
    }
    if (this.profile_photo_show == null) {
      formData.append('Profile_pic', this.profile_photo)
    }
    this.processingOneText = true;
    this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.processingOneText = false;
        this.getUserDetails();
        this.show_personal_information = false;
        // this.CompanyLogo_Show = null;
        this.messagesService.showSuccess('Profile Updated successfully.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
      else if (res.has_error == 1) {
        this.processingOneText = false;
        for (let [key, value] of Object.entries(res.error)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingOneText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
  }
  updateprofileTwo() {
    this.submitted3 = true;
    // stop here if form is invalid
    if (this.profileUpdateTwoForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.juryAwardIdArray),
      formData.append('industries_id', this.profileUpdateTwoForm.value.industry),
      formData.append('qualifications_id', this.profileUpdateTwoForm.value.qualification),
      formData.append('professional_language_id', this.profileUpdateTwoForm.value.professional_language),
      formData.append('yearsof_work_experiences_id', this.profileUpdateTwoForm.value.yearsofWorkExperience),
      formData.append('LinkedIn_Profile_Link', this.profileUpdateTwoForm.value.linkedIn_profile_link),
      formData.append('how_did_you_hear_about', this.profileUpdateTwoForm.value.about_us)
    formData.append('email', this.userDetails.user.email)
    this.processingTwoText = true;
    this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.processingTwoText = false;
        this.getUserDetails();
        this.show_professional_information = false;
        // this.CompanyLogo_Show = null;
        this.messagesService.showSuccess('Profile Updated successfully.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
      else if (res.has_error == 1) {
        this.processingTwoText = false;
        for (let [key, value] of Object.entries(res.error)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingTwoText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
  }
  updateprofileThree() {
    this.submitted4 = true;
    // stop here if form is invalid
    if (this.profileUpdateThreeForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.juryAwardIdArray),
      formData.append('interest_description', this.profileUpdateThreeForm.value.interest_description),
      formData.append('experience_description', this.profileUpdateThreeForm.value.experience_description),
      formData.append('confict_description', this.profileUpdateThreeForm.value.confict_description),
      formData.append('recomended_email', this.profileUpdateThreeForm.value.recomended_email ? this.profileUpdateThreeForm.value.recomended_email : ''),
      formData.append('notes', this.profileUpdateThreeForm.value.notes ? this.profileUpdateThreeForm.value.notes : '')

    if (this.Profile_Cv_Show != null) {
      formData.append('Profile_CV', this.Profile_Cv_Show)
    }
    if (this.Profile_Cv_Show == null) {
      formData.append('Profile_CV', this.profile_CV)
    }
    formData.append('email', this.userDetails.user.email)
    if (this.profile_CV != null || this.Profile_Cv_Show != null) {
      this.ProfessionalCVErr = '';
      this.processingThreeText = true;
      this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingThreeText = false;
          this.getUserDetails();
          this.show_industry_information = false;
          this.Profile_Cv_Show = null;
          this.messagesService.showSuccess('Profile Updated successfully.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
        else if (res.has_error == 1) {
          this.processingThreeText = false;
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
      },
        error => {
          this.processingThreeText = false;
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        })
    }
    else if (this.Profile_Cv_Show == null && this.profile_CV == null) {
      this.ProfessionalCVErr = 'please select a document'
    }
  }
  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AssessorRegisterComponent } from './assessor-register.component';
import { AuthService } from '../services/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AwardBannerModule } from '../components/award-banner/award-banner.module';
import { BrowserModule } from '@angular/platform-browser';
import { ClickOutsideModule } from 'ng-click-outside';
import { MessagesService } from '../services/messages.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// intro routing
export const routes: Routes = [
  { path: '', component: AssessorRegisterComponent },
];

@NgModule({
  declarations: [AssessorRegisterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    AwardBannerModule,
    BrowserModule,
    ClickOutsideModule,
    AngularMultiSelectModule
  ], providers: [AuthService,MessagesService]
})
export class AssessorRegisterModule { }

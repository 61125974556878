import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecursiveCriteriaComponent } from './recursive-criteria.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [RecursiveCriteriaComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [RecursiveCriteriaComponent]
})
export class RecursiveCriteriaModule { }

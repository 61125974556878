import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd} from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../services/excel.service';
import { MessagesService } from '../../services/messages.service';
import { DatePipe } from '@angular/common';
import { appConfig } from '../../app.config';

@Component({
  selector: 'app-office-archive',
  templateUrl: './office-archive.component.html',
  styleUrls: ['./office-archive.component.scss']
})
export class OfficeArchiveComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  lazyLoad: boolean = false;
  user_token: any;
  api_token: any;
  awardOfficeDetails: any = [];
  singleOfficeDetails: any;
  closeResult: string;
  licenseDownloadLink: any;
  company_logo_path: any;
  userDetails: any;
  assessorDocsData: any =[];
  juryyDocumentDownloadLink: any;
  juryUploadDocumentLink: any;
  companyGeneralDocumentDownloadLink: any;
  criteriaName: any;
  companymanagementListArray: any =[];
  permissionMsg: any;
  permissionStatus: boolean = false;
  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private router: Router,
    private excelService: ExcelService) {
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd == true) {
          if (val.url == '/award-office-archive')
            this.leftbarselected = 'Award Office Archive';
          this.dataService.leftPanelSelection('award_office_dashboard');
        }
      });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.awardOfficeDetails = JSON.parse(localStorage.getItem('eachOfficeDetails'));
    this.company_logo_path = localStorage.getItem('uploadPath');
   }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-archive`]);
    }
    // this.archivelist();
    this.companymanagementList();
  }
  
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  archivelist() {
    this.lazyLoad = true;
    this.dashboardService.archiveList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.awardOfficeDetails = res.office_dashboard_details;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        this.juryyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
        this.company_logo_path = res.company_logo_path;
        this.awardOfficeDetails.map((item) => {
          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
        })
        console.log(this.awardOfficeDetails);
      }
    if (res.has_error == 1) {
      this.lazyLoad = false;
      this.permissionStatus = true;
      this.permissionMsg = res.msg;
      this.router.navigate(['/award-office-dashboard']);
    }
    }, err => {
      this.lazyLoad = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  open(content, officeItem, className) {
    console.log(this.singleOfficeDetails);
    let modalClassName = className;
    this.criteriaName = '';
    this.singleOfficeDetails = officeItem;
    console.log(this.singleOfficeDetails);
    let award_Id = this.singleOfficeDetails.award_year_details.id;
    if(this.singleOfficeDetails.company_name.company_general_documents.length > 0){
      this.singleOfficeDetails.company_name.company_general_documents = this.singleOfficeDetails.company_name.company_general_documents.filter(x => x.award_year_id === award_Id);
    }

    if(this.singleOfficeDetails.company_name.company_documents.length > 0){
    this.singleOfficeDetails.company_name.company_documents = this.singleOfficeDetails.company_name.company_documents.filter(x => x.award_year_id === award_Id);
    }
    this.assessorDocsData = officeItem.assessor_docs;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder' , centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  exportAsXLSX(singleOfficeDetails): void {
    let data = [{
      'Name': singleOfficeDetails.company_name.company_name,
      'Type': singleOfficeDetails.company_name.industry.trans_data,
      'Year in UAE': singleOfficeDetails.company_name.year_in_uae.title,
      'Email': singleOfficeDetails.company_name.user.email,
      'Address': singleOfficeDetails.company_name.head_office_addr,
      'Mobile': singleOfficeDetails.company_name.mob_phone,
      'PB NO': singleOfficeDetails.company_name.po_box,
      'Number of Branch': singleOfficeDetails.company_name.branch.title,
      'Location': singleOfficeDetails.company_name.user.location.trans_data,
      'Website': singleOfficeDetails.company_name.website,
      'CEO/GM': singleOfficeDetails.company_name.ceo_prefix + singleOfficeDetails.company_name.ceo_firstname + singleOfficeDetails.company_name.ceo_lastname,
      'Representative': singleOfficeDetails.company_name.representative_prefix +
        singleOfficeDetails.company_name.representative_firstname +
        singleOfficeDetails.company_name.representative_lastname,

      'Registration Date and Time': this.datepipe.transform(singleOfficeDetails.company_name.user.created_at, 'dd MMM yy h:mm a')
    }]

    this.excelService.exportAsExcelFile(data, singleOfficeDetails.company_name.company_name);
  }
  goToAssesmentPoint(officeItem) {
    this.router.navigate(['award-office-assessment', officeItem.id]);
  }
}

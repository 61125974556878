import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { JudgeService } from '../../services/judge.service';
import { MessagesService } from '../../services/messages.service';
import { DataService } from '../../services/data.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  selector: 'app-jury-assessment',
  templateUrl: './jury-assessment.component.html',
  styleUrls: ['./jury-assessment.component.scss']
})
export class JuryAssessmentComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  api_token: any;
  user_token: any;
  criteriaList: any = [];
  subcriteriaList: any = [];
  subcriteriaTitle: any;
  subcriteriaStatus: any;
  finaAassignmentDetails: any;
  breadcrumbSelected: any;
  IdParams: any;
  mainCriteraNumber: number = 1;
  CriteraNumber: any;
  strength: any;
  comment: any;
  selectPointType: any;
  selectPoint: any;
  criteraPoint: any;
  selectIndex: number = 0;
  critera_id: any;
  ScorePoint: any;
  awardMeasurmentsList: any = [];
  pointMeasurmentsList: any = [];
  rejetedData: any = [];
  send_approval_status: any;
  lazyLoad: boolean = false;
  processingtext: boolean = false;
  processingThirdLebeltext: boolean = false;
  criteriatype_name_Array: any = [];
  criteraNumber: any;
  thirdLebelStrength: any = [];
  thirdLebelComment: any = [];
  thirdLebelPointType: any = [];
  thirdLebelAllPoint: any = [];
  errorMessageValue: any;
  thirdlevelSendApprovalStatus: boolean = false;
  userDetails: any;
  score_data_Array: any = [];
  submitted1: boolean = false;
  submitted2: boolean = false;
  processingText1: boolean = false;
  processingText2: boolean = false;
  approveForm: FormGroup;
  rejectForm: FormGroup;
  errMsg1: any;
  errMsg2: any;
  closeResult: string;
  singleJuryDetails: any;
  commentMessage: any;
  teamleaderStatus: any;
  score_point_id_array: any = [];
  juryGroupMemberArray: any = [];
  memberStatusComment: any;
  memberStatus: any;
  showGroupMemebrData: boolean = false;
  jurymemberSelfComment: any;
  jurymemberSelfSatus: any;
  showApprove: boolean = false;
  groupMemberStatus: any;
  share_status: string;
  leaderStatus: any;
  leaderComment: any;
  leaderSectionShow: boolean = false;
  memberName: any;
  leaderName: any;
  memberArray: any = [];
  constructor(private judgeService: JudgeService,
    private messagesService: MessagesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dashboardService: DashboardService) {
    this.activatedRoute.params.subscribe(params => {
      this.IdParams = params;
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url.match('/jury-assessment'))
          this.breadcrumbSelected = 'Add';
        this.dataService.leftPanelSelection('judge-dashboard');
      }
    });
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/jury-assessment/` + this.IdParams.id + '/' + this.IdParams.id2 + '/' + this.IdParams.id3 + '/' + this.IdParams.id4+ '/' + this.IdParams.id5]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getScoreBoarddetails();
    this.addEditor();
    this.approveForm = this.formBuilder.group({
      comments1: ['', Validators.required]
    });
    this.rejectForm = this.formBuilder.group({
      comments2: ['', Validators.required]
    });
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  getScoreBoarddetails() {
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'award_id': this.IdParams.id2,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'teamleaderid': this.IdParams.id4,
      'assessor_group_id': this.IdParams.id5
    }
    this.lazyLoad = true;
    this.judgeService.showFinalAwardScore(this.api_token, data).subscribe((res: any) => {
      this.lazyLoad = false;
      this.finaAassignmentDetails = res;
      this.criteriatype_name_Array = res.criteriatype;
      this.criteriaList = res.criteria;
      this.subcriteriaList = res.get_all_criteria;
      this.juryGroupMemberArray = res.group_members;

      this.subcriteriaTitle = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].title;
      this.critera_id = this.subcriteriaList[0].id;
      this.criteraPoint = this.subcriteriaList[0].point;
      this.teamleaderStatus = res.teamleaderStatus;
      console.log(this.subcriteriaList);

      for (let i = 0; i < this.criteriaList.length; i++) {
        if (i == 0) {
          this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          var key = Object.keys(this.finaAassignmentDetails.award_measurments)[i];
          this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
          this.pointMeasurmentsList.push('0')
        }
        if (i != 0) {
          if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          }
          else {
            this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
          }
        }
      }
      // this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + this.subcriteriaList[0].hire_order;
      if (this.subcriteriaList[0].hire_order != 0) {
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + this.subcriteriaList[0].hire_order;
      }
      if (this.subcriteriaList[0].hire_order == 0) {
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + 1;
      }

      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) {
        this.rejetedData = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data;
        if (this.rejetedData.length > 0) {
          this.rejetedData.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
      }
      this.ScorePoint = this.subcriteriaList[0];
      this.leaderSectionShow = false;
      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => Number(o.parent_papa) === o2.id))[0].score_data.length > 0) {
        this.score_data_Array = this.subcriteriaList[0].score_data;
        // this.score_data_Array = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => Number(o.parent_papa) === o2.id))[0].score_data;
        if (this.score_data_Array.length > 0) {
          this.score_data_Array.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
      }
      if (this.teamleaderStatus == 'Y') {
        this.showGroupMemebrData = false;
        if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length > 0) {
          this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].approved_status;
        }
        if ((this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) && (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length == 0)) {

          this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data[0].approved_status;
        }

        if ((this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) && (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length > 0)) {

          this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].approved_status;
        }

        if ((this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length == 0) && (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length == 0)) {
          this.subcriteriaStatus = '';
        }



        this.juryGroupMemberArray.map((item) => {
          let group_members_key = Object.keys(item);
          var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
          if (x && item[x]) {
            if (item[x].status == "Y") {
              item.judge_name['share_button_name'] = 'Unshare';
            }
            else if (item[x].status == "N") {
              item.judge_name['share_button_name'] = 'Share';
            }
          }
          else {
            item.judge_name['share_button_name'] = 'Share';
          }
        })
      }
      else if (this.teamleaderStatus == 'N') {
        if (this.ScorePoint.score_data.length == 0) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if (this.ScorePoint.rejected_data.length == 0) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = true;
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
          this.ScorePoint.score_data[0].judges_comment.map((item) => {
            if (item.user_id == this.userDetails.id) {
              this.jurymemberSelfComment = item.status_comment;
              this.jurymemberSelfSatus = item.approved_status;
              this.showApprove = false;
            }
          })
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
          this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
            if (item.user_id == this.userDetails.id) {
              this.jurymemberSelfComment = item.status_comment;
              this.jurymemberSelfSatus = item.approved_status;
              this.showApprove = false;
            }
          })
        }

        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
          this.ScorePoint.score_data[0].judges_comment.map((item) => {
            if (item.user_id != this.userDetails.id) {
              this.showApprove = true;
            }
          })
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
          this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
            if (item.user_id != this.userDetails.id) {
              this.showApprove = false;
            }
          })
        }

        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
          this.showApprove = true;
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
          this.showApprove = false;
        }
      }

      // this.juryGroupMemberArray.map((item) => {
      //   if (item.is_team_leader == 'Y') {
      //     if (item.shared_data.length > 0) {
      //       item.shared_data.map((newItem) => {
      //         let group_members__share_key = Object.keys(newItem);
      //         var x = group_members__share_key.filter(x => Number(x) === this.ScorePoint.id).toString();
      //         console.log(x);
      //         if (x && item[x]) {
      //           if (item[x].status == "Y") {
      //             item['view_leader_status'] = 'Y';
      //           }
      //           else if (item[x].status == "N") {
      //             item['view_leader_status'] = 'N';
      //           }
      //         }
      //       })
      //     }
      //     else if (item.shared_data.length == 0) {
      //       item['view_leader_status'] = 'N';
      //     }
      //   }
      // })



      if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
        this.ScorePoint.score_data[0].judges_comment.map((item) => {
          this.juryGroupMemberArray.map((groupItem) => {
            if (groupItem.judge_id === item.user_id) {
              groupItem['view_status'] = 'Y';
            }
          })
        })
      }
      if ((this.ScorePoint.score_data.length == 0) || (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
        this.juryGroupMemberArray.map((groupItem) => {
          groupItem['view_status'] = 'N';
          this.showGroupMemebrData = false;
        })
      }
      if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
        this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
          this.juryGroupMemberArray.map((groupItem) => {
            if (groupItem.judge_id === item.user_id) {
              groupItem['view_status'] = 'Y';
            }
          })
        })
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      })
  }
  lineActive(subcriteria, criteraNumber, index) {
    this.selectIndex = index;
    this.criteraNumber = criteraNumber;
    if (this.criteraNumber != 3) {
      this.ScorePoint = subcriteria;
      this.leaderSectionShow = false;
      console.log(this.ScorePoint);
      // score data 
      if (this.teamleaderStatus == 'Y') {
        this.showGroupMemebrData = false;
        if (this.ScorePoint.score_data.length > 0) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        }
        if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
        }
        if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
          this.subcriteriaStatus = '';
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        }
        // if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
        //   this.ScorePoint.score_data[0].judges_comment.map((item) => {
        //     this.juryGroupMemberArray.map((groupItem) => {
        //       // if (groupItem.judge_id !== item.user_id) {
        //       //   groupItem['view_status'] = 'N';
        //       // }
        //       if (groupItem.judge_id === item.user_id) {
        //         groupItem['view_status'] = 'Y';
        //       }
        //     })
        //   })
        // }

        // if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
        //   this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
        //     this.juryGroupMemberArray.map((groupItem) => {
        //       // if (groupItem.judge_id !== item.user_id) {
        //       //   groupItem['view_status'] = 'N';
        //       // }
        //       if (groupItem.judge_id === item.user_id) {
        //         groupItem['view_status'] = 'Y';
        //       }
        //     })
        //   })
        // }
        // if ((this.ScorePoint.score_data.length == 0) || (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
        //   console.log(this.ScorePoint)
        //   this.juryGroupMemberArray.map((groupItem) => {
        //     groupItem['view_status'] = 'N';
        //     this.showGroupMemebrData = false;
        //   })
        // }

        this.juryGroupMemberArray.map((item) => {
          let group_members_key = Object.keys(item);
          var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
          console.log(x);
          // console.log(item[x]);
          if (x && item[x]) {
            if (item[x].status == "Y") {
              item.judge_name['share_button_name'] = 'Unshare';
            }
            else if (item[x].status == "N") {
              item.judge_name['share_button_name'] = 'Share';
            }
          }
          else {
            item.judge_name['share_button_name'] = 'Share';
          }
        });
        console.log(this.juryGroupMemberArray);
      }
      else if (this.teamleaderStatus == 'N') {
        if (this.ScorePoint.score_data.length == 0) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if (this.ScorePoint.rejected_data.length == 0) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = true;
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
          this.ScorePoint.score_data[0].judges_comment.map((item) => {
            if (item.user_id == this.userDetails.id) {
              this.jurymemberSelfComment = item.status_comment;
              this.jurymemberSelfSatus = item.approved_status;
              this.showApprove = false;
            }
          })
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
          this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
            if (item.user_id == this.userDetails.id) {
              this.jurymemberSelfComment = item.status_comment;
              this.jurymemberSelfSatus = item.approved_status;
              this.showApprove = false;
            }
          })
        }

        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
          this.ScorePoint.score_data[0].judges_comment.map((item) => {
            if (item.user_id != this.userDetails.id) {
              this.showApprove = true;
            }
          })
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
          this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
            if (item.user_id != this.userDetails.id) {
              this.showApprove = false;
            }
          })
        }

        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
          this.showApprove = true;
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
          this.showApprove = false;
        }
        console.log(this.jurymemberSelfComment);
        console.log(this.jurymemberSelfSatus);
      }

      if (this.ScorePoint.hire_order != 0) {
        this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      }
      if (this.ScorePoint.hire_order == 0) {
        this.CriteraNumber = criteraNumber + '.' + 1;
      }
      this.subcriteriaTitle = this.ScorePoint.title;

      this.score_data_Array = [];
      if (this.ScorePoint.score_data.length > 0) {
        this.score_data_Array.push(this.ScorePoint.score_data[0]);
        this.score_data_Array.map((item) => {
          item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
        })
      }
      this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
      if (this.rejetedData.length > 0) {
        this.rejetedData.map((item) => {
          item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
        })
      }


      if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
        this.ScorePoint.score_data[0].judges_comment.map((item) => {
          this.juryGroupMemberArray.map((groupItem) => {
            // if (groupItem.judge_id !== item.user_id) {
            //   groupItem['view_status'] = 'N';
            // }
            if (groupItem.judge_id === item.user_id) {
              groupItem['view_status'] = 'Y';
            }
          })
        })
      }

      if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
        this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
          this.juryGroupMemberArray.map((groupItem) => {
            // if (groupItem.judge_id !== item.user_id) {
            //   groupItem['view_status'] = 'N';
            // }
            if (groupItem.judge_id === item.user_id) {
              groupItem['view_status'] = 'Y';
            }
          })
        })
      }
      if ((this.ScorePoint.score_data.length == 0) || (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
        console.log(this.ScorePoint)
        this.juryGroupMemberArray.map((groupItem) => {
          groupItem['view_status'] = 'N';
          this.showGroupMemebrData = false;
        })
      }
      // this.juryGroupMemberArray.map((item) => {
      //   if (item.is_team_leader == 'Y') {
      //     if (item.shared_data.length > 0) {
      //       item.shared_data.map((newItem) => {
      //         let group_members__share_key = Object.keys(newItem);
      //         var x = group_members__share_key.filter(x => Number(x) === this.ScorePoint.id).toString();
      //         console.log(x);
      //         // console.log(item[x]);
      //         if (x && item[x]) {
      //           if (item[x].status == "Y") {
      //             item['view_leader_status'] = 'Y';
      //           }
      //           else if (item[x].status == "N") {
      //             item['view_leader_status'] = 'N';
      //           }
      //         }
      //       })
      //     }
      //     else if (item.shared_data.length == 0) {
      //       item['view_leader_status'] = 'N';
      //     }
      //   }
      // })
    }
    else if (this.criteraNumber == 3) {
      this.ScorePoint = subcriteria;
      this.leaderSectionShow = false;
      console.log(this.ScorePoint);
      // score data 
      if (this.teamleaderStatus == 'Y') {
        this.showGroupMemebrData = false;
        if (this.ScorePoint.score_data.length > 0) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        }
        if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
        }
        if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
          this.subcriteriaStatus = '';
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        }

        this.juryGroupMemberArray.map((item) => {
          let group_members_key = Object.keys(item);
          var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
          // console.log(x)
          // console.log(item[x][0]);
          if (x && item[x]) {
            if (item[x].status == "Y") {
              item.judge_name['share_button_name'] = 'Unshare';
            }
            else if (item[x].status == "N") {
              item.judge_name['share_button_name'] = 'Share';
            }
          }
          else {
            item.judge_name['share_button_name'] = 'Share';
          }
        });
      }
      else if (this.teamleaderStatus == 'N') {
        if (this.ScorePoint.score_data.length == 0) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if (this.ScorePoint.rejected_data.length == 0) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = true;
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
          this.jurymemberSelfComment = 'NA';
          this.jurymemberSelfSatus = 'NA';
          this.showApprove = false;
        }
        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
          this.ScorePoint.score_data[0].judges_comment.map((item) => {
            if (item.user_id == this.userDetails.id) {
              this.jurymemberSelfComment = item.status_comment;
              this.jurymemberSelfSatus = item.approved_status;
              this.showApprove = false;
            }
          })
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
          this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
            if (item.user_id == this.userDetails.id) {
              this.jurymemberSelfComment = item.status_comment;
              this.jurymemberSelfSatus = item.approved_status;
              this.showApprove = false;
            }
          })
        }

        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
          this.ScorePoint.score_data[0].judges_comment.map((item) => {
            if (item.user_id != this.userDetails.id) {
              this.showApprove = true;
            }
          })
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
          this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
            if (item.user_id != this.userDetails.id) {
              this.showApprove = false;
            }
          })
        }

        if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
          this.showApprove = true;
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
          this.showApprove = false;
        }
        console.log(this.jurymemberSelfComment);
        console.log(this.jurymemberSelfSatus);
      }
      this.critera_id = this.ScorePoint.id;
      this.criteraPoint = this.ScorePoint.point;
      if (this.ScorePoint.hire_order != 0) {
        this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      }
      if (this.ScorePoint.hire_order == 0) {
        this.CriteraNumber = criteraNumber + '.' + 1;
      }
      this.subcriteriaTitle = this.ScorePoint.title;

      var key = Object.keys(this.finaAassignmentDetails.award_measurments)[criteraNumber - 1];
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
      for (let i = 0; i < this.criteriatype_name_Array.length; i++) {
        if (this.ScorePoint.score_data.length > 0) {
          if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.score_data[i].criteria_type_id) {

            this.criteriatype_name_Array[i]['ScorePoint'] = this.ScorePoint.score_data[i];
            this.criteriatype_name_Array[i].ScorePoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].ScorePoint.point_type).measurement_title;
            // this.subcriteriaStatus = this.criteriatype_name_Array[i].ScorePoint.approved_status;
          }
        }
        this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
        if (this.rejetedData.length > 0) {
          this.rejetedData.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
      }
      if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
        this.ScorePoint.score_data[0].judges_comment.map((item) => {
          this.juryGroupMemberArray.map((groupItem) => {
            // if (groupItem.judge_id !== item.user_id) {
            //   groupItem['view_status'] = 'N';
            // }
            if (groupItem.judge_id === item.user_id) {
              groupItem['view_status'] = 'Y';
            }
          })
        })
      }

      if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
        this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
          this.juryGroupMemberArray.map((groupItem) => {
            // if (groupItem.judge_id !== item.user_id) {
            //   groupItem['view_status'] = 'N';
            // }
            if (groupItem.judge_id === item.user_id) {
              groupItem['view_status'] = 'Y';
            }
          })
        })
      }
      if ((this.ScorePoint.score_data.length == 0) || (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
        console.log(this.ScorePoint)
        this.juryGroupMemberArray.map((groupItem) => {
          groupItem['view_status'] = 'N';
          this.showGroupMemebrData = false;
        })
      }

      // this.juryGroupMemberArray.map((item) => {
      //   if (item.is_team_leader == 'Y') {
      //     if (item.shared_data.length > 0) {
      //       item.shared_data.map((newItem) => {
      //         let group_members__share_key = Object.keys(newItem);
      //         var x = group_members__share_key.filter(x => Number(x) === this.ScorePoint.id).toString();
      //         console.log(x);
      //         // console.log(item[x]);
      //         if (x && item[x]) {
      //           if (item[x].status == "Y") {
      //             item['view_leader_status'] = 'Y';
      //           }
      //           else if (item[x].status == "N") {
      //             item['view_leader_status'] = 'N';
      //           }
      //         }
      //       })
      //     }
      //     else if (item.shared_data.length == 0) {
      //       item['view_leader_status'] = 'N';
      //     }
      //   }
      // })
      console.log(this.criteriatype_name_Array);
    }
  }
  addEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      resize_enabled: false,
      // toolbar: {
      // items: ['bold','italic','underline','|','strikethrough','subscript','superscript','|','bulletedList','numberedList','|','blockQuote','codeBlock','code','horizontalLine']
      // },
      language: 'en-gb',
      licenseKey: '',

    })
      .then(editor => {
        console.log(editor)
      })
      .catch(error => {
        console.error(error);
      });
  }
  // convenience getter for easy access to form fields
  get f1() { return this.approveForm.controls; }

  // convenience getter for easy access to form fields
  get f2() { return this.rejectForm.controls; }
  open(content, juryItem, className) {
    let modalClassName = className;
    this.singleJuryDetails = juryItem;
    this.submitted1 = false;
    this.submitted2 = false;
    this.approveForm.reset();
    this.rejectForm.reset();
    this.processingText1 = false;
    this.processingText2 = false;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  formOneSubmit() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.approveForm.invalid) {
      return;
    }
    if (this.criteraNumber != 3) {
      if (this.teamleaderStatus == 'Y') {
        let data = {
          'user_token': this.user_token,
          'award_id': this.ScorePoint.score_data[0].award_id,
          'company_id': this.ScorePoint.score_data[0].company_id,
          'group_id': this.ScorePoint.score_data[0].group_id,
          'criteria_id': this.ScorePoint.score_data[0].criteria_id,
          'status': 'A',
          'comment': this.approveForm.value.comments1
        }
        console.log(data);
        this.errMsg1 = '';
        this.processingText1 = true;
        this.judgeService.assesmentStatusChange(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.scoreBoardDetails();
            // this.processingText1 = false;
            // this.approveForm.reset();
            // this.modalService.dismissAll();
            setTimeout(() => {
              this.messagesService.showSuccess(res.msg);
            }, 2500);
          }
          if (res.has_error == 1) {
            this.processingText1 = false;
            this.errMsg1 = res.msg;
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2500);
          }
        },
          err => {
            this.processingText1 = false;
            if (err.error.message == "Unauthenticated.") {
              this.modalService.dismissAll();
            }
            if (err.error.message != "Unauthenticated.") {
              this.errMsg1 = 'Something went wrong.';
              setTimeout(() => {
                this.errMsg1 = '';
              }, 2000);
            }
            setTimeout(() => {
              this.messagesService.clear();
            }, 2500);
          })
      }
      if (this.teamleaderStatus == 'N') {
        let data = {
          'user_token': this.user_token,
          'award_id': this.ScorePoint.score_data[0].award_id,
          'company_id': this.ScorePoint.score_data[0].company_id,
          'judge_group_id': this.finaAassignmentDetails.group_id,
          'assessor_group_id': this.ScorePoint.score_data[0].group_id,
          'criteria_id': this.ScorePoint.score_data[0].criteria_id,
          'status': 'A',
          'comment': this.approveForm.value.comments1,
          'score_point_id': this.ScorePoint.score_data[0].id,
        }
        console.log(data);
        this.processingText1 = true;
        this.judgeService.addJudgeComment(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.scoreBoardDetails();
            // this.processingText1 = false;
            // this.approveForm.reset();
            // this.modalService.dismissAll();
            setTimeout(() => {
              this.messagesService.showSuccess('Status updated successfully');
            }, 2500);
          }
          if (res.has_error == 1) {
            this.errMsg1 = res.msg;
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2500);
            this.processingText1 = false;
          }
        },
          err => {
            this.processingText1 = true;
            if (err.error.message == "Unauthenticated.") {
              this.modalService.dismissAll();
            }
            if (err.error.message != "Unauthenticated.") {
              this.errMsg1 = 'Something went wrong.';
              setTimeout(() => {
                this.errMsg1 = '';
              }, 2000);
            }
            setTimeout(() => {
              this.messagesService.clear();
            }, 2500);
          })
      }
    }
    if (this.criteraNumber == 3) {
      this.levelThreeApprove();
    }
  }
  levelThreeApprove() {
    if (this.teamleaderStatus == 'Y') {
      let data = {
        'user_token': this.user_token,
        'award_id': this.ScorePoint.score_data[0].award_id,
        'company_id': this.ScorePoint.score_data[0].company_id,
        'group_id': this.ScorePoint.score_data[0].group_id,
        'criteria_id': this.ScorePoint.score_data[0].criteria_id,
        'status': 'A',
        'comment': this.approveForm.value.comments1
      }
      console.log(data);
      this.errMsg1 = '';
      this.processingText1 = true;
      this.judgeService.assesmentStatusChange(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          // this.processingText1 = false;
          this.scoreBoardDetails();
          // this.approveForm.reset();
          // this.modalService.dismissAll();
          setTimeout(() => {
            this.messagesService.showSuccess(res.msg);
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg1 = res.msg;
          setTimeout(() => {
            this.errMsg1 = '';
          }, 2500);
          this.processingText1 = false;
        }
      },
        err => {
          this.processingText1 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg1 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
    if (this.teamleaderStatus == 'N') {
      this.score_point_id_array = [];
      this.ScorePoint.score_data.map((item) => {
        this.score_point_id_array.push(item.id)
      })
      this.score_point_id_array = this.score_point_id_array.reverse();
      let data = {
        'user_token': this.user_token,
        'award_id': this.ScorePoint.score_data[0].award_id,
        'company_id': this.ScorePoint.score_data[0].company_id,
        'judge_group_id': this.finaAassignmentDetails.group_id,
        'assessor_group_id': this.ScorePoint.score_data[0].group_id,
        'criteria_id': this.ScorePoint.score_data[0].criteria_id,
        'status': 'A',
        'comment': this.approveForm.value.comments1,
        'score_point_ids': this.score_point_id_array
      }
      console.log(data);
      this.processingText1 = true;
      this.judgeService.addJudgeCommentMul(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          // this.processingText1 = false;
          this.scoreBoardDetails();
          // this.approveForm.reset();
          // this.modalService.dismissAll();
          setTimeout(() => {
            this.messagesService.showSuccess('Status updated successfully');
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg1 = res.msg;
          setTimeout(() => {
            this.errMsg1 = '';
          }, 2500);
          this.processingText1 = false;
        }
      },
        err => {
          this.processingText1 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg1 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
  }
  formTwoSubmit() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.rejectForm.invalid) {
      return;
    }
    if (this.criteraNumber != 3) {
      if (this.teamleaderStatus == 'Y') {
        let data = {
          'user_token': this.user_token,
          'award_id': this.ScorePoint.score_data[0].award_id,
          'company_id': this.ScorePoint.score_data[0].company_id,
          'group_id': this.ScorePoint.score_data[0].group_id,
          'criteria_id': this.ScorePoint.score_data[0].criteria_id,
          'status': 'R',
          'comment': this.rejectForm.value.comments2
        }
        this.errMsg2 = '';
        this.processingText2 = true;
        this.judgeService.assesmentStatusChange(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            // this.processingText2 = false;
            this.scoreBoardDetails();
            // this.rejectForm.reset();
            // this.modalService.dismissAll();
            setTimeout(() => {
              this.messagesService.showSuccess(res.msg);
            }, 2500);
          }
          if (res.has_error == 1) {
            this.errMsg2 = res.msg;
            setTimeout(() => {
              this.errMsg2 = '';
            }, 2500);
            this.processingText2 = false;
          }
        },
          err => {
            this.processingText2 = false;
            if (err.error.message == "Unauthenticated.") {
              this.modalService.dismissAll();
            }
            if (err.error.message != "Unauthenticated.") {
              this.errMsg2 = 'Something went wrong.';
              setTimeout(() => {
                this.errMsg2 = '';
              }, 2000);
            }
            setTimeout(() => {
              this.messagesService.clear();
            }, 2500);
          })
      }
      if (this.teamleaderStatus == 'N') {
        let data = {
          'user_token': this.user_token,
          'award_id': this.ScorePoint.score_data[0].award_id,
          'company_id': this.ScorePoint.score_data[0].company_id,
          'judge_group_id': this.finaAassignmentDetails.group_id,
          'assessor_group_id': this.ScorePoint.score_data[0].group_id,
          'criteria_id': this.ScorePoint.score_data[0].criteria_id,
          'status': 'R',
          'comment': this.rejectForm.value.comments2,
          'score_point_id': this.ScorePoint.score_data[0].id,
        }
        console.log(data);
        this.processingText2 = true;
        this.judgeService.addJudgeComment(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            // this.processingText2 = false;
            this.scoreBoardDetails();
            // this.approveForm.reset();
            // this.modalService.dismissAll();
            setTimeout(() => {
              this.messagesService.showSuccess('Status updated successfully');
            }, 2500);
          }
          if (res.has_error == 1) {
            this.errMsg1 = res.msg;
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2500);
            this.processingText2 = false;
          }
        },
          err => {
            this.processingText1 = false;
            if (err.error.message == "Unauthenticated.") {
              this.modalService.dismissAll();
            }
            if (err.error.message != "Unauthenticated.") {
              this.errMsg1 = 'Something went wrong.';
              setTimeout(() => {
                this.errMsg1 = '';
              }, 2000);
            }
            setTimeout(() => {
              this.messagesService.clear();
            }, 2500);
          })
      }
    }
    if (this.criteraNumber == 3) {
      this.levelThreeReject();
    }
  }
  levelThreeReject() {
    if (this.teamleaderStatus == 'Y') {
      let data = {
        'user_token': this.user_token,
        'award_id': this.ScorePoint.score_data[0].award_id,
        'company_id': this.ScorePoint.score_data[0].company_id,
        'group_id': this.ScorePoint.score_data[0].group_id,
        'criteria_id': this.ScorePoint.score_data[0].criteria_id,
        'status': 'R',
        'comment': this.rejectForm.value.comments2
      }
      this.errMsg2 = '';
      this.processingText2 = true;
      this.judgeService.assesmentStatusChange(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          // this.processingText2 = false;
          // this.rejectForm.reset();
          // this.modalService.dismissAll();
          this.scoreBoardDetails();
          setTimeout(() => {
            this.messagesService.showSuccess(res.msg);
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg2 = res.msg;
          setTimeout(() => {
            this.errMsg2 = '';
          }, 2500);
          this.processingText2 = false;
        }
      },
        err => {
          this.processingText2 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg2 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg2 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
    if (this.teamleaderStatus == 'N') {
      this.score_point_id_array = [];
      this.ScorePoint.score_data.map((item) => {
        this.score_point_id_array.push(item.id)
      })
      this.score_point_id_array = this.score_point_id_array.reverse();
      let data = {
        'user_token': this.user_token,
        'award_id': this.ScorePoint.score_data[0].award_id,
        'company_id': this.ScorePoint.score_data[0].company_id,
        'judge_group_id': this.finaAassignmentDetails.group_id,
        'assessor_group_id': this.ScorePoint.score_data[0].group_id,
        'criteria_id': this.ScorePoint.score_data[0].criteria_id,
        'status': 'R',
        'comment': this.rejectForm.value.comments2,
        'score_point_ids': this.score_point_id_array
      }
      console.log(data);
      this.processingText2 = true;
      this.judgeService.addJudgeCommentMul(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          // this.processingText2 = false;
          // this.approveForm.reset();
          // this.modalService.dismissAll();
          this.scoreBoardDetails();
          setTimeout(() => {
            this.messagesService.showSuccess('Status updated successfully');
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg1 = res.msg;
          setTimeout(() => {
            this.errMsg1 = '';
          }, 2500);
          this.processingText2 = false;
        }
      },
        err => {
          this.processingText2 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg1 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
  }
  scoreBoardDetails() {
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'award_id': this.IdParams.id2,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'teamleaderid': this.IdParams.id4
    }
    this.judgeService.showFinalAwardScore(this.api_token, data).subscribe((res: any) => {
      this.finaAassignmentDetails = res;
      this.subcriteriaList = res.get_all_criteria;
      console.log(this.subcriteriaList);
      this.juryGroupMemberArray = res.group_members;
      
      if (this.criteraNumber != 3) {
        this.subcriteriaList.map((item) => {
          if (item.id == this.ScorePoint.id) {
            this.ScorePoint = item;
          }
        })
        console.log(this.ScorePoint);
        // this.CriteraNumber = this.criteraNumber + '.' + this.ScorePoint.hire_order;

        if (this.ScorePoint.hire_order != 0) {
          this.CriteraNumber = this.criteraNumber + '.' + this.ScorePoint.hire_order;
        }
        if (this.ScorePoint.hire_order == 0) {
          this.CriteraNumber = this.criteraNumber + '.' + 1;
        }
        if (this.teamleaderStatus == 'Y') {
          this.showGroupMemebrData = false;
          if (this.ScorePoint.score_data.length > 0) {
            this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          }
          if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
            this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
          }
          if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
            this.subcriteriaStatus = '';
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
            this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          }
          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
            this.ScorePoint.score_data[0].judges_comment.map((item) => {
              this.juryGroupMemberArray.map((groupItem) => {
                // if (groupItem.judge_id !== item.user_id) {
                //   groupItem['view_status'] = 'N';
                // }
                if (groupItem.judge_id === item.user_id) {
                  groupItem['view_status'] = 'Y';
                }
              })
            })
          }

          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
            this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
              this.juryGroupMemberArray.map((groupItem) => {
                // if (groupItem.judge_id !== item.user_id) {
                //   groupItem['view_status'] = 'N';
                // }
                if (groupItem.judge_id === item.user_id) {
                  groupItem['view_status'] = 'Y';
                }
              })
            })
          }
          if ((this.ScorePoint.score_data.length == 0) || (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
            console.log(this.ScorePoint)
            this.juryGroupMemberArray.map((groupItem) => {
              groupItem['view_status'] = 'N';
              this.showGroupMemebrData = false;
            })
          }

          // this.juryGroupMemberArray.map((item) => {
          //   if (item.is_team_leader == 'Y') {
          //     if (item.shared_data.length > 0) {
          //       item.shared_data.map((newItem) => {
          //         let group_members__share_key = Object.keys(newItem);
          //         var x = group_members__share_key.filter(x => Number(x) === this.ScorePoint.id).toString();
          //         console.log(x);
          //         // console.log(item[x]);
          //         if (x && item[x]) {
          //           if (item[x].status == "Y") {
          //             item['view_leader_status'] = 'Y';
          //           }
          //           else if (item[x].status == "N") {
          //             item['view_leader_status'] = 'N';
          //           }
          //         }
          //       })
          //     }
          //     else if (item.shared_data.length == 0) {
          //       item['view_leader_status'] = 'N';
          //     }
          //   }
          // })

          this.juryGroupMemberArray.map((item) => {
            let group_members_key = Object.keys(item);
            var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
            console.log(x);
            // console.log(item[x]);
            if (x && item[x]) {
              if (item[x].status == "Y") {
                item.judge_name['share_button_name'] = 'Unshare';
              }
              else if (item[x].status == "N") {
                item.judge_name['share_button_name'] = 'Share';
              }
            }
            else {
              item.judge_name['share_button_name'] = 'Share';
            }
          });
        }
        else if (this.teamleaderStatus == 'N') {
          if (this.ScorePoint.score_data.length == 0) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = false;
          }
          if (this.ScorePoint.rejected_data.length == 0) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = false;
          }
          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = true;
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = false;
          }
          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
            this.ScorePoint.score_data[0].judges_comment.map((item) => {
              if (item.user_id == this.userDetails.id) {
                this.jurymemberSelfComment = item.status_comment;
                this.jurymemberSelfSatus = item.approved_status;
                this.showApprove = false;
              }
            })
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
            this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
              if (item.user_id == this.userDetails.id) {
                this.jurymemberSelfComment = item.status_comment;
                this.jurymemberSelfSatus = item.approved_status;
                this.showApprove = false;
              }
            })
          }

          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
            this.ScorePoint.score_data[0].judges_comment.map((item) => {
              if (item.user_id != this.userDetails.id) {
                this.showApprove = true;
              }
            })
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
            this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
              if (item.user_id != this.userDetails.id) {
                this.showApprove = false;
              }
            })
          }

          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
            this.showApprove = true;
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
            this.showApprove = false;
          }
          // console.log(this.jurymemberSelfComment);
          // console.log(this.jurymemberSelfSatus);
        }
        this.processingText2 = false;
        this.processingText1 = false;
        this.approveForm.reset();
        this.rejectForm.reset();
        this.modalService.dismissAll();
        // console.log(this.subcriteriaStatus);
        // console.log(this.jurymemberSelfSatus);
        this.score_data_Array = [];
        if (this.ScorePoint.score_data.length > 0) {
          this.score_data_Array.push(this.ScorePoint.score_data[0]);
          this.score_data_Array.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }

        this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
        if (this.rejetedData.length > 0) {
          this.rejetedData.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
      }
      if (this.criteraNumber == 3) {
        this.subcriteriaList.map((item) => {
          if (item.id == this.ScorePoint.id) {
            this.ScorePoint = item;
          }
        })

        if (this.ScorePoint.score_data.length > 0) {
          this.ScorePoint.score_data = this.ScorePoint.score_data.reverse();
        }

        this.CriteraNumber = this.criteraNumber + '.' + this.ScorePoint.hire_order;
        if (this.teamleaderStatus == 'Y') {
          this.subcriteriaStatus = '';
          if (this.ScorePoint.score_data.length > 0) {
            this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          }
          if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
            this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
          }
          if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
            this.subcriteriaStatus = '';
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
            this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          }

          this.juryGroupMemberArray.map((item) => {
            let group_members_key = Object.keys(item);
            var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
            console.log(x);
            // console.log(item[x]);
            if (x && item[x]) {
              if (item[x].status == "Y") {
                item.judge_name['share_button_name'] = 'Unshare';
              }
              else if (item[x].status == "N") {
                item.judge_name['share_button_name'] = 'Share';
              }
            }
            else {
              item.judge_name['share_button_name'] = 'Share';
            }
          });
        }
        else if (this.teamleaderStatus == 'N') {
          if (this.ScorePoint.score_data.length == 0) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = false;
          }
          if (this.ScorePoint.rejected_data.length == 0) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = false;
          }
          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = true;
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
            this.jurymemberSelfComment = 'NA';
            this.jurymemberSelfSatus = 'NA';
            this.showApprove = false;
          }
          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
            this.ScorePoint.score_data[0].judges_comment.map((item) => {
              if (item.user_id == this.userDetails.id) {
                this.jurymemberSelfComment = item.status_comment;
                this.jurymemberSelfSatus = item.approved_status;
                this.showApprove = false;
              }
            })
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
            this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
              if (item.user_id == this.userDetails.id) {
                this.jurymemberSelfComment = item.status_comment;
                this.jurymemberSelfSatus = item.approved_status;
                this.showApprove = false;
              }
            })
          }

          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length > 0)) {
            this.ScorePoint.score_data[0].judges_comment.map((item) => {
              if (item.user_id != this.userDetails.id) {
                this.showApprove = true;
              }
            })
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length > 0)) {
            this.ScorePoint.rejected_data[0].judges_comment.map((item) => {
              if (item.user_id != this.userDetails.id) {
                this.showApprove = false;
              }
            })
          }

          if ((this.ScorePoint.score_data.length > 0) && (this.ScorePoint.score_data[0].judges_comment.length == 0)) {
            this.showApprove = true;
          }
          if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.rejected_data[0].judges_comment.length == 0)) {
            this.showApprove = false;
          }
          // console.log(this.jurymemberSelfComment);
          // console.log(this.jurymemberSelfSatus);
        }
        // console.log(this.subcriteriaStatus);
        this.processingText2 = false;
        this.processingText1 = false;
        this.approveForm.reset();
        this.rejectForm.reset();
        this.modalService.dismissAll();
        var key = Object.keys(this.finaAassignmentDetails.award_measurments)[this.criteraNumber - 1];
        this.awardMeasurmentsList = [];
        this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
        for (let i = 0; i < this.criteriatype_name_Array.length; i++) {
          if (this.ScorePoint.score_data.length > 0) {
            if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.score_data[i].criteria_type_id) {

              this.criteriatype_name_Array[i]['ScorePoint'] = this.ScorePoint.score_data[i];
              this.criteriatype_name_Array[i].ScorePoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].ScorePoint.point_type).measurement_title;
              this.subcriteriaStatus = this.criteriatype_name_Array[i].ScorePoint.approved_status;
            }
          }
          if (this.ScorePoint.rejected_data.length > 0) {
            if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.rejected_data[i].criteria_type_id) {
              this.criteriatype_name_Array[i]['RejectedPoint'] = this.ScorePoint.rejected_data[i];
              this.criteriatype_name_Array[i].RejectedPoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].RejectedPoint.point_type).measurement_title;
              this.subcriteriaStatus = this.criteriatype_name_Array[i].RejectedPoint.approved_status;
            }
          }
        }
        console.log(this.criteriatype_name_Array);
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  showMemberDeatils(member) {
    console.log(member);
    if (member.view_status == 'Y') {
      this.ScorePoint.score_data[0].judges_comment.map((item) => {
        if (item.user_id == member.judge_id) {
          this.leaderSectionShow = false;
          this.showGroupMemebrData = true;
          this.memberName = member.judge_name.name;
          this.memberStatusComment = item.status_comment;
          this.memberStatus = item.approved_status;

          if(document.querySelector(".dev_jury_member[boxId='"+ member.judge_id +"']") !=undefined){
            this.memberArray = this.memberArray.filter(x => x.judge_id != member.judge_id);
          }
          else {
          this.memberArray.push({
            'memberName': this.memberName,
            'memberStatusComment': this.memberStatusComment,
            'memberStatus': this.memberStatus,
            'judge_id': member.judge_id
          });
          this.memberArray = this.memberArray.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.judge_id === thing.judge_id
          )));
          }
        }
      })
    }
    else if (member.view_status == 'N') {
      this.messagesService.showError('No member Comment Found for this criteria');
    }
    else if (!member.hasOwnProperty('view_status')) {
      this.messagesService.showError('No member Comment Found for this criteria');
    }
  }
  // view share data
  viewSharedData(member) {
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'criteria_id': this.ScorePoint.id,
      'company_id': this.IdParams.id,
      'user_id': member.judge_name.id
    }
    this.dashboardService.showSharedData(this.api_token, data).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        if (res.shareData != null) {
          if (res.shareData.status == 'N') {
            this.leaderStatus = '';
            this.leaderComment = '';
            this.leaderSectionShow = false;
            this.messagesService.showError('No shared Data Found');
          }
          if (res.shareData.status == 'Y') {
            this.leaderSectionShow = true;
            if (this.ScorePoint.score_data.length > 0 && this.ScorePoint.score_data[0].approved_status == 'A') {
              this.leaderName = member.judge_name.name;
              this.leaderStatus = this.ScorePoint.score_data[0].approved_status;
              this.leaderComment = this.ScorePoint.score_data[0].status_comment;

              if(document.querySelector(".dev_jury_member[boxId='"+ member.judge_id +"']") !=undefined){
                this.memberArray = this.memberArray.filter(x => x.judge_id != member.judge_id);
              }
              else {
              this.memberArray.push({
                'memberName': this.leaderName,
                'memberStatusComment': this.leaderComment,
                'memberStatus': this.leaderStatus,
                'judge_id': member.judge_id,
              });
              this.memberArray = this.memberArray.filter((thing, index, self) =>
              index === self.findIndex((t) => (
                t.judge_id === thing.judge_id
              )));
              }
            }
            // if (this.ScorePoint.score_data.length == 0 && this.ScorePoint.rejected_data.length > 0 && this.ScorePoint.rejected_data[0].approved_status == 'R') {
            //   this.leaderStatus = this.ScorePoint.rejected_data[0].approved_status;
            //   this.leaderComment = this.ScorePoint.rejected_data[0].status_comment;
            // }
            if (this.ScorePoint.score_data.length == 0 || this.ScorePoint.score_data[0].approved_status == 'U' && this.ScorePoint.rejected_data.length > 0) {
              console.log(this.ScorePoint.rejected_data.length);
              this.leaderName = member.judge_name.name;
              this.leaderStatus = this.ScorePoint.rejected_data[this.ScorePoint.rejected_data.length - 1].approved_status;
              this.leaderComment = this.ScorePoint.rejected_data[this.ScorePoint.rejected_data.length - 1].status_comment;

              if(document.querySelector(".dev_jury_member[boxId='"+ member.judge_id +"']") !=undefined){
                this.memberArray = this.memberArray.filter(x => x.judge_id != member.judge_id);
              }
              else {
              this.memberArray.push({
                'memberName': this.leaderName,
                'memberStatusComment': this.leaderComment,
                'memberStatus': this.leaderStatus,
                'judge_id': member.judge_id,
              });
              this.memberArray = this.memberArray.filter((thing, index, self) =>
              index === self.findIndex((t) => (
                t.judge_id === thing.judge_id
              )));
              }
            }
          }
        }
        else if (res.shareData == null) {
          this.leaderSectionShow = false;
          this.leaderStatus = '';
          this.leaderComment = '';
          this.messagesService.showError('No shared Data Found');
          setTimeout(() => {
            this.messagesService.clear();
          }, 1500);
        }
      }
      if (res.has_error == 1) {
        if (res.shareData == null) {
          this.leaderSectionShow = false;
          this.leaderStatus = '';
          this.leaderComment = '';
          this.messagesService.showError('No shared Data Found');
          setTimeout(() => {
            this.messagesService.clear();
          }, 1500);
        }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1500);
      })
    // }
    // else if (this.showOthersData == false) {
    //   this.messagesService.showError('Please Fillup your own data First');
    //   setTimeout(() => {
    //     this.messagesService.clear();
    //   }, 1500);
    // }
    // }
  }
  openDialog(member) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to proceed?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.leaderCommentShare(member);
      }
    });
  }

  leaderCommentShare(member) {
    console.log(member)
    var key = Object.keys(member);
    var x = key.filter(x => Number(x) === this.ScorePoint.id).toString();
    if (x && member[x]) {
      if (member[x].status == 'Y') {
        this.share_status = 'N';
      }
      else if (member[x].status == 'N') {
        this.share_status = 'Y';
      }
    }
    else if (x && !member[x]) {
      this.share_status = 'Y';
    }
    else if (!x || !member[x]) {
      this.share_status = 'Y';
    }
    else if (!x && !member[x]) {
      this.share_status = 'Y';
    }
    console.log(this.share_status);
    let data = {
      criteria_id: this.ScorePoint.id,
      company_id: this.IdParams.id,
      team_member_id: member.judge_id,
      judge_group_id: member.group_id,
      share_status: this.share_status
    }
    this.judgeService.leaderCommentShare(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.scoreBoardDetails();
        this.messagesService.showSuccess(res.msg);
        if (res.msg == "Un-Shared successfully.") {
          this.juryGroupMemberArray.map((item) => {
            if (item.judge_id == member.judge_id) {
              let group_members_key = Object.keys(item);
              var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
              if (x && item[x]) {
                item[x].status = this.share_status;
                if (item[x].status == "Y") {
                  item.judge_name['share_button_name'] = 'Unshare';
                }
                else if (item[x].status == "N") {
                  item.judge_name['share_button_name'] = 'Share';
                }
              }
              else if (x && !item[x]) {
                item[x] = {};
                item[x]['status'] = this.share_status
                console.log(item[x]);
                if (item[x].status == "Y") {
                  item.judge_name['share_button_name'] = 'Unshare';
                }
                else if (item[x].status == "N") {
                  item.judge_name['share_button_name'] = 'Share';
                }
              }
            }
          });
          console.log(this.juryGroupMemberArray)

        }
        else if (res.msg == "Shared successfully.") {
          // this.assignmentDetails.group_members
          this.juryGroupMemberArray.map((item) => {
            if (item.judge_id == member.judge_id) {
              let group_members_key = Object.keys(item);
              var x = group_members_key.filter(x => Number(x) === this.ScorePoint.id).toString();
              if (x && item[x]) {
                item[x].status = this.share_status;
                if (item[x].status == "Y") {
                  item.judge_name['share_button_name'] = 'Unshare';
                }
                else if (item[x].status == "N") {
                  item.judge_name['share_button_name'] = 'Share';
                }
              }
              else if (x && !item[x]) {
                item[x] = {};
                item[x]['status'] = this.share_status
                console.log(item[x]);
                if (item[x].status == "Y") {
                  item.judge_name['share_button_name'] = 'Unshare';
                }
                else if (item[x].status == "N") {
                  item.judge_name['share_button_name'] = 'Share';
                }
              }
            }
          });
          console.log(this.juryGroupMemberArray)

        }
      }
      if (res.has_error == 1) {
        this.messagesService.showError(res.msg);
        // for (let [key, value] of Object.entries(res.msg)) {
        //   this.messagesService.showError(value + 'Please Select one Member');
        // }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }

  sendComment() {
    let data = {
      'user_token': this.user_token,
      'award_id': this.IdParams.id2,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'type': 'T',
      'message': this.commentMessage,
      'last_chat_id': ''
    }
    console.log(data);
    // this.judgeService.sendComment(this.api_token, data).subscribe((res: any) => {
    //   console.log(data)
    // })
  }
}

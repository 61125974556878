import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentHistoryComponent } from './payment-history.component';
import { RouterModule, Routes } from '@angular/router';
import { MessagesService } from '../../services/messages.service';
// intro routing
export const routes: Routes = [
  { path: '', component: PaymentHistoryComponent },
];

@NgModule({
  declarations: [PaymentHistoryComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],providers: [MessagesService]
})
export class PaymentHistoryModule { }

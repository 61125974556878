import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactUsForm: FormGroup;
  submitted = false;
  processingText: boolean = false;
  awardList: any = [];
  userTypeList: any = [];
  user_token: string;
  InquiryList: any = ['Award Information','Award Application','Join Assessment Team','Partnership','Others'];
  recapChaResponse: any;
  capchaErr: any;
  recaptcha: any;
  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private messagesService: MessagesService) {
      this.user_token = localStorage.getItem('user_token');
     }

  ngOnInit() {
    this.contactUsForm = this.formBuilder.group({
      award: ['', Validators.required],
      user_type: [''],
      organization: ['', Validators.required],
      position: ['', Validators.required],
      inquiry_type: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
    this.getAwardList();
    this.getUserTypeList();
    setTimeout(() => {
      this.recaptcha = (window as any).grecaptcha;
    console.log(this.recaptcha);
    }, 1500);
  }

  showResponse(response) {
    console.log(response);
    this.recapChaResponse = response.response;
    console.log(this.recapChaResponse);
    if(this.recapChaResponse != undefined){
      this.capchaErr ='';
    }
    //call to a backend to verify against recaptcha with private key
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactUsForm.controls; }

  getAwardList() {
    this.authService.allAwardList(this.user_token).subscribe((res: any) => {
      console.log(res)
      if (res.has_error == 0) {
        this.awardList = res.award_data;
      }
    }, err => {
    })
  }

  getUserTypeList() {
      this.authService.accountType(this.user_token).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.userTypeList = res.userTypes;
        }
      }, err => {
      })
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      return;
    }
    let data = {
      award_id: this.contactUsForm.value.award,
      user_type: this.contactUsForm.value.user_type,
      organization: this.contactUsForm.value.organization,
      position: this.contactUsForm.value.position,
      first_name: this.contactUsForm.value.first_name,
      last_name: this.contactUsForm.value.last_name,
      email: this.contactUsForm.value.email,
      phone: this.contactUsForm.value.phone,
      subject: this.contactUsForm.value.subject,
      message: this.contactUsForm.value.message
    }
    if (this.recapChaResponse != undefined) {
    this.processingText = true;
    this.authService.contactUs(data).subscribe((res: any) => {
      console.log(res)
      if (res.has_error == 0) {
        this.processingText = false;
        this.submitted = false;
        this.contactUsForm.reset();
        this.messagesService.showSuccess(res.msg);
        setTimeout(() => {
          this.messagesService.clear();
        }, 2000);
      }
      else if (res.has_error == 1) {
        this.processingText = false;
        // this.messagesService.showError('Invalid email');
        // setTimeout(() => {
        //   this.messagesService.clear();
        // }, 2000);
      }
    }, err => {
      this.processingText = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 2000);
    })
}
    else if (this.recapChaResponse == undefined) {
      this.capchaErr = 'Please validate the capcha';
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { LandingComponent } from './landing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WinnerService } from '../services/winner.service';
import { PartnersModule } from '../components/partners/partners.module';
import { WinnersModule } from '../components/winners/winners.module';
import { JudgesModule } from '../components/judges/judges.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MessagesService } from '../services/messages.service';

// intro routing
export const routes: Routes = [
  { path: '', component: LandingComponent },
];

@NgModule({
  declarations: [LandingComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PartnersModule,
    WinnersModule,
    JudgesModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  providers: [
    WinnerService,
    MessagesService
  ]
})
export class LandingModule { }

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../services/data.service';
import { DashboardService } from '../services/dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../services/excel.service';
import { MessagesService } from '../services/messages.service';
import { DatePipe } from '@angular/common';
import { appConfig } from '../app.config';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JudgeService } from '../services/judge.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-award-office-dashboard',
  templateUrl: './award-office-dashboard.component.html',
  styleUrls: ['./award-office-dashboard.component.scss'],
  providers: [NgbRatingConfig]
})
export class AwardOfficeDashboardComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  lazyLoad: boolean = false;
  user_token: any;
  api_token: any;
  awardOfficeDetails: any = [];
  singleOfficeDetails: any;
  closeResult: string;
  licenseDownloadLink: any;
  company_logo_path: any;
  userDetails: any;
  archiveProcessing: boolean = false;
  assessorDocsData: any = [];
  juryyDocumentDownloadLink: any;
  juryUploadDocumentLink: any;
  companyGeneralDocumentDownloadLink: any;
  criteriaName: any;
  officeRating: any;
  companymanagementListArray: any = [];
  searchAwardList: any = [];
  searchCompanyNameList: any = [];
  searchCompany: any;
  searchAwardDetails: any = [];
  submitted1: boolean = false;
  submitted2: boolean = false;
  processingText1: boolean = false;
  processingText2: boolean = false;
  approveForm: FormGroup;
  rejectForm: FormGroup;
  errMsg1: any;
  errMsg2: any;
  permissionMsg: any;
  permissionStatus: boolean = false;

  chartCriteriaList: any = [];
  selectIndex: any;
  public fullChartArray: Array<any> = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: 'Your chart title',
      fontColor: 'black',  // chart title color (can be hexadecimal too)
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Criteria'
        },
        gridLines: {
          display: false,
        }
      }], yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Scores'
        },
        gridLines: {
          drawBorder: false,
        },
        barThickness: 10
      }]
    },
  };

  chartData = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];

  chartLabels = [];

  public barChartColors = [
    { backgroundColor: '#4fb5ef' },
    { backgroundColor: '#a931a9' },
  ]

  chartImageArray: any = [];
  processigntext: boolean = false;
  submissionDateChangeForm: FormGroup;
  scoringDateChangeForm: FormGroup;
  submitted3: boolean = false;
  submitted4: boolean = false;
  processingText3: boolean = false;
  processingText4: boolean = false;
  errMsg3: any;
  errMsg4: any;
  currentDate: any;
  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private router: Router,
    private excelService: ExcelService,
    private dialog: MatDialog,
    config: NgbRatingConfig,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/award-office-dashboard')
          this.leftbarselected = 'dashboard';
        this.dataService.leftPanelSelection('award_office_dashboard');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.currentDate = new Date();
    // this.currentDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    console.log(this.currentDate);

  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getDashboardDeails();
    this.companymanagementList();
    this.approveForm = this.formBuilder.group({
      comments1: ['', Validators.required]
    });
    this.rejectForm = this.formBuilder.group({
      comments2: ['', Validators.required]
    });
    this.submissionDateChangeForm = this.formBuilder.group({
      submission_date: ['', Validators.required]
    });
    this.scoringDateChangeForm = this.formBuilder.group({
      scoring_date: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f1() { return this.approveForm.controls; }

  // convenience getter for easy access to form fields
  get f2() { return this.rejectForm.controls; }

  // convenience getter for easy access to form fields
  get f3() { return this.submissionDateChangeForm.controls; }

  // convenience getter for easy access to form fields
  get f4() { return this.scoringDateChangeForm.controls; }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  getDashboardDeails() {
    this.lazyLoad = true;
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.awardOfficeDetails = res.office_dashboard_details;
        this.searchAwardList = res.searchAwardList;
        this.searchCompanyNameList = res.searchCompanyNameList;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';

        this.juryyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
        this.company_logo_path = res.logo_path;
        // console.log(this.awardOfficeDetails);
        this.awardOfficeDetails.map((item) => {
          if (item.submission_date == null) {
            item.final_submission_date = new Date(item.award_year.submission_date);
          }
          else if (item.submission_date != null) {
            item.final_submission_date = new Date(item.submission_date);
          }
          if (item.final_scoring_date == null) {
            item.final_scoring_date = new Date(item.award_year.scoring_date);
          }
          else if (item.final_scoring_date != null) {
            item.final_scoring_date = new Date(item.scoring_date);
          }

          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;

          if (this.currentDate < item.final_submission_date) {
            item.show_submission_date = false;
          }
          if (this.currentDate > item.final_submission_date) {
            item.show_submission_date = true;
          }
          if (this.currentDate < item.final_scoring_date) {
            item.show_scoring_date = false;
          }
          if (this.currentDate > item.final_scoring_date) {
            item.show_scoring_date = true;
          }
        })
        console.log(this.awardOfficeDetails);
        console.log(this.currentDate);
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
      }
    }, err => {
      this.lazyLoad = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  onChangeCompany(value) {
    this.searchCompany = value;
  }
  onChangeAward(value) {
    this.searchAwardDetails = this.searchAwardList.filter(x => x.id == value);
  }
  awardSearchSubmit() {
    let data = {
      searchAward: this.searchAwardDetails.length > 0 ? this.searchAwardDetails[0].id : '',
      searchAwardYear: this.searchAwardDetails.length > 0 ? this.searchAwardDetails[0].year : '',
      searchCompany: this.searchCompany
    }
    this.dashboardService.filterAwardOfficeDashboard(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardOfficeDetails = res.office_dashboard_details;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';

        this.juryyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
        this.company_logo_path = res.logo_path;
        console.log(this.awardOfficeDetails);
        this.awardOfficeDetails.map((item) => {
          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
        })
        console.log(this.awardOfficeDetails);
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  open(content, officeItem, className) {
    let modalClassName = className;
    this.criteriaName = '';
    this.singleOfficeDetails = officeItem;

    let award_Id = this.singleOfficeDetails.award_year.id;

    if (this.singleOfficeDetails.company_name.company_general_documents.length > 0) {
      this.singleOfficeDetails.company_name.company_general_documents = this.singleOfficeDetails.company_name.company_general_documents.filter(x => x.award_year_id === award_Id);
    }
    if (this.singleOfficeDetails.company_name.company_documents.length > 0) {
      this.singleOfficeDetails.company_name.company_documents = this.singleOfficeDetails.company_name.company_documents.filter(x => x.award_year_id === award_Id);
    }
    // console.log(this.singleOfficeDetails);
    this.assessorDocsData = officeItem.assessor_docs;
    this.submitted1 = false;
    this.submitted2 = false;
    this.submitted3 = false;
    this.submitted4 = false;
    this.approveForm.reset();
    this.rejectForm.reset();
    this.submissionDateChangeForm.reset();
    this.scoringDateChangeForm.reset();
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder' , centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  exportAsXLSX(singleOfficeDetails): void {
    let data = [{
      'Name': singleOfficeDetails.company_name.company_name,
      'Type': singleOfficeDetails.company_name.industry.trans_data,
      'Year in UAE': singleOfficeDetails.company_name.year_in_uae.title,
      'Email': singleOfficeDetails.company_name.user.email,
      'Address': singleOfficeDetails.company_name.head_office_addr,
      'Mobile': singleOfficeDetails.company_name.mob_phone,
      'PB NO': singleOfficeDetails.company_name.po_box,
      'Number of Branch': singleOfficeDetails.company_name.branch.title,
      'Location': singleOfficeDetails.company_name.user.location.trans_data,
      'Website': singleOfficeDetails.company_name.website,
      'CEO/GM': singleOfficeDetails.company_name.ceo_prefix + singleOfficeDetails.company_name.ceo_firstname + singleOfficeDetails.company_name.ceo_lastname,
      'Representative': singleOfficeDetails.company_name.representative_prefix +
        singleOfficeDetails.company_name.representative_firstname +
        singleOfficeDetails.company_name.representative_lastname,

      'Registration Date and Time': this.datepipe.transform(singleOfficeDetails.company_name.user.created_at, 'dd MMM yy h:mm a')
    }]

    this.excelService.exportAsExcelFile(data, singleOfficeDetails.company_name.company_name);
  }
  openDialogArchive(officeItem) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to archive this assessment?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.makeArchive(officeItem);
      }
    });
  }
  makeArchive(officeItem) {
    console.log(officeItem)
    let data = {
      group_id: officeItem.assessor_group_id,
      award_id: officeItem.award_id,
      company_id: officeItem.company_id,
      award_year_id: officeItem.award_year.id
    }
    console.log(data);
    this.archiveProcessing = true;
    this.dashboardService.createArchive(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.dashboardDeails();
        this.archiveProcessing = false;
        this.messagesService.showSuccess(res.msg);
        setTimeout(() => {
          this.messagesService.clear();
          this.modalService.dismissAll();
        }, 2500);
      }
    }, err => {
      this.archiveProcessing = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  dashboardDeails() {
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardOfficeDetails = res.office_dashboard_details;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        this.juryyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
        this.company_logo_path = res.logo_path;
        console.log(this.awardOfficeDetails)
        this.awardOfficeDetails.map((item) => {
          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
        })
        console.log(this.awardOfficeDetails);
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  goToAssesmentPoint(officeItem) {
    this.router.navigate(['award-office-assessment', officeItem.id]);
  }
  openDialogRating(officeItem) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to submit the rating?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log(officeItem);
        this.updateRating(officeItem);
      }
      else {
        this.dashboardDeails();
      }
    });
  }
  updateRating(officeItem) {
    // console.log(officeItem)
    if (officeItem.updated_rating == null) {
      this.officeRating = officeItem.stars
    }
    if (officeItem.updated_rating != null) {
      this.officeRating = officeItem.updated_rating
    }
    let data = {
      company_id: officeItem.company_id,
      award_id: officeItem.award_id,
      award_year: officeItem.award_year.year,
      rating: this.officeRating
    }
    console.log(data);
    this.dashboardService.changeRating(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.dashboardDeails();
        this.messagesService.showSuccess('Rating updated successfully');
        setTimeout(() => {
          this.messagesService.clear();
          this.modalService.dismissAll();
        }, 2500);
      }
    }, err => {
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }

  formOneSubmit() {
    this.submitted1 = true;
    // console.log(this.singleJuryDetails)
    // stop here if form is invalid
    if (this.approveForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleOfficeDetails.award_id,
      'company_id': this.singleOfficeDetails.company_id,
      'judge_group_id': this.singleOfficeDetails.judge_group_id,
      'assessor_group_id': this.singleOfficeDetails.assessor_group_id,
      'status': 'A',
      'status_comment': this.approveForm.value.comments1
    }
    console.log(data)
    this.errMsg1 = '';
    this.processingText1 = true;
    this.judgeService.allAssesmentStatusChangeOffice(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.processingText1 = false;
        this.awardOfficeDetails.map((item) => {
          if (item.company_id == this.singleOfficeDetails.company_id) {
            item.accepted_office_report_status = 'A';
          }
        })
        this.approveForm.reset();
        this.modalService.dismissAll();
        this.messagesService.showSuccess(res.msg);
        setTimeout(() => {
          this.messagesService.clear();
        }, 2500);
      }
      if (res.has_error == 1) {
        this.errMsg1 = res.msg;
        setTimeout(() => {
          this.errMsg1 = '';
        }, 2500);
      }
    },
      err => {
        this.processingText1 = false;
        if (err.error.message == "Unauthenticated.") {
          this.modalService.dismissAll();
        }
        if (err.error.message != "Unauthenticated.") {
          this.errMsg1 = 'Something went wrong.';
          setTimeout(() => {
            this.errMsg1 = '';
          }, 2000);
        }
        setTimeout(() => {
          this.messagesService.clear();
        }, 2500);
      })
  }
  formTwoSubmit() {
    this.submitted2 = true;
    // console.log(this.singleJuryDetails)
    // stop here if form is invalid
    if (this.rejectForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleOfficeDetails.award_id,
      'company_id': this.singleOfficeDetails.company_id,
      'judge_group_id': this.singleOfficeDetails.judge_group_id,
      'assessor_group_id': this.singleOfficeDetails.assessor_group_id,
      'status': 'R',
      'status_comment': this.rejectForm.value.comments2
    }
    console.log(data);
    this.errMsg2 = '';
    this.processingText2 = true;
    this.judgeService.allAssesmentStatusChangeOffice(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.processingText2 = false;
        this.awardOfficeDetails.map((item) => {
          if (item.company_id == this.singleOfficeDetails.company_id) {
            item.accepted_office_report_status = 'R';
          }
        })
        this.rejectForm.reset();
        this.modalService.dismissAll();
        this.messagesService.showSuccess(res.msg);
        setTimeout(() => {
          this.messagesService.clear();
        }, 2500);
      }
      if (res.has_error == 1) {
        this.errMsg2 = res.msg;
        setTimeout(() => {
          this.errMsg2 = '';
        }, 2500);
      }
    },
      err => {
        this.processingText2 = false;
        if (err.error.message == "Unauthenticated.") {
          this.modalService.dismissAll();
        }
        if (err.error.message != "Unauthenticated.") {
          this.errMsg2 = 'Something went wrong.';
          setTimeout(() => {
            this.errMsg2 = '';
          }, 2000);
        }
        setTimeout(() => {
          this.messagesService.clear();
        }, 2500);
      })

  }
  openDialogReport(officeItem, index) {
    this.selectIndex = index;
    if (officeItem.criteria_count != officeItem.total_bar_percent) {
      this.processigntext = false;
      this.messagesService.showWarning('Please fillup all criteria');
    }
    if (officeItem.criteria_count == officeItem.total_bar_percent) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to generate report?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          'api_token': this.api_token,
          'award_id': officeItem.award_id,
          'company_id': officeItem.company_id,
          'assessor_group_id': officeItem.assessor_group_id
        }
        this.processigntext = true;
        this.dashboardService.getOfficeChart(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.fullChartArray = [];
            this.chartImageArray = [];
            let count = 1
            res.criteria.map((item) => {
              item['critera_number'] = count;
              count++;
            })
            for (let item of res.criteria) {
              let chartLabels = [];
              let lineChartTotalScore = [];
              let lineChartTotalPoint = [];
  
              // let chartLabels
              item.getTotalScoreValue.map((item1) => {
                // var str = item1.title;
                // var res = str.substring(0, 4);
                item1.title = item1.title.length > 30 ? item1.title.substring(0, 30) + '..' : item1.title;
                item['chartLabels'] = chartLabels.push(item1.title);
                item['total_score'] = lineChartTotalScore.push(item1.total_score);
                item['point'] = lineChartTotalPoint.push(item1.point);
              })
              // let chartData = [
              //   { data: lineChartTotalScore, label: 'Scores Achieved for Critera '},
              //   { data: lineChartTotalPoint, label: '' }
              // ];
              let chartData = [
                { data: lineChartTotalScore, label: '' },
                { data: lineChartTotalPoint, label: '' }
              ];
              this.barChartOptions = {
                responsive: true,
                barDatasetSpacing: 20,
                title: {
                  display: true,
                  text: 'Scores Achieved for Critera' + item.critera_number,
                  fontColor: 'black',  // chart title color (can be hexadecimal too)
                },
                scales: {
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Criteria'
                    },
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      fontFamily: "'Open Sans Bold', sans-serif",
                      fontSize: 11
                    },
                    // barThickness: 10,
                    barPercentage: 0.9,
                    // categorySpacing: 100
                    categoryPercentage: 0.9,
                  }], yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Scores'
                    },
                    gridLines: {
                      drawBorder: false,
                    },
                    barThickness: 10,
                    ticks: {
                      fontFamily: "'Open Sans Bold', sans-serif",
                      fontSize: 11
                    },
                    // categorySpacing: 100
                  }]
                },
              }
              this.fullChartArray.push({ chartData: chartData, chartLabels: chartLabels, id: item.id, barChartOptions: this.barChartOptions })
              setTimeout(() => {
                var canvas = <HTMLCanvasElement>document.getElementById('canvasChart' + item.id);
                let a = canvas.toDataURL();
                this.chartImageArray.push(a);
                // console.log(this.chartImageArray);
                if (this.chartImageArray.length == this.fullChartArray.length) {
                  this.getPdfLink(officeItem.award_id, officeItem.company_id, officeItem.assessor_group_id)
                }
                // console.log(a);
              }, 1000);
              // this.processigntext = false;
            }
            // console.log(this.fullChartArray);
          }
        }, err => {
          this.processigntext = false;
          this.messagesService.showError('Something went wrong!');
        })
      }
    });
  }
  }
  downloadReport(officeItem, index) {
    this.selectIndex = index;

    this.processigntext = true;

    console.log(officeItem);
    let data = {
      'api_token': this.api_token,
      'award_id': officeItem.award_id,
      'company_id': officeItem.company_id,
      'assessor_group_id': officeItem.assessor_group_id
    }

    if (officeItem.criteria_count != officeItem.total_bar_percent) {
      this.processigntext = false;
      this.messagesService.showWarning('Please fillup all criteria');
    }
    if (officeItem.criteria_count == officeItem.total_bar_percent) {
      this.dashboardService.getOfficeChart(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.fullChartArray = [];
          this.chartImageArray = [];
          let count = 1
          res.criteria.map((item) => {
            item['critera_number'] = count;
            count++;
          })
          for (let item of res.criteria) {
            let chartLabels = [];
            let lineChartTotalScore = [];
            let lineChartTotalPoint = [];

            // let chartLabels
            item.getTotalScoreValue.map((item1) => {
              // var str = item1.title;
              // var res = str.substring(0, 4);
              item1.title = item1.title.length > 30 ? item1.title.substring(0, 30) + '..' : item1.title;
              item['chartLabels'] = chartLabels.push(item1.title);
              item['total_score'] = lineChartTotalScore.push(item1.total_score);
              item['point'] = lineChartTotalPoint.push(item1.point);
            })
            // let chartData = [
            //   { data: lineChartTotalScore, label: 'Scores Achieved for Critera '},
            //   { data: lineChartTotalPoint, label: '' }
            // ];
            let chartData = [
              { data: lineChartTotalScore, label: '' },
              { data: lineChartTotalPoint, label: '' }
            ];
            this.barChartOptions = {
              responsive: true,
              barDatasetSpacing: 20,
              title: {
                display: true,
                text: 'Scores Achieved for Critera' + item.critera_number,
                fontColor: 'black',  // chart title color (can be hexadecimal too)
              },
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Criteria'
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    fontFamily: "'Open Sans Bold', sans-serif",
                    fontSize: 11
                  },
                  // barThickness: 10,
                  barPercentage: 0.9,
                  // categorySpacing: 100
                  categoryPercentage: 0.9,
                }], yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Scores'
                  },
                  gridLines: {
                    drawBorder: false,
                  },
                  barThickness: 10,
                  ticks: {
                    fontFamily: "'Open Sans Bold', sans-serif",
                    fontSize: 11
                  },
                  // categorySpacing: 100
                }]
              },
            }
            this.fullChartArray.push({ chartData: chartData, chartLabels: chartLabels, id: item.id, barChartOptions: this.barChartOptions })
            setTimeout(() => {
              var canvas = <HTMLCanvasElement>document.getElementById('canvasChart' + item.id);
              let a = canvas.toDataURL();
              this.chartImageArray.push(a);
              // console.log(this.chartImageArray);
              if (this.chartImageArray.length == this.fullChartArray.length) {
                this.getPdfLink(officeItem.award_id, officeItem.company_id, officeItem.assessor_group_id)
              }
              // console.log(a);
            }, 1000);
            // this.processigntext = false;
          }
          // console.log(this.fullChartArray);
        }
      }, err => {
        this.messagesService.showError('Something went wrong!');
      })
    }
  }

  getPdfLink(award_id, company_id, assessor_group_id) {
    let data = {
      'api_token': this.api_token,
      'award_id': award_id,
      'company_id': company_id,
      'assessor_group_id': assessor_group_id,
      'base64Code': this.chartImageArray
    }
    this.dashboardService.downloadOfficeChartReport(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.processigntext = false;
        window.open(res.next_url);
      }
    }, err => {
      this.messagesService.showError('Something went wrong!');
    })
  }

  onChartClick(event) {
  }

  submissionDateChange() {
    this.submitted3 = true;

    if (this.submissionDateChangeForm.invalid) {
      return;
    }

    let data = {
      'api_token': this.api_token,
      'company_id': this.singleOfficeDetails.company_id,
      'award_id': this.singleOfficeDetails.award_id,
      'award_year': this.singleOfficeDetails.award_year.year,
      'submission_date': this.datepipe.transform(this.submissionDateChangeForm.value.submission_date, 'yyyy-MM-dd'),
    }
    if (new Date(this.submissionDateChangeForm.value.submission_date) > this.currentDate) {
      this.processingText3 = true;
      this.dashboardService.changeDate(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.submissionDateChangeForm.reset();
          this.modalService.dismissAll();
          this.errMsg3 = '';
          this.processingText3 = false;
          this.errMsg3 = res.msg;
          setTimeout(() => {
            this.errMsg3 = '';
          }, 3000);
        }
        if (res.has_error == 1) {
          this.errMsg3 = res.msg;
          setTimeout(() => {
            this.errMsg3 = '';
          }, 3000);
        }
      }, err => {
        this.processingText3 = false;
        this.errMsg3 = 'Something went wrong!';
        setTimeout(() => {
          this.errMsg3 = '';
        }, 3000);
      })
    }
    else if (new Date(this.submissionDateChangeForm.value.submission_date) <= this.currentDate) {
      console.log(new Date(this.submissionDateChangeForm.value.submission_date));
      console.log(this.currentDate);
      this.errMsg3 = 'Please select the submission date after ' + this.datepipe.transform(this.currentDate, 'dd-MM-yyyy');
      setTimeout(() => {
        this.errMsg3 = '';
      }, 3000);
    }
  }
  scoringDateChange() {
    this.submitted4 = true;

    if (this.scoringDateChangeForm.invalid) {
      return;
    }

    let data = {
      'api_token': this.api_token,
      'company_id': this.singleOfficeDetails.company_id,
      'award_id': this.singleOfficeDetails.award_id,
      'award_year': this.singleOfficeDetails.award_year.year,
      'scoring_date': this.datepipe.transform(this.scoringDateChangeForm.value.scoring_date, 'yyyy-MM-dd'),
    }
    if (new Date(this.scoringDateChangeForm.value.scoring_date) > this.currentDate) {
      this.processingText4 = true;
      console.log(data);
      this.dashboardService.changeDate(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.scoringDateChangeForm.reset();
          this.modalService.dismissAll();
          this.errMsg4 = '';
          this.processingText4 = false;
          this.errMsg4 = res.msg;
          setTimeout(() => {
            this.errMsg4 = '';
          }, 3000);
        }
        if (res.has_error == 1) {
          this.errMsg4 = res.msg;
          setTimeout(() => {
            this.errMsg4 = '';
          }, 3000);
        }
      }, err => {
        this.processingText4 = false;
        this.errMsg4 = 'Something went wrong!';
        setTimeout(() => {
          this.errMsg4 = '';
        }, 3000);
      })
    }
    else if (new Date(this.scoringDateChangeForm.value.scoring_date) <= this.currentDate) {
      this.errMsg4 = 'Please select the submission date after ' + this.datepipe.transform(this.currentDate, 'dd-MM-yyyy');
      setTimeout(() => {
        this.errMsg4 = '';
      }, 3000);
    }
  }
}

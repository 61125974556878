import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { JudgeService } from '../../services/judge.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-assessor-assign-mgmt',
  templateUrl: './assessor-assign-mgmt.component.html',
  styleUrls: ['./assessor-assign-mgmt.component.scss']
})
export class AssessorAssignMgmtComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  showAddForm: boolean = false;
  assessorAssignGroupList: any = [];
  lazyLoad: boolean = false;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  companymanagementListArray: any = [];
  loadingCount: number = 0;
  processignText: boolean = false;
  formAction: any;
  singleAssessorDeatils: any;
  awardListArray: any = [];
  assessorAssignGroupForm: FormGroup;
  submitted: boolean = false;
  award_year: any;
  companyList: any = [];
  searchAward: any = '';
  searchCompany: any = '';
  awardYearList: any = [];
  award_year_id: any;
  AssignGroupErrMsg: string;
  groupList: any = [];
  award_id: any;
  awardMainListArray: any = [];
  permissionMsg: any;
  permissionStatus: boolean = false;
  awardData: any = [];
  companySaerchList: any = [];
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private judgeService: JudgeService,
    private formBuilder: FormBuilder) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/assign-assessor-management')
          this.leftbarselected = 'Assign Assessor Group Management';
        this.dataService.leftPanelSelection('assign_assessor_group');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/assign-assessor-management`]);
    }
    this.awardManagementList();
    this.assessorAssignManagementlist();
    this.companymanagementList();
    this.assessorAssignGroupForm = this.formBuilder.group({
      award_name: ['', [Validators.required]],
      company_id: ['', [Validators.required]],
      accessorGroup_id: ['', [Validators.required]],
      award_year_id: ['', [Validators.required]]
    });
  }

  get f() { return this.assessorAssignGroupForm.controls; }

  awardManagementList() {
    this.judgeService.awardmanagementlist(this.api_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.awardMainListArray = res.award_data;
        console.log(this.awardMainListArray)
        this.awardListArray = res.award_data.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)))
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  assessorAssignManagementlist() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    let user_type = 'assessor';
    this.dashboardService.assesorAssignedGroupList(this.api_token, user_type).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.assessorAssignGroupList = res.list;
        this.awardData =  res.awardData;
        this.companySaerchList =  res.companyList;
        this.loadingCount == 1;
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }
  onChangeSearchAward(value) {
    this.searchAward = value;
  }
  onChangeSearchCompany(value) {
    this.searchCompany = value;
  }
  searchAssignAssessor() {
    let user_type = 'assessor';
    this.dashboardService.searchAssignedAssessorGroupList(this.api_token,user_type,this.searchAward,this.searchCompany).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.assessorAssignGroupList = res.list;
      }
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  onChangeAward(value) {
    this.award_id = value;
    this.assessorAssignGroupForm.get('award_year_id').reset();
    this.assessorAssignGroupForm.get('company_id').reset();
    this.assessorAssignGroupForm.get('accessorGroup_id').reset();
    this.submitted = false;
    this.awardYearList = this.awardMainListArray.filter(x => x.id == this.award_id);
    console.log(this.awardYearList);
  }
  onChangeAwardYear(value) {
    this.award_year_id = value;
    this.submitted = false;
    this.assessorAssignGroupForm.get('company_id').reset();
    this.assessorAssignGroupForm.get('accessorGroup_id').reset();
    this.findcompanyList(this.award_year_id);
    this.findGroupList(this.award_year_id);
  }
  findcompanyList(award_year_id) {
    this.dashboardService.findCompanyList(this.api_token, award_year_id).subscribe((res: any) => {
      this.lazyLoad = false;
      if (res.has_error == 0) {
        this.companyList = res.companies;
      }
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  findGroupList(award_year_id) {
    this.dashboardService.findAssessorGroupList(this.api_token, award_year_id).subscribe((res: any) => {
      this.lazyLoad = false;
      if (res.has_error == 0) {
        this.groupList = res.assessor_groups;
        if (this.groupList.length > 0) {
          this.AssignGroupErrMsg = '';
        }
        else if (this.groupList.length == 0) {
          this.AssignGroupErrMsg = 'No Group Associated with this Award !!';
        }
      }
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  addAssessorgroup() {
    this.submitted = true;

    if (this.assessorAssignGroupForm.invalid) {
      return;
    }

    if (this.formAction == 'add') {
      let data = {
        company_id: this.assessorAssignGroupForm.value.company_id,
        accessorGroup_id: this.assessorAssignGroupForm.value.accessorGroup_id,
        award_year_id: this.assessorAssignGroupForm.value.award_year_id
      }
      this.processignText = true;
      this.dashboardService.addAssignAssessorGroup(this.api_token, data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processignText = false;
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
          this.assessorAssignManagementlist();
          this.closeAssessorAssignGroup();
        }
        if (res.has_error == 1) {
          this.processignText = false;
          this.messagesService.showError(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }, err => {
        this.processignText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 2000);
      })
    }
    if (this.formAction == 'edit') {
      let data = {
        company_id: this.assessorAssignGroupForm.value.company_id,
        accessorGroup_id: this.assessorAssignGroupForm.value.accessorGroup_id,
        award_year_id: this.assessorAssignGroupForm.value.award_year_id,
        assign_group_id: this.singleAssessorDeatils.id
      }
      this.processignText = true;
      this.dashboardService.addAssignAssessorGroup(this.api_token, data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processignText = false;
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
          this.assessorAssignManagementlist();
          this.closeAssessorAssignGroup();
        }
        if (res.has_error == 1) {
          this.processignText = false;
          this.messagesService.showError(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }, err => {
        this.processignText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 2000);
      })
    }
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }
  addAssessorAssignGroup(action, item) {
    this.showAddForm = true;
    this.formAction = action;
    this.panelExpanded = false;
    if ((this.formAction == 'add') && (item == null)) {
      this.singleAssessorDeatils = null;
      this.assessorAssignGroupForm.reset();
    }
    if ((this.formAction == 'edit') && (item != null)) {
      this.singleAssessorDeatils = item;
      console.log(this.singleAssessorDeatils);
      this.onChangeAward(this.singleAssessorDeatils.award_id);
      this.onChangeAwardYear(this.singleAssessorDeatils.award_year_id);
      this.assessorAssignGroupForm.patchValue({
        award_name: this.singleAssessorDeatils.award_id,
        award_year_id: this.singleAssessorDeatils.award_year_id,
        company_id: this.singleAssessorDeatils.company_id,
        accessorGroup_id: this.singleAssessorDeatils.group_id
      });
    }
  }
  closeAssessorAssignGroup() {
    this.showAddForm = false;
    this.submitted = false;
  }
}

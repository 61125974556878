import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeadiaService {
  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  constructor(private http: HttpClient) { }
  // media list
  mediaList(user_token, year, page_no, per_page) {
    return this.http.get(`${appConfig.apiUrl}/gallery-data?user_token=${user_token}&year=${year}&page_no=${page_no}&per_page=${per_page}`, { headers: this.headers });
  }

  // assessor list
  assessorList(user_token, page_no, per_page) {
    if (page_no == null) {
      return this.http.get(`${appConfig.apiUrl}/assessor-list?user_token=${user_token}&per_page=${per_page}`, { headers: this.headers })
    }
    else {
      return this.http.get(`${appConfig.apiUrl}/assessor-list?user_token=${user_token}&page_no=${page_no}&per_page=${per_page}`, { headers: this.headers })
    }
  }

  // assessor search
  assessorSearch(user_token, page_no, per_page, $obj) {
    if (page_no == null) {
      return this.http.post(`${appConfig.apiUrl}/assessor-list?user_token=${user_token}&per_page=${per_page}`, $obj, { headers: this.headers });
    }
    else {
      return this.http.post(`${appConfig.apiUrl}/assessor-list?user_token=${user_token}&page_no=${page_no}&per_page=${per_page}`, $obj, { headers: this.headers });
    }
  }

  // jury list
  juryList(user_token, page_no, per_page) {
    if (page_no == null) {
      return this.http.get(`${appConfig.apiUrl}/jury-list?user_token=${user_token}&per_page=${per_page}`, { headers: this.headers })
    }
    else {
      return this.http.get(`${appConfig.apiUrl}/jury-list?user_token=${user_token}&page_no=${page_no}&per_page=${per_page}`, { headers: this.headers })
    }
  }

  // jury search
  jurySearch(user_token, page_no, per_page, $obj) {
    if (page_no == null) {
      return this.http.post(`${appConfig.apiUrl}/jury-list?user_token=${user_token}&per_page=${per_page}`, $obj, { headers: this.headers });
    }
    else {
      return this.http.post(`${appConfig.apiUrl}/jury-list?user_token=${user_token}&page_no=${page_no}&per_page=${per_page}`, $obj, { headers: this.headers });
    }
  }
  // Faq list
  FaqList(user_token) {
    return this.http.get(`${appConfig.apiUrl}/faq?user_token=${user_token}`, { headers: this.headers })
  }

  // our jury list
  ourJuryText() {
    return this.http.get(`${appConfig.apiUrl}/text-managment-data/our-jury`, { headers: this.headers })
  }

  ourAssesorText() {
    return this.http.get(`${appConfig.apiUrl}/text-managment-data/join-assessor-text`, { headers: this.headers })
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwardFremeworkComponent } from './award-fremework.component';
import { RouterModule, Routes } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

// intro routing
export const routes: Routes = [
  { path: '', component: AwardFremeworkComponent },
];

@NgModule({
  declarations: [AwardFremeworkComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PdfViewerModule,
    NgxDocViewerModule
  ]
})
export class AwardFremeworkModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-award-details',
  templateUrl: './award-details.component.html',
  styleUrls: ['./award-details.component.scss']
})
export class AwardDetailsComponent implements OnInit {
  awardId: any;
  awardYearId: any;
  award_image_path: any;
  award_details: any = [];
  lazyLoad: boolean = false;
  user_token: any;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private messagesService: MessagesService) {

    this.activatedRoute.params.subscribe(params => {
      // this.awardId = params['id'];
      this.awardId = params.id;
      this.awardYearId = params.id1;
    });
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getuserToken();
    this.Awarddetails();
  }
  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }
  Awarddetails() {
    this.lazyLoad = true;
    this.authService.awardDetails(this.user_token, this.awardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        console.log(res);
        this.lazyLoad = false;
        this.award_details =  res.awardDetails.filter(x => x.year_id === Number(this.awardYearId));
        this.award_image_path = res.award_image_path;
      }

    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      })
  }
  navigateCompanyRegisterPage() {
    if (this.award_details[0].status != 'N') {
      this.messagesService.clear();
      this.router.navigate([`/company-register/`+this.awardId+'/'+this.awardYearId]);
    }
    else if (this.award_details[0].status == 'N') {
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
      this.messagesService.clear();
      this.messagesService.showError('The Award is Inactive');
      setTimeout(() => {
        this.messagesService.clear();
      }, 1500)
    }
  }

  navigateAssessorRegisterPage() {
    if (this.award_details[0].status != 'N') {
      this.messagesService.clear();
      this.router.navigate([`/assessor-register/`+this.awardId+'/'+this.awardYearId]);
    }
    else if (this.award_details[0].status == 'N') {
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
      this.messagesService.clear();
      this.messagesService.showError('The Award is Inactive');
      setTimeout(() => {
        this.messagesService.clear();
      }, 1500);
    }
  }
}

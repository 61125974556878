import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  public userDataSubscriber = new BehaviorSubject<any>('');
  userDetails = this.userDataSubscriber.asObservable();

  public userLoggedInStatus = new BehaviorSubject<any>('');
  userLoggedIn = this.userLoggedInStatus.asObservable();

  public leftPanelselection = new BehaviorSubject<any>('');
  leftbarselection = this.leftPanelselection.asObservable();

  private dropdownScrollSection =  new BehaviorSubject<any>('');
  dropdownscroll = this.dropdownScrollSection.asObservable();

  private userdetailsSection =  new BehaviorSubject<any>('');
  userDetailsData = this.userdetailsSection.asObservable();
  
  private awardDetailsSection =  new BehaviorSubject<any>('');
  awardDetailsData = this.awardDetailsSection.asObservable();

  private blinkSection =  new BehaviorSubject<any>('');
  blinkData = this.blinkSection.asObservable();
  constructor() { }

  userData(message: any) {
    this.userDataSubscriber.next(message);
  }
  userLoggenInStatus(message: any) {
    this.userLoggedInStatus.next(message);
  }
  leftPanelSelection(message: any) {
    this.leftPanelselection.next(message);
  }
  dropdownScroll(message: any) {
    this.dropdownScrollSection.next(message);
  }
  userdetails(message: any) {
    this.userdetailsSection.next(message);
  }
  awarddetails(message: any) {
    this.awardDetailsSection.next(message);
  }
  blinkTab(message: any) {
    this.blinkSection.next(message);
  }
}

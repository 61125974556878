import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { JudgeGroupDashboardComponent } from './judge-group-dashboard.component';

// intro routing
export const routes: Routes = [
  { path: '', component: JudgeGroupDashboardComponent },
];

@NgModule({
  declarations: [JudgeGroupDashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class JudgeGroupDashboardModule { }

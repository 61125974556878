import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { JudgeService } from '../../services/judge.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-award-management',
  templateUrl: './award-management.component.html',
  styleUrls: ['./award-management.component.scss']
})
export class AwardManagementComponent implements OnInit {
  leftbarselected: any;
  panelExpanded: boolean = false;
  panelExpanded2: boolean = false;
  panelExpanded3: boolean = false;
  panelExpanded4: boolean = false;
  user_token: any;
  api_token: any;
  awardListArray: any = [];
  lazyLoad: boolean = false;
  loadingCount: number = 0;
  selectedIndex: any = -1;
  showAwardForm: boolean = false;
  awardManagementForm: FormGroup;
  submitted: boolean = false;
  profile_pic_show: any;
  display_profile_photo: any;
  processingText: boolean = false;
  formAction: any;
  profilePicturErr: any;
  errorMessageValue: any;
  singleAwardDetails: any;
  statusList: any = [];
  awardImagePath: any;
  awardTypes: any = [];
  all_country: any = [];
  show_measurement_form: boolean = false;
  show_stars_form: boolean = false;
  criteria_order_no: any = '1';
  award_id: any;
  award_measurmentsList: any = [];
  editMeasurementShow: boolean = false;
  singleMeasurementDetails: any;
  awardMeasurementForm: FormGroup;
  submitted1: boolean = false;
  measurementProcessText: boolean = false;
  measurementLoad: boolean = false;
  starsPointArray: any = [];
  singleAwardStarsDetails: any;
  fromStars: any = [];
  toStars: any = [];
  starsProcessText: boolean = false;
  check_plus: any = 'N';
  awardStarsArray: any = [];
  check_plus_value: any = 'N';
  starPointLoad: boolean = false;
  currency_codes: any = [];
  companymanagementListArray: any = [];
  permissionMsg: any;
  permissionStatus: boolean = false;
  award_ppt: any;
  award_ppt_name: any;
  PptErr: string;
  fileTypeText: any;
  awardPptPath: any;
  constructor(private router: Router,
    private dataService: DataService,
    private judgeService: JudgeService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/award-management')
          this.leftbarselected = 'Award Management';
        this.dataService.leftPanelSelection('award_management');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-management`]);
    }
    this.statusList = [
      {
        id: 1,
        name: 'Active',
        status: 'Y'
      },
      {
        id: 2,
        name: 'In-Active',
        status: 'N'
      },
      {
        id: 3,
        name: 'Approved',
        status: 'A'
      }, {
        id: 4,
        name: 'Blocked',
        status: 'B'
      }
    ]
    this.starsPointArray = [
      {
        from: null,
        to: null
      },
      {
        from: null,
        to: null
      },
      {
        from: null,
        to: null
      },
      {
        from: null,
        to: null
      },
      {
        from: null,
        to: null
      }
    ]
    this.awardManagementForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      award_year: [''],
      country: [''],
      award_type: [''],
      status: [''],
      initial_date: ['', [Validators.required]],
      submission_date: ['', [Validators.required]],
      scoring_date: ['', [Validators.required]],
      details: ['', [Validators.required]],
      beneficiary_name: ['', [Validators.required]],
      bank_name: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      iban: ['', [Validators.required]],
      account_number: ['', [Validators.required]],
      swift_code: ['', [Validators.required]],
      bank_address: ['', [Validators.required]],
      payment_email: ['', [Validators.required, Validators.email]],
      vat: ['', [Validators.required]],
      payment_amount: ['', [Validators.required]],
      local_payment_amount: ['', [Validators.required]],
      currency_code: ['', [Validators.required]],
    });
    this.awardMeasurementForm = this.formBuilder.group({
      measurement_title: ['', [Validators.required]],
      points: ['', [Validators.required]],
    });
    this.awardManagementList();
    this.companymanagementList();
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf,ppx,pptx';
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  awardManagementList() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.judgeService.awardmanagementlist(this.api_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.loadingCount = 1;
        this.awardListArray = res.award_data;
        this.awardListArray = res.award_data.filter((thing, index, self) => index === self.findIndex((t) => (t.year_id === thing.year_id)))
        this.awardTypes = res.awardTypes;
        this.all_country = res.all_country;
        this.currency_codes = res.currency_codes;
        this.awardImagePath = res.award_image_path;
        this.awardPptPath = res.ppt_path;
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
    })
  }
  get f() { return this.awardManagementForm.controls; }

  get f1() { return this.awardMeasurementForm.controls; }

  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.award_ppt = event.target.files[0];
      console.log(this.award_ppt);
      if (this.award_ppt != '') {
        this.PptErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {

      }
    }
  }

  addAward(awardItem) {
    this.singleAwardDetails = awardItem;
    console.log(this.singleAwardDetails);
    this.award_ppt = null;
    this.awardManagementForm.patchValue({
      title: this.singleAwardDetails.title,
      award_year_id: this.singleAwardDetails.year_id,
      initial_date: this.singleAwardDetails.initial_date,
      submission_date: this.singleAwardDetails.submission_date,
      scoring_date: this.singleAwardDetails.scoring_date,
      details: this.singleAwardDetails.details,
      award_year: this.singleAwardDetails.year,
      country: this.singleAwardDetails.country_id,
      award_type: Number(this.singleAwardDetails.award_type),
      status: this.singleAwardDetails.status,
      beneficiary_name: this.singleAwardDetails.beneficiary_name,
      bank_name: this.singleAwardDetails.bank_name,
      branch: this.singleAwardDetails.branch,
      iban: this.singleAwardDetails.iban,
      account_number: this.singleAwardDetails.account_number,
      swift_code: this.singleAwardDetails.swift_code,
      bank_address: this.singleAwardDetails.bank_address,
      payment_email: this.singleAwardDetails.payment_email,
      vat: this.singleAwardDetails.vat,
      local_payment_amount: this.singleAwardDetails.local_payment_amount,
      payment_amount: this.singleAwardDetails.payment_amount,
      currency_code: this.singleAwardDetails.currency_code,
    });
    this.award_ppt_name = this.singleAwardDetails.ppt;
    this.showAwardForm = true;
  }
  closeAward() {
    this.showAwardForm = false;
    this.panelExpanded = false;
  }
  openshow_measurement_form(awardItem) {
    this.measurementLoad = true;
    console.log(awardItem);
    this.award_id = awardItem.id;
    this.criteria_order_no = '1';
    this.measurementsData(this.award_id, this.criteria_order_no);
    this.show_measurement_form = true;
  }
  closeshow_measurement_form() {
    this.show_measurement_form = false;
  }
  openshow_stars_form(awardItem) {
    this.panelExpanded = false;
    this.singleAwardStarsDetails = awardItem;
    this.awardStarPoints(this.singleAwardStarsDetails);
    this.show_stars_form = true;
  }
  closeshow_stars_form() {
    this.show_stars_form = false;
  }
  updateAwardManagement() {
    this.submitted = true;
    if ((this.award_ppt_name == null) && (this.award_ppt == null)) {
      this.PptErr = 'Please Upload Document';
    }
    if (this.awardManagementForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('title', this.awardManagementForm.value.title),
      formData.append('award_year_id', this.singleAwardDetails.year_id),
      formData.append('is_payble', this.singleAwardDetails.is_payble),
      formData.append('initial_date', this.awardManagementForm.value.initial_date),
      formData.append('submission_date', this.awardManagementForm.value.submission_date),
      formData.append('scoring_date', this.awardManagementForm.value.scoring_date),
      formData.append('details', this.awardManagementForm.value.details),
      formData.append('payment_amount', this.singleAwardDetails.payment_amount),

      formData.append('beneficiary_name', this.awardManagementForm.value.beneficiary_name),
      formData.append('bank_name', this.awardManagementForm.value.bank_name),
      formData.append('branch', this.awardManagementForm.value.branch),
      formData.append('iban', this.awardManagementForm.value.iban),
      formData.append('account_number', this.awardManagementForm.value.account_number),
      formData.append('swift_code', this.awardManagementForm.value.swift_code),
      formData.append('bank_address', this.awardManagementForm.value.bank_address),
      formData.append('payment_email', this.awardManagementForm.value.payment_email), formData.append('vat', this.awardManagementForm.value.vat),
      formData.append('payment_amount', this.awardManagementForm.value.payment_amount),
      formData.append('local_payment_amount', this.awardManagementForm.value.local_payment_amount),
      formData.append('currency_code', this.awardManagementForm.value.currency_code)
    if (this.award_ppt != null) {
      formData.append('ppt', this.award_ppt)
    }
    if (this.award_ppt == null) {
      formData.append('ppt', this.award_ppt_name)
    }
    if ((this.award_ppt_name != null || this.award_ppt != null)) {
      this.processingText = true;
      this.dashboardService.editAward(this.api_token, formData).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingText = false;
          this.closeAward();
          this.awardManagementList();
          this.submitted = false;
          this.panelExpanded = false;
          this.awardManagementForm.reset();

          this.messagesService.showSuccess('Award Updated Successfully.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
      },
        error => {
          this.processingText = false;
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        })
    }
    else if ((this.award_ppt_name == null) && (this.award_ppt == null)) {
      this.PptErr = 'Please Upload Document';
    }
  }
  showReports(awardItem) {
    console.log(awardItem)
    this.dashboardService.showFinalReports(this.api_token, awardItem.id).subscribe((res: any) => {
      console.log(res);
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  onChangeCriteria($event) {
    this.criteria_order_no = $event;
    this.measurementsData(this.award_id, this.criteria_order_no);
  }
  measurementsData(award_id, criteria_order_no) {
    this.submitted1 = true;
    let data = {
      award_id: this.award_id,
      criteria_order_id: this.criteria_order_no,
      // measurement_title: ,
      // points: , 
      // award_measurment_id:
    }
    this.dashboardService.awardMeasurements(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.measurementLoad = false;
        this.award_measurmentsList = res.award_measurments;
      }
    }, err => {
      this.measurementLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  awardStarPoints(awardItem) {
    console.log(awardItem);
    let data = {
      award_id: this.singleAwardStarsDetails.id,
      award_year_id: this.singleAwardStarsDetails.year_id
    }
    this.starPointLoad = true;
    this.dashboardService.awardStar(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardStarsArray = res.awardStars;
        for (let i = 0; i < this.awardStarsArray.length; i++) {
          this.fromStars.push(this.awardStarsArray[i].from_point ? this.awardStarsArray[i].from_point : null);
          this.toStars.push(this.awardStarsArray[i].to_point ? this.awardStarsArray[i].to_point : null);

        }
        // console.log(this.toStars)
        this.starsPointArray = [];
        this.awardStarsArray.map((item) => {
          this.starsPointArray.push(
            {
              from: item.from_point,
              to: item.to_point
            }
          )
        })
        this.starPointLoad = false;
        for (let i = 0; i < this.awardStarsArray.length; i++) {
          if (this.awardStarsArray[4].check_plus == 'Y') {
            this.check_plus_value = 'Y';
            this.starsPointArray[4].to = null;
          }
          if (this.awardStarsArray[4].check_plus == 'N') {
            this.check_plus_value = 'N';
          }
        }
        console.log(this.starsPointArray);
        console.log(this.toStars);
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  selectPlusPoint($event) {
    if ($event.srcElement.checked == true) {
      this.check_plus = 'Y';
      this.check_plus_value = 'Y';
      this.starsPointArray[4].to = null;
    }
    else if ($event.srcElement.checked == false) {
      this.check_plus = 'N';
      this.check_plus_value = 'N';
    }
  }
  openEdit(measurmentItem) {
    this.panelExpanded = false;
    this.singleMeasurementDetails = measurmentItem;
    this.awardMeasurementForm.patchValue({
      measurement_title: this.singleMeasurementDetails.measurement_title,
      points: this.singleMeasurementDetails.points
    });
    this.editMeasurementShow = true;
  }
  close_edit_form() {
    this.editMeasurementShow = false;
  }
  updatemeasurement() {
    console.log(this.singleMeasurementDetails)
    if (this.awardMeasurementForm.invalid) {
      return;
    }
    let data = {
      award_id: this.award_id,
      criteria_order_id: this.singleMeasurementDetails.criteria_order_id,
      measurement_title: this.awardMeasurementForm.value.measurement_title,
      points: this.awardMeasurementForm.value.points,
      award_measurment_id: this.singleMeasurementDetails.id
    }
    this.measurementProcessText = true;
    this.dashboardService.editawardMeasurement(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.award_measurmentsList = res.award_measurments;
        this.measurementProcessText = false;
        this.editMeasurementShow = false;
        this.messagesService.showSuccess('Measurement updated successfully');
      }
    }, err => {
      this.measurementProcessText = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  updateStars() {
    // console.log(this.singleMeasurementDetails)
    this.fromStars = [];
    this.toStars = [];
    for (let i = 0; i < this.starsPointArray.length; i++) {
      this.fromStars.push(this.starsPointArray[i].from ? this.starsPointArray[i].from : null);
      this.toStars.push(this.starsPointArray[i].to ? this.starsPointArray[i].to : null);
    }
    let data = {
      award_id: this.singleAwardStarsDetails.id,
      award_year_id: this.singleAwardStarsDetails.year_id,
      from_star: this.fromStars,
      to_star: this.toStars,
      check_plus: this.check_plus
    }
    console.log(data);
    this.starsProcessText = true;
    this.dashboardService.editAwardStar(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.starsProcessText = false;
        this.closeshow_stars_form();
        this.fromStars = [];
        this.toStars = [];
        this.messagesService.showSuccess('Star points updated successfully');
      }
    }, err => {
      this.starsProcessText = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  closeAddForm() {
    this.showAwardForm = false;
    this.panelExpanded = false;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CompanyProfileComponent } from './company-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesService } from '../../services/messages.service';
// intro routing
export const routes: Routes = [
  { path: '', component: CompanyProfileComponent },
];

@NgModule({
  declarations: [CompanyProfileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule
  ],providers:[MessagesService]
})
export class CompanyProfileModule { }

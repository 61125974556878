import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { DashboardService } from '../../services/dashboard.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-office-assessment',
  templateUrl: './office-assessment.component.html',
  styleUrls: ['./office-assessment.component.scss']
})
export class OfficeAssessmentComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  lazyLoad: boolean = false;
  user_token: any;
  api_token: any;
  userDetails: any;
  assessmentId: any;
  finaAassignmentDetails: any;
  mainCriteraNumber: number = 1;
  CriteraNumber: any;
  criteriaList: any = [];
  subcriteriaList: any = [];
  subcriteriaTitle: any;
  awardMeasurmentsList: any = [];
  pointMeasurmentsList: any = [];
  criteraNumber: any;
  rejetedData: any = [];
  subcriteriaStatus: any;
  selectIndex: number = 0;
  ScorePoint: any;
  score_data_Array: any = [];
  submitted1: boolean = false;
  submitted2: boolean = false;
  processingText1: boolean = false;
  processingText2: boolean = false;
  approveForm: FormGroup;
  rejectForm: FormGroup;
  errMsg1: any;
  errMsg2: any;
  closeResult: string;
  criteriatype_name_Array: any = [];
  critera_id: any;
  criteraPoint: any;
  singleOfficeDetails: any;
  mineOfficeApproveStatus: any;
  giveOwnAssessment: boolean = false;
  companymanagementListArray: any;
  permissionMsg: any;
  permissionStatus: boolean = false;
  constructor(private messagesService: MessagesService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder) {
    this.activatedRoute.params.subscribe(params => {
      this.assessmentId = params.id;
    });
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url.match('/award-office-assessment'))
          this.leftbarselected = 'Final Assignment Point';
        this.dataService.leftPanelSelection('award_office_dashboard');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`award-office-assessment/` + this.assessmentId]);
    }
    this.getofficeScore();
    this.companymanagementList();
    this.approveForm = this.formBuilder.group({
      comments1: ['', Validators.required]
    });
    this.rejectForm = this.formBuilder.group({
      comments2: ['', Validators.required]
    });
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  getofficeScore() {
    let data = {
      id: this.assessmentId
    }
    this.lazyLoad = true;
    this.dashboardService.officeScore(this.api_token, data).subscribe((res: any) => {
      if(res.has_error == 0){
      this.lazyLoad = false;
      this.permissionStatus = false;
      this.permissionMsg = '';
      this.finaAassignmentDetails = res;
      this.criteriatype_name_Array = res.criteriatype;
      this.criteriaList = res.criteria;
      this.subcriteriaList = res.get_all_criteria;
      this.subcriteriaTitle = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].title;
      this.critera_id = this.subcriteriaList[0].id;
      this.criteraPoint = this.subcriteriaList[0].point;
      console.log(this.subcriteriaList);
      for (let i = 0; i < this.criteriaList.length; i++) {
        if (i == 0) {
          this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          var key = Object.keys(this.finaAassignmentDetails.award_measurments)[i];
          this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
          this.pointMeasurmentsList.push('0')
        }
        if (i != 0) {
          if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          }
          else {
            this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
          }
        }
      }
      if (this.subcriteriaList[0].hire_order != 0) {
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + this.subcriteriaList[0].hire_order;
      }
      if (this.subcriteriaList[0].hire_order == 0) {
        this.CriteraNumber = this.criteriaList[0].criteria_number  + '.' + 1;
      }
      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length > 0) {
        this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].approved_status;

        this.mineOfficeApproveStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].office_approve_status;
      }
      if ((this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) && (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length == 0)) {

        this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data[0].approved_status;
        this.mineOfficeApproveStatus = '';
      }

      if ((this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) && (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length > 0)) {

        this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].approved_status;

        this.mineOfficeApproveStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].office_approve_status;
      }

      if ((this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length == 0) && (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length == 0)) {
        this.subcriteriaStatus = '';
        this.mineOfficeApproveStatus = '';
      }


      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) {
        this.rejetedData = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data;
        if (this.rejetedData.length > 0) {
          this.rejetedData.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
      }
      this.ScorePoint = this.subcriteriaList[0];
      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => Number(o.parent_papa) === o2.id))[0].score_data.length > 0) {
        this.score_data_Array = this.subcriteriaList[0].score_data;
        // this.score_data_Array = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => Number(o.parent_papa) === o2.id))[0].score_data;
        if (this.score_data_Array.length > 0) {
          this.score_data_Array.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
      }
    }
    if (res.has_error == 1) {
      this.lazyLoad = false;
      this.permissionStatus = true;
      this.permissionMsg = res.msg;
      this.router.navigate(['/award-office-dashboard']);
    }
    })
  }
  lineActive(subcriteria, criteraNumber, index) {
    this.selectIndex = index;
    this.criteraNumber = criteraNumber;
    if (this.criteraNumber != 3) {
      this.ScorePoint = subcriteria;
      console.log(this.ScorePoint);
      if (this.ScorePoint.hire_order != 0) {
        this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      }
      if (this.ScorePoint.hire_order == 0) {
        this.CriteraNumber = criteraNumber + '.' + 1;
      }
      this.subcriteriaTitle = this.ScorePoint.title;
      if ((this.ScorePoint.status_log.length > 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')) {
        this.ScorePoint.status_log.map((item)=>{
          if(item.award_office_id != this.userDetails.id){
            this.giveOwnAssessment = true;
          }
          if(item.award_office_id == this.userDetails.id){
            this.giveOwnAssessment = false;
          }
        });
      }
      if((this.ScorePoint.status_log.length == 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')){
        this.giveOwnAssessment = true;
      }

      if (this.ScorePoint.score_data.length > 0) {
        this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
      }
      if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
        this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
        this.mineOfficeApproveStatus = '';
      }
      if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
        this.subcriteriaStatus = '';
        this.mineOfficeApproveStatus = '';
      }
      if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
        this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
      }
      // console.log(this.subcriteriaStatus)
      this.score_data_Array = [];
      if (this.ScorePoint.score_data.length > 0) {
        this.score_data_Array.push(this.ScorePoint.score_data[0]);
        this.score_data_Array.map((item) => {
          item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
        })
      }
      this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
      if (this.rejetedData.length > 0) {
        this.rejetedData.map((item) => {
          item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
        })
      }
    }
    else if (this.criteraNumber == 3) {
      this.ScorePoint = subcriteria;
      console.log(this.ScorePoint);
      // score data 
      if (this.ScorePoint.score_data.length > 0) {
        this.ScorePoint.score_data = this.ScorePoint.score_data.reverse();
      }
      console.log(this.ScorePoint);

      this.critera_id = this.ScorePoint.id;
      this.criteraPoint = this.ScorePoint.point;
       if (this.ScorePoint.hire_order != 0) {
        this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      }
      if (this.ScorePoint.hire_order == 0) {
        this.CriteraNumber = criteraNumber + '.' + 1;
      }
      this.subcriteriaTitle = this.ScorePoint.title;


   if ((this.ScorePoint.status_log.length > 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')) {
        this.ScorePoint.status_log.map((item)=>{
          if(item.award_office_id != this.userDetails.id){
            this.giveOwnAssessment = true;
          }
          if(item.award_office_id == this.userDetails.id){
            this.giveOwnAssessment = false;
          }
        });
      }
      if((this.ScorePoint.status_log.length == 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')){
        this.giveOwnAssessment = true;
      }

      if (this.ScorePoint.score_data.length > 0) {
        this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
      }
      if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
        this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
        this.mineOfficeApproveStatus = '';
      }
      if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
        this.subcriteriaStatus = '';
        this.mineOfficeApproveStatus = '';
      }
      if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
        this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
        this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
      }

      var key = Object.keys(this.finaAassignmentDetails.award_measurments)[criteraNumber - 1];
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
      for (let i = 0; i < this.criteriatype_name_Array.length; i++) {
        if (this.ScorePoint.score_data.length > 0) {
          if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.score_data[i].criteria_type_id) {

            this.criteriatype_name_Array[i]['ScorePoint'] = this.ScorePoint.score_data[i];
            this.criteriatype_name_Array[i].ScorePoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].ScorePoint.point_type).measurement_title;
            this.subcriteriaStatus = this.criteriatype_name_Array[i].ScorePoint.approved_status;
          }
        }
        this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
        if (this.rejetedData.length > 0) {
          this.rejetedData.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
        // if (this.ScorePoint.rejected_data.length > 0) {
        //   if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.rejected_data[i].criteria_type_id) {
        //     this.criteriatype_name_Array[i]['RejectedPoint'] = this.ScorePoint.rejected_data[i];
        //     this.criteriatype_name_Array[i].RejectedPoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].RejectedPoint.point_type).measurement_title;
        //     this.subcriteriaStatus = this.criteriatype_name_Array[i].RejectedPoint.approved_status;
        //   }
        // }
      }
      console.log(this.criteriatype_name_Array);
    }
  }
  addEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      resize_enabled: false,
      // toolbar: {
      // items: ['bold','italic','underline','|','strikethrough','subscript','superscript','|','bulletedList','numberedList','|','blockQuote','codeBlock','code','horizontalLine']
      // },
      language: 'en-gb',
      licenseKey: '',

    })
      .then(editor => {
        console.log(editor)
      })
      .catch(error => {
        console.error(error);
      });
  }
  // convenience getter for easy access to form fields
  get f1() { return this.approveForm.controls; }

  // convenience getter for easy access to form fields
  get f2() { return this.rejectForm.controls; }
  open(content, className) {
    let modalClassName = className;
    this.singleOfficeDetails = this.ScorePoint;
    console.log(this.singleOfficeDetails)
    this.submitted1 = false;
    this.submitted2 = false;
    // this.approveForm.reset();
    // this.rejectForm.reset();
    this.processingText1 = false;
    this.processingText2 = false;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  formOneSubmit() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.approveForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'criteria_id': this.ScorePoint.score_data[0].criteria_id,
      'award_id': this.ScorePoint.score_data[0].award_id,
      'company_id': this.ScorePoint.score_data[0].company_id,
      'assessor_group_id':  this.finaAassignmentDetails.assessor_group_id,
      'judge_group_id':  this.finaAassignmentDetails.judge_group_id,
      'status': 'A',
      'status_comment': this.approveForm.value.comments1
    }
    console.log(data);
    this.errMsg1 = '';
    this.processingText1 = true;
    this.dashboardService.officeAssesmentStatusChange(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.scoreBoardDetails();
        setTimeout(() => {
          this.messagesService.showSuccess(res.msg);
        }, 2500);
      }
      if (res.has_error == 1) {
        this.processingText1 = false;
        this.errMsg1 = res.msg;
        setTimeout(() => {
          this.errMsg1 = '';
        }, 2500);
      }
    },
      err => {
        this.processingText1 = false;
        if (err.error.message == "Unauthenticated.") {
          this.modalService.dismissAll();
        }
        if (err.error.message != "Unauthenticated.") {
          this.errMsg1 = 'Something went wrong.';
          setTimeout(() => {
            this.errMsg1 = '';
          }, 2000);
        }
        setTimeout(() => {
          this.messagesService.clear();
        }, 2500);
      })

  }
  formTwoSubmit() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.rejectForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'criteria_id': this.ScorePoint.score_data[0].criteria_id,
      'award_id': this.ScorePoint.score_data[0].award_id,
      'company_id': this.ScorePoint.score_data[0].company_id,
      'assessor_group_id':  this.finaAassignmentDetails.assessor_group_id,
      'judge_group_id':  this.finaAassignmentDetails.judge_group_id,
      'status': 'R',
      'status_comment': this.rejectForm.value.comments2
    }
    this.errMsg2 = '';
    this.processingText2 = true;
    this.dashboardService.officeAssesmentStatusChange(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.scoreBoardDetails();
        setTimeout(() => {
          this.messagesService.showSuccess(res.msg);
        }, 2500);
      }
      if (res.has_error == 1) {
        this.errMsg2 = res.msg;
        setTimeout(() => {
          this.errMsg2 = '';
        }, 2500);
        this.processingText2 = false;
      }
    },
      err => {
        this.processingText2 = false;
        if (err.error.message == "Unauthenticated.") {
          this.modalService.dismissAll();
        }
        if (err.error.message != "Unauthenticated.") {
          this.errMsg2 = 'Something went wrong.';
          setTimeout(() => {
            this.errMsg2 = '';
          }, 2000);
        }
        setTimeout(() => {
          this.messagesService.clear();
        }, 2500);
      })


  }
  scoreBoardDetails() {
    let data = {
      id: this.assessmentId
    }
    this.dashboardService.officeScore(this.api_token, data).subscribe((res: any) => {
      this.finaAassignmentDetails = res;
      this.subcriteriaList = res.get_all_criteria;
      console.log(this.subcriteriaList);
      if (this.criteraNumber != 3) {
        this.subcriteriaList.map((item) => {
          if (item.id == this.ScorePoint.id) {
            this.ScorePoint = item;
          }
        })
        console.log(this.ScorePoint);
        this.CriteraNumber = this.criteraNumber + '.' + this.ScorePoint.hire_order;
        if ((this.ScorePoint.status_log.length > 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')) {
          this.ScorePoint.status_log.map((item)=>{
            if(item.award_office_id != this.userDetails.id){
              this.giveOwnAssessment = true;
            }
            if(item.award_office_id == this.userDetails.id){
              this.giveOwnAssessment = false;
            }
          });
        }
        if((this.ScorePoint.status_log.length == 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')){
          this.giveOwnAssessment = true;
        }
  
        if (this.ScorePoint.score_data.length > 0) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
        }
        if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
          this.mineOfficeApproveStatus = '';
        }
        if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
          this.subcriteriaStatus = '';
          this.mineOfficeApproveStatus = '';
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
        }
        this.processingText2 = false;
        this.processingText1 = false;
        this.approveForm.reset();
        this.rejectForm.reset();
        this.modalService.dismissAll();
        console.log(this.subcriteriaStatus);
        this.score_data_Array = [];
        if (this.ScorePoint.score_data.length > 0) {
          this.score_data_Array.push(this.ScorePoint.score_data[0]);
          this.score_data_Array.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
        console.log(this.score_data_Array);
        this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
        if (this.rejetedData.length > 0) {
          this.rejetedData.map((item) => {
            item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
          })
        }
        console.log(this.rejetedData);
      }
      if (this.criteraNumber == 3) {
        this.subcriteriaList.map((item) => {
          if (item.id == this.ScorePoint.id) {
            this.ScorePoint = item;
          }
        })

        if (this.ScorePoint.score_data.length > 0) {
          this.ScorePoint.score_data = this.ScorePoint.score_data.reverse();
        }
        console.log(this.ScorePoint);
        this.CriteraNumber = this.criteraNumber + '.' + this.ScorePoint.hire_order;
        if ((this.ScorePoint.status_log.length > 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')) {
          this.ScorePoint.status_log.map((item)=>{
            if(item.award_office_id != this.userDetails.id){
              this.giveOwnAssessment = true;
            }
            if(item.award_office_id == this.userDetails.id){
              this.giveOwnAssessment = false;
            }
          });
        }
        if((this.ScorePoint.status_log.length == 0) && (this.finaAassignmentDetails.final_submission_status == 'Y')){
          this.giveOwnAssessment = true;
        }
  
        if (this.ScorePoint.score_data.length > 0) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
        }
        if ((this.ScorePoint.score_data.length == 0) && (this.ScorePoint.rejected_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.rejected_data[0].approved_status;
          this.mineOfficeApproveStatus = '';
        }
        if ((this.ScorePoint.rejected_data.length == 0) && (this.ScorePoint.score_data.length == 0)) {
          this.subcriteriaStatus = '';
          this.mineOfficeApproveStatus = '';
        }
        if ((this.ScorePoint.rejected_data.length > 0) && (this.ScorePoint.score_data.length > 0)) {
          this.subcriteriaStatus = this.ScorePoint.score_data[0].approved_status;
          this.mineOfficeApproveStatus = this.ScorePoint.score_data[0].office_approve_status;
        }
        console.log(this.subcriteriaStatus);
        this.processingText2 = false;
        this.processingText1 = false;
        this.approveForm.reset();
        this.rejectForm.reset();
        this.modalService.dismissAll();
        var key = Object.keys(this.finaAassignmentDetails.award_measurments)[this.criteraNumber - 1];
        this.awardMeasurmentsList = [];
        this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
        for (let i = 0; i < this.criteriatype_name_Array.length; i++) {
          if (this.ScorePoint.score_data.length > 0) {
            if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.score_data[i].criteria_type_id) {

              this.criteriatype_name_Array[i]['ScorePoint'] = this.ScorePoint.score_data[i];
              this.criteriatype_name_Array[i].ScorePoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].ScorePoint.point_type).measurement_title;
              this.subcriteriaStatus = this.criteriatype_name_Array[i].ScorePoint.approved_status;
            }
          }
          if (this.ScorePoint.rejected_data.length > 0) {
            if (this.criteriatype_name_Array[i].criteria_type_id == this.ScorePoint.rejected_data[i].criteria_type_id) {
              this.criteriatype_name_Array[i]['RejectedPoint'] = this.ScorePoint.rejected_data[i];
              this.criteriatype_name_Array[i].RejectedPoint.point_type_name = this.awardMeasurmentsList.find(o => o.points === this.criteriatype_name_Array[i].RejectedPoint.point_type).measurement_title;
              this.subcriteriaStatus = this.criteriatype_name_Array[i].RejectedPoint.approved_status;
            }
          }
        }
        console.log(this.criteriatype_name_Array);
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
}

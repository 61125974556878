import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { TestimonialsComponent } from './testimonials.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WinnerService } from '../services/winner.service';

// intro routing
export const routes: Routes = [
  { path: '', component: TestimonialsComponent },
];

@NgModule({
  declarations: [TestimonialsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CarouselModule
  ],
  providers: [WinnerService]
})
export class TestimonialsModule { }

import { Component, OnInit } from '@angular/core';
import { MeadiaService } from '../services/meadia.service';
import { MessagesService } from '../services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-view-all-media',
  templateUrl: './view-all-media.component.html',
  styleUrls: ['./view-all-media.component.scss']
})
export class ViewAllMediaComponent implements OnInit {
  user_token: any;
  mediaImageArray: any = [];
  year: any;
  yearList: any = [];
  mediaImagePath: any;
  // lazyLoad: boolean = false;
  galleryShow: boolean = false;
  pageNumber: number = 1;
  totalNumber: number = 1;
  currentYear: any;
  newYearArray: any = [];
  mediaArray: any = [];
  awardYear: any;
  loadMoreImagesArray: any = [];
  loadmorePageNumber: any;
  moreMediaArray: any = [];
  viewAllSelectedyear: any = 2020;
  viewAllSelectedIndex: any;
  constructor(private meadiaService: MeadiaService,
    private messagesService: MessagesService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private authService: AuthService) {
    this.user_token = localStorage.getItem('user_token');
    this.activatedRoute.params.subscribe(params => {
      this.viewAllSelectedyear = Number(params['id']);
    });
    if (this.router.url === '/view-all-media') {
      this.router.navigate(['/view-all-media/2020']);
    }
  }

  ngOnInit() {
    this.getuserToken();
    var d = new Date();
    this.currentYear = d.getFullYear();
    console.log(this.currentYear)
    for (let i = this.currentYear; i > this.currentYear - 4; i--) {
      this.yearList.push(i);
    }
    this.yearList.forEach((element, index) => {
      if (element == this.viewAllSelectedyear) {
        this.viewAllSelectedIndex = index;
      }
    });
    this.newYearArray = [this.viewAllSelectedyear];
    window.scroll(0, 0);
    this.getMediaList();
  }

  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  yearFilteration(year, index) {
    this.location.go('/view-all-media/' + year);
    let yearId = 'year' + index;
    // this.awardYear = year;
    this.year = year;
    let parentEl = document.getElementById(yearId).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('a').classList.remove('active');
    });
    document.getElementById(yearId).className += ' active';
    if (!this.newYearArray.includes(year)) {
      this.newYearArray.push(year);
      // this.year = year;
      this.galleryShow = true;
      this.pageNumber = 1;
      let per_page = 10;
      if (this.moreMediaArray.findIndex(x => x.awardYear === this.year) >= 0) {
        let newobject = this.moreMediaArray.find(x => x.awardYear === this.year)
        newobject.loadmorePageNumber = this.pageNumber;
      }
      else {
        let a = {
          'awardYear': this.year,
          'loadmorePageNumber': this.pageNumber
        }
        this.moreMediaArray.push(a)
      }
      this.mediaImageArray = [];
      this.meadiaService.mediaList(this.user_token, this.year, this.pageNumber, per_page).subscribe((res: any) => {

        if (res.has_error == 0) {
          this.galleryShow = false;
          this.mediaImagePath = res.imagePath;
          this.totalNumber = res.totalImageData;
          res.imageData.map((item) => {
            this.mediaImageArray.push(item);
          })
          this.mediaImageArray.map((item) => {
            this.mediaArray.push(item)
          })
        }
      }, err => {
        this.galleryShow = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1200)
      })
    }
    else if (this.newYearArray.includes(year)) {
      let a = year.toString();
      this.mediaImageArray = this.mediaArray.filter(x => x.year == a);
      console.log(this.moreMediaArray)
      let b = this.moreMediaArray.find(x => x.awardYear === year);
      console.log(b)
      this.pageNumber = b.loadmorePageNumber;
    }
  }

  getMediaList() {
    this.awardYear = this.viewAllSelectedyear;
    // this.lazyLoad = true;
    this.galleryShow = true;
    if (this.year == undefined) this.year = this.viewAllSelectedyear;
    let page_no = this.pageNumber;
    let per_page = 10;
    if (this.moreMediaArray.findIndex(x => x.awardYear === this.year) >= 0) {
      let newobject = this.moreMediaArray.find(x => x.awardYear === this.year)
      newobject.loadmorePageNumber = this.pageNumber;
    }
    else {
      let a = {
        'awardYear': this.year,
        'loadmorePageNumber': this.pageNumber
      }
      this.moreMediaArray.push(a)
    }
    this.meadiaService.mediaList(this.user_token, this.year, this.pageNumber, per_page).subscribe((res: any) => {
      if (res.has_error == 0) {
        // this.lazyLoad = false;
        this.galleryShow = false;
        this.mediaImagePath = res.imagePath;
        this.totalNumber = res.totalImageData;
        res.imageData.map((item) => {
          this.mediaImageArray.push(item);
        })
        this.mediaImageArray.map((item) => {
          this.mediaArray.push(item)
        })
        console.log(this.mediaArray)
        // this.mediaArray = this.mediaImageArray;
      }
    }, err => {
      // this.lazyLoad = false;
      this.galleryShow = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 1200)
    })
  }
  // load more awards
  loadMoreAwards() {
    this.pageNumber++;
    let pagenumber = this.pageNumber;
    let per_page = 10;
    this.loadmorePageNumber = this.pageNumber;
    this.awardYear = this.year;

    if (this.moreMediaArray.findIndex(x => x.awardYear === this.awardYear) >= 0) {
      let newobject = this.moreMediaArray.find(x => x.awardYear === this.awardYear)
      newobject.loadmorePageNumber = this.loadmorePageNumber;
    }
    else {
      let a = {
        'awardYear': this.awardYear,
        'loadmorePageNumber': this.loadmorePageNumber
      }
      this.moreMediaArray.push(a)
    }

    this.meadiaService.mediaList(this.user_token, this.awardYear, pagenumber, per_page).subscribe((res: any) => {
      if (res.has_error == 0) {
        // this.lazyLoad = false;
        this.mediaImagePath = res.imagePath;
        this.totalNumber = res.totalImageData;
        this.loadMoreImagesArray = res.imageData;
        res.imageData.map((item) => {
          this.mediaImageArray.push(item);
          this.mediaArray.push(item);
        })
      }
    }, err => {
      // this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 1200)
    })
  }
  onScroll() {
    console.log('scrolled!')
    this.loadMoreAwards();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/_helpers/_guards/custom.validator';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { JudgeService } from '../../services/judge.service';

@Component({
  selector: 'app-award-office-profile',
  templateUrl: './award-office-profile.component.html',
  styleUrls: ['./award-office-profile.component.scss']
})
export class AwardOfficeProfileComponent implements OnInit {
  leftbarselected: any;
  show_personal_information: boolean = false;
  show_change_password: boolean = false;
  user_token: any;
  api_token: any;
  updatePasswordForm: FormGroup;
  profileUpdateForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  lazyLoad: boolean = false;
  loadingCount: number = 0;
  userDetails: any;
  profileImagePath: any;
  profile_pic_show: null;
  display_profile_photo: any;
  upperCaseNewErrMsg: string;
  NumberNewErrMsg: string;
  processingPasswordText: boolean;
  processingText: boolean = false;
  profile_photo: any;
  errorMessageValue: any;
  companymanagementListArray: any = [];
  permissionMsg: any;
  permissionStatus: boolean = false;
  constructor(private router: Router,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private judgeService: JudgeService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/award-office-profile')
          this.leftbarselected = 'profile';
        this.dataService.leftPanelSelection('award-office-profile');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-profile`]);
    }
    this.updatePasswordForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });
    this.profileUpdateForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
    this.getUserDetails();
    this.companymanagementList();
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  get f() { return this.updatePasswordForm.controls; }

  get f1() { return this.profileUpdateForm.controls; }
  openshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  closeshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  openshow_change_password() {
    this.show_change_password = !this.show_change_password;
  }
  closeshow_change_password() {
    this.show_change_password = !this.show_change_password;

  }
  getUserDetails() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.getUserDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.loadingCount = 1;
        if (this.loadingCount == 1) {
          this.dataService.userdetails(res);
          this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
          this.userDetails.name = res.user_details.name;
          this.userDetails.profile_picture = res.user_details.profile_picture;
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        }
        this.userDetails = res.user_details;
        this.profileImagePath = res.imagePath;
        this.display_profile_photo = this.userDetails.profile_picture != null ? this.profileImagePath + this.userDetails.profile_picture : 'assets/images/default-user.png';
        this.profile_photo = this.userDetails.profile_picture;

        this.profileUpdateForm.patchValue({
          first_name: this.userDetails.first_name,
          last_name: this.userDetails.last_name,
          email: this.userDetails.email,
          phone: this.userDetails.phone,
          city: this.userDetails.city,
          address: this.userDetails.address,
        });
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.')
      })
  }
  onSelectPhoto(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_pic_show = event.target.files[0];
      console.log(this.profile_pic_show);
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
        // console.log(this.display_profile_photo)
      }
    }
  }
  onKeyNewPassword(event: any) { // without type info
    let upperCaseCharacters = /[A-Z]+/;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/;
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (event.target.value != '') {
      this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';
      this.NumberNewErrMsg = 'New Password must contain at least one number';
      if ((upperCaseCharacters.test(event.target.value) === false)) {
        this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';

      }
      if ((upperCaseCharacters.test(event.target.value) === true)) {
        this.upperCaseNewErrMsg = '';
      }
      if ((numberCharacters.test(event.target.value) === false)) {
        this.NumberNewErrMsg = 'New Password must contain at least one number';
      }
      if ((numberCharacters.test(event.target.value) === true)) {
        this.NumberNewErrMsg = '';
      }
    }
    else if (event.target.value == '') {
      this.NumberNewErrMsg = '';
      this.upperCaseNewErrMsg = '';
    }
  }
  updatepassword() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      current_password: this.updatePasswordForm.value.current_password,
      new_password: this.updatePasswordForm.value.new_password,
    }
    if ((this.NumberNewErrMsg == '') && (this.upperCaseNewErrMsg == '')) {
      this.processingPasswordText = true;
      this.judgeService.updatePaasword(this.api_token, data).subscribe((res: any) => {
        console.log(res)
        if (res.has_error == 0) {
          this.processingPasswordText = false;
          this.messagesService.showSuccess(res.msg);
          this.submitted1 = false;
          console.log(this.updatePasswordForm.value);
          this.show_change_password = false;
          this.updatePasswordForm.reset();
          this.upperCaseNewErrMsg = '';
          this.NumberNewErrMsg = '';
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingPasswordText = false;
        }
      },
        err => {
          this.processingPasswordText = false;
          this.messagesService.showError('Something went wrong.');
        });
    }
  }
  updateprofile() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.profileUpdateForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('first_name', this.profileUpdateForm.value.first_name),
      formData.append('last_name', this.profileUpdateForm.value.last_name),
      formData.append('phone', this.profileUpdateForm.value.phone),
      formData.append('city', this.profileUpdateForm.value.city),
      formData.append('address', this.profileUpdateForm.value.address),
      formData.append('email', this.profileUpdateForm.value.email)
    if (this.profile_pic_show != null) {
      formData.append('profile_picture', this.profile_pic_show)
    }
    if (this.profile_pic_show == null) {
      formData.append('profile_picture', this.profile_photo)
    }
    this.processingText = true;
    this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.processingText = false;
        this.getUserDetails();
        this.show_personal_information = false;
        this.profile_pic_show = null;
        this.messagesService.showSuccess('Profile Updated successfully.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
      else if (res.has_error == 1) {
        this.processingText = false;
        for (let [key, value] of Object.entries(res.error)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
  }
}

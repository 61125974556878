import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SponsorsListComponent } from './sponsors-list.component';
import { AuthService } from '../services/auth.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MessagesService } from '../services/messages.service';

// intro routing
export const routes: Routes = [
  { path: '', component: SponsorsListComponent },
];

@NgModule({
  declarations: [SponsorsListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CarouselModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    InfiniteScrollModule
  ],
  providers: [
    AuthService,
    MessagesService
  ]
})
export class SponsorsListModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OfficeAssessmentComponent } from './office-assessment.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// intro routing
export const routes: Routes = [
  { path: '', component: OfficeAssessmentComponent },
];

@NgModule({
  declarations: [OfficeAssessmentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgScrollbarModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class OfficeAssessmentModule { }

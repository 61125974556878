import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveAccountComponent } from './active-account.component';
import { RouterModule, Routes } from '@angular/router';

// intro routing
export const routes: Routes = [
  { path: '', component: ActiveAccountComponent },
];

@NgModule({
  declarations: [ActiveAccountComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class ActiveAccountModule { }

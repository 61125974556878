import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JudgeService } from '../../services/judge.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { last } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-office-company-documents',
  templateUrl: './office-company-documents.component.html',
  styleUrls: ['./office-company-documents.component.scss']
})
export class OfficeCompanyDocumentsComponent implements OnInit {
  show_document_popup: any = [];
  show_doc_list: boolean = true;
  view_doc_status: boolean = false;
  view_general_doc_status: boolean = false;
  leftbarselected: any;
  user_token: any;
  api_token: any;
  addDocmentForm: FormGroup;
  addGeneralDocmentForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  criteraList: any = [];
  document: any = [];
  general_document: any;
  documentErr: any;
  generalDocumentErr: any;
  processingAddDocument: boolean = false;
  processingAddGeneralDocument: boolean = false;
  documentList: any = [];
  generalDocumentList: any = [];
  documentImagePath: any;
  generalDocumentImagePath: any;
  final_document: any;
  final_general_document: any;
  lazyLoad: boolean = false;
  documentLoad: boolean = false;
  generaDocumentLoad: boolean = false;
  documentType: any;
  generalDocumentAction: any;
  generalDocumentId: any;
  document_name: any;
  NoDatamsg: any;
  generalDocumentErrmsg: any;
  loadingCount: number = 0;
  documentres: any;
  allDocumentData: any = [];
  allGeneralDocumentData: any = [];
  documentDocs: any = [];
  generalDocumentDocs: any = [];
  allDocumentStatusShow: boolean = false;
  allGeneralDocumentStatusShow: boolean = false;
  company_doc_text: any;
  generalDocumentDownloadPath: any;
  documentDownloadPath: any;
  generalDocumentRes: any;
  documentAwardId: any;
  documentAwardYear: any;
  generalDocumentAwardId: any;
  generalDocumentAwardYear: any;
  searchCompany: any;
  generalSearchCompany: any;
  documentFilterationYearList: any = [];
  generalDocumentFilterationYearList: any = [];
  documentFilterationAwardList: any = [];
  generalDocumentFilterationAwardList: any = [];
  companymanagementListArray: any = [];
  documentFilterationCompany: any = [];
  generalDocumentFilterationCompany: any = [];
  permissionMsg: any;
  permissionStatus: boolean = false;
  documentCompanyId: any;
  generalDocumentCompanyId: any;
  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private dashboardService: DashboardService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/company-documents')
          this.leftbarselected = 'Company Documents';
        this.dataService.leftPanelSelection('company_documents');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/company-documents`]);
    }
    this.getDocumentList();
    this.getGeneralDocumentList();
    this.companymanagementList();
    this.addDocmentForm = this.formBuilder.group({
      criteria: ['', [Validators.required]]
    });
    this.addGeneralDocmentForm = this.formBuilder.group({
      document_name: ['', [Validators.required]],
    });
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  get f1() { return this.addDocmentForm.controls; }

  get f2() { return this.addGeneralDocmentForm.controls; }

  open_add_document(generalDocument, action) {
    if (this.show_doc_list == true) {
      this.show_document_popup = 1;
    }
    else {
      this.show_document_popup = 2;
      this.generalDocumentAction = action;
      if (generalDocument != null) {
        this.generalDocumentId = generalDocument.id;
        this.addGeneralDocmentForm.patchValue({
          document_name: generalDocument.name
        })
      }
    }
  }
  close_add_document() {
    this.show_document_popup = 0;
    this.submitted1 = false;
    this.submitted2 = false;
    this.addDocmentForm.reset();
    this.addGeneralDocmentForm.reset();
    this.documentErr = '';
    this.generalDocumentErr = '';
    this.document = [];
    this.general_document = null;
    this.criteraList = [];
    this.criteraList.push({
      'key': 'loop-' + this.documentres.criteria.length,
      'option': this.documentres.criteria
    });
  }
  doc_list_status() {
    this.show_doc_list = true;
    this.documentAwardYear = '';
    this.documentAwardId = '';
    this.getDocumentList();
  }
  general_doc_status() {
    this.show_doc_list = false;
    this.generalDocumentAwardYear = '';
    this.generalDocumentAwardId = '';
    this.getGeneralDocumentList();
  }
  open_view_document1(document1) {
    this.documentType = document1.doc_name.split('.').pop();
    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png') || (this.documentType == 'JPEG')) {
      this.view_doc_status = true;
      this.documentLoad = true;
      this.final_document = this.documentImagePath + document1.doc_name;
      this.documentLoad = false;
    }
    else if (this.documentType == 'pdf') {
      this.view_doc_status = true;
      this.documentLoad = true;
      this.final_document = this.documentImagePath + document1.doc_name;
      this.final_document = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentImagePath + document1.doc_name);
      this.documentLoad = false;
    }
    else if (this.documentType == 'docx') {
      this.view_doc_status = false;
      this.final_document = this.documentImagePath + document1.doc_name;
      const downloadLink = document.createElement("a");
      const fileName = document1.document_name;

      downloadLink.href = this.final_document;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  open_view_document2(generalDocument) {
    this.documentType = generalDocument.document_name.split('.').pop();
    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png') || (this.documentType == 'JPEG')) {
      this.view_general_doc_status = true;
      this.generaDocumentLoad = true;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      this.generaDocumentLoad = false;
    }
    else if (this.documentType == 'pdf') {
      // this.view_doc_status = true;
      // this.documentLoad = true;
      this.view_general_doc_status = true;
      this.generaDocumentLoad = true;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      this.final_general_document = this.sanitizer.bypassSecurityTrustResourceUrl(this.generalDocumentImagePath + generalDocument.document_name);
      // this.documentLoad = false;
      this.generaDocumentLoad = false;
    }
    else if (this.documentType == 'docx') {
      this.view_general_doc_status = false;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      const downloadLink = document.createElement("a");
      const fileName = generalDocument.document_name;

      downloadLink.href = this.final_general_document;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  close_view_document1() {
    this.view_doc_status = false;
  }
  close_view_document2() {
    this.view_general_doc_status = false;
  }
  onSelectFileAddDocument(event) {

    let files = event.target.files;
    console.log(files);
    if (files) {
      for (let file of files) {
        this.document.push(file)
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //     }
        //     reader.readAsDataURL(file);
      }
    }
    if (this.document.length > 0) {
      this.documentErr = '';
    }
    console.log(this.document);
  }
  onSelectFileAddGeneralDocument(event) {
    if (event.target.files && event.target.files[0]) {
      this.general_document = event.target.files[0];
      console.log(this.general_document);
      if (this.general_document != '') {
        this.generalDocumentErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        // this.display_profile_photo = event.target.result;
      }
    }
  }
  onChange(keyname, $event) {
    this.NoDatamsg = '';
    let changesIndex = this.criteraList.findIndex(x => x.key == keyname);
    if (this.criteraList.length > changesIndex + 1) {
      let criLen = this.criteraList.length
      this.criteraList.splice(changesIndex + 1, criLen - (changesIndex + 1));
      // let lastObj = this.criteraList[this.criteraList.length - 1]
      // lastObj.option = []
    }
    this.getCriteria($event.target.value);
  }
  deleteCriteria(keyname) {
    this.NoDatamsg = '';
    let changesIndex = this.criteraList.findIndex(x => x.key == keyname);
    if (this.criteraList.length > changesIndex + 1) {
      let criLen = this.criteraList.length
      this.criteraList.splice(changesIndex + 1, criLen - (changesIndex + 1));
      // let lastObj = this.criteraList[this.criteraList.length - 1]
      // lastObj.option = []
    }
  }
  getCriteria(parentId) {
    let data = {
      parent_id: parentId
    }
    this.judgeService.getCritera(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        if (res.allcriteria.length > 0) {
          this.criteraList.push({
            'key': 'loop-' + this.criteraList.length,
            'option': res.allcriteria
          });
          console.log(this.criteraList)
        }
        if (res.allcriteria.length == 0) {
          this.NoDatamsg = 'This is the last subcriteria';
          this.generalDocumentErrmsg = '';
        }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  getDocumentList() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.judgeService.searchCompanylist(this.api_token, this.documentAwardYear, this.documentAwardId, this.searchCompany).subscribe((res: any) => {
      console.log(res);
      this.loadingCount = 1;
      this.lazyLoad = false;
      this.permissionStatus = false;
      this.permissionMsg = '';
      this.documentres = res;
      this.documentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';

      if(this.documentres.hasOwnProperty('filerationAwards')){
        this.documentFilterationAwardList = this.documentres.filerationAwards.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)));
      }
      if(this.documentres.hasOwnProperty('filerationYears')){
      this.documentFilterationYearList = this.documentres.filerationYears.filter((thing, index, self) => index === self.findIndex((t) => (t === thing)));
      }
      this.documentList = res.company_doc;
      console.log(this.documentList)
      this.documentFilterationCompany = res.filterationCompany;
      // this.company_doc_text = res.company_doc_text;
      
      // this.documentImagePath = res.image_path;
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
        
      }
      console.log(this.permissionMsg);
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      })
  }
  getGeneralDocumentList() {
    this.judgeService.searchGeneralCompanylist(this.api_token, this.generalDocumentAwardYear, this.generalDocumentAwardId, this.generalSearchCompany).subscribe((res: any) => {
      console.log(res);
      this.generalDocumentRes = res;
      this.generalDocumentFilterationAwardList = this.generalDocumentRes.filerationAwards.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)));
      console.log('generalDocumentFilterationAwardList', this.generalDocumentFilterationAwardList);
      this.generalDocumentFilterationYearList = this.generalDocumentRes.filerationYears.filter((thing, index, self) => index === self.findIndex((t) => (t === thing)));
      this.generalDocumentFilterationCompany = res.filterationCompany;
      this.generalDocumentList = res.company_doc;
      this.company_doc_text = res.company_doc_text;
      // this.generalDocumentImagePath = res.image_path;
      this.generalDocumentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  filterDocument() {
    this.judgeService.searchCompanylist(this.api_token, this.documentAwardYear, this.documentAwardId, this.documentCompanyId).subscribe((res: any) => {
      console.log(res);
      this.loadingCount = 1;
      this.documentres = res;
      this.documentList = res.company_doc;
      // this.documentList.map((item) => {
      //   item.checked = false;
      // })
      this.allDocumentStatusShow = false;
      this.allDocumentData = [];
      this.documentDocs = [];
      console.log(this.documentList)
      this.company_doc_text = res.company_doc_text;
      this.criteraList = [];
      this.NoDatamsg = '';
      this.criteraList.push({
        'key': 'loop-' + this.criteraList.length,
        'option': res.criteria
      });
      // this.documentImagePath = res.image_path;

    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  filterGeneralDocument() {
    this.judgeService.searchGeneralCompanylist(this.api_token, this.generalDocumentAwardYear, this.generalDocumentAwardId, this.generalDocumentCompanyId).subscribe((res: any) => {
      console.log(res);
      this.generalDocumentRes = res;
      this.generalDocumentList = res.company_doc;
      this.allGeneralDocumentStatusShow = false;
      this.allGeneralDocumentData = [];
      this.generalDocumentDocs = [];
      this.company_doc_text = res.company_doc_text;
      // this.generalDocumentImagePath = res.image_path;
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  onChangeCompany(value){
    if (this.show_doc_list == true) {
      this.documentCompanyId = value;
      console.log(this.documentCompanyId);
    }
    if (this.show_doc_list == false) {
      this.generalDocumentCompanyId = value;
    }
  }
  onChangeGeneralCompany(value){
    if (this.show_doc_list == true) {
      this.documentCompanyId = value;
      console.log(this.documentCompanyId);
    }
    if (this.show_doc_list == false) {
      this.generalDocumentCompanyId = value;
    }
  }
  onChangeAward(value) {
    if (this.show_doc_list == true) {
      this.documentAwardId = value;
      console.log(this.documentAwardId);
    }
    if (this.show_doc_list == false) {
      this.generalDocumentAwardId = value;
    }

  }
  onChangeYear(value) {
    if (this.show_doc_list == true) {
      this.documentAwardYear = value;
    }
    if (this.show_doc_list == false) {
      this.generalDocumentAwardYear = value;
    }
  }
  addDocment() {
    this.submitted1 = true;
    if (this.document.length == 0) {
      this.documentErr = 'Document is required';
    }
    // stop here if form is invalid
    if (this.addDocmentForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token)
    formData.append('criteria_id', this.addDocmentForm.value.criteria),
      formData.append('award_year_id', this.documentres.company_data.company.company_award.award_year_id),
      formData.append('file_count', this.document.length)
    for (let i = 0; i < this.document.length; i++) {
      formData.append("comp_excel_upload_" + i, this.document[i]);
    }
    if (this.document.length == 0) {
      this.documentErr = 'Document is required';
    }
    if (this.document.length > 0) {
      this.documentErr = '';
      this.generalDocumentErrmsg = '';
      if (this.NoDatamsg != '') {
        this.processingAddDocument = true;
        this.judgeService.addDocument(this.api_token, formData).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.processingAddDocument = false;
            // let a = this.criteraList[0]
            // this.criteraList = [];
            // this.criteraList.push(a);
            // console.log(this.criteraList)
            this.getDocumentList();
            this.close_add_document();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.processingAddDocument = false;
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.processingAddDocument = false;
            this.messagesService.showError('Something went wrong.');
          })
      }
    }
    else if (this.NoDatamsg == '') {
      this.generalDocumentErrmsg = 'Please wait there is some other criteria';
    }
  }
  addGeneralDocment() {
    console.log(this.generalDocumentRes.current_award_year_id)
    this.submitted2 = true;
    if (this.generalDocumentAction == 'add') {
      if ((this.general_document == null) || (this.general_document == undefined)) {
        this.generalDocumentErr = 'General Document is required';
      }
      // stop here if form is invalid
      if (this.addGeneralDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('action', this.generalDocumentAction),
        formData.append('name', this.addGeneralDocmentForm.value.document_name),
        formData.append('award_year_id', this.generalDocumentRes.current_award_year_id),
        formData.append('document_name', this.general_document)

      if ((this.general_document == null) || (this.general_document == undefined)) {
        this.generalDocumentErr = 'General Document is required';
      }
      if ((this.general_document != null) || (this.general_document != undefined)) {
        this.processingAddGeneralDocument = true;
        this.judgeService.addGeneralDocument(this.api_token, formData).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.processingAddGeneralDocument = false;
            this.close_add_document();
            this.getGeneralDocumentList();
            this.messagesService.showSuccess('General Document Added Successfully');
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
            this.processingAddGeneralDocument = false;
          }
        },
          err => {
            this.processingAddGeneralDocument = false;
            this.messagesService.showError('Something went wrong.');
          })
      }
    }
    else if (this.generalDocumentAction == 'edit') {
      // stop here if form is invalid
      if (this.addGeneralDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('action', this.generalDocumentAction),
        formData.append('name', this.addGeneralDocmentForm.value.document_name),
        formData.append('doc_id', this.generalDocumentId),
        formData.append('award_year_id', this.generalDocumentRes.current_award_year_id),
        // console.log(this.criteraList)
        this.processingAddGeneralDocument = true;
      this.judgeService.addGeneralDocument(this.api_token, formData).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingAddGeneralDocument = false;
          console.log(this.criteraList);
          this.close_add_document();
          this.getGeneralDocumentList();
          this.messagesService.showSuccess('General Document Updated Successfully');
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingAddGeneralDocument = false;
        }
      },
        err => {
          this.processingAddGeneralDocument = false;
          this.messagesService.showError('Something went wrong.');
        })
    }
  }
  openDocumentDialog(generaldocument) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          document_id: generaldocument.id
        }
        this.judgeService.deleteCompanyDocument(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.getDocumentList();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.messagesService.showError('Something went wrong.');
          })
        // const a = document.createElement('a');
        // a.click();
        // a.remove();
      }
    });
  }
  openGeneralDocumentDialog(generaldocument) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          document_id: generaldocument.id
        }
        this.judgeService.deleteCompanyGeneralDocument(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.getGeneralDocumentList();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.messagesService.showError('Something went wrong.');
          })
      }
    });
  }
  singleDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.documentList.map((docItem) => {
        if (this.allDocumentData.indexOf(docItem.id) == -1) {
          if (docItem.id == Item.id) {
            this.allDocumentData.push(docItem.id);
            docItem.checked = true;
            this.documentDocs.push('assets/upload/category_documents/' + docItem.doc_name);
          }
        }
      })
      let documentPath = this.documentDocs.join('--');
      this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      if (this.documentList.length === this.documentDocs.length) {
        this.allDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allDocumentData.findIndex(itm => itm === Item.id);
      // console.log(removeIndex)
      if (removeIndex !== -1) {
        this.allDocumentData.splice(removeIndex, 1);
        this.documentDocs.splice(removeIndex, 1);
      }
      if (this.documentDocs.length > 0) {
        let documentPath = this.documentDocs.join('--');
        this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      }
      if (this.documentList.length !== this.documentDocs.length) {
        this.allDocumentStatusShow = false;
      }
    }
    console.log(this.allDocumentData);
    console.log(this.documentDocs);
  }
  SelectAllDocumentValue($event) {
    this.allDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.documentDocs = [];
      this.documentList.map((item) => {
        if (this.allDocumentData.indexOf(item.id) == -1) {
          this.allDocumentData.push(item.id);
          item.checked = true;
          this.documentDocs.push('assets/upload/category_documents/' + item.doc_name);
        }
      });
      this.allDocumentStatusShow = true;
      let documentPath = this.documentDocs.join('--');
      this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      // this.allDocumentStatusShow = true;
    }
    else if ($event.srcElement.checked == false) {
      this.allDocumentData = [];
      this.documentDocs = [];
      this.documentList.map((item) => {
        item.checked = false;
      });
      this.allDocumentStatusShow = false;
    }
    console.log(this.documentDocs)
    console.log(this.allDocumentData)
  }

  singleGeneralDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.generalDocumentList.map((generaldocItem) => {
        if (this.allGeneralDocumentData.indexOf(generaldocItem.id) == -1) {
          if (generaldocItem.id == Item.id) {
            this.allGeneralDocumentData.push(generaldocItem.id);
            generaldocItem.checked = true;
            this.generalDocumentDocs.push('assets/upload/comp_general_doc/' + generaldocItem.document_name);
          }
        }
      })
      let generalDocumentPath = this.generalDocumentDocs.join('--');
      this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      if (this.generalDocumentList.length == this.generalDocumentDocs.length) {
        this.allGeneralDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allGeneralDocumentData.findIndex(itm => itm === Item.id);
      // console.log(removeIndex)
      if (removeIndex !== -1) {
        this.allGeneralDocumentData.splice(removeIndex, 1);
        this.generalDocumentDocs.splice(removeIndex, 1);
      }
      if (this.generalDocumentDocs.length > 0) {
        let generalDocumentPath = this.generalDocumentDocs.join('--');
        this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      }
      if (this.generalDocumentList.length !== this.generalDocumentDocs.length) {
        this.allGeneralDocumentStatusShow = false;
      }
    }
    console.log(this.allGeneralDocumentData);
    console.log(this.generalDocumentDocs);
  }
  SelectAllGeneralDocumentValue($event) {
    this.allGeneralDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.generalDocumentDocs = [];
      this.generalDocumentList.map((item) => {
        if (this.allGeneralDocumentData.indexOf(item.id) == -1) {
          this.allGeneralDocumentData.push(item.id);
          item.checked = true;
          this.generalDocumentDocs.push('assets/upload/comp_general_doc/' + item.document_name);
        }
      });
      this.allGeneralDocumentStatusShow = true;
      let generalDocumentPath = this.generalDocumentDocs.join('--');
      this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      // this.allDocumentStatusShow = true;
    }
    else if ($event.srcElement.checked == false) {
      this.allGeneralDocumentData = [];
      this.generalDocumentDocs = [];
      this.generalDocumentList.map((item) => {
        item.checked = false;
      });
      this.allGeneralDocumentStatusShow = false;
    }
    console.log(this.generalDocumentDocs);
    console.log(this.allGeneralDocumentData);
  }

}

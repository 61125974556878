import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import { appConfig } from '../app.config';

@Component({
  selector: 'app-assessor-application',
  templateUrl: './assessor-application.component.html',
  styleUrls: ['./assessor-application.component.scss']
})
export class AssessorApplicationComponent implements OnInit {
  formOneShow: boolean = true;
  formTwoShow: boolean = false;
  formThreeShow: boolean = false;

  showOpenAward: boolean = false;
  showOpenCountry: boolean = false;
  allAwardList: any = [];
  allAwardSelected: any = [];
  allAwardCountryList: any = [];
  allAwardCountrySelected: any = [];
  allAwardCountrySelectedString: any = "";
  allAwardSelectedString: any = "";

  awardId: any = [];
  Nationalities: any = [];
  NationalityId: any = [];
  cityListArray: any = [];
  genderList: any = [];
  namePrefixsList: any = [];

  ageGroupsList: any = [];
  yearsofWorkExperiencesList: any = [];
  AssessmentCompletesList: any = [];
  assessmentExperiencesList: any = [];
  ConfirmationsList: any = [];
  employmentStatusesList: any = [];
  industriesList: any = [];
  qualificationsList: any = [];
  professionalLanguagesList: any = [];
  areasofExpertiseList: any = [];
  awardsParticipatesList: any = [];
  awardAssessmentTeamLeaderYearsList: any = [];
  innovationHandsonExperiencesList: any = [];
  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  stepThreeForm: FormGroup;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  user_token: any;
  pageNumber: number = 1;
  totalNumber: number = 1;
  countryCallingCodes: any = [];
  awardList: any = [];
  countryCodeArray: any = [];
  Areas_of_Expertise: any = [];
  external_awards_participateds: any = [];
  Innovation_Hands_on_Experience: any = [];
  Profile_CV: any;
  Profile_pic: any;
  NationalityErr: any;
  awardErr: any;
  expertiseErr: any;
  ProfessionalProfileErr: any;
  ProfessionalPicErr: any;
  ExternalAwardsParticipatedErr: any;
  InnovationExperienceErr: any;
  PrefixNameErr: any;
  mobileCountryCodeeErr: any;
  WhatsAppCountryErr: any;
  newAwardIdArray: any = [];
  showCountryborder: boolean = false;
  showAwardborder: boolean = false;
  registrationSuccessMsg: any;
  Profile_pic_Show: any;
  Profile_Cv_Show: any;
  errorMessageValue: any;
  processingText: boolean = false;
  uniquePersonalEmailShow: boolean = false;
  uniquePersonalEmailVerified: boolean = false;
  uniquePersonalEmailErr: any;
  lazyLoad: boolean = false;
  emailProsessingtext: boolean = false;
  webSitePatternErr: boolean = false;
  imageResolutionText: any;
  awardIdList: any = [];
  allCountryList: any = [];
  fileTypeText: any;

  settings1 = {};
  settings2 = {};
  settings3 = {};
  ExternalAwardsParticipatesSelectedItem: any = [];
  InnovationHandsonExperiencesSelectedItem: any = [];
  AreasofExpertisesSelectedItem: any = [];
  loading:boolean = false;

  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private messagesService: MessagesService,
    private router: Router) {
    localStorage.removeItem('awardId');
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
    this.getuserToken();
    this.stepOneForm = this.formBuilder.group({
      namePrefix: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      qualification: ['', Validators.required],
      Country_code: ['', Validators.required],
      mobile_no: ['', Validators.required],
      WhatsApp_Country_code: ['', Validators.required],
      WhatsApp_mobile_no: ['', Validators.required],
      graduatedIn: ['', Validators.required],
      nationality: ['', Validators.required],
      workEmail: ['', [Validators.required, Validators.email]],
      personalEmail: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      city: ['', Validators.required],
      age: ['', Validators.required],
      license_code: ['']
    });
    this.stepTwoForm = this.formBuilder.group({
      totalYearExperience: ['', Validators.required],
      internalAssessment: ['', Validators.required],
      externalAssessment: ['', Validators.required],
      teamLeaderName: ['', Validators.required],
      externalYearAwards: ['', Validators.required],
      assessmentLanguage: ['', Validators.required],
      professionalCertifications: ['', Validators.required],
      assessmentExperience: ['', Validators.required],
      externalAwardsAssessment: ['', Validators.required],
      awardAssessorTraining: ['', Validators.required],
      awardAssessmen: ['', Validators.required],
      writtingLanguage: ['', Validators.required],
    });
    this.stepThreeForm = this.formBuilder.group({
      employmentStatus: ['', Validators.required],
      currentEmployeer: ['', Validators.required],
      jobTitle: ['', Validators.required],
      industry: ['', Validators.required],
      linkedlnProfileLink: ['', Validators.required],
      businessTravelPlan: ['', Validators.required],
      participateAward: ['', Validators.required],
    });
    this.callingCodes();
    this.assesordetails();

    this.settings1 = {
      singleSelection: false,
      text: "Select Hands-on Experience",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settings2 = {
      singleSelection: false,
      text: "Select Areas of Expertise",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settings3 = {
      singleSelection: false,
      text: "Select External Awards Participated",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }

  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }
  
  assesordetails() {
    this.lazyLoad = true;
    let data = {
      user_token: this.user_token,
    }
    this.authService.assessorDetails(data).subscribe((response: any) => {
      this.lazyLoad = false;
      this.imageResolutionText = response.assessor_image_text;
      this.awardList = response.award_list;
      this.namePrefixsList = response.NamePrefixs;
      this.genderList = response.genders;
      this.qualificationsList = response.Qualifications;
      this.allCountryList = response.all_country;
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    });
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      // console.log(res);
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
      // console.log(this.countryCallingCodes);
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  // get city list
  cityList(NationalityId) {
    let data = {
      nationalities_id: NationalityId
    }
    console.log(data)
    this.authService.getCityList(data).subscribe((response: any) => {
      console.log('response', response);
      this.cityListArray = response.data;
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.stepOneForm.controls; }
  // get f1() { return this.stepOneForm.controls; }

  get f2() { return this.stepTwoForm.controls; }

  get f3() { return this.stepThreeForm.controls; }

  expertiseValue($event, expertiseItem) {
    if ($event.srcElement.checked == true) {
      this.Areas_of_Expertise.push(Number(expertiseItem.areasof_expertises_id));
      if (this.Areas_of_Expertise.length > 0) {
        this.expertiseErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.Areas_of_Expertise.splice(this.Areas_of_Expertise.indexOf($event.srcElement.value * 1), 1);
      if (this.Areas_of_Expertise.length == 0) {
        this.expertiseErr = 'Areas of Expertise is required';
      }
    }
  }

  awardsParticipatedValue($event, participatesItem) {
    if ($event.srcElement.checked == true) {
      this.external_awards_participateds.push(Number(participatesItem.external_awards_participateds_id));
      if (this.external_awards_participateds.length > 0) {
        this.ExternalAwardsParticipatedErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.external_awards_participateds.splice(this.external_awards_participateds.indexOf($event.srcElement.value * 1), 1);
      if (this.external_awards_participateds.length == 0) {
        this.ExternalAwardsParticipatedErr = 'External Awards Participated is required';
      }
    }
  }

  handsExperienceValue($event, handsonExperiencesItem) {
    if ($event.srcElement.checked == true) {
      this.Innovation_Hands_on_Experience.push(Number(handsonExperiencesItem.innovation_handson_experiences_id));
      if (this.Innovation_Hands_on_Experience.length > 0) {
        this.InnovationExperienceErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.Innovation_Hands_on_Experience.splice(this.Innovation_Hands_on_Experience.indexOf($event.srcElement.value * 1), 1);
      if (this.Innovation_Hands_on_Experience.length == 0) {
        this.InnovationExperienceErr = 'Innovation Hands-on Experience is required';
      }
    }
  }

  onItemSelect1(item: any) {
    // console.log(this.InnovationHandsonExperiencesSelectedItem);
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.InnovationExperienceErr = 'Innovation Hands-on Experience is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.InnovationExperienceErr = '';
    }
  }
  OnItemDeSelect1(item: any) {
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.InnovationExperienceErr = 'Innovation Hands-on Experience is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.InnovationExperienceErr = '';
    }
  }
  onSelectAll1(items: any) {
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.InnovationExperienceErr = 'Innovation Hands-on Experience is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.InnovationExperienceErr = '';
    }
  }
  onDeSelectAll1(items: any) {
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.InnovationExperienceErr = 'Innovation Hands-on Experience is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.InnovationExperienceErr = '';
    }
  }
  onItemSelect2(item: any) {
    // console.log(this.AreasofExpertisesSelectedItem);
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.expertiseErr = 'Areas of Expertises is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.expertiseErr = '';
    }
  }
  OnItemDeSelect2(item: any) {
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.expertiseErr = 'Areas of Expertise is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.expertiseErr = '';
    }
  }
  onSelectAll2(items: any) {
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.expertiseErr = 'Areas of Expertise is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.expertiseErr = '';
    }
  }
  onDeSelectAll2(items: any) {
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.expertiseErr = 'Areas of Expertise is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.expertiseErr = '';
    }
  }
  onItemSelect3(item: any) {
    // console.log(this.ExternalAwardsParticipatesSelectedItem);
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.ExternalAwardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.ExternalAwardsParticipatedErr = '';
    }
  }
  OnItemDeSelect3(item: any) {
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.ExternalAwardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.ExternalAwardsParticipatedErr = '';
    }
  }
  onSelectAll3(items: any) {
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.ExternalAwardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.ExternalAwardsParticipatedErr = '';
    }
  }
  onDeSelectAll3(items: any) {
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.ExternalAwardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.ExternalAwardsParticipatedErr = '';
    }
  }

  onChangeMobileCode(event) {
    if (event == '') {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.mobileCountryCodeeErr = '';
    }
  }

  onChangeWhatappCode(event) {
    if (event == '') {
      this.WhatsAppCountryErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.WhatsAppCountryErr = '';
    }
  }
  onChangePrefix(event) {
    if (event == '') {
      this.PrefixNameErr = 'Assessor Prefix Name is required';
    }
    if (event != '') {
      this.PrefixNameErr = '';
    }
  }

  uniquePersonalEmailCheck() {
    if (this.stepOneForm.value.personalEmail != '') {
      let data = {
        email: this.stepOneForm.value.personalEmail
      }
      this.emailProsessingtext = true;
      this.authService.uniqueEmail(data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.uniquePersonalEmailShow = true;
          this.emailProsessingtext = false;
          this.uniquePersonalEmailVerified = false;
          this.uniquePersonalEmailErr = '';
        }
        else if (res.has_error == 1) {
          this.uniquePersonalEmailShow = false;
          this.emailProsessingtext = false;
          window.scroll({
            top: 800,
            left: 0,
            behavior: 'smooth'
          });
          this.uniquePersonalEmailVerified = true;
          this.uniquePersonalEmailErr = 'Personal Email is already registered';
        }
      }, err => {
        this.uniquePersonalEmailErr = 'Something went wrong.';
        this.uniquePersonalEmailShow = false;
        this.emailProsessingtext = false;
        this.uniquePersonalEmailVerified = false;
      })
    }
  }
  onKey(event: any) { // without type info
    if (event.target.value == '') {
      this.uniquePersonalEmailVerified = false;
    }
  }
  formOneSubmit() {
    this.submitted1 = true;
    if ((this.stepOneForm.value.workEmail == '') || (this.stepOneForm.value.personalEmail == '')
      || (this.stepOneForm.value.city == '') || (this.stepOneForm.value.age == '')) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.nationality == '') || (this.stepOneForm.value.WhatsApp_mobile_no == '')) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.WhatsApp_Country_code == '') && (this.stepOneForm.value.WhatsApp_mobile_no != '')) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    if ((this.stepOneForm.value.graduatedIn == '') || (this.stepOneForm.value.mobile_no == '')) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.Country_code == '') && (this.stepOneForm.value.mobile_no != '')) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if ((this.stepOneForm.value.gender == '') || (this.stepOneForm.value.qualification == '')) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.namePrefix == null) && (this.stepOneForm.value.firstName != '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
      this.PrefixNameErr = 'Assessor Prefix Name is required';
    }
    if ((this.stepOneForm.value.firstName == '') || (this.stepOneForm.value.lastName == '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.allAwardSelected.length == 0) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
      this.awardErr = 'Award is required';
      this.showAwardborder = true;
    }
    // if (this.NationalityId.length == 0) {
    //   window.scroll({
    //     top: 400,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    //   this.NationalityErr = 'Award Country is required';
    //   this.showCountryborder = true;
    // }
    // stop here if form is invalid
    if (this.stepOneForm.invalid) {
      return;
    }
    // if ((this.allAwardSelected.length == 0) && (this.NationalityId.length == 0)) {
    //   window.scroll({
    //     top: 400,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }
    // if ((this.allAwardSelected.length > 0) && (this.NationalityId.length > 0)) {
    if (this.allAwardSelected.length > 0) {
      if (this.stepOneForm.value.personalEmail != '') {
        this.uniquePersonalEmailCheck();
      }
      setTimeout(() => {
        if (this.uniquePersonalEmailShow == true) {
          this.formOneShow = false;
          this.formTwoShow = true;
          this.formThreeShow = false;
          window.scroll({
            top: 200,
            left: 0,
            behavior: 'smooth'
          });
        }
      }, 1500);
    }
  }

  formTwoSubmit() {
    this.submitted2 = true;
    if ((this.stepTwoForm.value.awardAssessorTraining == '') || (this.stepTwoForm.value.awardAssessmen == '')
      || (this.stepTwoForm.value.assessmentLanguage == '') || (this.stepTwoForm.value.writtingLanguage == '')) {
      window.scroll({
        top: 1400,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      window.scroll({
        top: 1100,
        left: 0,
        behavior: 'smooth'
      });
      this.ExternalAwardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      window.scroll({
        top: 1100,
        left: 0,
        behavior: 'smooth'
      });
      this.InnovationExperienceErr = 'Innovation Hands-on Experience is required';
    }
    if ((this.stepTwoForm.value.externalAwardsAssessment == '') || (this.stepTwoForm.value.externalYearAwards == '')) {
      window.scroll({
        top: 900,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepTwoForm.value.assessmentExperience == '') || (this.stepTwoForm.value.internalAssessment == '')
      || (this.stepTwoForm.value.externalAssessment == '') || (this.stepTwoForm.value.teamLeaderName == '')) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
      this.expertiseErr = 'Areas of Expertise is required';
    }
    if ((this.stepTwoForm.value.totalYearExperience == '') || (this.stepTwoForm.value.professionalCertifications == '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    // stop here if form is invalid
    if (this.stepTwoForm.invalid) {
      return;
    }
    if ((this.AreasofExpertisesSelectedItem.length > 0) && (this.InnovationHandsonExperiencesSelectedItem.length > 0) && (this.ExternalAwardsParticipatesSelectedItem.length > 0)) {
      this.formOneShow = false;
      this.formTwoShow = false;
      this.formThreeShow = true;
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  formthreeSubmit() {
    this.submitted3 = true;

    this.external_awards_participateds = [];
    this.Innovation_Hands_on_Experience = [];
    this.Areas_of_Expertise = [];
  
    this.ExternalAwardsParticipatesSelectedItem.map((item1) => {
      this.external_awards_participateds.push(item1.external_awards_participateds_id);
    });
    this.InnovationHandsonExperiencesSelectedItem.map((item2) => {
      this.Innovation_Hands_on_Experience.push(item2.innovation_handson_experiences_id);
    });
    this.AreasofExpertisesSelectedItem.map((item3) => {
      this.Areas_of_Expertise.push(item3.areasof_expertises_id);
    });

    console.log('external_awards_participateds',this.external_awards_participateds);
    console.log('Innovation_Hands_on_Experience',this.Innovation_Hands_on_Experience);
    console.log('Areas_of_Expertise',this.Areas_of_Expertise);
    if ((this.stepThreeForm.value.businessTravelPlan == '') || (this.stepThreeForm.value.participateAward == '')) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.Profile_pic_Show == null) {
      window.scroll({
        top: 700,
        left: 0,
        behavior: 'smooth'
      });
      this.ProfessionalPicErr = 'Professional Photo is required';
    }
    else {
      this.ProfessionalPicErr = '';
    }
    if (this.Profile_Cv_Show == null) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.ProfessionalProfileErr = 'Professional Profile/ CV is required';
    }
    else {
      this.ProfessionalProfileErr = '';
    }
    if ((this.stepThreeForm.value.linkedlnProfileLink == '')) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepThreeForm.value.employmentStatus == '') || (this.stepThreeForm.value.currentEmployeer == '')
      || (this.stepThreeForm.value.jobTitle == '') || (this.stepThreeForm.value.industry == '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    // stop here if form is invalid
    if (this.stepThreeForm.invalid) {
      return;
    }
    if ((this.Profile_pic_Show == null) && (this.Profile_Cv_Show == null)) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.Profile_pic_Show != null) && (this.Profile_Cv_Show != null)) {

      this.stepOneForm.value.namePrefix = Number(this.stepOneForm.value.namePrefix);
      this.stepOneForm.value.gender = Number(this.stepOneForm.value.gender);
      this.stepOneForm.value.qualification = Number(this.stepOneForm.value.qualification);
      this.stepOneForm.value.Country_code = parseInt(this.stepOneForm.value.Country_code);
      this.stepOneForm.value.WhatsApp_Country_code = parseInt(this.stepOneForm.value.WhatsApp_Country_code);
      this.stepOneForm.value.nationality = Number(this.stepOneForm.value.nationality);
      this.stepOneForm.value.country = Number(this.stepOneForm.value.country);
      this.stepOneForm.value.age = Number(this.stepOneForm.value.age);

      this.stepTwoForm.value.totalYearExperience = Number(this.stepTwoForm.value.totalYearExperience);
      this.stepTwoForm.value.assessmentExperience = Number(this.stepTwoForm.value.assessmentExperience);
      this.stepTwoForm.value.internalAssessment = Number(this.stepTwoForm.value.internalAssessment);
      this.stepTwoForm.value.externalAssessment = Number(this.stepTwoForm.value.externalAssessment);
      this.stepTwoForm.value.externalAwardsAssessment = Number(this.stepTwoForm.value.externalAwardsAssessment);
      this.stepTwoForm.value.awardAssessorTraining = Number(this.stepTwoForm.value.awardAssessorTraining);
      this.stepTwoForm.value.awardAssessmen = Number(this.stepTwoForm.value.awardAssessmen);
      this.stepTwoForm.value.assessmentLanguage = Number(this.stepTwoForm.value.assessmentLanguage);
      this.stepTwoForm.value.writtingLanguage = Number(this.stepTwoForm.value.writtingLanguage);

      this.stepThreeForm.value.employmentStatus = Number(this.stepThreeForm.value.employmentStatus);
      this.stepThreeForm.value.industry = Number(this.stepThreeForm.value.industry);
      this.stepThreeForm.value.participateAward = Number(this.stepThreeForm.value.participateAward);
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('award_id', this.newAwardIdArray),
        formData.append('NamePrefix_id', this.stepOneForm.value.namePrefix),
        formData.append('first_name', this.stepOneForm.value.firstName),
        formData.append('last_name', this.stepOneForm.value.lastName),
        formData.append('Gender_id', this.stepOneForm.value.gender),
        formData.append('qualifications_id', this.stepOneForm.value.qualification),
        formData.append('Graduated_In', this.stepOneForm.value.graduatedIn),
        formData.append('Country_code', this.stepOneForm.value.Country_code),
        formData.append('mobile_no', this.stepOneForm.value.mobile_no),
        formData.append('WhatsApp_Country_code', this.stepOneForm.value.WhatsApp_Country_code),
        formData.append('WhatsApp_mobile_no', this.stepOneForm.value.WhatsApp_mobile_no),
        formData.append('Work_E_mail', this.stepOneForm.value.workEmail),
        formData.append('email', this.stepOneForm.value.personalEmail),
        formData.append('nationalities_id', this.stepOneForm.value.nationality),
        formData.append('country_id', this.stepOneForm.value.country),
        formData.append('cityof_residences_id', this.stepOneForm.value.city),
        formData.append('age_groups_id', this.stepOneForm.value.age),

        formData.append('yearsof_work_experiences_id', this.stepTwoForm.value.totalYearExperience),
        formData.append('Professional_Certifications', this.stepTwoForm.value.professionalCertifications),
        formData.append('Areas_of_Expertise', this.Areas_of_Expertise),
        formData.append('professional_assessment_experiences_id', this.stepTwoForm.value.assessmentExperience),
        formData.append('AssessmentComplete_id', this.stepTwoForm.value.internalAssessment),
        formData.append('External_AssessmentComplete_id', this.stepTwoForm.value.externalAssessment),
        formData.append('external_awards_participateds', this.external_awards_participateds),
        formData.append('Team_Leader_name', this.stepTwoForm.value.teamLeaderName),
        formData.append('external_awards_Assessment_id', this.stepTwoForm.value.externalAwardsAssessment),
        formData.append('Confirmation_Award_Assessor_Training', this.stepTwoForm.value.awardAssessorTraining),
        formData.append('Confirmation_Award_2017_Assessment', this.stepTwoForm.value.awardAssessmen),
        formData.append('Innovation_Hands_on_Experience', this.Innovation_Hands_on_Experience),
        formData.append('professional_language_id', this.stepTwoForm.value.assessmentLanguage),
        formData.append('Report_Writing_professional_language_id', this.stepTwoForm.value.writtingLanguage),
        formData.append('external_award_assessment_team_leader_year_id', this.stepTwoForm.value.externalYearAwards),
        
        formData.append('employment_statuses_id', this.stepThreeForm.value.employmentStatus),
        formData.append('Current_Employer', this.stepThreeForm.value.currentEmployeer),
        formData.append('Job_Title', this.stepThreeForm.value.jobTitle),
        formData.append('industries_id', this.stepThreeForm.value.industry),
        formData.append('LinkedIn_Profile_Link', this.stepThreeForm.value.linkedlnProfileLink),
        formData.append('Business_Travel_Leave', this.stepThreeForm.value.businessTravelPlan),
        formData.append('your_company_interested_participate_the_UAE_Innovation_Award', this.stepThreeForm.value.participateAward),
        formData.append('Profile_CV', this.Profile_Cv_Show),
        formData.append('Profile_pic', this.Profile_pic_Show),
        formData.append('license_code', this.stepOneForm.value.license_code)
        // console.log(this.stepOneForm.value);

      if (this.user_token != null) {
        if (this.webSitePatternErr == false) {
          this.processingText = true;
          this.authService.assessorRegister(formData).subscribe((res: any) => {
            console.log(res);
            if (res.has_error == 0) {
              this.processingText = false;
              this.messagesService.showSuccess('Registration is successfully done.Please check your email for an account activation link.');
              setTimeout(() => {
                this.messagesService.clear();
              }, 1500);
              this.router.navigate(['/thank-you']);
            }
            else if (res.has_error == 1) {
              this.processingText = false;
              window.scroll({
                top: 400,
                left: 0,
                behavior: 'smooth'
              });
              for (let [key, value] of Object.entries(res.error)) {
                console.log(`${key}: ${value}`);
                this.errorMessageValue = value;
              }
              this.messagesService.showError(this.errorMessageValue);
              setTimeout(() => {
                this.messagesService.clear();
              }, 1500);
            }
          },
            error => {
              this.processingText = false;
              window.scroll({
                top: 400,
                left: 0,
                behavior: 'smooth'
              });
              this.messagesService.showError('Something went wrong.');
              setTimeout(() => {
                this.messagesService.clear();
              }, 1500);
            })
        }
        else if (this.webSitePatternErr == true) {
          window.scroll({
            top: 500,
            left: 0,
            behavior: 'smooth'
          });
        }
      }
      else if (this.user_token == null) {
        this.messagesService.showError('User token is missing.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1500);
      }
    }
  }

  onSelectFileProfilepic(event) { // called each time file input changes
    // window.URL = window.URL || window.webkitURL;
    if (event.target.files && event.target.files[0]) {
      // Array.from(event.target.files)[0];    
      this.Profile_pic_Show = event.target.files[0];
      console.log(this.Profile_pic_Show)
      if (this.Profile_pic_Show != '') {
        this.ProfessionalPicErr = '';
      }
      // const img = new Image();
      // img.src = window.URL.createObjectURL(event.target.files[0]);

      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        // this.Profile_pic = event.target.result;
        // const width = img.naturalWidth;
        // const height = img.naturalHeight;
        // console.log('width', width);
        // console.log('height', height);
        // if (width !== 225 && height !== 295) {
        //   this.messagesService.showError('Profile Picture resolution should be 295 X 225');
        // }
      }
    }
  }

  onSelectFileProfileCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.Profile_Cv_Show = event.target.files[0];
      console.log(this.Profile_CV);
      if (this.Profile_CV != '') {
        this.ProfessionalProfileErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {

      }
    }
  }
  checkAward(item) {
    this.loading = true;
    this.submitted1 = false;

    this.Nationalities = [];
    this.cityListArray = [];
    this.genderList = [];
    this.namePrefixsList = [];
    this.ageGroupsList = [];
    this.yearsofWorkExperiencesList = [];
    this.AssessmentCompletesList = [];
    this.assessmentExperiencesList = [];
    this.ConfirmationsList = [];
    this.employmentStatusesList = [];
    this.industriesList = [];
    this.qualificationsList = [];
    this.professionalLanguagesList = [];
    this.areasofExpertiseList = [];
    this.awardsParticipatesList = [];
    this.awardAssessmentTeamLeaderYearsList = [];
    this.innovationHandsonExperiencesList = [];

    this.stepOneForm.get('namePrefix').reset();
    this.stepOneForm.get('gender').reset();
    this.stepOneForm.get('qualification').reset();
    this.stepOneForm.get('nationality').reset();
    this.stepOneForm.get('city').reset();
    this.stepOneForm.get('age').reset();

    if (!this.allAwardSelected.some((allAwardSelecteditem) => allAwardSelecteditem.year_id == item.year_id)) {
      this.awardId.push(item.year_id);
      this.allAwardSelected.push(item);
      this.awardIdList.push(item.id);
      this.NationalityId.push(item.country_id);
      if (this.allAwardSelected.length > 0) {
        this.newAwardIdArray = this.allAwardSelected.map(item => {
          return item.year_id;
        });
      }
      this.awardIdList = this.awardIdList.filter((item, i, ar) => ar.indexOf(item) === i);
      this.NationalityId = this.NationalityId.filter((item, i, ar) => ar.indexOf(item) === i);
      this.awardAssessorDetails(this.awardIdList);
      if (this.allAwardSelected.length > 0) {
        this.awardErr = '';
        this.showAwardborder = false;
      }
    }
    else {
      const index: number = this.allAwardSelected.indexOf(item);
      if (index !== -1) {
        this.allAwardSelected.splice(index, 1);
        this.newAwardIdArray.splice(index, 1);
        this.awardId.splice(index, 1);
        if (document.querySelectorAll('.dev_test li[data-list-id = "' + item.id + '"] input[type = "checkbox"]:checked').length == 0) {
          const index1: number = this.awardIdList.indexOf(item.id);
          this.awardIdList.splice(index1, 1);
          this.NationalityId.splice(index1, 1);
        }
        if (this.allAwardSelected.length > 0) {
          this.awardAssessorDetails(this.awardIdList);
        }
        if (this.allAwardSelected.length == 0) {
          let data = {
            user_token: this.user_token,
          }
          this.authService.assessorDetails(data).subscribe((response: any) => {
            this.lazyLoad = false;
            this.imageResolutionText = response.assessor_image_text;
            this.awardList = response.award_list;
            this.namePrefixsList = response.NamePrefixs;
            this.genderList = response.genders;
            this.qualificationsList = response.Qualifications;
            this.allCountryList = response.all_country;
            this.loading = false;
          }, err => {
            this.loading = false;
          });

          this.allAwardCountrySelectedString = "";
          this.allAwardCountrySelected = [];
          this.awardErr = 'Award is required';
          this.showAwardborder = true;
        }
      }
    }
    this.allAwardSelectedString = "";
    if (this.allAwardSelected.length > 3) {
      this.allAwardSelectedString = this.allAwardSelected.length + " items selected";

    }
    else {
      for (let itemlist of this.allAwardSelected) {
        this.allAwardSelectedString += itemlist.title + ',';

      }
    }
    console.log('allAwardSelected',this.allAwardSelected)
  }

  awardAssessorDetails(newAwardIdArray) {
    let data = {
      user_token: this.user_token,
      award_id: newAwardIdArray
    }
    this.authService.assessorDetails(data).subscribe((response: any) => {
      this.Nationalities = response.Nationalities;
      this.genderList = response.genders;
      this.namePrefixsList = response.NamePrefixs;
      this.ageGroupsList = response.AgeGroups;
      this.yearsofWorkExperiencesList = response.YearsofWorkExperiences;
      this.AssessmentCompletesList = response.AssessmentCompletes;
      this.assessmentExperiencesList = response.ProfessionalAssessmentExperiences;
      this.ConfirmationsList = response.Confirmations;
      this.employmentStatusesList = response.EmploymentStatuses;
      this.industriesList = response.Industries;
      this.qualificationsList = response.Qualifications;
      this.professionalLanguagesList = response.ProfessionalUseLanguages;
      this.areasofExpertiseList = response.AreasofExpertises;
      this.awardsParticipatesList = response.ExternalAwardsParticipates;
      this.awardAssessmentTeamLeaderYearsList = response.ExternalAwardAssessmentTeamLeader_years;
      this.innovationHandsonExperiencesList = response.InnovationHandsonExperiences;
      this.allCountryList = response.all_country;

      this.awardsParticipatesList.map((item) => {
        item['itemName'] = item.trans_data;
      });
      this.innovationHandsonExperiencesList.map((item) => {
        item['itemName'] = item.trans_data;
      });
      this.areasofExpertiseList.map((item) => {
        item['itemName'] = item.trans_data;
      });
      this.allAwardCountrySelected = this.allCountryList.filter(o => this.NationalityId.find(o2 => o.nationalities_id === o2)).reverse();
      this.allAwardCountrySelectedString = "";
      if (this.allAwardCountrySelected.length > 3) {
        this.allAwardCountrySelectedString = this.allAwardCountrySelected.length + " items selected";

      }
      else {
        for (let itemlist of this.allAwardCountrySelected) {
          this.allAwardCountrySelectedString += itemlist.trans_data + ',';

        }
      }
      this.loading = false;
      console.log('awardId',this.awardId);
      console.log('newAwardIdArray',this.newAwardIdArray);
    }, err => {
      this.loading = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    });
  }
  checkAwardCountry(item) {
    if (!this.allAwardCountrySelected.some((allAwardCountrySelecteditem) => allAwardCountrySelecteditem.id == item.id)) {
      // this.NationalityId.push(item.id);
      this.submitted1 = false;
      this.cityListArray = [];
      this.stepOneForm.get('city').reset();
      // this.cityList(this.NationalityId);
      this.allAwardCountrySelected.push(item);
      // if (this.NationalityId.length > 0) {
      //   this.NationalityErr = '';
      //   this.showCountryborder = false;
      // }
    }
    else {
      const index: number = this.allAwardCountrySelected.indexOf(item);
      if (index !== -1) {
        this.allAwardCountrySelected.splice(index, 1);
        // this.NationalityId.splice(index, 1);
        this.submitted1 = false;
        this.cityListArray = [];
        this.stepOneForm.get('city').reset();
        // this.cityList(this.NationalityId);
        // if (this.NationalityId.length == 0) {
        //   this.NationalityErr = 'Award Country is required';
        //   this.showCountryborder = true;
        // }
      }
    }
    this.allAwardCountrySelectedString = "";
    if (this.allAwardCountrySelected.length > 3) {
      this.allAwardCountrySelectedString = this.allAwardCountrySelected.length + " items selected";

    }
    else {
      for (let itemlist of this.allAwardCountrySelected) {
        this.allAwardCountrySelectedString += itemlist.trans_data + ',';

      }
    }
  }
  openClosePopup() {
    this.showOpenAward = !this.showOpenAward;
  }

  openClosePopupCountry() {
    this.showOpenCountry = !this.showOpenCountry;
    console.log(this.showOpenCountry)
  }

  onClickedOutside(e: Event) {
    this.showOpenCountry = false;
  }

  onClickedOutsideAward(e: Event) {
    this.showOpenAward = false;
  }
  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  onChangeCountry($event) {
    // this.cityListArray = [];
    // this.stepOneForm.get('city').reset();
    // this.cityList($event);
  }
}

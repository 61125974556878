import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwardStatisticsComponent } from './award-statistics.component';
import { RouterModule, Routes } from '@angular/router';
import { MessagesService } from '../../services/messages.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
// intro routing
export const routes: Routes = [
  { path: '', component: AwardStatisticsComponent },
];


@NgModule({
  declarations: [AwardStatisticsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    ChartsModule
  ],providers:[MessagesService]
})
export class AwardStatisticsModule { }

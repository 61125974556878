import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  activeLineId: any;
  userDetails: any;
  IsUserLogin: boolean = false;
  showmenu: boolean = false;
  profilePicturePath: any;
  currenPageUrl: any;
  constructor(private router: Router,
    private dataService: DataService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        this.currenPageUrl = val.url;
        if (val.url == '/login' || val.url == '/' || val.url === '/forgot-password'
          || val.url === '/award-list' || val.url === '/thank-you' || val.url === '/sponsors-list'
          || val.url === '/media-gallery' || val.url === '/our-jury' || val.url === '/assessor-appliaction' || val.url === '/our-assessors' || val.url === '/faq' || val.url === '/assessor-appliaction' || val.url.match('/award-details') || val.url.match('/assessor-register') || val.url.match('/company-register') || val.url.match('/view-all-media')
          || val.url.match('/active-account') || val.url.match('/reset-password') || val.url.match('/jury-appliaction') || val.url.match('/award-framework') || val.url.match('/testimonials') || (val.url.match('/contact-us')) || (val.url.match('/award-winner')) || (val.url.match('/terms-and-condition')) || (val.url.match('/privacy-policy')) || (val.url.match('/cookies-policy'))) {
          this.IsUserLogin = false;
        }
        else {
          this.IsUserLogin = true;
          this.profilePicturePath = appConfig.company_logo_path;
          this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        }
      }
    });
  }

  ngOnInit() {
    this.dataService.userDetailsData.subscribe((res: any) => {
      console.log(res);
      if ((res != undefined) && (res != '')) {
        if (this.userDetails.user_type == '5') {
          this.userDetails.name = res.user_details.company_name;
          this.userDetails.company.company_logo = res.user_details.company_logo;
        }
       else if ((this.userDetails.user_type == '6') || (this.userDetails.user_type == '7')) {
          this.userDetails.name = res.user_details.user.name;
          this.userDetails.profile_picture = res.user_details.user.profile_picture;
        }
        else if (this.userDetails.user_type == '2') {
          this.userDetails.name = res.user_details.name;
          this.userDetails.profile_picture = res.user_details.profile_picture;
        }
        this.profilePicturePath = res.imagePath;
      }

    })
  }
  openMenu() {

    this.showmenu = true;
    console.log(this.showmenu);
  }
  closeMenu() {
    this.showmenu = false;
  }
  showWebMenu(id, menuId) {
    this.activeMenu(menuId);
    document.getElementById(id).classList.toggle('show');
  }
  outsideCloseMenu(id) {
    document.getElementById(id).classList.remove('show');
    document.getElementById('main-menu').classList.remove('open');
    document.getElementById('menu-overlay').classList.remove('open');
  }
  activeMenu(id) {
    let parentEl = document.getElementById(id).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('a').classList.remove('active');
    });
    document.getElementById(id).className += ' active';
  }
  activeLine(id) {
    this.activeLineId = id;
    console.log(this.activeLineId);
    let parentEl = document.getElementById(id).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('a').classList.remove('active');
    });
    document.getElementById(id).className += ' active';

    document.getElementById('main-menu').classList.remove('open');
    document.getElementById('menu-overlay').classList.remove('open');
  }
  removeActiveLine() {
    console.log(this.activeLine);
    this.router.navigate(['/']);
    if (this.activeLineId) {
      let parentEl = document.getElementById(this.activeLineId).parentNode.parentNode;
      parentEl.querySelectorAll('li').forEach((item) => {
        item.querySelector('a').classList.remove('active');
      });
    }
  }
  onClickedOutsideMenu(e: Event, id) {
    document.getElementById(id).classList.remove('show');
    document.getElementById('main-menu').classList.remove('open');
    document.getElementById('menu-overlay').classList.remove('open');
  }
  goToLogin() {
    this.router.navigate(['/login']);
    console.log(this.activeLine);
    if (this.activeLineId) {
      let parentEl = document.getElementById(this.activeLineId).parentNode.parentNode;
      parentEl.querySelectorAll('li').forEach((item) => {
        item.querySelector('a').classList.remove('active');
      });
    }
  }
  positionScroll(dropDownName) {
    if (this.currenPageUrl == '/') {
      this.dataService.dropdownScroll(dropDownName);
    }
    if ((this.currenPageUrl == '/login') || (this.currenPageUrl === '/forgot-password') || (this.currenPageUrl === '/award-list') || (this.currenPageUrl === '/thank-you') || (this.currenPageUrl === '/sponsors-list') || (this.currenPageUrl === '/media-gallery') || (this.currenPageUrl === '/our-juries') || (this.currenPageUrl === '/assessor-appliaction') || (this.currenPageUrl === '/our-assessor') || (this.currenPageUrl === '/faq') || (this.currenPageUrl === '/jury-appliaction') || (this.currenPageUrl === '/award-framework') || (this.currenPageUrl === '/testimonials') || (this.currenPageUrl.match('/award-details')) || (this.currenPageUrl.match('/assessor-register')) || (this.currenPageUrl.match('/company-register')) || (this.currenPageUrl.match('/view-all-media')) || (this.currenPageUrl.match('/reset-password')) || (this.currenPageUrl.match('/contact-us')) || (this.currenPageUrl.match('/award-winner'))) {
      this.router.navigate(['/']);
      this.dataService.dropdownScroll(dropDownName);
    }
  }
}

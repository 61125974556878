import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  user_token: any;
  sponserListArray: any = [];
  sponsorImagePath: any;
  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 3
      },
      1025: {
        items: 6
      }
    },
    nav: false
  }
  constructor(private authService: AuthService,
    private messagesService: MessagesService) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.SponsorsList();
  }
  // all sponsors list
  SponsorsList() {
    this.authService.sponsorsList(this.user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.sponserListArray = res.sponserList;
        this.sponsorImagePath = res.image_path;
      }
    },
      error => {
        console.log('error', error);
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      })
  }
  
  // open website url
  windowOpen(url) {
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    return window.open(url);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WinnersComponent } from './winners.component';
import { WinnerService } from 'src/app/services/winner.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [WinnersComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgbModule
  ],
  providers: [
    WinnerService
  ],
  exports: [WinnersComponent]
})
export class WinnersModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignmentPointComponent } from './assignment-point.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// intro routing
export const routes: Routes = [
  { path: '', component: AssignmentPointComponent },
];

@NgModule({
  declarations: [AssignmentPointComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgScrollbarModule,
    CKEditorModule
  ]
})
export class AssignmentPointModule { }

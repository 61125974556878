import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WinnerService } from 'src/app/services/winner.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-judges',
  templateUrl: './judges.component.html',
  styleUrls: ['./judges.component.scss']
})
export class JudgesComponent implements OnInit {
  judgeimageIcon: any;
  judgeListArray: any = [];

  customOptions: OwlOptions = {
    loop: true,
    // items: 5,
    margin: 20,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    // navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
    responsive: {
      0: {
        items: 1
      },
      575: {
        items: 2
      },
      767: {
        items: 4
      },
      1280: {
        items: 4,
      },
      1366: {
        items: 4
      }
     
    },
    nav: true
  }
  user_token: any;
  constructor(private winnerService: WinnerService,
    private messagesService: MessagesService) { 
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.judgeList();
  }
  judgeList(){
    this.winnerService.judgeList(this.user_token).subscribe((res: any) => {
      this.judgeimageIcon = res.image_path;
      this.judgeListArray = res.judgeList;
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class PublicGuard implements CanActivate {
    constructor(
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (JSON.parse(localStorage.getItem('userDetails')) == null) {
            return true;
        }
        this.router.navigate(['/assesor-dashboard']);
        return false;
    }

}









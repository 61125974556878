import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AwardDetailsComponent } from './award-details.component';
import { MessagesService } from '../services/messages.service';

// intro routing
export const routes: Routes = [
  { path: '', component: AwardDetailsComponent },
];

@NgModule({
  declarations: [AwardDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  providers: [MessagesService]
})
export class AwardDetailsModule { }

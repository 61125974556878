import { Component, OnInit } from '@angular/core';
import { WinnerService } from '../services/winner.service';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { appConfig } from '../app.config';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  sectorListArray: any = [];
  sectorimageIcon: any;
  user_token: any;
  lazyLoad: boolean = false;
  sectorFinalListArray: any = [];
  bannerArray: any = [];
  bannerImagePath: any;
  customOptions: OwlOptions = {
    loop: true,
    // margin: 10,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayHoverPause: true,
    dots: true,
    navSpeed: 2000,
    dotsSpeed: 2000,
    autoplaySpeed: 2000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false
  }
  framaework_name: any;
  awardFrameworkDetails: any;
  award_ppt_path: any;


  constructor(
    private winnerService: WinnerService,
    private dataService: DataService,
    private authService: AuthService,
    private messagesService: MessagesService
  ) {
    this.framaework_name = localStorage.getItem('framaework_name');

  }

  ngOnInit() {
    this.getuserToken();
    // this.dataService.dropdownscroll.subscribe((res: any) => {
    //   console.log(res);
    //   if (res == 'Assessment Process') {
    //     setTimeout(() => {
    //       // var ele = document.querySelector('#assessment-process')
    //       // ele.scrollIntoView();
    //       window.scroll({
    //         top: 4600,
    //         left: 0,
    //         behavior: 'smooth'
    //       });
    //     }, 500);
    //   }
    //   if (res == 'Award Framework') {
    //     setTimeout(() => {
    //       // var ele = document.querySelector('#award-framework')
    //       // ele.scrollIntoView();
    //       window.scroll({
    //         top: 2600,
    //         left: 0,
    //         behavior: 'smooth'
    //       });
    //     }, 500);
    //   }
    //   if (res == 'Global Innovation Award') {

    //     setTimeout(() => {
    //       // var ele = document.querySelector('#global-inovation-award')
    //       // ele.scrollIntoView();
    //     window.scroll({
    //       top: 700,
    //       left: 0,
    //       behavior: 'smooth'
    //     });
    //   }, 500);
    //   }
    // });
  }

  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.lazyLoad = true;
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
        this.Sectorlist(this.user_token);
        this.bannerList(this.user_token);
        this.getAwardFramework(this.user_token);
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  scrollDown() {
    // window.scrollTo(0,document.body.scrollHeight);
    window.scroll({
      top: 800,
      left: 0,
      behavior: 'smooth'
    });
  }
  Sectorlist(user_token) {
    // this.lazyLoad = false;
    this.winnerService.sectorList(user_token).subscribe((response: any) => {
      if (response.has_error == 0) {
        // this.lazyLoad = false;
        this.sectorimageIcon = response.image_path;
        this.sectorListArray = response.sectorList;
        let sectorLength = Math.round(this.sectorListArray.length / 4);

        this.sectorFinalListArray = this.chunkArray(this.sectorListArray, sectorLength);

        // let sectorListThreelength = this.sectorFinalListArray[2].length - 5;
        // let sectorThreeColumnElement = this.sectorFinalListArray[2].splice(5,sectorListThreelength);
        // sectorThreeColumnElement.map((item)=>{
        // this.sectorFinalListArray[3].push(item)
        // });
      }
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
      // this.lazyLoad = false;
    });
  }
  chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  bannerList(user_token){
    this.authService.getBannerData(user_token).subscribe((response: any) => {
      if (response.has_error == 0) {
        this.lazyLoad = false;
        this.bannerImagePath = response.imagePath;
        this.bannerArray = response.banners;
        this.dataService.dropdownscroll.subscribe((res: any) => {
          console.log(res);
          if (res == 'Assessment Process') {
            setTimeout(() => {
              // var ele = document.querySelector('#assessment-process')
              // ele.scrollIntoView();
              window.scroll({
                top: 4600,
                left: 0,
                behavior: 'smooth'
              });
            }, 500);
          }
          if (res == 'Award Framework') {
            setTimeout(() => {
              // var ele = document.querySelector('#award-framework')
              // ele.scrollIntoView();
              window.scroll({
                top: 2600,
                left: 0,
                behavior: 'smooth'
              });
            }, 500);
          }
          if (res == 'Global Innovation Award') {
    
            setTimeout(() => {
              // var ele = document.querySelector('#global-inovation-award')
              // ele.scrollIntoView();
            window.scroll({
              top: 700,
              left: 0,
              behavior: 'smooth'
            });
          }, 500);
          }
        });
      }
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
      // this.lazyLoad = false;
    });
  }

  getAwardFramework(user_token) {
    this.authService.awardFramework(user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.awardFrameworkDetails = res.framework_data;
        this.award_ppt_path = res.file_path;
      }
    },
      err => {

      });
  }
}

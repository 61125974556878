import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { ActivatedRoute, Router } from "@angular/router";
import { appConfig } from '../app.config';
import { MustMatch } from 'src/_helpers/_guards/custom.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  user_token: any;
  resetPasswordForm: FormGroup;
  submitted = false;
  processingText: boolean = false;
  newPasswordShow: boolean;
  confirmPasswordShow: boolean;
  token: any;
  api_token: any;
  upperCaseErrMsg: any = '';
  NumberErrMsg: any = '';
  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private messagesService: MessagesService,
    private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.token = params.id;
    });
    this.newPasswordShow = false;
    this.confirmPasswordShow = false;
  }

  ngOnInit() {
    this.getuserToken();
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.resetPasswordForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      // confirm_password: ['', [Validators.required, Validators.minLength(8)]]
      confirm_password: ['', Validators.required]
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });
  }

  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  onKey(event: any) { // without type info
    let upperCaseCharacters = /[A-Z]+/;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/;
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (event.target.value != '') {
      this.upperCaseErrMsg = 'Password must contain at least one uppercase letters';
      this.NumberErrMsg = 'Password must contain at least one number';
      if ((upperCaseCharacters.test(event.target.value) === false)) {
        this.upperCaseErrMsg = 'Password must contain at least one uppercase letters';

      }
      if ((upperCaseCharacters.test(event.target.value) === true)) {
        this.upperCaseErrMsg = '';
      }
      if ((numberCharacters.test(event.target.value) === false)) {
        this.NumberErrMsg = 'Password must contain at least one number';
      }
      if ((numberCharacters.test(event.target.value) === true)) {
        this.NumberErrMsg = '';
      }
    }
    else if (event.target.value == '') {
      this.NumberErrMsg = '';
      this.upperCaseErrMsg = '';
    }
  }
  // click event function toggle
  newpassword() {
    this.newPasswordShow = !this.newPasswordShow;
  }

  // click event function toggle
  confirmPassword() {
    this.confirmPasswordShow = !this.confirmPasswordShow;
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }

  resetPasswordFormSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      api_token: this.api_token,
      token: this.token,
      new_password: this.resetPasswordForm.value.new_password,
      confirm_password: this.resetPasswordForm.value.confirm_password
    }
    if ((this.NumberErrMsg == '') && (this.upperCaseErrMsg == '')) {
      this.processingText = true;
      this.authService.resetPassword(data).subscribe((res: any) => {
        // console.log(res)
        if (res.has_error == 0) {
          this.processingText = false;
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
            this.router.navigate(['/login']);
            localStorage.removeItem('api_token');
          }, 2000);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          this.messagesService.showError(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }, err => {
        this.processingText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 2000);
      })
    }
    // else if (this.user_token == null) {
    //   this.messagesService.showError('User token is missing.');
    //   setTimeout(() => {
    //     this.messagesService.clear();
    //   }, 2000);
    // }
  }
}

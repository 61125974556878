import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '../services/auth.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appConfig } from '../app.config';
import { WinnerService } from '../services/winner.service';
import { MessagesService } from '../services/messages.service';

@Component({
  selector: 'app-sponsors-list',
  templateUrl: './sponsors-list.component.html',
  styleUrls: ['./sponsors-list.component.scss']
})
export class SponsorsListComponent implements OnInit {
  submitted = false;
  user_token: any;
  sponserListArray: any = [];
  closeResult: string;
  awardIdParam: any;
  sponsorsForm: FormGroup;
  copmany_logo_Show: any;
  companyLogoErr: any;
  awardTypeList: any = [];
  NationalitiesList: any = [];
  cityList: any = [];
  sponsorLogoPath: any;
  processingText: boolean = false;
  award_image_path: any;
  award_details: any;
  awardListArray: any = [];
  awardIconShow: boolean = false;
  generalSponsorCategoryList: any = [];
  awardimageIcon: any;
  sponserCategoryList: any = [];
  lazyLoad: boolean = false;
  sponsorRegistrationFormShow: boolean = false;
  awardTitle: any;
  sponser_logo_path: any;
  awardListShow: boolean = false;
  registerPpopupShow: boolean = false;
  categoryDescription: any;
  selectedIndex: any = -1;
  pageNumber: number = 1;
  totalNumber: number = 1;
  registrationSuccess: boolean = false;
  webSitePatternErr: boolean = false;
  sponser_description: any;
  noDataErr: any;
  errMsg: any;
  sponserCategoryListArray: any =[];
  allCountry: any =[];
  customOptions: OwlOptions = {
    loop: true,
    // items: 5,
    margin: 10,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: false,
    navSpeed: 700,
    nav: true,
    // navText: ['', ''],
    navText: ['<img src="assets/images/arrow4.png" />', '<img src="assets/images/arrow2.png" />'],
    responsive: {
      0: {
        items: 1
      },
      575: {
        items: 2
      },
      767: {
        items: 2
      },
      1000: {
        items: 5
      }
    },

  }
  customOptions1: OwlOptions = {
    loop: true,
    // items: 5,
    margin: 10,
    nav: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,
    navSpeed: 700,
    navText: ['', ''],
    //navText: ['<img src="assets/images/arrow4.png" />', '<img src="assets/images/arrow2.png" />'],
    responsive: {
      0: {
        items: 1
      },
      575: {
        items: 2
      },
      767: {
        items: 2
      },
      1000: {
        items: 5
      }
      // 0: {
      //   items: 1
      // },
      // 400: {
      //   items: 2
      // },
      // 740: {
      //   items: 3
      // },
      // 940: {
      //   items: 4
      // }
    },

  }
  constructor(private authService: AuthService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private winnerService: WinnerService,
    private messageService: MessagesService) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.getuserToken();
    this.SponsorsList();
    this.getGeneralCategorySponsers();
    this.sponserdescription();
    this.sponsorsForm = this.formBuilder.group({
      sponsorship_category: ['', Validators.required],
      company_name: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      job_title: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact_number: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      website: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }

  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messageService.showError('Something went wrong.');
        setTimeout(() => {
          this.messageService.clear();
        }, 800);
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.sponsorsForm.controls; }

  // all sponsors list
  SponsorsList() {
    this.lazyLoad = true;
    this.authService.sponsorsList(this.user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.lazyLoad = false;
        window.scrollTo(0, 0);
        this.sponserListArray = res.sponserList;
        this.sponsorLogoPath = res.image_path;
        this.sponser_logo_path = res.sponser_logo_path;
      }
    },
      error => {
        this.lazyLoad = false;
        this.messageService.showError('Something went wrong.');
        console.log('error', error);
      })
  }

  // general category sponsors
  getGeneralCategorySponsers() {
    this.authService.generalCategorySponsers(this.user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.generalSponsorCategoryList = res.sponserCategory;
        this.generalSponsorCategoryList.map((item)=>{
          this.customOptions1.autoplayTimeout = 3000 ;
          console.log(this.customOptions1.autoplayTimeout)
        })
      }
    },
      err => {
        this.messageService.showError('Something went wrong.');
      })
  }

  // read more
  openReadMore(index) {
    this.selectedIndex = index;
  }

  // award list display
  awardListDisplay() {
    this.awardlist();
    this.awardIconShow = true;
    window.scroll({
      top: 400,
      left: 0,
      behavior: 'smooth'
    });
  }

  // award list
  awardlist() {
    this.awardListShow = true;
    // let per_page = 6;
    // let page_no = this.pageNumber;
    this.authService.allAwardList(this.user_token).subscribe((response: any) => {
      if (response.has_error == 0) {
        this.awardListShow = false;
        this.awardListArray = response.award_data;
        this.awardimageIcon = response.image_path;
        this.totalNumber = response.total_no_of_award;
        this.award_image_path = response.award_image_path;
        // response.award_data.map((item) => {
        //   this.awardListArray.push(item);
        // })
      }
    }, error => {
      this.awardListShow = false
      this.messageService.showError('Something went wrong.');
      // console.error(error);
    });
  }

  // // load more awards
  // loadMoreAwards() {
  //   let per_page = 6;
  //   this.pageNumber++;
  //   let skip = this.pageNumber;
  //   this.winnerService.awardList(this.user_token, per_page, skip).subscribe((response: any) => {
  //     // console.log('response', response);
  //     if (response.has_error == 0) {
  //       this.awardimageIcon = response.image_path;
  //       response.award_data.map((item) => {
  //         this.awardListArray.push(item);
  //       })
  //     }
  //   }, error => {
  //     this.messageService.showError('Something went wrong.');
  //     // console.error(error);
  //   });
  // }

  // onScroll() {
  //   console.log('scrolled!!');
  //   this.loadMoreAwards();
  // }
  // back to sponsors section
  backToCategory() {
    this.awardIconShow = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  // get category of sponsers
  getcategorysponsersType() {
    this.authService.getCategorySponsersType(this.user_token, this.awardIdParam).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.registerPpopupShow = false;
        this.sponserCategoryList = res.sponserCategory.filter(x => x.category_group.length > 0);
        console.log(this.sponserCategoryList);
      }
    },
      err => {
        this.registerPpopupShow = false;
        this.messageService.showError('Something went wrong.');
      })
  }

  // sponsor registration popup open
  open(content, awrardItem) {
    this.registerPpopupShow = true;
    this.awardIdParam = awrardItem.id;
    this.awardTitle = awrardItem.title;
    this.sponsorRegistrationFormShow = false;
    this.getcategorysponsersType();
    this.submitted = false;
    this.webSitePatternErr = false;
    this.copmany_logo_Show = null;
    this.companyLogoErr = '';
    this.sponsorsForm.reset();
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'custom-modal animated slideInUp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.awardIconShow = false;
    });
  }

  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // sponsors invesment section display
  sponsorRegisterFormDisplay(sponserItem) {
    console.log(sponserItem)
    this.awardIdParam = sponserItem.award_id;
    this.SponsorDetails(sponserItem.award_id);
    this.sponsorRegistrationFormShow = true;
    this.registrationSuccess = false;
    this.webSitePatternErr = false;
    this.sponsorsForm.reset();
    this.copmany_logo_Show = null;
    this.companyLogoErr = '';
    var scrollModal = document.querySelector(".modal.show");
    scrollModal.scrollTop = 0;
    console.log(sponserItem.id)
    this.sponsorsForm.patchValue({
      sponsorship_category: sponserItem.id,
      country: '',
      city: ''
    });
  }

  // sponsors details
  SponsorDetails(awardIdParam) {
    this.registerPpopupShow = true;
    this.authService.sponsersDetails(this.user_token, awardIdParam).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.registerPpopupShow = false;
        this.awardTypeList = res.awardType;
        this.NationalitiesList = res.Nationalities;
        this.allCountry = res.all_country;
        this.sponserCategoryListArray = res.sponserCategory;
        console.log('sponserCategoryListArray',this.sponserCategoryListArray)
      }
    },
      err => {
        this.registerPpopupShow = false;
        this.messageService.showError('Something went wrong.');
      })
  }

  // back to sponsors invesment section
  backTosponsorCategory() {
    this.submitted = false;
    this.copmany_logo_Show == null;
    this.companyLogoErr = '';
    this.webSitePatternErr = false;
    this.sponsorRegistrationFormShow = false;
    var scrollModal = document.querySelector(".modal.show");
    scrollModal.scrollTop = 0;
  }

  // country change
  onChangeCountry(event) {
    // this.submitted = false;
    // this.sponsorsForm.get('city').reset();
    // this.cityList = [];
    // this.CityList(event);
  }

  // city list
  CityList(nationalities_id) {
    let data = {
      nationalities_id: nationalities_id
    }
    this.authService.getCityList(data).subscribe((res: any) => {
      this.cityList = res.data;
    },
      err => {
        this.messageService.showError('Something went wrong.');
      })
  }

  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  // select compnay logo
  onSelectFileCompanyLogo(event) {
    if (event.target.files && event.target.files[0]) {
      this.copmany_logo_Show = event.target.files[0];
      if (this.copmany_logo_Show != '') {
        this.companyLogoErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        // console.log(event.target.result);
      }
    }
  }

  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      // console.log(url)
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  // submit sponsors registration
  formSubmit() {
    this.submitted = true;
    if (this.copmany_logo_Show == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 500;
      this.companyLogoErr = 'Company Logo is required';
    }
    else {
      this.companyLogoErr = '';
    }
    if (this.sponsorsForm.value.country == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 400;
    }
    if (this.sponsorsForm.value.city == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 400;
    }
    if (this.sponsorsForm.value.website == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 400;
    }
    if (this.sponsorsForm.value.comments == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 400;
    }
    if (this.sponsorsForm.value.last_name == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 300;
    }
    if (this.sponsorsForm.value.job_title == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 300;
    }
    if (this.sponsorsForm.value.email == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 300;
    }
    if (this.sponsorsForm.value.contact_number == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 300;
    }
    if (this.sponsorsForm.value.sponsorship_category == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 200;
    }
    if (this.sponsorsForm.value.company_name == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 200;
    }
    if (this.sponsorsForm.value.first_name == null) {
      var scrollModal = document.querySelector(".modal.show");
      scrollModal.scrollTop = 200;
    }

    // stop here if form is invalid
    if (this.sponsorsForm.invalid) {
      return;
    }
    if (this.copmany_logo_Show != null) {
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('award_id', this.awardIdParam),

        formData.append('category_sponser_id', this.sponsorsForm.value.sponsorship_category),
        formData.append('company_name', this.sponsorsForm.value.company_name),
        formData.append('first_name', this.sponsorsForm.value.first_name),
        formData.append('last_name', this.sponsorsForm.value.last_name),

        formData.append('job_title', this.sponsorsForm.value.job_title),
        formData.append('email', this.sponsorsForm.value.email),
        formData.append('contact_number', this.sponsorsForm.value.contact_number),
        formData.append('country_id', this.sponsorsForm.value.country),
        formData.append('city_id', this.sponsorsForm.value.city),
        formData.append('website', this.sponsorsForm.value.website),
        formData.append('comments', this.sponsorsForm.value.comments),
        formData.append('logo', this.copmany_logo_Show)

      if (this.user_token != null) {
        if (this.webSitePatternErr == false) {
          this.processingText = true;
          this.authService.sponsersRegister(formData).subscribe((res: any) => {
            console.log(res);
            if (res.has_error == 0) {
              this.errMsg = '';
              this.processingText = false;
              var scrollModal = document.querySelector(".modal.show");
              scrollModal.scrollTop = 0;
              this.registrationSuccess = true;
              this.SponsorsList();
              this.getGeneralCategorySponsers();
            }
            else if (res.has_error == 1) {
              this.processingText = false;
              var scrollModal = document.querySelector(".modal.show");
              scrollModal.scrollTop = 0;
              // for (let [key, value] of Object.entries(res.error)) {
              //   console.log(`${key}: ${value}`);
                // this.messageService.showError(res.error);
                this.errMsg = res.error;
              // }
              setTimeout(() => {
                this.messageService.clear();
              }, 4000);
            }
          },
            err => {
              this.processingText = false;
              var scrollModal = document.querySelector(".modal.show");
              scrollModal.scrollTop = 0;
              this.errMsg = 'Something went wrong.';
              // this.messageService.showError('Something went wrong.');
              setTimeout(() => {
                this.messageService.clear();
              }, 4000);
            }
          )
        }
        else if (this.webSitePatternErr == true) {
          var scrollModal = document.querySelector(".modal.show");
          scrollModal.scrollTop = 400;
        }
      }
      else if (this.user_token == null) {
        window.scroll(0, 0);
        this.messageService.showError('User token is missing');
        setTimeout(() => {
          this.messageService.clear();
        }, 2000);
      }
    }
  }

  // after registration go to sponsors list
  anotherSponsor() {
    this.modalService.dismissAll();
    window.scroll({
      top: 400,
      left: 0,
      behavior: 'smooth'
    });
    this.awardIconShow = false;
  }

  // sponsors description
  sponserdescription(){
    this.authService.sponserDescription(this.user_token).subscribe((res : any) => {
    console.log(res);
    if(res.has_error == 0){
      this.sponser_description = res.cmsData.description;
    }
    },
    err =>{
      this.messageService.showError('Something went wrong.');
    })
  }

  // open website url
 windowOpen(url) {
    if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
    }
    return window.open(url);
}
}

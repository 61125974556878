import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessagesService } from 'src/app/services/messages.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ViewChild } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { appConfig } from '../../app.config';

@Component({
  selector: 'app-edit-self-assessment',
  templateUrl: './edit-self-assessment.component.html',
  styleUrls: ['./edit-self-assessment.component.scss']
})
export class EditSelfAssessmentComponent implements OnInit {
  // public Editor = ClassicEditor;

  @ViewChild('myInput')
  myInputVariable: ElementRef;
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  user_token: any;
  api_token: any;
  selfAssessmentDetails: any;
  criteriaList: any = [];
  subCriteriaList: any = [];
  subcriteriaTitle: any;
  mainCriteraNumber: number = 1;
  CriteraNumber: any;
  processingtext: boolean = false;
  lazyLoad: boolean = false;
  levelOneSelectIndex: number = 0;
  levelTwoSelectIndex: number = 0;
  levelThirdSelectIndex: number = 0;
  levelFourthSelectIndex: number = 0;
  assessmentId: any;
  strength: any;
  comment: any;
  selectPointType: any;
  selectPoint: any;
  criteraPoint: any;
  awardMeasurmentsList: any = [];
  pointMeasurmentsList: any = [];
  criteriatype_name_Array: any = [];
  critera_id: any;
  award_id: any;
  FirstNumber: number = 1;
  SceondNumber: number = 1;
  ThirdNumber: number = 1;
  criteraNumber: any;
  ScorePoint: any;
  thirdLebelStrength: any = [];
  thirdLebelComment: any = [];
  thirdLebelPointType: any = [];
  thirdLebelAllPoint: any = [];
  processingThirdLebeltext: boolean = false;
  errorMessageValue: any;
  levelOneCriteraNumber: number = 1;
  levelTwoCriteraNumber: number = 1;
  levelThreeCriteraNumber: number = 1;
  parentId: any;
  childParentId: any;
  showlevelThreeFile: any;
  levelThreeFileUploadErr: any;
  // filesToUpload: Array<File> = [];
  levelthreeOriginalImage: any = [];
  originalImage: any = [];
  closeResult: string;
  criteraItemDoc: any;
  criteriaFilePath: any;
  documentType: any;
  showDocument: boolean = false;
  fileDocument: boolean = false;
  singleCriteradataArray: any = [];
  private documentShow: boolean = false;
  fileTypeText: any;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog) {
    this.activatedRoute.params.subscribe(params => {
      this.assessmentId = params.id;
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url.match('/edit-self-assesment'))
          this.dataService.leftPanelSelection('selfassessment');
      }
    });
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      // this.router.navigate([`/assesor-assignment/`+this.assessmentId]);
      this.router.navigate([`/edit-self-assesment/`+this.assessmentId]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getScoreBoarddetails();
    this.addEditor();
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
  }
  getScoreBoarddetails() {
    let data = {
      user_token: this.user_token,
      'assessorAssesmentID': this.assessmentId
    }
    this.lazyLoad = true;
    this.dashboardService.editSelfAssesment(this.api_token, data).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.selfAssessmentDetails = res;
        this.criteriaList = res.criteria;
        this.criteriatype_name_Array = res.criteriatype;
        this.subCriteriaList = res.get_all_criteria;
        // this.criteriaFilePath = res.criteriaFilePath;
        this.criteriaFilePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/self_criteria_file/';

        for (let i = 0; i < this.criteriaList.length; i++) {
          for (let j = 0; j < this.criteriaList[i].getTotalScoreValue.length; j++) {
            if (j == 0) {
              this.levelOneCriteraNumber = 1;
              this.criteriaList[i].getTotalScoreValue[j]['level_one_criteria_number'] = this.levelOneCriteraNumber;
            }
            if (j != 0) {
              if (this.criteriaList[i].getTotalScoreValue[j - 1].parent_id != this.criteriaList[i].getTotalScoreValue[j].parent_id) {
                this.criteriaList[i].getTotalScoreValue[j]['level_one_criteria_number'] = this.levelOneCriteraNumber;
              }
              if (this.criteriaList[i].getTotalScoreValue[j - 1].parent_id == this.criteriaList[i].getTotalScoreValue[j].parent_id) {
                this.criteriaList[i].getTotalScoreValue[j]['level_one_criteria_number'] = ++this.levelOneCriteraNumber;
              }
            }
            let a = this.subCriteriaList.filter((x) => parseInt(x.middle_cr.parent_id) === this.criteriaList[i].getTotalScoreValue[j].id);
            let _data = [];
            a.map((item) => {
              if (_data.findIndex(x => x.id == item.middle_cr.id) < 0) {
                _data.push(item.middle_cr);
              }
            })
            let middledata = this.subCriteriaList.filter((a) => parseInt(a.parent_id) === this.criteriaList[i].getTotalScoreValue[j].id).concat(_data);
            this.criteriaList[i].getTotalScoreValue[j]['childList'] = middledata;
            for (let k = 0; k < this.criteriaList[i].getTotalScoreValue[j].childList.length; k++) {
              this.criteriaList[i].getTotalScoreValue[j].childList[k]['sub_childList'] = this.subCriteriaList.filter((x) => parseInt(x.parent_id) === this.criteriaList[i].getTotalScoreValue[j].childList[k].id);
              // if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('total_point')) {
              //   this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point = this.criteriaList[i].getTotalScoreValue[j].childList[k].total_score;
              // }
              if (k == 0) {
                this.levelTwoCriteraNumber = 1;
                this.criteriaList[i].getTotalScoreValue[j].childList[k]['level_two_criteria_number'] = this.levelTwoCriteraNumber;
              }
              if (k != 0) {
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k - 1].parent_id != this.criteriaList[i].getTotalScoreValue[j].childList[k].parent_id) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['level_two_criteria_number'] = this.levelTwoCriteraNumber;
                }
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k - 1].parent_id == this.criteriaList[i].getTotalScoreValue[j].childList[k].parent_id) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['level_two_criteria_number'] = ++this.levelTwoCriteraNumber;
                }
              }
              for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                if (l == 0) {
                  this.levelThreeCriteraNumber = 1;
                  this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['level_three_criteria_number'] = this.levelThreeCriteraNumber;
                }
                if (l != 0) {
                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l - 1].parent_id != this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].parent_id) {
                    this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['level_three_criteria_number'] = this.levelThreeCriteraNumber;
                  }
                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l - 1].parent_id == this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].parent_id) {
                    this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['level_three_criteria_number'] = ++this.levelThreeCriteraNumber;
                  }
                }
              }
            }
          }
        }
        for (let i = 0; i < this.criteriaList.length; i++) {
          // this.criteriaList[i].each_point = this.criteriaList[i].each_point.toFixed(2);
          for (let j = 0; j < this.criteriaList[i].getTotalScoreValue.length; j++) {
            for (let k = 0; k < this.criteriaList[i].getTotalScoreValue[j].childList.length; k++) {
              if (this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('score_data')) {
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k].score_data.length > 0) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].score_data[0].point_percent;

                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length > 0) {
                    for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                      if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.hasOwnProperty('score_data')) {
                        this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].score_data[0].point_percent;
                      }
                      else if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.hasOwnProperty('score_data')) {
                        this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList['total_point'] = 0;
                      }
                    }
                  }
                }
              }
              else if (this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('1')) {
                this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = 0;
                for (let m = 0; m < this.criteriatype_name_Array.length; m++) {
                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty(m + 1)) {
                    this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] += parseFloat(this.criteriaList[i].getTotalScoreValue[j].childList[k][m + 1].score_data[0].point_percent);

                    if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length > 0) {
                      for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                        if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('score_data')) {
                          this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0;
                        }
                        if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('1')) {
                          this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0
                          for (let n = 0; n < this.criteriatype_name_Array.length; n++) {
                            if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty(n + 1)) {
                              this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] += parseFloat(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l][n + 1].score_data[0].point_percent);


                              // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point)
                              // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point + Number.EPSILON) * 100) / 100
                            }
                          }
                        }
                      }
                    }

                  }
                }
              }
              else if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('score_data')) {
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length == 0) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = 0;
                }
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length > 0) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].total_score;

                  // this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point)
                  // this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point + Number.EPSILON) * 100) / 100

                  for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                    if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.hasOwnProperty('score_data')) {
                      if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].score_data.length > 0) {
                        this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].score_data[0].point_percent;

                        // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point)
                        // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point + Number.EPSILON) * 100) / 100
                      }
                    }
                    if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('score_data')) {
                      this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0;
                    }
                    if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('1')) {
                      this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0
                      for (let n = 0; n < this.criteriatype_name_Array.length; n++) {
                        if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty(n + 1)) {
                          this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] += parseFloat(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l][n + 1].score_data[0].point_percent);

                          // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point)
                          // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point + Number.EPSILON) * 100) / 100
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }



        console.log('criteriaList', this.criteriaList)
        this.award_id = res.award_id;
        this.subcriteriaTitle = this.subCriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].title;
        this.critera_id = this.subCriteriaList[0].id;
        this.criteraPoint = this.subCriteriaList[0].point;
        if (this.subCriteriaList[0].hasOwnProperty('score_data')) {
          this.singleCriteradataArray = this.subCriteriaList[0].score_data[0].criteria_images;
          // console.log(this.singleCriteradataArray);
          // if (this.subCriteriaList[0].score_data[0].file_data != null) {
          //   this.fileDocument = true;
          //   this.viewDocument(this.subCriteriaList[0].score_data[0].file_data);
          // }
          // else if (this.subCriteriaList[0].score_data[0].file_data == null) {
          //   this.fileDocument = false;
          // }

          this.selectPointType = this.subCriteriaList[0].score_data[0].point_type;
          this.strength = this.subCriteriaList[0].score_data[0].strength;
          this.comment = this.subCriteriaList[0].score_data[0].comment;
          this.selectPoint = this.subCriteriaList[0].score_data[0].allopoint;
          if (this.subCriteriaList[0].score_data[0].allopoint == '0') {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('0')
          }
          else if ((this.subCriteriaList[0].score_data[0].allopoint == '5') || (this.subCriteriaList[0].score_data[0].allopoint == '10') || (this.subCriteriaList[0].score_data[0].allopoint == '15') || (this.subCriteriaList[0].score_data[0].allopoint == '20') || (this.subCriteriaList[0].score_data[0].allopoint == '25')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
          }

          else if ((this.subCriteriaList[0].score_data[0].allopoint == '30') || (this.subCriteriaList[0].score_data[0].allopoint == '35') || (this.subCriteriaList[0].score_data[0].allopoint == '40') || (this.subCriteriaList[0].score_data[0].allopoint == '45') || (this.subCriteriaList[0].score_data[0].allopoint == '50')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          }

          else if ((this.subCriteriaList[0].score_data[0].allopoint == '55') || (this.subCriteriaList[0].score_data[0].allopoint == '60') || (this.subCriteriaList[0].score_data[0].allopoint == '65') || (this.subCriteriaList[0].score_data[0].allopoint == '70') || (this.subCriteriaList[0].score_data[0].allopoint == '75')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
          }

          else if ((this.subCriteriaList[0].score_data[0].allopoint == '80') || (this.subCriteriaList[0].score_data[0].allopoint == '85') || (this.subCriteriaList[0].score_data[0].allopoint == '90') || (this.subCriteriaList[0].score_data[0].allopoint == '95') || (this.subCriteriaList[0].score_data[0].allopoint == '100')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
          }
        }
        else if (!this.subCriteriaList[0].hasOwnProperty('score_data')) {
          this.selectPointType = '';
          this.strength = '';
          this.comment = '';
          this.selectPoint = '';
          this.fileDocument = false;
        }

        for (let i = 0; i < this.criteriaList.length; i++) {
          if (i == 0) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            var key = Object.keys(this.selfAssessmentDetails.award_measurments)[i];
            this.awardMeasurmentsList = this.selfAssessmentDetails.award_measurments[key];
            this.pointMeasurmentsList.push('0')
          }
          if (i != 0) {
            if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
              this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            }
            else {
              this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
            }
          }
        }
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + this.subCriteriaList[0].hire_order;
      }
    },
      err => {
        this.lazyLoad = false;
      })
  }
  lineActive(criteria, item, child, subchild, firstlevelindex, secondlevelindex, thirdlevelindex, fourthlevelindex) {
    this.levelOneSelectIndex = firstlevelindex;
    this.levelTwoSelectIndex = secondlevelindex;
    this.levelThirdSelectIndex = thirdlevelindex;
    this.levelFourthSelectIndex = fourthlevelindex;
    this.criteraNumber = criteria.criteria_number;
    if (this.criteraNumber != 3) {
      this.reset();
      this.originalImage = [];
      this.singleCriteradataArray = [];
      this.subcriteriaTitle = child.title;
      this.CriteraNumber = criteria.criteria_number + '.' + item.level_one_criteria_number + '.' + child.level_two_criteria_number;

      var key = Object.keys(this.selfAssessmentDetails.award_measurments)[criteria.criteria_number - 1];
      this.selectPointType = "";
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.selfAssessmentDetails.award_measurments[key];
      console.log(child)
      this.selectPoint = "";
      this.pointMeasurmentsList = [];
      this.pointMeasurmentsList.push('0')
      this.critera_id = child.id;
      this.criteraPoint = child.point;
      if (child.hasOwnProperty('score_data')) {
        this.singleCriteradataArray = child.score_data[0].criteria_images;
        // if (child.score_data[0].file_data != null) {
        //   this.fileDocument = true;
        //   this.viewDocument(child.score_data[0].file_data);
        // }
        // else if (child.score_data[0].file_data == null) {
        //   this.fileDocument = false;
        // }
        this.selectPointType = child.score_data[0].point_type;
        this.strength = child.score_data[0].strength;
        this.comment = child.score_data[0].comment;
        this.selectPoint = child.score_data[0].allopoint;
        if (child.score_data[0].allopoint == '0') {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('0')
        }
        else if ((child.score_data[0].allopoint == '5') || (child.score_data[0].allopoint == '10') || (child.score_data[0].allopoint == '15') || (child.score_data[0].allopoint == '20') || (child.score_data[0].allopoint == '25')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
        }

        else if ((child.score_data[0].allopoint == '30') || (child.score_data[0].allopoint == '35') || (child.score_data[0].allopoint == '40') || (child.score_data[0].allopoint == '45') || (child.score_data[0].allopoint == '50')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
        }

        else if ((child.score_data[0].allopoint == '55') || (child.score_data[0].allopoint == '60') || (child.score_data[0].allopoint == '65') || (child.score_data[0].allopoint == '70') || (child.score_data[0].allopoint == '75')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
        }

        else if ((child.score_data[0].allopoint == '80') || (child.score_data[0].allopoint == '85') || (child.score_data[0].allopoint == '90') || (child.score_data[0].allopoint == '95') || (child.score_data[0].allopoint == '100')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
        }
      }
      else if (!child.hasOwnProperty('score_data')) {
        this.selectPointType = '';
        this.strength = '';
        this.comment = '';
        this.selectPoint = '';
        this.singleCriteradataArray = [];
        this.fileDocument = false;
      }
    }
    else if (this.criteraNumber == 3) {
      // console.log('child',child);
      // console.log('subchild',subchild);
      // this.reset();
      this.criteriatype_name_Array.map((criteriaItem) => {
        delete criteriaItem.file;
      })
      this.subcriteriaTitle = subchild == '' ? child.title : subchild.title;
      this.CriteraNumber = subchild == '' ? criteria.criteria_number + '.' + item.level_one_criteria_number + '.' + child.level_two_criteria_number : criteria.criteria_number + '.' + item.level_one_criteria_number + '.' + child.level_two_criteria_number + '.' + subchild.level_three_criteria_number;
      this.critera_id = subchild == '' ? child.id : subchild.id;
      this.criteraPoint = subchild == '' ? child.point : subchild.point;
      this.ScorePoint = subchild == '' ? child : subchild;
      var key = Object.keys(this.selfAssessmentDetails.award_measurments)[criteria.criteria_number - 1];
      this.selectPointType = "";
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.selfAssessmentDetails.award_measurments[key];
      console.log(this.ScorePoint);
      for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

        if (!this.ScorePoint.hasOwnProperty('score_data')) {
          if (this.ScorePoint.hasOwnProperty(i)) {
            if (this.criteriatype_name_Array[i - 1].criteria_type_id == this.ScorePoint[i].score_data[0].criteria_type_id) {

              this.criteriatype_name_Array[i - 1]['ScorePoint'] = this.ScorePoint[i].score_data[0];

              if (this.ScorePoint[i].score_data[0].allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '5') || (this.ScorePoint[i].score_data[0].allopoint == '10') || (this.ScorePoint[i].score_data[0].allopoint == '15') || (this.ScorePoint[i].score_data[0].allopoint == '20') || (this.ScorePoint[i].score_data[0].allopoint == '25')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '30') || (this.ScorePoint[i].score_data[0].allopoint == '35') || (this.ScorePoint[i].score_data[0].allopoint == '40') || (this.ScorePoint[i].score_data[0].allopoint == '45') || (this.ScorePoint[i].score_data[0].allopoint == '50')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '55') || (this.ScorePoint[i].score_data[0].allopoint == '60') || (this.ScorePoint[i].score_data[0].allopoint == '65') || (this.ScorePoint[i].score_data[0].allopoint == '70') || (this.ScorePoint[i].score_data[0].allopoint == '75')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '80') || (this.ScorePoint[i].score_data[0].allopoint == '85') || (this.ScorePoint[i].score_data[0].allopoint == '90') || (this.ScorePoint[i].score_data[0].allopoint == '95') || (this.ScorePoint[i].score_data[0].allopoint == '100')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
              console.log(this.criteriatype_name_Array[i - 1]['ScorePoint']);
            }
          }
          if (!this.ScorePoint.hasOwnProperty(i)) {
            let blankdata = {
              'allopoint': '0',
              'point_type': '0',
              'strength': null,
              'comment': null
            }
            if (this.criteriatype_name_Array[i - 1] != undefined) {
              this.criteriatype_name_Array[i - 1]['ScorePoint'] = blankdata;
              if (blankdata.allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
            }
          }
        }
      }
      // console.log(this.criteriatype_name_Array);
    }
  }
  viewDocument(content, documentItem, className) {
    this.documentShow = true;
    this.documentType = documentItem.split('.').pop();

    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png')) {
      this.showDocument = true;
      this.criteraItemDoc = this.criteriaFilePath + documentItem;
      this.open(content, documentItem, className);
      this.documentShow = false;
    }
    else if (this.documentType == 'pdf') {
      this.showDocument = true;
      this.criteraItemDoc = this.criteraItemDoc + documentItem;
      this.criteraItemDoc = this.sanitizer.bypassSecurityTrustResourceUrl(this.criteriaFilePath + documentItem);
      this.open(content, documentItem, className);
      this.documentShow = false;
    }
    else if (this.documentType == 'docx') {
      this.showDocument = false;
      this.documentShow = false;
      this.criteraItemDoc = this.criteriaFilePath + documentItem;
      const downloadLink = document.createElement("a");
      const fileName = documentItem;

      downloadLink.href = this.criteraItemDoc;
      downloadLink.download = fileName;
      downloadLink.click();

    }
  }
  open(content, documentItem, className) {
    // this.criteraItemDoc = crteriaItem;
    let modalClassName = className;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onChange($event) {
    console.log($event)
    this.pointMeasurmentsList = [];
    if ($event == '0') {
      this.pointMeasurmentsList.push('0')
    }
    else if ($event == '25') {
      this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
    }
    else if ($event == '50') {
      this.pointMeasurmentsList.push('30', '35', '40', '45', '50')
    }
    else if ($event == '75') {
      this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
    }
    else if ($event == '100') {
      this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
    }
  }
  onChangeThirdLebel($event, index1) {
    this.criteriatype_name_Array.forEach((element, index) => {
      if (index == index1) {
        console.log($event)
        this.pointMeasurmentsList = [];
        if ($event == '0') {
          this.pointMeasurmentsList.push('0');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '25') {
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '50') {
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '75') {
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '100') {
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
      }
    });
  }
  reset() {
    this.myInputVariable.nativeElement.value = "";
    // console.log(this.myInputVariable.nativeElement.files);
  }
  // add  score point for first and sceond level
  addScorepoint() {
    if (this.criteraNumber != 3) {
      if ((this.strength != '') && (this.comment != '') && (this.selectPointType != '') && (this.selectPoint != '')) {
        let formData: FormData = new FormData();
        formData.append('user_token', this.user_token),
          formData.append('assessorAssesmentID', this.assessmentId),
          formData.append('award_id', this.award_id),
          formData.append('criteria_id', this.critera_id),
          formData.append('point_type', this.selectPointType),
          formData.append('allopoint', this.selectPoint ? this.selectPoint : ''),
          formData.append('criteria_point', this.criteraPoint),
          formData.append('strength', this.strength ? this.strength : ''),
          formData.append('comment', this.comment ? this.comment : ''),
          // formData.append('comment', JSON.stringify(this.thirdLebelComment)
          formData.append('file_count', this.originalImage.length)
        for (let i = 0; i < this.originalImage.length; i++) {
          formData.append("file_data" + i, this.originalImage[i]);
        }
        this.processingtext = true;
        this.dashboardService.updateSelfAssessment(this.api_token, formData).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.processingtext = false;
            this.messagesService.showSuccess('Score Data Updated successfully.');
            this.reset();
            this.getScore();
            this.originalImage = [];
            this.singleCriteradataArray = [];
            this.singleCriteradataArray = res.score_point.criteria_images;
            console.log(this.singleCriteradataArray);
            this.subCriteriaList.map((item) => {
              if (item.id == this.critera_id) {
                this.parentId = Number(item.parent_id);
                item['score_data'] = [];
                item.score_data.push(res.score_point);
                item['total_point'] = item.score_data[0].point_percent;
              }
            })
            // console.log(this.subCriteriaList);
            // console.log(this.criteriaList)
          }
          if (res.has_error == 1) {
            this.processingtext = false;
            for (let [key, value] of Object.entries(res.msg)) {
              this.messagesService.showError(value);
              setTimeout(() => {
                this.messagesService.clear();
              }, 1500);
            }
          }
        },
          err => {
            this.processingtext = false;
            this.messagesService.showError('Something went wrong.');
            console.log(err);
          })
      }
      else if ((this.strength == '') || (this.comment == '') || (this.selectPointType == '') || (this.selectPoint == '')) {
        this.messagesService.showError('Please Fillup All the Credentials');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1500);
      }
    }
    else if (this.criteraNumber == 3) {
      this.addThirdLebelScorePoint();
    }
  }
  // add third level score point
  addThirdLebelScorePoint() {
    this.thirdLebelStrength = [];
    this.thirdLebelComment = [];
    this.thirdLebelAllPoint = [],
      this.thirdLebelPointType = []
    for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

      if (this.criteriatype_name_Array[i - 1] != undefined) {
        this.thirdLebelStrength.push(this.criteriatype_name_Array[i - 1].ScorePoint.strength);
        this.thirdLebelComment.push(this.criteriatype_name_Array[i - 1].ScorePoint.comment);
        this.thirdLebelPointType.push(this.criteriatype_name_Array[i - 1].ScorePoint.point_type);
        this.thirdLebelAllPoint.push(this.criteriatype_name_Array[i - 1].ScorePoint.allopoint);
        this.originalImage.push(this.criteriatype_name_Array[i - 1].file ? this.criteriatype_name_Array[i - 1].file : null);
      }
    }

    // const files: Array<File> = this.originalImage;
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('assessorAssesmentID', this.selfAssessmentDetails.assessorAssesmentID),
      formData.append('award_id', this.selfAssessmentDetails.award_id),
      formData.append('criteria_id', this.critera_id),
      formData.append('point_type', this.thirdLebelPointType),
      formData.append('allopoint', this.thirdLebelAllPoint),
      formData.append('criteria_point', this.criteraPoint),
      formData.append('strength', this.thirdLebelStrength),
      formData.append('comment', this.thirdLebelComment)
    console.log(this.criteriatype_name_Array);
    for (let i = 0; i < this.criteriatype_name_Array.length; i++) {
      if (this.criteriatype_name_Array[i] != undefined) {
      formData.append('file_count' + (i + 1), this.criteriatype_name_Array[i].file_count ? this.criteriatype_name_Array[i].file_count : 0)
      for (let j = 0; j < this.criteriatype_name_Array[i].file_count; j++) {
        formData.append("file_data" + (i + 1) + '_' + j, this.criteriatype_name_Array[i].file[j] ? this.criteriatype_name_Array[i].file[j] : null);
      }
      }
    }
    this.processingThirdLebeltext = true;
    this.dashboardService.addThirdLebelSelfScorePoint(this.api_token, formData).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.processingThirdLebeltext = false;
        this.getScore();
        this.messagesService.showSuccess('Score Data Updated successfully.');
        this.criteriatype_name_Array.map((criteriaItem) => {
          delete criteriaItem.file;
        })
        for (let i = 0; i < this.criteriatype_name_Array.length; i++) {
          if (!this.ScorePoint.hasOwnProperty('score_data')) {
            if (this.ScorePoint.hasOwnProperty('1')) {
              if (this.criteriatype_name_Array[i].criteria_type_id == res.score_point[i].criteria_type_id) {

                this.criteriatype_name_Array[i].ScorePoint.criteria_images = res.score_point[i].criteria_images;
              }
            }
            if (!this.ScorePoint.hasOwnProperty('1')) {
              if (this.criteriatype_name_Array[i].criteria_type_id == res.score_point[i].criteria_type_id) {
                console.log(this.criteriatype_name_Array[i])
                console.log(res.score_point[i])
                this.criteriatype_name_Array[i].ScorePoint.criteria_images = res.score_point[i].criteria_images;
              }
            }
          }
        }
        console.log(this.criteriatype_name_Array);
      }
      if (res.has_error == 1) {
        this.processingThirdLebeltext = false;
        for (let [key, value] of Object.entries(res.msg)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
      }
    },
      err => {
        this.processingThirdLebeltext = false;
        this.messagesService.showError('Something went wrong.');
        console.log(err);
      })
  }
  getScore() {
    let data = {
      user_token: this.user_token,
      'assessorAssesmentID': this.assessmentId
    }
    this.dashboardService.editSelfAssesment(this.api_token, data).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        this.selfAssessmentDetails = res;
        this.criteriaList = res.criteria;
        // this.criteriatype_name_Array = res.criteriatype;
        this.subCriteriaList = res.get_all_criteria;
        this.criteriaFilePath = res.criteriaFilePath;

        for (let i = 0; i < this.criteriaList.length; i++) {
          for (let j = 0; j < this.criteriaList[i].getTotalScoreValue.length; j++) {
            if (j == 0) {
              this.levelOneCriteraNumber = 1;
              this.criteriaList[i].getTotalScoreValue[j]['level_one_criteria_number'] = this.levelOneCriteraNumber;
            }
            if (j != 0) {
              if (this.criteriaList[i].getTotalScoreValue[j - 1].parent_id != this.criteriaList[i].getTotalScoreValue[j].parent_id) {
                this.criteriaList[i].getTotalScoreValue[j]['level_one_criteria_number'] = this.levelOneCriteraNumber;
              }
              if (this.criteriaList[i].getTotalScoreValue[j - 1].parent_id == this.criteriaList[i].getTotalScoreValue[j].parent_id) {
                this.criteriaList[i].getTotalScoreValue[j]['level_one_criteria_number'] = ++this.levelOneCriteraNumber;
              }
            }
            let a = this.subCriteriaList.filter((x) => parseInt(x.middle_cr.parent_id) === this.criteriaList[i].getTotalScoreValue[j].id);
            let _data = [];
            a.map((item) => {
              if (_data.findIndex(x => x.id == item.middle_cr.id) < 0) {
                _data.push(item.middle_cr);
              }
            })
            let middledata = this.subCriteriaList.filter((a) => parseInt(a.parent_id) === this.criteriaList[i].getTotalScoreValue[j].id).concat(_data);
            this.criteriaList[i].getTotalScoreValue[j]['childList'] = middledata;
            for (let k = 0; k < this.criteriaList[i].getTotalScoreValue[j].childList.length; k++) {
              this.criteriaList[i].getTotalScoreValue[j].childList[k]['sub_childList'] = this.subCriteriaList.filter((x) => parseInt(x.parent_id) === this.criteriaList[i].getTotalScoreValue[j].childList[k].id);
              // if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('total_point')) {
              //   this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point = this.criteriaList[i].getTotalScoreValue[j].childList[k].total_score;
              // }
              if (k == 0) {
                this.levelTwoCriteraNumber = 1;
                this.criteriaList[i].getTotalScoreValue[j].childList[k]['level_two_criteria_number'] = this.levelTwoCriteraNumber;
              }
              if (k != 0) {
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k - 1].parent_id != this.criteriaList[i].getTotalScoreValue[j].childList[k].parent_id) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['level_two_criteria_number'] = this.levelTwoCriteraNumber;
                }
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k - 1].parent_id == this.criteriaList[i].getTotalScoreValue[j].childList[k].parent_id) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['level_two_criteria_number'] = ++this.levelTwoCriteraNumber;
                }
              }
              for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                if (l == 0) {
                  this.levelThreeCriteraNumber = 1;
                  this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['level_three_criteria_number'] = this.levelThreeCriteraNumber;
                }
                if (l != 0) {
                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l - 1].parent_id != this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].parent_id) {
                    this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['level_three_criteria_number'] = this.levelThreeCriteraNumber;
                  }
                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l - 1].parent_id == this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].parent_id) {
                    this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['level_three_criteria_number'] = ++this.levelThreeCriteraNumber;
                  }
                }
              }
            }
          }
        }
        for (let i = 0; i < this.criteriaList.length; i++) {
          // this.criteriaList[i].each_point = this.criteriaList[i].each_point.toFixed(2);
          for (let j = 0; j < this.criteriaList[i].getTotalScoreValue.length; j++) {
            for (let k = 0; k < this.criteriaList[i].getTotalScoreValue[j].childList.length; k++) {
              if (this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('score_data')) {
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k].score_data.length > 0) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].score_data[0].point_percent;

                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length > 0) {
                    for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                      if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.hasOwnProperty('score_data')) {
                        this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].score_data[0].point_percent;
                      }
                      else if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.hasOwnProperty('score_data')) {
                        this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList['total_point'] = 0;
                      }
                    }
                  }
                }
              }
              else if (this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('1')) {
                this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = 0;
                for (let m = 0; m < this.criteriatype_name_Array.length; m++) {
                  if (this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty(m + 1)) {
                    this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] += parseFloat(this.criteriaList[i].getTotalScoreValue[j].childList[k][m + 1].score_data[0].point_percent);

                    if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length > 0) {
                      for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                        if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('score_data')) {
                          this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0;
                        }
                        if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('1')) {
                          this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0
                          for (let n = 0; n < this.criteriatype_name_Array.length; n++) {
                            if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty(n + 1)) {
                              this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] += parseFloat(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l][n + 1].score_data[0].point_percent);


                              // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point)
                              // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point + Number.EPSILON) * 100) / 100
                            }
                          }
                        }
                      }
                    }

                  }
                }
              }
              else if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].hasOwnProperty('score_data')) {
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length == 0) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = 0;
                }
                if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length > 0) {
                  this.criteriaList[i].getTotalScoreValue[j].childList[k]['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].total_score;

                  // this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point)
                  // this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].total_point + Number.EPSILON) * 100) / 100

                  for (let l = 0; l < this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.length; l++) {
                    if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.hasOwnProperty('score_data')) {
                      if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].score_data.length > 0) {
                        this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList['total_point'] = this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].score_data[0].point_percent;

                        // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point)
                        // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList.total_point + Number.EPSILON) * 100) / 100
                      }
                    }
                    if (!this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('score_data')) {
                      this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0;
                    }
                    if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty('1')) {
                      this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] = 0
                      for (let n = 0; n < this.criteriatype_name_Array.length; n++) {
                        if (this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].hasOwnProperty(n + 1)) {
                          this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l]['total_point'] += parseFloat(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l][n + 1].score_data[0].point_percent);

                          // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Number(this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point)
                          // this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point = Math.round((this.criteriaList[i].getTotalScoreValue[j].childList[k].sub_childList[l].total_point + Number.EPSILON) * 100) / 100
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        console.log('criteriaList', this.criteriaList)
        this.mainCriteraNumber = 1;
        for (let i = 0; i < this.criteriaList.length; i++) {
          if (i == 0) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            var key = Object.keys(this.selfAssessmentDetails.award_measurments)[i];
            this.awardMeasurmentsList = this.selfAssessmentDetails.award_measurments[key];
            // this.pointMeasurmentsList.push('0')
          }
          if (i != 0) {
            if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
              this.criteriaList[i].criteria_number = this.mainCriteraNumber;
            }
            else {
              this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
            }
          }
        }
      }
    },
      err => {
      })

  }
  uploadFile(event) {
    let files = event.target.files;
    // this.filesToUpload.push(event.target.files);
    if (files) {
      for (let file of files) {
        this.originalImage.push(file)
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //     }
        //     reader.readAsDataURL(file);
      }
    }
    console.log(this.originalImage)
    // const files1: Array<File> = this.originalImage;
  }
  uploadLevelThreeFile(event: any, item, index) {
    let files = event.target.files;
    if (files) {
      this.levelthreeOriginalImage = [];
      for (let file of files) {
        this.levelthreeOriginalImage.push(file)
      }
    }
    this.criteriatype_name_Array.map((criteriaItem) => {
      if (criteriaItem.criteria_type_id == item.criteria_type_id) {
        criteriaItem['file'] = this.levelthreeOriginalImage;
        criteriaItem['file_count'] = this.levelthreeOriginalImage.length;
      }
    })
  }
  deleteImage(file, index) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this selected document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.originalImage.splice(index, 1);
      }
    });
    // this.reset();
  }
  deleteThirdLevelImage(file, item, index) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this selected document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.criteriatype_name_Array.map((criteraItem) => {
          if (criteraItem.criteria_type_id == item.criteria_type_id) {
            criteraItem.file.splice(index, 1)
            criteraItem.file_count = criteraItem.file.length
          }
        })
      }
    });

  }
  openDialog(item, index) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.criteraNumber != 3) {
          this.singleCriteradataArray.splice(index, 1);
          this.deleteDocument(item);
        }
        if (this.criteraNumber == 3) {
          this.criteriatype_name_Array.map((criteraItem) => {
            if (criteraItem.criteria_type_id == item.criteria_type_id) {
              criteraItem.ScorePoint.criteria_images.splice(index, 1)
            }
          })
          this.deleteDocument(item);
        }
      }
    });
  }
  deleteDocument(item) {
    let data = {
      user_token: this.user_token,
      document_id: item.id
    }
    this.dashboardService.deleteSelfAssessmentDocument(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.messagesService.showSuccess(res.msg);
      }
    })
  }
  addEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      resize_enabled: false,
      // toolbar: {
      // items: ['bold','italic','underline','|','strikethrough','subscript','superscript','|','bulletedList','numberedList','|','blockQuote','codeBlock','code','horizontalLine']
      // },
      language: 'en-gb',
      licenseKey: '',

    })
      .then(editor => {
        console.log(editor)
      })
      .catch(error => {
        console.error(error);
      });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { MessagesService } from '../services/messages.service';
import { AssessorApplicationComponent } from './assessor-application.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// intro routing
export const routes: Routes = [
  { path: '', component: AssessorApplicationComponent },
];

@NgModule({
  declarations: [AssessorApplicationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AngularMultiSelectModule
  ],
  providers: [MessagesService]
})
export class AssessorApplicationModule { }

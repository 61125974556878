import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './faq.component';
import { MeadiaService } from '../services/meadia.service';
import { MessagesService } from '../services/messages.service';
// intro routing
export const routes: Routes = [
  { path: '', component: FaqComponent },
];

@NgModule({
  declarations: [FaqComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],providers: [MeadiaService, MessagesService]
})
export class FaqModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AwardApplyComponent } from './award-apply.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule } from '@angular/forms';


// intro routing
export const routes: Routes = [
  { path: '', component: AwardApplyComponent },
];

@NgModule({
  declarations: [AwardApplyComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    FormsModule
  ]
})
export class AwardApplyModule { }

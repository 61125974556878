import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OfficeCrteriaManagemnetComponent } from './office-crteria-managemnet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecursiveCriteriaModule } from '../recursive-criteria/recursive-criteria.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: OfficeCrteriaManagemnetComponent },
];

@NgModule({
  declarations: [OfficeCrteriaManagemnetComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    RecursiveCriteriaModule,
    NgScrollbarModule
  ]
})
export class OfficeCrteriaManagemnetModule { }

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-active-account',
  templateUrl: './active-account.component.html',
  styleUrls: ['./active-account.component.scss']
})
export class ActiveAccountComponent implements OnInit {
  activatedStatus: any;
  acitivateMsg: any;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      console.log(params)
      this.activatedStatus = params.id;
    });
  }

  ngOnInit() {
    if (this.activatedStatus == 'already_activated') {
      this.acitivateMsg = 'Your Account is Already Activated'
    }
    else if (this.activatedStatus == 'active') {
      this.acitivateMsg = 'Your Account is Activated Successfully'
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { WinnerService } from '../services/winner.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    // margin: 10,
    center: true,
    items: 1,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    autoplayHoverPause: true,
    dots: false,
    navSpeed: 2000,
    dotsSpeed: 2000,
    autoplaySpeed: 5000,
    navText: ['<i aria-hidden="true" class="fa fa-arrow-left"></i>', '<i aria-hidden="true" class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }
  activeSlides: SlidesOutputData;

  user_token: any;
  tesimonialDataArray: any = [];
  tesimonialPath: any;
  lazyLoad: boolean = false;
  safeSrc: SafeResourceUrl;
  youtube_video_thumnail: string;
  vimeoVideoThumbnail: any;
  selectedIndex: any;
  videoShow: boolean = false;
  testimonialVideoThumbPath: any;
  constructor(private winnerService: WinnerService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private authService: AuthService) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.getuserToken();
    this.getTestimonialData();
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
      });
  }
  getTestimonialData() {
    this.lazyLoad = true;
    this.winnerService.testimonialData(this.user_token).subscribe((response: any) => {
      if (response.has_error == 0) {
        this.lazyLoad = false;
        console.log(response);
        this.tesimonialDataArray = response.tesimonialData;
        this.testimonialVideoThumbPath = response.testimonialVideoThumbPath;
        this.tesimonialDataArray.map((item) => {
          if (item.type == "V") {
            if (item.video_type == "L") {
              if (item.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(item.video + '?autoplay=1');
                item['videoSrc'] = this.safeSrc;
                var youtube_video_id = item.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
                this.youtube_video_thumnail = 'http://img.youtube.com/vi/' + youtube_video_id + '/hqdefault.jpg';
                item['videoThumbnail'] = this.youtube_video_thumnail;
                item['videoType'] = 'youtube';
              }
              else if (item.video.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
                var match = item.video.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/);
                var vimeo_video_id = match[2];
                var vimeoVideo = 'https://player.vimeo.com/video/' + match[2];
                this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(vimeoVideo + '?autoplay=1');
                item['videoSrc'] = this.safeSrc;
                this.http.get('http://vimeo.com/api/v2/video/' + vimeo_video_id + '.json')
                  .subscribe((response: any) => {
                    this.vimeoVideoThumbnail = response[0].thumbnail_large;
                    item['videoThumbnail'] = this.vimeoVideoThumbnail;
                  });
                item['videoType'] = 'vimeo';
              }
            }
            else if (item.video_type == "F") {
              item['videoSrc'] = item.video;
            }
          }

          if ((item.type == "P") && (item.video = null)) {
            this.selectedIndex = null;
          }
        })
        this.tesimonialPath = response.path;
      }
    },
      err => {
        this.lazyLoad = false;
      })
  }
  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;
    console.log(this.activeSlides);
  }
  videoPlay(index) {
    this.videoShow = true;
    this.selectedIndex = index;
    setTimeout(() => {
      this.videoShow = false;
    }, 2000);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwardManagementComponent } from './award-management.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesService } from '../../services/messages.service';

// intro routing
export const routes: Routes = [
  { path: '', component: AwardManagementComponent },
];

@NgModule({
  declarations: [AwardManagementComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AwardManagementModule { }

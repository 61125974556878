import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessagesService } from 'src/app/services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

declare let paypal: any;

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;

  leftbarselected; any;
  licenselist: any = [];
  user_token: any;
  api_token: any;
  lazyLoad: boolean = false;
  addLicenseForm: FormGroup;
  submitted: boolean = false;
  processingText: boolean = false;
  yearArray: any = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  monthArray: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  licenseNumberArray: any = [1, 2, 3, 4, 5];
  no_of_assesments: any;

  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/assesor-license')
          this.leftbarselected = 'license';
        this.dataService.leftPanelSelection('license');
      }
    });
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-license`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.addLicenseForm = this.formBuilder.group({
      no_of_assesments: ['', Validators.required],
      licenseAmmount: ['']
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.getLicenselist();
    this.initConfig();
    
  }
  private initConfig(): void {
    this.payPalConfig = {
    currency: 'USD',
    clientId: 'AQnsML_nHJ_P12Eo2LmHTBq2DHPiik1RnkPfviuYQtWNu79rY-C7cTXNtZgKvFqyffYB7oDy20-7bC3O',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: this.addLicenseForm.value.licenseAmmount
          }
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'horizontal'
      // layout: 'vertical'
    },
    onApprove: (data, actions) => {
      console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);
        if(details.status == "APPROVED"){
        this.messagesService.showSuccess('Your payment is on progress');
      }
      });
    },
    onClientAuthorization: (data) => {
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      // this.showSuccess = true;
      if(data.status == "COMPLETED"){
        let transactionData = {
          'no_of_assesments': this.addLicenseForm.value.no_of_assesments,
          'id': data.id,
          'payer_id': data.payer.payer_id,
          'payment_status': data.status
        }
        this.dashboardService.createLicense(this.api_token, transactionData).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.licenselist = res.licenseList;
            this.messagesService.showSuccess(res.msg);
            this.submitted = false;
            this.addLicenseForm.reset();
          }
        }, err => {
        })
      }
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
      this.messagesService.showError('Something went wrong.');
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
    },
  };
  }

  getLicenselist() {
    this.lazyLoad = true;
    this.dashboardService.getLicense(this.user_token, this.api_token).subscribe((res: any) => {
      this.lazyLoad = false;
      this.licenselist = res.licenseList;
    }, err => {
      this.lazyLoad = false;
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }

  onChangeLicensenumber(event) {
    this.no_of_assesments = event;
    this.licensePrice(this.no_of_assesments);
  }

  licensePrice(no_of_assesments) {
    let data = {
      'user_token': this.user_token,
      'no_of_assesments': no_of_assesments
    }
    this.dashboardService.getLicensePrice(this.api_token ,data).subscribe((res: any) => {

      if(res.has_error == 0){
        this.addLicenseForm.patchValue({
          licenseAmmount: res.licensePrice.price
        });
      }
    }, err => {
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  // convenience getter for easy access to form fields
  get f() { return this.addLicenseForm.controls; }

  formSubmit() {
    // this.submitted = true;
    // if (this.addLicenseForm.invalid) {
    //   return;
    // }
    // let data = {
    //   'user_token': this.user_token,
    //   'no_of_assesments': this.addLicenseForm.value.no_of_assesments
    // }
    // this.processingText = true;
    // this.dashboardService.createLicense(this.api_token, data).subscribe((res: any) => {
    //   console.log(res);
    //   if (res.has_error == 0) {
    //     this.processingText = false;
    //     this.licenselist = res.licenseList;
    //     this.messagesService.showSuccess(res.msg);
    //   }
    // }, err => {
    //   this.processingText = false;
    //   if (err.error.message != "Unauthenticated.") {
    //     this.messagesService.showError('Something went wrong.');
    //   }
    // })
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiesPolicyComponent } from './cookies-policy.component';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

// intro routing
export const routes: Routes = [
  { path: '', component: CookiesPolicyComponent },
];
@NgModule({
  declarations: [CookiesPolicyComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class CookiesPolicyModule { }

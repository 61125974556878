import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NamePipe } from './name.pipe';
import { FilterlistPipe } from './filter.pipe';

@NgModule({
    declarations: [
        FilterlistPipe,
        NamePipe
    ],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    exports: [
        FilterlistPipe,
        NamePipe
    ]
})
export class SharedModule { }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CompanyRegisterComponent } from './company-register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AwardBannerModule } from '../components/award-banner/award-banner.module';
import { BrowserModule } from '@angular/platform-browser';
import { ClickOutsideModule } from 'ng-click-outside';

// intro routing
export const routes: Routes = [
  { path: '', component: CompanyRegisterComponent },
];

@NgModule({
  declarations: [CompanyRegisterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    AwardBannerModule,
    BrowserModule,
    ClickOutsideModule
  ]
})
export class CompanyRegisterModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ThankYouComponent } from './thank-you.component';
import { AwardBannerModule } from '../components/award-banner/award-banner.module';

// intro routing
export const routes: Routes = [
  { path: '', component: ThankYouComponent },
];

@NgModule({
  declarations: [ThankYouComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AwardBannerModule
  ]
})
export class ThankYouModule { }

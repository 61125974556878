import { Component, OnInit } from '@angular/core';
import { MeadiaService } from '../services/meadia.service';
import { MessagesService } from '../services/messages.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  user_token: any;
  FaqListArray: any = [];
  selectedIndex; any;
  lazyLoad: boolean = false;
  constructor(private meadiaService: MeadiaService,
    private messagesService: MessagesService,
    private authService: AuthService) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.Faqlist();
    this.getuserToken();
  }
  
  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  Faqlist() {
    this.lazyLoad = true;
    this.meadiaService.FaqList(this.user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        console.log(res);
        this.FaqListArray = res.faqData;
        setTimeout(() => {
          var element = document.getElementById('collapse0');
          element.classList.add("show");
          var element1 = document.getElementById('card-link-0');
          element1.classList.add("collapsed");
          document.getElementById('card-link-0').setAttribute('aria-expanded', 'true');
        }, 200);
        this.lazyLoad = false;
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1500);
      })
  }
  modalToggle(index) {
    // Close all accordion
    document.querySelectorAll("a.dev-card-link").forEach((el) => {
      el.classList.remove("collapsed")
    })
    document.querySelectorAll("a.dev-card-link").forEach((el) => {
      el.setAttribute('aria-expanded', 'false');
    })
    document.querySelectorAll(".dev-collapse").forEach((el) => {
      el.classList.remove("show")
    })

    if (this.selectedIndex != index) {
      let collapseId = 'collapse' + index;
      let cardlinkId = 'card-link-' + index;
      // Open selected block
      document.querySelector("#" + collapseId).classList.add("show")
      document.querySelector("#" + cardlinkId).classList.add("collapsed")
      document.querySelector("#" + cardlinkId).setAttribute('aria-expanded', 'true');
      this.selectedIndex = index;
    } else {
      this.selectedIndex = null;
    }



    // this.selectedIndex = index;
    // let collapseId = 'collapse' + this.selectedIndex;
    // let cardlinkId = 'card-link-' + this.selectedIndex;
    // var element = document.getElementById(collapseId);
    // element.classList.toggle("show");
    // var element1 = document.getElementById(cardlinkId);
    // element1.classList.toggle("collapsed");
    // if (document.getElementById(cardlinkId).classList.contains('collapsed')) {
    //   document.getElementById(cardlinkId).setAttribute('aria-expanded', 'true');
    // }
    // else if (!document.getElementById(cardlinkId).classList.contains('collapsed')) {
    //   document.getElementById(cardlinkId).setAttribute('aria-expanded', 'false');
    // }
  }
}

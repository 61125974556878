import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JudgeProfileComponent } from './judge-profile.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// intro routing
export const routes: Routes = [
  { path: '', component: JudgeProfileComponent },
];
@NgModule({
  declarations: [JudgeProfileComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class JudgeProfileModule { }

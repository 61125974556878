import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { DashboardService } from '../../services/dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../services/excel.service';
import { saveAs as fileSaverSave } from 'file-saver'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { JudgeService } from '../../services/judge.service';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { appConfig } from '../../app.config';

@Component({
  selector: 'app-judge-archive',
  templateUrl: './judge-archive.component.html',
  styleUrls: ['./judge-archive.component.scss']
})
export class JudgeArchiveComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  show_final_report: boolean = false;
  leftbarselected: any;
  lazyLoad: boolean = false;
  user_token: any;
  api_token: any;
  juryArchiveDetails: any = [];
  licenseDownloadLink: any;
  juryyDocumentDownloadLink: any;
  logo_path: any;
  company_logo_path: any;
  closeResult: string;
  singleJuryDetails: any;
  ActivityLogData: any = [];
  assessorDocsData: any = [];
  submitted1: boolean = false;
  submitted2: boolean = false;
  processingText1: boolean = false;
  processingText2: boolean = false;
  approveForm: FormGroup;
  rejectForm: FormGroup;
  errMsg1: any;
  errMsg2: any;
  juryUploadDocumentLink: any;
  userDetails: any;
  archiveProcessing: boolean = false;
  assesorDocumentList: any = [];
  companyGeneralDocumentDownloadLink: any;
  criteriaName: any;
  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private excelService: ExcelService,
    private judgeService: JudgeService,
    private dialog: MatDialog) {
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd == true) {
          if (val.url == '/jury-archive')
            this.leftbarselected = 'Jury Archive';
          this.dataService.leftPanelSelection('judge-dashboard');
        }
      });
      this.user_token = localStorage.getItem('user_token');
      this.api_token = localStorage.getItem('api_token');
     }

  ngOnInit() {
        let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/jury-archive`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.approveForm = this.formBuilder.group({
      comments1: ['', Validators.required]
    });
    this.rejectForm = this.formBuilder.group({
      comments2: ['', Validators.required]
    });
    this.archivelist();
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  
  archivelist(){
    this.lazyLoad = true;
    this.dashboardService.archiveList(this.api_token).subscribe((res: any)=>{
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.juryArchiveDetails = res.jury_details;
        this.licenseDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        this.juryyDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';

        this.juryUploadDocumentLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/assessor_documents/';

        this.companyGeneralDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
        this.logo_path = res.logo_path;
        this.company_logo_path = res.company_logo_path;
        this.juryArchiveDetails.map((item) => {
          item.points = item.points.toFixed(1);
          item.all_total_points = item.all_total_points.toFixed(1);
          item['total_bar_percent'] = 0;
          item['total_bar_my_assessment_percent'] = 0;
          item.criteria.map((criteriaItem) => {
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            criteriaItem['my_assessment_percent_status'] = criteriaItem.bar_my_assessment_percent;
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
            item['total_bar_my_assessment_percent'] += criteriaItem.my_assessment_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
        })
        console.log(this.juryArchiveDetails);
      }
    }, err => {
      this.lazyLoad = false;
      console.log(err);
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  
  // convenience getter for easy access to form fields
  get f1() { return this.approveForm.controls; }

  // convenience getter for easy access to form fields
  get f2() { return this.rejectForm.controls; }
  open(content, juryItem, className) {
    let modalClassName = className;
    this.singleJuryDetails = juryItem;
    let award_Id = this.singleJuryDetails.award_year_id;
    if(this.singleJuryDetails.company_name.company_general_documents.length > 0){
      this.singleJuryDetails.company_name.company_general_documents = this.singleJuryDetails.company_name.company_general_documents.filter(x => x.award_year_id === award_Id);
    }

    if(this.singleJuryDetails.company_name.company_documents.length > 0){
    this.singleJuryDetails.company_name.company_documents = this.singleJuryDetails.company_name.company_documents.filter(x => x.award_year_id === award_Id);
    }
    this.ActivityLogData = juryItem.ActivityLog_datas;
    this.assessorDocsData = juryItem.assessor_docs;
    this.criteriaName = '';
    this.submitted1 = false;
    this.submitted2 = false;
    this.approveForm.reset();
    this.rejectForm.reset();
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder' , centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openshow_final_report() {
    this.show_final_report = !this.show_final_report;
  }
  closeshow_final_report() {
    this.show_final_report = !this.show_final_report;
  }
  goToAssesmentPoint(juryItem) {
    this.router.navigate(['jury-assessment', juryItem.company_id, juryItem.award_id, juryItem.group_id, juryItem.group_name.group_leader_id,juryItem.assessor_group_id]);
  }
  exportAsXLSX(singleJuryDetails): void {
    let data = [{
      'Name': singleJuryDetails.company_name.company_name,
      'Type': singleJuryDetails.company_name.industry.trans_data,
      'Year in UAE': singleJuryDetails.company_name.year_in_uae.title,
      'Email': singleJuryDetails.company_name.user.email,
      'Address': singleJuryDetails.company_name.head_office_addr,
      'Mobile': singleJuryDetails.company_name.mob_phone,
      'PB NO': singleJuryDetails.company_name.po_box,
      'Number of Branch': singleJuryDetails.company_name.branch.title,
      // 'Location': singleJuryDetails.company_name.user.location.trans_data,
      'Location': '',
      'Website': singleJuryDetails.company_name.website,
      'CEO/GM': singleJuryDetails.company_name.ceo_prefix + singleJuryDetails.company_name.ceo_firstname + singleJuryDetails.company_name.ceo_lastname,
      'Representative': singleJuryDetails.company_name.representative_prefix +
        singleJuryDetails.company_name.representative_firstname +
        singleJuryDetails.company_name.representative_lastname,

      'Registration Date and Time': this.datepipe.transform(singleJuryDetails.company_name.user.created_at, 'dd MMM yy h:mm a')
    }]

    this.excelService.exportAsExcelFile(data, singleJuryDetails.company_name.company_name);
  }
  formOneSubmit() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.approveForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleJuryDetails.award_id,
      'company_id': this.singleJuryDetails.company_id,
      'group_id': this.singleJuryDetails.group_id,
      'status': 'A',
      'comment': this.approveForm.value.comments1
    }
    if (this.user_token != null) {
      this.errMsg1 = '';
      this.processingText1 = true;
      this.judgeService.allAssesmentStatusChange(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingText1 = false;
          this.juryArchiveDetails.map((item) => {
            if (item.company_id == this.singleJuryDetails.company_id) {
              item.award_user.accepted_report_status = 'A';
            }
          })
          this.approveForm.reset();
          this.modalService.dismissAll();
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg1 = res.msg;
          setTimeout(() => {
            this.errMsg1 = '';
          }, 2500);
        }
      },
        err => {
          this.processingText1 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg1 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg1 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
    else if (this.user_token == null) {
      this.errMsg1 = 'User token is missing,Please go to the home page';
      setTimeout(() => {
        this.errMsg1 = '';
      }, 2000);
    }
  }
  formTwoSubmit() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.rejectForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'award_id': this.singleJuryDetails.award_id,
      'company_id': this.singleJuryDetails.company_id,
      'group_id': this.singleJuryDetails.group_id,
      'status': 'R',
      'Comments': this.rejectForm.value.comments2
    }
    if (this.user_token != null) {
      this.errMsg2 = '';
      this.processingText2 = true;
      this.judgeService.allAssesmentStatusChange(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingText2 = false;
          this.juryArchiveDetails.map((item) => {
            if (item.company_id == this.singleJuryDetails.company_id) {
              item.award_user.accepted_report_status = 'R';
            }
          })
          this.rejectForm.reset();
          this.modalService.dismissAll();
          this.messagesService.showSuccess(res.msg);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        }
        if (res.has_error == 1) {
          this.errMsg2 = res.msg;
          setTimeout(() => {
            this.errMsg2 = '';
          }, 2500);
        }
      },
        err => {
          this.processingText2 = false;
          if (err.error.message == "Unauthenticated.") {
            this.modalService.dismissAll();
          }
          if (err.error.message != "Unauthenticated.") {
            this.errMsg2 = 'Something went wrong.';
            setTimeout(() => {
              this.errMsg2 = '';
            }, 2000);
          }
          setTimeout(() => {
            this.messagesService.clear();
          }, 2500);
        })
    }
    else if (this.user_token == null) {
      this.errMsg2 = 'User token is missing,Please go to the home page';
      setTimeout(() => {
        this.errMsg2 = '';
      }, 2000);
    }
  }
  openDialog(juryItem) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you submit Final report?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.submitToAwardOffice(juryItem);
      }
    });
  }
  submitToAwardOffice(juryItem) {
    console.log(juryItem)
    let data = {
      award_id: juryItem.award_id,
      company_id: juryItem.company_id,
      group_id: juryItem.group_id,
      assessor_group_id: juryItem.assessor_group_id
    }
    this.judgeService.SubmitFinalreport(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.juryArchiveDetails.map((item) => {
          if (item.company_id == juryItem.company_id) {
            item.final_submission_status = 'Y';
          }
        })
        this.messagesService.showSuccess(res.msg);
      }

    })
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyDocumentComponent } from './company-document.component';
import {MatTooltipModule} from '@angular/material/tooltip';
// intro routing
export const routes: Routes = [
  { path: '', component: CompanyDocumentComponent },
];

@NgModule({
  declarations: [CompanyDocumentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ]
})
export class CompanyDocumentModule { }

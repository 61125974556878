import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JuryAssignMgmtComponent } from './jury-assign-mgmt.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// intro routing
export const routes: Routes = [
  { path: '', component: JuryAssignMgmtComponent },
];

@NgModule({
  declarations: [JuryAssignMgmtComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class JuryAssignMgmtModule { }

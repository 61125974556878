import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { JudgeService } from '../../services/judge.service';
import { MessagesService } from '../../services/messages.service';
import { appConfig } from '../../app.config';
@Component({
  selector: 'app-all-participated-award',
  templateUrl: './all-participated-award.component.html',
  styleUrls: ['./all-participated-award.component.scss']
})
export class AllParticipatedAwardComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  userDetails: any;
  lazyLoad: boolean = false;
  participatedAwardArray: any = [];
  constructor(private router: Router,
    private dataService: DataService,
    private judgeService: JudgeService,
    private messagesService: MessagesService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/all-participated-award')
          this.leftbarselected = 'All Participated Award';
        this.dataService.leftPanelSelection('all_participated_award');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/all-participated-award`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.participatedAwardList();
  }
  participatedAwardList() {
    this.lazyLoad = true;
    this.judgeService.participatedAwardlist(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.participatedAwardArray = res.allParticipatedAwards;
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { group } from '@angular/animations';

@Component({
  selector: 'app-office-group-mgmt',
  templateUrl: './office-group-mgmt.component.html',
  styleUrls: ['./office-group-mgmt.component.scss']
})
export class OfficeGroupMgmtComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  show_edit_form: boolean = false;
  // panelExpanded2: boolean = false;
  lazyLoad: boolean = false;
  awardOfficeGrouplist: any = [];
  loadingCount: number = 0;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  officeGroupForm: FormGroup;
  submitted: boolean = false;
  processingText: boolean = false;
  errorMessageValue: any;
  groupMemberId: any;
  awardOfficelist: any = [];
  allSelectedMemberData: any = [];
  singleGroupDetails: any;
  awardErr: any;
  companymanagementListArray: any =[];
  permissionMsg: any;
  permissionStatus: boolean = false;
  searchGroupName: any;
  searchCompanyName: any;
  allSelectedMemberId: any =[];
  settings = {};

  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/award-office-group-MGMT')
          this.leftbarselected = 'Award Office Group Management';
        this.dataService.leftPanelSelection('award_office_group_mgmt');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-group-MGMT`]);
    }
    this.officeGroupForm = this.formBuilder.group({
      group_name: ['', [Validators.required]],
      company_detail: ['', [Validators.required]],
      award_name: [''],
      year: ['']
    });
    this.awardOfficeGroupList();
    this.companymanagementList();

    this.settings = {
      singleSelection: false,
      text: "Select Menu",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }
  get f() { return this.officeGroupForm.controls; }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  openshow_edit_form(groupItem) {
    this.awardErr = '';
    this.allSelectedMemberData = [];
    this.singleGroupDetails = groupItem;
    console.log(groupItem);
    this.officeGroupForm.patchValue({
      group_name: this.singleGroupDetails.group_name,
      company_detail: this.singleGroupDetails.company_detail,
      award_name: this.singleGroupDetails.award_year.title,
      year: this.singleGroupDetails.award_year.year
    });
    if (this.singleGroupDetails.group_members.length > 0) {
      this.singleGroupDetails.group_members.map((item) => {
        item['itemName'] = item.name ;
        this.allSelectedMemberData.push(item);
      })
    }
    this.panelExpanded = false;
    console.log(this.allSelectedMemberData)
    this.show_edit_form = true;
  }
  closeshow_edit_form() {
    this.show_edit_form = false;
  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }
  awardOfficeGroupList() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.officeMemberGroupList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.awardOfficeGrouplist = res.awardOfficeGroup;
        this.awardOfficelist = res.awardOfficeList;
        this.awardOfficelist.map((item) => {
          item['itemName'] = item.name ;
        })
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  onItemSelect(item: any) {
    console.log(item);
    // console.log(this.allSelectedMemberData);
    if (this.allSelectedMemberData.length == 0) {
      this.awardErr = 'Please Select One Member';
    }
    else if (this.allSelectedMemberData.length > 0) {
      this.awardErr = '';
    }
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    // console.log(this.allSelectedMemberData);
    if (this.allSelectedMemberData.length == 0) {
      this.awardErr = 'Please Select One Member';
    }
    else if (this.allSelectedMemberData.length > 0) {
      this.awardErr = '';
    }
  }
  onSelectAll(items: any) {
    console.log(items);
    if (this.allSelectedMemberData.length == 0) {
      this.awardErr = 'Please Select One Member';
    }
    else if (this.allSelectedMemberData.length > 0) {
      this.awardErr = '';
    }
    // console.log('allSelectedMemberData',this.allSelectedMemberData);
  }
  onDeSelectAll(items: any) {
    console.log(items);
    if (this.allSelectedMemberData.length == 0) {
      this.awardErr = 'Please Select One Member';
    }
    else if (this.allSelectedMemberData.length > 0) {
      this.awardErr = '';
    }
    // console.log('allSelectedMemberData',this.allSelectedMemberData);
  }

  selectSubadmin($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.allSelectedMemberData.indexOf(Item.id) == -1) {
        this.allSelectedMemberData.push(Item.id);
      }
      if (this.allSelectedMemberData.length > 0) {
        this.awardErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.allSelectedMemberData.splice(this.allSelectedMemberData.indexOf($event.srcElement.value * 1), 1);
      if (this.allSelectedMemberData.length == 0) {
        this.awardErr = 'Please Select One Member';
      }
    }
    // console.log(this.allSelectedMemberData)
  }
  updateGroup() {
    this.submitted = true;
    if (this.allSelectedMemberData.length == 0) {
      this.awardErr = 'Please Select One Member';
    }
    this.allSelectedMemberId = [];
    for(let item of this.allSelectedMemberData){
      this.allSelectedMemberId.push(item.id)
    }
    // console.log(this.allSelectedMemberData)
    // stop here if form is invalid
    if (this.officeGroupForm.invalid) {
      return;
    }
    let data = {
      'user_token': this.user_token,
      'group_id': this.singleGroupDetails.id,
      'group_name': this.officeGroupForm.value.group_name,
      'award_office_ids': this.allSelectedMemberId,
      'company_detail': this.officeGroupForm.value.company_detail
    }
    console.log(this.allSelectedMemberId)
    if (this.allSelectedMemberData.length > 0) {
      this.awardErr = '';
      this.processingText = true;
      this.dashboardService.editOfficeGroup(this.api_token, data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingText = false;
          this.panelExpanded = false;
          this.closeshow_edit_form();
          this.awardOfficeGroupList();
          this.allSelectedMemberData = [];
          this.submitted = false;
          this.officeGroupForm.reset();

          this.messagesService.showSuccess('Office Group Updated Successfully.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
      },
        error => {
          this.processingText = false;
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        })
    }
    else if (this.allSelectedMemberData.length == 0) {
      this.awardErr = 'Please Select One Member';
    }
  }
  searchOfficeGroup(){
    let data = {
      group_name: this.searchGroupName
    }
    this.dashboardService.seachAwardOfficeGroup(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardOfficeGrouplist = res.awardOfficeGroup;
        this.awardOfficelist = res.awardOfficeList;
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  closeAddForm() {
    this.show_edit_form = false;
    this.panelExpanded = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { WinnerService } from '../../services/winner.service';


@Component({
  selector: 'app-award-apply',
  templateUrl: './award-apply.component.html',
  styleUrls: ['./award-apply.component.scss']
})
export class AwardApplyComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;

  awardimageIcon: any;
  awardListArray: any = [];
  pageNumber: number = 1;
  totalNumber: number = 1;
  thumImagePath: any;
  lazyLoad: boolean = false;
  award_image_path: any;
  awardTypes: any = [];
  searchAwardType: any = '';
  searchName: any = '';
  loadingCount: number = 0;


  loadSearch: boolean = false;
  awardSearch: boolean = false;
  awardShow: boolean = false;
  windowScroll: boolean = false;
  WithoutPageReload: boolean = false;
  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private winnerService: WinnerService) { 
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/company-award-apply')
          this.leftbarselected = 'Apply';
        this.dataService.leftPanelSelection('company-award-apply');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-award-apply`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.onScroll();
  }

  awardlist() {
    if (this.pageNumber == 1) {
      if (this.WithoutPageReload == false) {
        this.lazyLoad = true;
      }
      if (this.WithoutPageReload == true) {
        this.awardShow = true;
      }
      let per_page = 6;
      let page_no = this.pageNumber;
      this.winnerService.awardList(this.user_token, per_page, page_no).subscribe((res: any) => {
        if (res.has_error == 0) {
          if(this.WithoutPageReload == false){
            this.lazyLoad = false;
          }
          if (this.WithoutPageReload == true) {
            this.awardShow = false;
          }
          this.loadingCount = 1;
          this.awardListArray = res.award_data;
          this.award_image_path = res.award_image_path;
          this.awardimageIcon = res.image_path;
          this.totalNumber = res.total_no_of_award;
          this.awardTypes = res.awardTypes;

          this.pageNumber = 2;
        }
      },
        err => {
          if(this.WithoutPageReload == false){
            this.lazyLoad = false;
          }
          if (this.WithoutPageReload == true) {
            this.awardShow = false;
          }
        })
    }
    else if (this.pageNumber > 1) {
      let per_page = 6;
        this.pageNumber++;
      let skip = this.pageNumber -1;
      this.windowScroll = true;
      this.winnerService.awardList(this.user_token, per_page, skip).subscribe((res: any)=> {
        // if (res.has_error == 0) {
          res.award_data.map((item) => {
            this.awardListArray.push(item)
          })
          this.totalNumber = res.total_no_of_award;
          this.windowScroll = false;
        // }
      },
        err => {
          this.windowScroll = false;
        })
    }
  }
  awardFilter(param) {
    if (param == 'search') {
      this.awardListArray = [];

      this.pageNumber = 1;
      let per_page = 6;
      this.loadSearch = true;
      this.awardSearch = true;
      this.awardShow = true;
      this.winnerService.awarSearch(this.user_token,per_page, this.pageNumber, this.searchAwardType,this.searchName).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.awardListArray = res.award_data;
          this.awardShow = false;
          this.totalNumber = res.total_no_of_award;
          this.pageNumber = 2;
        }
      }, err => {
        this.awardShow = false;
      })
    }
    else if (param = 'scroll') {
      let per_page = 6;
      this.pageNumber++;
      let skip = this.pageNumber - 1;
      this.awardSearch = true;
      this.windowScroll = true;
      this.winnerService.awarSearch(this.user_token, per_page, skip, this.searchAwardType,this.searchName).subscribe((response: any) => {
        // console.log('response', response);
        if (response.has_error == 0) {
          this.totalNumber = response.total_no_of_award;
          response.award_data.map((item) => {
            this.awardListArray.push(item);
          });
          this.windowScroll = false;
        }
      }, error => {
        this.windowScroll = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800)
        // console.error(error);
      });

    }
  }
  onScroll() {
    // this.loadMoreAwards();
    console.log('scrolled!!');
    if (this.awardSearch == false) {
      this.awardlist();
    }
    else if (this.awardSearch == true) {
      this.awardFilter('scroll');
    }
  }
}

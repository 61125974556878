import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JudgeService } from '../../services/judge.service';


@Component({
  selector: 'app-assessor-group',
  templateUrl: './assessor-group.component.html',
  styleUrls: ['./assessor-group.component.scss']
})
export class AssessorGroupComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  showAddForm: boolean = false;
  assessorGroupList: any = [];
  lazyLoad: boolean = false;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  companymanagementListArray: any = [];
  groupCreationDatalist: any = [];
  assessorGroupForm: FormGroup;
  submitted: boolean = false;
  formAction: any;
  processignText: boolean = false;
  loadingNumber: number = 0;
  singleAssessorGroupDeatils: any;
  allSelectedMemberData: any = [];
  memberErr: string;
  awardListArray: any = [];
  AssessorErrMsg: boolean = false;
  permissionMsg: any;
  permissionStatus: boolean = false;
  settings = {};
  settingsLeader = {};
  allSelectedMemberId: any = [];
  selectedTeamLeaderData: any = [];
  TeamLeaderErr: string;

  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/assessor-group')
          this.leftbarselected = 'Assessor Group';
        this.dataService.leftPanelSelection('assessor_group');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/assessor-group`]);
    }
    this.AssessorGroupList();
    this.companymanagementList();

    this.assessorGroupForm = this.formBuilder.group({
      group_name: ['', [Validators.required]],
      // teamleader: ['', [Validators.required]],
      award_year_id: ['', [Validators.required]]
    });

    this.settings = {
      singleSelection: false,
      text: "Select Assessor",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: "myclass custom-class-example"
    };
    // limitSelection: 4,
    this.settingsLeader = {
      singleSelection: true,
      text: "Select TeamLeader",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      classes: "myclass custom-class-example"
    };
  }

  get f() { return this.assessorGroupForm.controls; }

  AssessorGroupList() {
    if (this.loadingNumber == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.assessorGrouplist(this.api_token).subscribe((res: any) => {
      // if (res.has_error == 0) {
      this.assessorGroupList = res.assessor_group_list;
      this.lazyLoad = false;
      this.permissionStatus = false;
      this.permissionMsg = '';
      this.loadingNumber = 1;
      // }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }

  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  awardManagementList() {
    this.judgeService.awardmanagementlist(this.api_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.awardListArray = res.award_data;
        this.awardListArray = res.award_data.filter((thing, index, self) => index === self.findIndex((t) => (t.year_id === thing.year_id)))
        // console.log(this.awardListArray);
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }

  onChangeAward(value) {
    this.allSelectedMemberData = [];
    this.allSelectedMemberId = [];
    this.selectedTeamLeaderData = [],
      this.allSelectedMemberId = [];
    // this.assessorGroupForm.get('teamleader').reset();
    this.submitted = false;
    this.groupCreationlist(value);
  }

  groupCreationlist(year_id) {
    this.dashboardService.groupCreationList(this.api_token, year_id).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.AssessorErrMsg = true;
        this.groupCreationDatalist = res.assessor_list;
        this.groupCreationDatalist.map((item) => {
          item['itemName'] = item.name;
        })
      }
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }


  onItemSelect(item: any) {
    console.log(item);
    this.selectedTeamLeaderData = [];
    // console.log(this.allSelectedMemberData);
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Assessor';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    this.selectedTeamLeaderData = [];
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Assessor';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
    // console.log(this.allSelectedMemberData);
  }
  onSelectAll(items: any) {
    console.log(items);
    this.allSelectedMemberData = [];
    this.selectedTeamLeaderData = [];
    this.allSelectedMemberData = items;
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Assessor';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
    // console.log('allSelectedMemberData',this.allSelectedMemberData);
  }
  onDeSelectAll(items: any) {
    console.log(items);
    this.allSelectedMemberData = [];
    this.selectedTeamLeaderData = [];
    if(this.allSelectedMemberData.length == 0){
      this.memberErr = 'Please Select One Assessor';
    }
    if(this.allSelectedMemberData.length > 0){
      this.memberErr = '';
    }
    // console.log('allSelectedMemberData',this.allSelectedMemberData);
  }

  onItemSelectTeamLeader(item: any) {
    console.log(item);
    // console.log(this.selectedTeamLeaderData);
    if(this.selectedTeamLeaderData.length == 0){
      this.TeamLeaderErr = 'Please Select One Team Leader';
    }
    if(this.allSelectedMemberData.length > 0){
      this.TeamLeaderErr = '';
    }
  }
  OnItemDeSelectTeamLeader(item: any) {
    console.log(item);
    // console.log(this.selectedTeamLeaderData);
    if(this.selectedTeamLeaderData.length == 0){
      this.TeamLeaderErr = 'Please Select One Team Leader';
    }
    if(this.allSelectedMemberData.length > 0){
      this.TeamLeaderErr = '';
    }
  }

  selectAssessor($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.allSelectedMemberData.indexOf(Item.id) == -1) {
        this.allSelectedMemberData.push(Item.id);
      }
      if (this.allSelectedMemberData.length > 0) {
        this.memberErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.allSelectedMemberData.splice(this.allSelectedMemberData.indexOf($event.srcElement.value * 1), 1);
      if (this.allSelectedMemberData.length == 0) {
        this.memberErr = 'Please Select One Assessor';
      }
    }
    console.log(this.allSelectedMemberData);
  }

  addassessorGroup() {
    this.submitted = true;

    if (this.assessorGroupForm.invalid) {
      return;
    }

    this.allSelectedMemberId = [];
    for (let item of this.allSelectedMemberData) {
      this.allSelectedMemberId.push(item.id)
    }
    // console.log(this.selectedTeamLeaderData);
    if (this.formAction == 'add') {
      if ((this.allSelectedMemberData.length > 0) && (this.selectedTeamLeaderData.length > 0)) {
        let data = {
          group_name: this.assessorGroupForm.value.group_name,
          teamleader: this.selectedTeamLeaderData[0].id,
          award_year_id: this.assessorGroupForm.value.award_year_id,
          assessorInGroup: this.allSelectedMemberId
        }
        console.log(data);
        // if (this.allSelectedMemberData.length > 0) {
        this.memberErr = '';
        this.TeamLeaderErr = '';
        this.processignText = true;
        this.dashboardService.addAssessorGroup(this.api_token, data).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processignText = false;
            this.messagesService.showSuccess(res.msg);
            setTimeout(() => {
              this.messagesService.clear();
            }, 2000);
            this.AssessorGroupList();
            this.closeAssessorGroup();
          }
          if (res.has_error == 1) {
            this.processignText = false;
            this.messagesService.showError(res.msg);
          }
        }, err => {
          this.processignText = false;
          this.messagesService.showError('Something went wrong.')
        })
      }
      if (this.allSelectedMemberData.length == 0) {
        this.memberErr = 'Please Select One Assessor';
      }
      if (this.selectedTeamLeaderData.length == 0) {
        this.TeamLeaderErr = 'Please Select One Team Leader';
      }
    }
    if (this.formAction == 'edit') {
      if ((this.allSelectedMemberData.length > 0) && (this.selectedTeamLeaderData.length > 0)) {
        let data = {
          group_name: this.assessorGroupForm.value.group_name,
          teamleader: this.selectedTeamLeaderData[0].id,
          award_year_id: this.assessorGroupForm.value.award_year_id,
          assessorInGroup: this.allSelectedMemberId,
          group_id: this.singleAssessorGroupDeatils.id
        }
        console.log(data);
        // if (this.allSelectedMemberData.length > 0) {
        this.memberErr = '';
        this.TeamLeaderErr = '';
        this.processignText = true;
        this.dashboardService.editAssessorGroup(this.api_token, data).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.messagesService.showSuccess(res.msg);
            setTimeout(() => {
              this.messagesService.clear();
            }, 2000);
            this.processignText = false;
            this.AssessorGroupList();
            this.closeAssessorGroup();
          }
          if (res.has_error == 1) {
            this.processignText = false;
            this.messagesService.showError(res.msg);
          }
        }, err => {
          this.processignText = false;
          this.messagesService.showError('Something went wrong.')
        })
      }
      if (this.allSelectedMemberData.length == 0) {
        this.memberErr = 'Please Select One Assessor';
      }
      if (this.selectedTeamLeaderData.length == 0) {
        this.TeamLeaderErr = 'Please Select One Team Leader';
      }
    }
  }

  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }

  openAssessorGroupForm(action, groupItem) {
    this.showAddForm = true;
    this.formAction = action;
    this.panelExpanded = false;
    this.awardManagementList();
    if ((this.formAction == 'add') && (groupItem == null)) {
      this.singleAssessorGroupDeatils = null;
      this.assessorGroupForm.reset();
      this.allSelectedMemberData = [];
      this.groupCreationDatalist = [];
      this.allSelectedMemberId = [];
      this.selectedTeamLeaderData = [],
        this.allSelectedMemberId = [];
    }
    if ((this.formAction == 'edit') && (groupItem != null)) {
      this.singleAssessorGroupDeatils = groupItem;
      console.log(this.singleAssessorGroupDeatils);

      this.assessorGroupForm.patchValue({
        group_name: this.singleAssessorGroupDeatils.group_name,
        // teamleader: this.singleAssessorGroupDeatils.teamleader,
        award_year_id: this.singleAssessorGroupDeatils.award_year_id,
      });
      this.onChangeAward(this.singleAssessorGroupDeatils.award_year_id);
      this.allSelectedMemberData = [];
      this.selectedTeamLeaderData = [];
      this.singleAssessorGroupDeatils.accessors_in_groups.map((item) => {
        item['itemName'] = item.accessors_name.name;
        item['activity_logs_id'] = item.accessors_name.activity_logs_id,
          item['address'] = item.accessors_name.address,
          item['api_token'] = item.accessors_name.api_token,
          item['assessor'] = {}
        item['award_id'] = item.accessors_name.award_id,
          item['city'] = item.accessors_name.city,
          item['country_id'] = item.accessors_name.country_id,
          item['created_at'] = item.accessors_name.created_at,
          item['deleted_at'] = item.accessors_name.deleted_at,
          item['email'] = item.accessors_name.email,
          item['first_name'] = item.accessors_name.first_name,
          item['forget_password_link_status'] = item.accessors_name.forget_password_link_status,
          item['gender'] = item.accessors_name.gender,
          item['group_ids'] = item.accessors_name.group_ids,
          item['id'] = item.assessor_id,
          item['is_active'] = item.accessors_name.is_active,
          item['language_id'] = item.accessors_name.language_id,
          item['last_name'] = item.accessors_name.last_name,
          item['name'] = item.accessors_name.name,
          item['office_company'] = item.accessors_name.office_company,
          item['parent_id'] = item.accessors_name.parent_id,
          item['phone'] = item.accessors_name.phone,
          item['phone_number_code'] = item.accessors_name.phone_number_code,
          item['profile_picture'] = item.accessors_name.profile_picture,
          item['updated_at'] = item.accessors_name.updated_at,
          item['updated_by'] = item.accessors_name.updated_by,
          item['user_name'] = item.accessors_name.user_name,
          item['user_type'] = item.accessors_name.user_type,
          item['view_on_dashboard'] = item.view_on_dashboard,
          this.allSelectedMemberData.push(item);
        if (item.is_team_leader == 'Y') {
          this.selectedTeamLeaderData.push(item)
        }
        // this.allSelectedMemberData.push(item.assessor_id);
        // if (item.is_team_leader == 'Y') {
        //   this.assessorGroupForm.patchValue({
        //     teamleader: item.assessor_id
        //   });
        // }
      })
      console.log(this.allSelectedMemberData);
      console.log(this.selectedTeamLeaderData);
    }
  }

  closeAssessorGroup() {
    this.showAddForm = false;
    this.submitted = false;
  }
}

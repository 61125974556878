import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { JudgeArchiveComponent } from './judge-archive.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common'
import { ExcelService } from '../../services/excel.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/_helpers/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: JudgeArchiveComponent },
]; 
@NgModule({
  declarations: [JudgeArchiveComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgScrollbarModule
  ]
})
export class JudgeArchiveModule { }

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appConfig } from '../../app.config';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  showAddForm: boolean = false;
  show_approveaward_form: boolean = false;
  show_activiltylog: boolean = false;
  show_documents: boolean = false;
  show_general_documents: boolean = false;
  panelExpanded2: boolean = false;
  show_license: boolean = false;
  companymanagementListArray: any = [];
  panelExpanded: boolean = false;
  lazyLoad: boolean = false;
  loadingCount: number = 0;
  selectedIndex: any = -1;
  show_invoice_form: boolean = false;
  show_payment_form: boolean = false;
  show_companydetails_display_form: boolean = false;

  invoiceForm: FormGroup;
  paymentForm: FormGroup;
  submitted: boolean = false;
  submitted1: boolean = false;
  processingText: boolean = false;
  paymentProcessingText: boolean = false;
  emailArray: any = [];
  InvoiceErrMsg: string;
  singleCompanyDetails: any;
  paymentDetails: any;
  paymentDocumentpath: any;
  paymentStatusList: any = [];
  companyLogoPath: any;
  businessLicensePath: any;
  allCountryList: any = [];
  no_of_staff_List: any = [];
  years_in_uae_list: any = [];
  branchesList: any = [];
  NationalitiesList: any = [];
  sectorsList: any = [];
  IndustriesList: any = [];
  NamePrefixsList: any = [];
  loadingNumber: number = 0;
  searchName: any;
  searchEmail: any;
  companyManagementForm: FormGroup;
  submitted2: boolean = false;
  companyProcessingText: boolean = false;
  webSitePatternErr: boolean;
  BusinessLicense_Show: null;
  CompanyLogo_Show: null;
  errorMessageValue: any;
  display_profile_photo: any;
  profileImagePath: any;
  // businessLicensePath: any;
  formAction: any;
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  business_license: any;
  company_logo: any;
  award_id: any;
  contactCountryCodeErr: string;
  AuthorizedPrefixNameErr: string;
  CeoPrefixNameErr: string;
  mobileCountryCodeErr: string;
  business_licenseErr: string;
  Company_LogoErr: string;
  documentDownloadLink: any;
  generalDocumentDownloadLink: any;
  permissionMsg: any;
  permissionStatus: boolean = false;
  awardList: any = [];
  statusArray: any = [];
  disbleAward: boolean = false;
  fileTypeText: any;
  
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/company-management')
          this.leftbarselected = 'Company Management';
        this.dataService.leftPanelSelection('company_management');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/company-management`]);
    }
    this.invoiceForm = this.formBuilder.group({
      // award_id: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]]
      email: ['']
    });
    this.paymentForm = this.formBuilder.group({
      payment_status: ['', Validators.required],
    });

    this.companyManagementForm = this.formBuilder.group({
      award_type: ['', [Validators.required]],
      company_name: ['', [Validators.required]],
      industry: ['', [Validators.required]],
      sector: ['', [Validators.required]],
      no_of_staff: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      years_in_uae: ['', [Validators.required]],
      work_email: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      contact_Country_code: ['', [Validators.required]],
      website: ['', [Validators.required]],
      ceo_prefix: ['', [Validators.required]],
      ceo_first_name: ['', [Validators.required]],
      ceo_last_name: ['', [Validators.required]],
      authorized_representative_prefix: ['', [Validators.required]],
      authorized_representative: ['', [Validators.required]],
      representative_job_title: ['', [Validators.required]],
      mobile_Country_code: ['', [Validators.required]],
      mobile_number: ['', [Validators.required]],
      email: ['', [Validators.required]],
      company_profile_description: ['', [Validators.required]],
      head_office_address: ['', [Validators.required]],
    });
    this.companymanagementList();
    this.formlistData();
    this.callingCodes();
    this.paymentStatusList = [
      {
        name: 'Accepted',
        status: 'Y'
      },
      {
        name: 'Rejected',
        status: 'N'
      }
    ]
    this.statusArray = [
      {
        name: 'Active',
        status: 'Y'
      },
      {
        name: 'In-Active',
        status: 'N'
      },
      {
        name: 'Approved',
        status: 'A'
      },
      {
        name: 'Blocked',
        status: 'B'
      }
    ]
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
  }

  get f() { return this.invoiceForm.controls; }

  get f1() { return this.paymentForm.controls; }

  get f2() { return this.companyManagementForm.controls; }

  companymanagementList() {
    if (this.loadingNumber == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.loadingNumber = 1;
        this.companymanagementListArray = res.company_list;
        console.log(res);

        this.documentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';

        this.generalDocumentDownloadLink = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';

        this.paymentDocumentpath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/company_payment_docs/';
        this.companyLogoPath = res.profile_image_path;
        this.businessLicensePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  formlistData() {
    this.dashboardService.formList(this.api_token).subscribe((res: any) => {
      console.log(res);
      this.allCountryList = res.all_country;
      this.no_of_staff_List = res.no_of_staff;
      this.years_in_uae_list = res.years_in_uae;
      this.branchesList = res.branches;
      this.NationalitiesList = res.Nationalities;
      this.sectorsList = res.sectors;
      this.IndustriesList = res.Industries;
      this.NamePrefixsList = res.NamePrefixs;
      this.awardList = res.awardList;

    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  searchCompany() {
    let data = {
      searchName: this.searchName,
      searchEmail: this.searchEmail,
    }
    this.dashboardService.filterCompanyManagementList(this.api_token, data).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        this.companymanagementListArray = res.company_list;
        // console.log(res);
        this.paymentDocumentpath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/company_payment_docs/';
        this.companyLogoPath = res.profile_image_path;
        this.businessLicensePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/evidence/';
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  onChangeStatus($event, companyItem) {
    let status = $event;
    let data = {
      'user_id': companyItem.user_id,
      'status': status
    }
    this.dashboardService.statusChange(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.messagesService.showSuccess('Status Changes Successfully');
        this.companymanagementList();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  openshow_Invoice_form(companyItem) {
    this.show_invoice_form = true;
    this.singleCompanyDetails = companyItem;
    this.panelExpanded = false;
  }
  closeshow_Invoice_form() {
    this.show_invoice_form = false;
    this.emailArray = [];
    this.invoiceForm.reset();
    this.InvoiceErrMsg = '';
    this.panelExpanded = false;
  }
  openshow_Payment_Action_form(companyItem) {
    this.show_payment_form = true;
    this.paymentDetails = companyItem;
    this.panelExpanded = false;
  }
  closeshow_Payment_Action_form() {
    this.show_payment_form = false;
    this.panelExpanded = false;
  }
  openshow_ComapnyDetails_form(companyItem) {
    this.show_companydetails_display_form = true;
    this.singleCompanyDetails = companyItem;
    this.panelExpanded = false;
    console.log(this.singleCompanyDetails);
    this.singleCompanyDetails.company_details.industry_name = this.IndustriesList.find((x) => x.industries_id === this.singleCompanyDetails.company_details.industry_id).trans_data;

    this.singleCompanyDetails.company_details.no_of_staff_name = this.no_of_staff_List.find((x) => x.id === Number(this.singleCompanyDetails.company_details.no_of_staff)).title;

    this.singleCompanyDetails.company_details.branch_name = this.branchesList.find((x) => x.id === Number(this.singleCompanyDetails.company_details.no_of_branch)).title;

    this.singleCompanyDetails.company_details.years_in_uae_name = this.years_in_uae_list.find((x) => x.id === Number(this.singleCompanyDetails.company_details.years_in_uae)).title;

    this.singleCompanyDetails.company_details.country_name = this.allCountryList.find((x) => x.nationalities_id === this.singleCompanyDetails.user.country_id).trans_data;

    this.singleCompanyDetails.company_details.ceo_prefix_name = this.NamePrefixsList.find((x) => x.NamePrefix_id === Number(this.singleCompanyDetails.company_details.ceo_prefix)).Prefix_name;

    this.singleCompanyDetails.company_details.representative_prefix_name = this.NamePrefixsList.find((x) => x.NamePrefix_id === Number(this.singleCompanyDetails.company_details.representative_prefix)).Prefix_name;

    this.singleCompanyDetails.company_details.sector_name = this.sectorsList.find((x) => x.sector_id === this.singleCompanyDetails.company_details.sector_id).trans_data;
    console.log(this.singleCompanyDetails)
  }
  closeshow_ComapnyDetails_form() {
    this.show_companydetails_display_form = false;
    this.panelExpanded = false;
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }

  onChangeContactCode(event) {
    if (event == '') {
      this.contactCountryCodeErr = 'Country code of Contact Number is required';
    }
    if (event != '') {
      this.contactCountryCodeErr = '';
    }
  }
  onChangeMobileCode(event) {
    if (event == '') {
      this.mobileCountryCodeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.mobileCountryCodeErr = '';
    }
  }
  onChangeAuthorizedPrefix(event) {
    if (event == '') {
      this.AuthorizedPrefixNameErr = 'Authorized Representative Prefix Name is required';
    }
    if (event != '') {
      this.AuthorizedPrefixNameErr = '';
    }
  }
  onChangeCeoPrefix(event) {
    if (event == '') {
      this.CeoPrefixNameErr = 'CEO Prefix Name is required';
    }
    if (event != '') {
      this.CeoPrefixNameErr = '';
    }
  }
  deleteEmail(index) {
    this.emailArray.splice(index, 1)
  }
  addEmail() {
    this.emailArray.push(this.invoiceForm.value.email);
    this.invoiceForm.reset();
    this.InvoiceErrMsg = '';
  }
  generateInvoice() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.invoiceForm.invalid) {
      return;
    }

    let data = {
      id: this.singleCompanyDetails.id,
      email: this.emailArray
    }
    if (this.emailArray.length > 0) {
      this.processingText = true;
      this.dashboardService.invoiceGenerate(this.api_token, data).subscribe((res: any) => {
        console.log(res)
        if (res.has_error == 0) {
          this.processingText = false;
          this.messagesService.showSuccess(res.msg);
          this.companymanagementList();
          this.submitted = false;
          this.show_invoice_form = false;
          this.invoiceForm.reset();
          this.panelExpanded = false;
          this.emailArray = [];
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingText = false;
        }
      },
        err => {
          this.processingText = false;
          this.messagesService.showError('Something went wrong.');
        });
    }

    else if (this.emailArray.length == 0) {
      this.InvoiceErrMsg = 'please enter atleast one email';
    }
  }
  paymentAction() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.invoiceForm.invalid) {
      return;
    }

    let data = {
      award_user_id: this.paymentDetails.id,
      status: this.paymentForm.value.payment_status
    }

    this.paymentProcessingText = true;
    this.dashboardService.paymentUpdate(this.api_token, data).subscribe((res: any) => {
      console.log(res)
      if (res.has_error == 0) {
        this.paymentProcessingText = false;
        this.messagesService.showSuccess(res.msg);
        this.companymanagementList();
        this.submitted1 = false;
        this.show_payment_form = false;
        this.paymentForm.reset();
        this.panelExpanded = false;
      }
      if (res.has_error == 1) {
        this.messagesService.showError(res.msg);
        this.paymentProcessingText = false;
      }
    },
      err => {
        this.paymentProcessingText = false;
        this.messagesService.showError('Something went wrong.');
      });

  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }
  openDialogApprove(companyItem) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure to approve for ' + companyItem.award_year.title + ' ' + companyItem.award_year.year + ' award?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let awardId = [];
        awardId.push(companyItem.year_id)
        let data = {
          award_year_id: awardId,
          user_id: companyItem.user_id
        }
        this.dashboardService.approveAward(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.messagesService.showSuccess(res.msg);
            this.companymanagementList();
          }
        }, err => {
          this.messagesService.showError('Something went wrong');
        })
      }
    });
  }
  openAddForm(action, companyItem) {
    document.getElementById("form_open").style.display = "block";
    document.getElementById("form_close").style.display = "none";
    this.showAddForm = true;
    this.panelExpanded = false;
    this.submitted2 = false;
    this.formAction = action;
    if ((this.formAction == 'add') && (companyItem == null)) {
      this.disbleAward = false;
      this.singleCompanyDetails = null;
      this.companyManagementForm.reset();
      this.business_license = null;
      this.company_logo = null;
      this.display_profile_photo = '';
      this.CompanyLogo_Show = null;
      this.BusinessLicense_Show = null;
      this.Company_LogoErr = '';
      this.business_licenseErr = '';
    }
    if ((this.formAction == 'edit') && (companyItem != null)) {
      this.disbleAward = true;
      this.singleCompanyDetails = companyItem;
      console.log(this.singleCompanyDetails);
      this.CompanyLogo_Show = null;
      this.BusinessLicense_Show = null;
      this.Company_LogoErr = '';
      this.business_licenseErr = '';
      // this.award_id = this.singleCompanyDetails.award_id;
      this.business_license = this.singleCompanyDetails.company_details.business_license;
      this.company_logo = this.singleCompanyDetails.company_details.company_logo;
      this.display_profile_photo = this.singleCompanyDetails.company_details.company_logo != null ? this.companyLogoPath + this.singleCompanyDetails.company_details.company_logo : 'assets/images/default-user.png';


      this.companyManagementForm.patchValue({
        award_type: this.singleCompanyDetails.award_year.id,
        company_name: this.singleCompanyDetails.company_details.company_name,
        industry: this.singleCompanyDetails.company_details.industry_id,
        sector: this.singleCompanyDetails.company_details.sector_id,
        no_of_staff: this.singleCompanyDetails.company_details.no_of_staff,
        branch: this.singleCompanyDetails.company_details.no_of_branch,
        country: this.singleCompanyDetails.user.country_id,
        city: this.singleCompanyDetails.user.city,
        postal_code: this.singleCompanyDetails.company_details.po_box,
        years_in_uae: this.singleCompanyDetails.company_details.years_in_uae,
        work_email: this.singleCompanyDetails.company_details.Work_E_mail,
        contact_number: this.singleCompanyDetails.user.phone,
        contact_Country_code: this.singleCompanyDetails.company_details.country_code,
        website: this.singleCompanyDetails.company_details.website,
        ceo_prefix: this.singleCompanyDetails.company_details.ceo_prefix,
        ceo_first_name: this.singleCompanyDetails.company_details.ceo_firstname,
        ceo_last_name: this.singleCompanyDetails.company_details.ceo_lastname,
        authorized_representative_prefix: this.singleCompanyDetails.company_details.representative_prefix,
        authorized_representative: this.singleCompanyDetails.company_details.representative_firstname,
        representative_job_title: this.singleCompanyDetails.company_details.job_title,
        mobile_Country_code: this.singleCompanyDetails.company_details.mob_country_code,
        mobile_number: this.singleCompanyDetails.company_details.mob_phone,
        email: this.singleCompanyDetails.user.email,
        company_profile_description: this.singleCompanyDetails.company_details.description,
        head_office_address: this.singleCompanyDetails.company_details.head_office_addr
      });
    }
  }
  closeAddForm() {
    this.showAddForm = false;
    document.getElementById("form_open").style.display = "none";
    document.getElementById("form_close").style.display = "block";
  }
  openshow_approveaward_form() {
    this.show_approveaward_form = true;
  }
  closeshow_approveaward_form() {
    this.show_approveaward_form = false;
  }

  openshow_activiltylog_form() {
    this.show_activiltylog = true;
  }
  closeshow_activiltylog_form() {
    this.show_activiltylog = false;
  }
  openshow_documents_form(companyItem) {
    this.singleCompanyDetails = companyItem;
    this.panelExpanded = false;
    this.show_documents = true;
  }
  closeshow_documents_form() {
    this.show_documents = false;
  }
  openshow_generaldocuments_form(companyItem) {
    this.singleCompanyDetails = companyItem;
    this.panelExpanded = false;
    this.show_general_documents = true;
  }
  closeshow_generaldocuments_form() {
    this.show_general_documents = false;
  }
  openshow_edit_form() {

  }
  closeshow_license_form() {

  }
  onSelectBusinessLicense(event) {
    if (event.target.files && event.target.files[0]) {
      this.BusinessLicense_Show = event.target.files[0];
      if (this.BusinessLicense_Show != null) {
        this.business_licenseErr = '';
      }
      // console.log(this.profile_CV)
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  onSelectCompanyLogo(event) {
    if (event.target.files && event.target.files[0]) {
      this.CompanyLogo_Show = event.target.files[0];
      console.log(this.CompanyLogo_Show);
      if (this.CompanyLogo_Show != null) {
        this.Company_LogoErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
        // console.log(this.display_profile_photo)
      }
    }
  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  updateprofile() {
    this.submitted2 = true;

    if ((this.business_license == null) && (this.BusinessLicense_Show == null)) {
      this.business_licenseErr = 'Business License is required';
    }
    if ((this.company_logo == null) && (this.CompanyLogo_Show == null)) {
      this.Company_LogoErr = 'Company Logo is required';
    }
    if ((this.companyManagementForm.value.authorized_representative_prefix == null) && (this.companyManagementForm.value.authorized_representative != null)) {
      this.AuthorizedPrefixNameErr = 'Authorized Representative Prefix Name is required';
    }
    if ((this.companyManagementForm.value.ceo_prefix == null) && (this.companyManagementForm.value.ceo_first_name != null)) {
      this.CeoPrefixNameErr = 'CEO Prefix Name is required';
    }
    if ((this.companyManagementForm.value.mobile_Country_code == null) && (this.companyManagementForm.value.mobile_number != null)) {
      this.mobileCountryCodeErr = 'Country code of Mobile Number is required';
    }
    if ((this.companyManagementForm.value.contact_Country_code == null) && (this.companyManagementForm.value.contact_number != null)) {
      this.contactCountryCodeErr = 'Country code of Contact Number is required';
    }

    // stop here if form is invalid
    if (this.companyManagementForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('company_name', this.companyManagementForm.value.company_name),
      formData.append('industry_id', this.companyManagementForm.value.industry),
      formData.append('sector_id', this.companyManagementForm.value.sector),
      formData.append('city', this.companyManagementForm.value.city),
      formData.append('country_id', this.companyManagementForm.value.country),
      formData.append('no_of_staff', this.companyManagementForm.value.no_of_staff),
      formData.append('po_box', this.companyManagementForm.value.postal_code),
      formData.append('years_in_uae', this.companyManagementForm.value.years_in_uae),
      formData.append('website', this.companyManagementForm.value.website),
      formData.append('no_of_branch', this.companyManagementForm.value.branch),
      formData.append('phone', this.companyManagementForm.value.contact_number),
      formData.append('country_code', this.companyManagementForm.value.contact_Country_code),
      formData.append('Work_E_mail', this.companyManagementForm.value.work_email)
    formData.append('email', this.companyManagementForm.value.email)

    formData.append('ceo_prefix', this.companyManagementForm.value.ceo_prefix),
      formData.append('ceo_firstname', this.companyManagementForm.value.ceo_first_name),
      formData.append('ceo_lastname', this.companyManagementForm.value.ceo_last_name),
      formData.append('representative_prefix', this.companyManagementForm.value.authorized_representative_prefix),
      formData.append('representative_firstname', this.companyManagementForm.value.authorized_representative),
      formData.append('job_title', this.companyManagementForm.value.representative_job_title),
      formData.append('mob_country_code', this.companyManagementForm.value.mobile_Country_code),
      formData.append('mob_phone', this.companyManagementForm.value.mobile_number)
    formData.append('description', this.companyManagementForm.value.company_profile_description),
      formData.append('head_office_addr', this.companyManagementForm.value.head_office_address),

      formData.append('delegate1_prefix', null),
      formData.append('delegate1_firstname', ''),
      formData.append('delegate1_lastname', ''),
      formData.append('delegate2_prefix', null),
      formData.append('delegate2_firstname', ''),
      formData.append('delegate2_lastname', '');
    if (this.BusinessLicense_Show != null) {
      formData.append('business_license', this.BusinessLicense_Show)
    }
    if (this.BusinessLicense_Show == null) {
      formData.append('business_license', this.business_license)
    }
    if (this.CompanyLogo_Show != null) {
      formData.append('company_logo', this.CompanyLogo_Show)
    }
    if (this.CompanyLogo_Show == null) {
      formData.append('company_logo', this.company_logo)
    }
    if (this.formAction == 'add') {
      formData.append('award_id', this.companyManagementForm.value.award_type)
    }
    if (this.formAction == 'edit') {
      formData.append('company_id', this.singleCompanyDetails.company_details.user_id)
    }


    if ((this.business_license != null || this.BusinessLicense_Show != null)
      && (this.company_logo != null || this.CompanyLogo_Show != null) && (this.companyManagementForm.value.authorized_representative_prefix != '') && (this.companyManagementForm.value.ceo_prefix != '') && (this.companyManagementForm.value.mobile_Country_code != '') && (this.companyManagementForm.value.contact_Country_code != '')) {
      this.companyProcessingText = true;
      if (this.formAction == 'add') {
        this.dashboardService.addCompanyProfile(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.companyProcessingText = false;
            this.companymanagementList();
            this.closeAddForm();
            this.CompanyLogo_Show = null;
            this.BusinessLicense_Show = null;
            this.messagesService.showSuccess('Company Added successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.companyProcessingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.companyProcessingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
      }

      if (this.formAction == 'edit') {
        this.dashboardService.editCompanyProfile(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.companyProcessingText = false;
            this.companymanagementList();
            this.closeAddForm();
            this.CompanyLogo_Show = null;
            this.BusinessLicense_Show = null;
            this.messagesService.showSuccess('Profile Updated successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.companyProcessingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.companyProcessingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
      }
    }

    else if ((this.business_license == null) && (this.BusinessLicense_Show == null)) {
      this.business_licenseErr = 'Business License is required';
    }
    else if ((this.company_logo == null) && (this.CompanyLogo_Show == null)) {
      this.Company_LogoErr = 'Company Logo is required';
    }
    else if ((this.companyManagementForm.value.authorized_representative_prefix == null) && (this.companyManagementForm.value.authorized_representative != null)) {
      this.AuthorizedPrefixNameErr = 'Authorized Representative Prefix Name is required';
    }
    else if ((this.companyManagementForm.value.ceo_prefix == null) && (this.companyManagementForm.value.ceo_first_name != null)) {
      this.CeoPrefixNameErr = 'CEO Prefix Name is required';
    }
    else if ((this.companyManagementForm.value.mobile_Country_code == null) && (this.companyManagementForm.value.mobile_number != null)) {
      this.mobileCountryCodeErr = 'Country code of Mobile Number is required';
    }
    else if ((this.companyManagementForm.value.contact_Country_code == null) && (this.companyManagementForm.value.contact_number != null)) {
      this.contactCountryCodeErr = 'Country code of Contact Number is required';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JudgeService } from '../../services/judge.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { last } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  selector: 'app-company-document',
  templateUrl: './company-document.component.html',
  styleUrls: ['./company-document.component.scss']
})
export class CompanyDocumentComponent implements OnInit {

  show_document_popup: any = [];
  show_doc_list: boolean = true;
  view_doc_status: boolean = false;
  view_general_doc_status: boolean = false;
  leftbarselected: any;
  user_token: any;
  api_token: any;
  addDocmentForm: FormGroup;
  addGeneralDocmentForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  criteraList: any = [];
  document: any = [];
  general_document: any;
  documentErr: any;
  generalDocumentErr: any;
  processingAddDocument: boolean = false;
  processingAddGeneralDocument: boolean = false;
  documentList: any = [];
  generalDocumentList: any = [];
  documentImagePath: any;
  generalDocumentImagePath: any;
  final_document: any;
  final_general_document: any;
  lazyLoad: boolean = false;
  documentLoad: boolean = false;
  generaDocumentLoad: boolean = false;
  documentType: any;
  generalDocumentAction: any;
  generalDocumentId: any;
  document_name: any;
  NoDatamsg: any;
  generalDocumentErrmsg: any;
  loadingCount: number = 0;
  documentres: any;
  allDocumentData: any = [];
  allGeneralDocumentData: any = [];
  documentDocs: any = [];
  generalDocumentDocs: any = [];
  allDocumentStatusShow: boolean = false;
  allGeneralDocumentStatusShow: boolean = false;
  company_doc_text: any;
  generalDocumentDownloadPath: any;
  documentDownloadPath: any;
  generalDocumentRes: any;
  documentAwardId: any;
  documentAwardYear: any;
  generalDocumentAwardId: any;
  generalDocumentAwardYear: any;
  documentFilterationYearList: any = [];
  generalDocumentFilterationYearList: any = [];
  documentFilterationAwardList: any = [];
  generalDocumentFilterationAwardList: any = [];
  displayCriteriaNumber: any;
  criteriaArray: any = [];
  changesIndex: number;
  companyDetails: any = [];
  singleawrardDetails: any = [];
  isAddEvidenceDocument: boolean = true;
  isAddGeneralDocument: boolean = true;
  awardValue: any;
  documentSubmissionStatus: any = '';
  generalDocumentSubmissionStatus: any = '';
  addDocumentErr: any;
  addGeneralDocumentErr: any;
  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private dashboardService: DashboardService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/company-document')
          this.leftbarselected = 'document';
        this.dataService.leftPanelSelection('company_document');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-document`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getDashboardDeails();
    this.addDocmentForm = this.formBuilder.group({
      criteria: ['', [Validators.required]]
    });
    this.addGeneralDocmentForm = this.formBuilder.group({
      document_name: ['', [Validators.required]],
    });
  }
  get f1() { return this.addDocmentForm.controls; }

  get f2() { return this.addGeneralDocmentForm.controls; }

  open_add_document(generalDocument, action) {
    if (this.show_doc_list == true) {
      this.show_document_popup = 1;
      this.displayCriteriaNumber = '';
      this.criteriaArray = [];
    }
    else {
      this.show_document_popup = 2;
      this.generalDocumentAction = action;
      if (generalDocument != null) {
        this.generalDocumentId = generalDocument.id;
        this.addGeneralDocmentForm.patchValue({
          document_name: generalDocument.name
        })
      }
    }
  }
  close_add_document() {
    this.show_document_popup = 0;
    this.submitted1 = false;
    this.submitted2 = false;
    this.awardValue = '';
    this.isAddEvidenceDocument = true;
    this.isAddGeneralDocument = true;
    this.getDashboardDeails();
    this.criteraList = [];
    this.addDocmentForm.reset();
    this.addGeneralDocmentForm.reset();
    this.displayCriteriaNumber = '';
    this.criteriaArray = [];
    this.documentErr = '';
    this.generalDocumentErr = '';
    this.NoDatamsg == '';
    this.document = [];
    this.general_document = null;
    // this.criteraList = [];
    // this.criteraList.push({
    //   'key': 'loop-' + this.documentres.criteria.length,
    //   'option': this.documentres.criteria
    // });
  }
  doc_list_status() {
    this.show_doc_list = true;
    this.documentAwardYear = '';
    this.documentAwardId = '';
    this.getDocumentList();
  }
  general_doc_status() {
    this.show_doc_list = false;
    this.generalDocumentAwardYear = '';
    this.generalDocumentAwardId = '';
    this.getGeneralDocumentList();
  }
  open_view_document1(document1) {
    this.documentType = document1.doc_name.split('.').pop();
    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png') || (this.documentType == 'JPEG')) {
      this.view_doc_status = true;
      this.documentLoad = true;
      this.final_document = this.documentImagePath + document1.doc_name;
      this.documentLoad = false;
    }
    else if (this.documentType == 'pdf') {
      this.view_doc_status = true;
      this.documentLoad = true;
      this.final_document = this.documentImagePath + document1.doc_name;
      this.final_document = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentImagePath + document1.doc_name);
      this.documentLoad = false;
    }
    else if (this.documentType == 'docx') {
      this.view_doc_status = false;
      this.final_document = this.documentImagePath + document1.doc_name;
      const downloadLink = document.createElement("a");
      const fileName = document1.document_name;

      downloadLink.href = this.final_document;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  open_view_document2(generalDocument) {
    this.documentType = generalDocument.document_name.split('.').pop();
    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png') || (this.documentType == 'JPEG')) {
      this.view_general_doc_status = true;
      this.generaDocumentLoad = true;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      this.generaDocumentLoad = false;
    }
    else if (this.documentType == 'pdf') {
      // this.view_doc_status = true;
      // this.documentLoad = true;
      this.view_general_doc_status = true;
      this.generaDocumentLoad = true;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      this.final_general_document = this.sanitizer.bypassSecurityTrustResourceUrl(this.generalDocumentImagePath + generalDocument.document_name);
      // this.documentLoad = false;
      this.generaDocumentLoad = false;
    }
    else if (this.documentType == 'docx') {
      this.view_general_doc_status = false;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      const downloadLink = document.createElement("a");
      const fileName = generalDocument.document_name;

      downloadLink.href = this.final_general_document;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  close_view_document1() {
    this.view_doc_status = false;
  }
  close_view_document2() {
    this.view_general_doc_status = false;
  }
  onSelectFileAddDocument(event) {

    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.document.push(file)
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //     }
        //     reader.readAsDataURL(file);
      }
    }
    if (this.document.length > 0) {
      this.documentErr = '';
    }
  }
  onSelectFileAddGeneralDocument(event) {
    console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      this.general_document = event.target.files[0];
      console.log(this.general_document);
      if (this.general_document != '') {
        this.generalDocumentErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        // this.display_profile_photo = event.target.result;
      }
    }
  }
  onChange(item, keyname, $event) {
    this.NoDatamsg = '';
    this.changesIndex = this.criteraList.findIndex(x => x.key == keyname);
    for (let a of item.option) {
      if (a.id == $event.target.value) {
        if (this.criteriaArray.filter(x => x.keyIndex == this.changesIndex).length > 0) {
          let _obj = this.criteriaArray.find(x => x.keyIndex == this.changesIndex)
          _obj.criteria_number = a.criteria_number
        } else {
          this.criteriaArray.push({
            keyIndex: this.changesIndex,
            criteria_number: a.criteria_number
          });
        }
      }
    }
    if (this.criteraList.length > this.changesIndex + 1) {
      let criLen = this.criteraList.length
      this.criteraList.splice(this.changesIndex + 1, criLen - (this.changesIndex + 1));
      this.criteriaArray.splice(this.changesIndex + 1, criLen - (this.changesIndex + 1));
    }
    let CriteriaNumberArray = [];
    this.criteriaArray.map((item) => {
      CriteriaNumberArray.push(item.criteria_number);
    })
    this.displayCriteriaNumber = CriteriaNumberArray.join('.');
    this.getCriteria($event.target.value);
  }
  deleteCriteria(keyname) {
    this.NoDatamsg = '';
    let changesIndex = this.criteraList.findIndex(x => x.key == keyname);
    if (this.criteraList.length > changesIndex + 1) {
      let criLen = this.criteraList.length
      this.criteraList.splice(changesIndex + 1, criLen - (changesIndex + 1));
      // let lastObj = this.criteraList[this.criteraList.length - 1]
      // lastObj.option = []
    }
  }
  getCriteria(parentId) {
    let data = {
      parent_id: parentId
    }
    this.judgeService.getCritera(this.api_token, data).subscribe((res: any) => {
      if (res.has_error == 0) {
        if (res.allcriteria.length > 0) {
          for (let i = 0; i < res.allcriteria.length; i++) {
            res.allcriteria[i].criteria_number = i + 1;
          }
        }

        if (res.allcriteria.length > 0) {
          this.criteraList.push({
            'key': 'loop-' + this.criteraList.length,
            'option': res.allcriteria
          });
        }
        if (res.allcriteria.length == 0) {
          this.NoDatamsg = 'This is the last subcriteria';
          this.generalDocumentErrmsg = '';
        }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  getDashboardDeails() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {

      if (res.has_error == 0) {
        this.companyDetails = res.company_data;
        this.getDocumentList();
        this.getGeneralDocumentList();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }

  onChangAward($event) {
    this.awardValue = $event.target.value;
    this.document = [];
    this.submitted1 = false;
    this.submitted2 = false;
    this.displayCriteriaNumber = '';
    this.NoDatamsg = '';
    this.isAddEvidenceDocument = true;

    this.singleawrardDetails = this.companyDetails.filter(x => x.year_id === Number(this.awardValue));
    if (this.singleawrardDetails[0].criteria.length > 0) {
      for (let i = 0; i < this.singleawrardDetails[0].criteria.length; i++) {
        this.singleawrardDetails[0].criteria[i].criteria_number = i + 1;
      }
    }

    this.criteraList = [];
    this.criteraList.push({
      'key': 'loop-' + this.criteraList.length,
      'option': this.singleawrardDetails[0].criteria
    });
    if ((this.singleawrardDetails[0].company_doc.length > 0) && (this.singleawrardDetails[0].company_doc[0].status == 'Y')) {
      this.isAddEvidenceDocument = false;
    }
    if (this.singleawrardDetails[0].company_doc.length == 0) {
      this.isAddEvidenceDocument = true;
    }
    if ((this.singleawrardDetails[0].company_doc.length > 0) && (this.singleawrardDetails[0].company_doc[0].status == 'N')) {
      this.isAddEvidenceDocument = true;
    }

    if ((this.singleawrardDetails[0].general_document.length > 0) && (this.singleawrardDetails[0].general_document[0].status == 'Y')) {
      this.isAddGeneralDocument = false;
    }
    if (this.singleawrardDetails[0].general_document.length == 0) {
      this.isAddGeneralDocument = true;
    }
    if ((this.singleawrardDetails[0].general_document.length > 0) && (this.singleawrardDetails[0].general_document[0].status == 'N')) {
      this.isAddGeneralDocument = true;
    }
  }
  getDocumentList() {
    this.judgeService.documentlist(this.api_token, this.documentAwardYear, this.documentAwardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.lazyLoad = false;
        this.documentres = res;
        this.documentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
        this.documentFilterationAwardList = this.documentres.filerationAwards.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)));
        this.documentFilterationYearList = this.documentres.filerationYears.filter((thing, index, self) => index === self.findIndex((t) => (t === thing)));

        this.documentList = res.company_doc;

        let paymentdetails = [];
        if ((this.companyDetails.length > 0) && (this.documentList.length > 0)) {
          paymentdetails = this.companyDetails.filter(x => x.year_id == this.documentList[0].award_year_id);
        }

        if (paymentdetails.length > 0) {
          if (paymentdetails[0].awardDetails.is_payble == 'Y') {
            if ((paymentdetails[0].payment_status == 'Y') && (this.documentList.length > 0) && (this.documentList[0].status == 'N') && (paymentdetails[0].status == 'Y')) {
              this.documentSubmissionStatus = true;
            }
            else if (paymentdetails[0].payment_status != 'Y') {
              this.documentSubmissionStatus = false;
            }
            else if (this.documentList.length == 0) {
              this.documentSubmissionStatus = false;
            }
            else if (this.documentList[0].status == 'Y') {
              this.documentSubmissionStatus = false;
            }
            else if (paymentdetails[0].status == 'N') {
              this.documentSubmissionStatus = false;
            }
          }
          else if (paymentdetails[0].awardDetails.is_payble == 'N') {
            if ((this.documentList.length > 0) && (this.documentList[0].status == 'N') && (paymentdetails[0].status == 'Y')) {
              this.documentSubmissionStatus = true;
            }
            else if (this.documentList.length == 0) {
              this.documentSubmissionStatus = false;
            }
            else if (this.documentList[0].status == 'Y') {
              this.documentSubmissionStatus = false;
            }
            else if (paymentdetails[0].status == 'N') {
              this.documentSubmissionStatus = false;
            }
          }
        }
        this.company_doc_text = res.company_doc_text;
        this.NoDatamsg = '';
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      })
  }
  getGeneralDocumentList() {
    this.judgeService.generalDocumentlist(this.api_token, this.generalDocumentAwardYear, this.generalDocumentAwardId).subscribe((res: any) => {

      this.generalDocumentRes = res;
      this.generalDocumentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
      this.generalDocumentFilterationAwardList = this.generalDocumentRes.filerationAwards.filter((thing, index, self) => index === self.findIndex((t) => (t.id === thing.id)));
      this.generalDocumentFilterationYearList = this.generalDocumentRes.filerationYears.filter((thing, index, self) => index === self.findIndex((t) => (t === thing)))

      // let evilResponseProps = Object.values(this.generalDocumentRes.filerationYears);
      // this.generalDocumentFilterationYearList = [];
      // for (let item of evilResponseProps) {
      //   this.generalDocumentFilterationYearList.push(item)
      // }

      this.generalDocumentList = res.general_document;
      let generalPaymentdetails = [];
      if ((this.companyDetails.length > 0) && (this.generalDocumentList.length > 0)) {
        generalPaymentdetails = this.companyDetails.filter(x => x.year_id == this.generalDocumentList[0].award_year_id);
      }
      if (generalPaymentdetails.length > 0) {
        if (generalPaymentdetails[0].awardDetails.is_payble == 'Y') {
          if ((generalPaymentdetails[0].payment_status == 'Y') && (this.generalDocumentList.length > 0) && (this.generalDocumentList[0].status == 'N') && (generalPaymentdetails[0].status == 'Y')) {
            this.generalDocumentSubmissionStatus = true;
          }
          else if (generalPaymentdetails[0].payment_status != 'Y') {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (this.generalDocumentList.length == 0) {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (this.generalDocumentList[0].status == 'Y') {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (generalPaymentdetails[0].status == 'N') {
            this.generalDocumentSubmissionStatus = false;
          }
        }
        else if (generalPaymentdetails[0].awardDetails.is_payble == 'N') {
          if ((this.generalDocumentList.length > 0) && (this.generalDocumentList[0].status == 'N') && (generalPaymentdetails[0].status == 'Y')) {
            this.generalDocumentSubmissionStatus = true;
          }
          else if (this.generalDocumentList.length == 0) {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (this.generalDocumentList[0].status == 'Y') {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (generalPaymentdetails[0].status == 'N') {
            this.generalDocumentSubmissionStatus = false;
          }
        }
      }
      this.company_doc_text = res.company_doc_text;
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  filterDocument() {
    this.judgeService.documentlist(this.api_token, this.documentAwardYear, this.documentAwardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.documentres = res;
        this.documentList = res.company_doc;
        let paymentdetails = [];
        if ((this.companyDetails.length > 0) && (this.documentList.length > 0)) {
          paymentdetails = this.companyDetails.filter(x => x.year_id == this.documentList[0].award_year_id);
        }

        if (paymentdetails.length > 0) {
          if (paymentdetails[0].awardDetails.is_payble == 'Y') {
            if ((paymentdetails[0].payment_status == 'Y') && (this.documentList.length > 0) && (this.documentList[0].status == 'N') && (paymentdetails[0].status == 'N')) {
              this.documentSubmissionStatus = true;
            }
            else if (paymentdetails[0].payment_status != 'Y') {
              this.documentSubmissionStatus = false;
            }
            else if (this.documentList.length == 0) {
              this.documentSubmissionStatus = false;
            }
            else if (this.documentList[0].status == 'Y') {
              this.documentSubmissionStatus = false;
            }
            else if (paymentdetails[0].status == 'N') {
              this.documentSubmissionStatus = false;
            }
          }
          else if (paymentdetails[0].awardDetails.is_payble == 'N') {
            if ((this.documentList.length > 0) && (this.documentList[0].status == 'N') && (paymentdetails[0].status == 'Y')) {
              this.documentSubmissionStatus = true;
            }
            else if (this.documentList.length == 0) {
              this.documentSubmissionStatus = false;
            }
            else if (this.documentList[0].status == 'Y') {
              this.documentSubmissionStatus = false;
            }
            else if (paymentdetails[0].status == 'N') {
              this.documentSubmissionStatus = false;
            }
          }
        }

        this.allDocumentStatusShow = false;
        this.allDocumentData = [];
        this.documentDocs = [];
        this.company_doc_text = res.company_doc_text;
        // this.criteraList = [];
        // this.NoDatamsg = '';
        // this.criteraList.push({
        //   'key': 'loop-' + this.criteraList.length,
        //   'option': res.criteria
        // });
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  filterGeneralDocument() {
    this.judgeService.generalDocumentlist(this.api_token, this.generalDocumentAwardYear, this.generalDocumentAwardId).subscribe((res: any) => {
      this.generalDocumentRes = res;
      this.generalDocumentList = res.general_document;
      this.allGeneralDocumentStatusShow = false;
      this.allGeneralDocumentData = [];
      this.generalDocumentDocs = [];
      this.company_doc_text = res.company_doc_text;

      this.generalDocumentList = res.general_document;
      let generalPaymentdetails = [];
      if ((this.companyDetails.length > 0) && (this.generalDocumentList.length > 0)) {
        generalPaymentdetails = this.companyDetails.filter(x => x.year_id == this.generalDocumentList[0].award_year_id);
      }
      if (generalPaymentdetails.length > 0) {
        if (generalPaymentdetails[0].awardDetails.is_payble == 'Y') {
          if ((generalPaymentdetails[0].payment_status == 'Y') && (this.generalDocumentList.length > 0) && (this.generalDocumentList[0].status == 'N') && (generalPaymentdetails[0].status == 'Y')) {
            this.generalDocumentSubmissionStatus = true;
          }
          else if (generalPaymentdetails[0].payment_status != 'Y') {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (this.generalDocumentList.length == 0) {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (this.generalDocumentList[0].status == 'Y') {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (generalPaymentdetails[0].status == 'N') {
            this.generalDocumentSubmissionStatus = false;
          }

        }
        else if (generalPaymentdetails[0].awardDetails.is_payble == 'N') {
          if ((this.generalDocumentList.length > 0) && (this.generalDocumentList[0].status == 'N') && (generalPaymentdetails[0].status == 'Y')) {
            this.generalDocumentSubmissionStatus = true;
          }
          else if (this.generalDocumentList.length == 0) {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (this.generalDocumentList[0].status == 'Y') {
            this.generalDocumentSubmissionStatus = false;
          }
          else if (generalPaymentdetails[0].status == 'N') {
            this.generalDocumentSubmissionStatus = false;
          }
        }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  onChangeAward(value) {
    if (this.show_doc_list == true) {
      this.documentAwardId = value;
    }
    if (this.show_doc_list == false) {
      this.generalDocumentAwardId = value;
    }

  }
  onChangeYear(value) {
    if (this.show_doc_list == true) {
      this.documentAwardYear = value;
    }
    if (this.show_doc_list == false) {
      this.generalDocumentAwardYear = value;
    }
  }
  addDocment() {
      this.submitted1 = true;
      if (this.document.length == 0) {
        this.documentErr = 'Document is required';
      }
      // stop here if form is invalid
      if (this.addDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token)
      formData.append('criteria_id', this.addDocmentForm.value.criteria),
      formData.append('criteria_number', this.displayCriteriaNumber),
      formData.append('award_year_id', this.documentres.company_data.company.company_award.award_year_id),
      formData.append('file_count', this.document.length)
      for (let i = 0; i < this.document.length; i++) {
        formData.append("comp_excel_upload_" + i, this.document[i]);
      }
      if (this.document.length == 0) {
        this.documentErr = 'Document is required';
      }
      if (this.document.length > 0) {
        this.documentErr = '';
        this.generalDocumentErrmsg = '';
        this.processingAddDocument = true;
        this.judgeService.addDocument(this.api_token, formData).subscribe((res: any) => {

          if (res.has_error == 0) {
            this.processingAddDocument = false;
            this.getDocumentList();
            this.close_add_document();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.processingAddDocument = false;
            this.addDocumentErr = res.msg;
            setTimeout(() => {
              this.addDocumentErr = '';
            }, 3000);
          }
        },
          err => {
            this.processingAddDocument = false;
            this.messagesService.showError('Something went wrong.');
          })
      }
      else if (this.NoDatamsg == '') {
        this.generalDocumentErrmsg = 'Please wait there is some other criteria';
      }
  }
  addGeneralDocment() {
    this.submitted2 = true;
    if (this.generalDocumentAction == 'add') {
      if ((this.general_document == null) || (this.general_document == undefined)) {
        this.generalDocumentErr = 'General Document is required';
      }
      // stop here if form is invalid
      if (this.addGeneralDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('action', this.generalDocumentAction),
        formData.append('name', this.addGeneralDocmentForm.value.document_name),
        formData.append('award_year_id', this.generalDocumentRes.current_award_year_id),
        formData.append('document_name', this.general_document)

      if ((this.general_document == null) || (this.general_document == undefined)) {
        this.generalDocumentErr = 'General Document is required';
      }
      if ((this.general_document != null) || (this.general_document != undefined)) {
        this.processingAddGeneralDocument = true;
        this.judgeService.addGeneralDocument(this.api_token, formData).subscribe((res: any) => {

          if (res.has_error == 0) {
            this.processingAddGeneralDocument = false;
            this.close_add_document();
            this.getGeneralDocumentList();
            this.messagesService.showSuccess('General Document Added Successfully');
          }
          if (res.has_error == 1) {
            this.addGeneralDocumentErr = res.msg;
            setTimeout(() => {
              this.addGeneralDocumentErr = '';
            }, 3000);
            this.processingAddGeneralDocument = false;
          }
        },
          err => {
            this.processingAddGeneralDocument = false;
            this.messagesService.showError('Something went wrong.');
          })
      }
    }
    else if (this.generalDocumentAction == 'edit') {
      // stop here if form is invalid
      if (this.addGeneralDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('action', this.generalDocumentAction),
        formData.append('name', this.addGeneralDocmentForm.value.document_name),
        formData.append('doc_id', this.generalDocumentId),
        formData.append('award_year_id', this.generalDocumentRes.current_award_year_id),

        this.processingAddGeneralDocument = true;
      this.judgeService.addGeneralDocument(this.api_token, formData).subscribe((res: any) => {

        if (res.has_error == 0) {
          this.processingAddGeneralDocument = false;

          this.close_add_document();
          this.getGeneralDocumentList();
          this.messagesService.showSuccess('General Document Updated Successfully');
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingAddGeneralDocument = false;
        }
      },
        err => {
          this.processingAddGeneralDocument = false;
          this.messagesService.showError('Something went wrong.');
        })
    }
  }
  openDocumentDialog(generaldocument) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          document_id: generaldocument.id
        }
        this.judgeService.deleteCompanyDocument(this.api_token, data).subscribe((res: any) => {

          if (res.has_error == 0) {
            this.getDocumentList();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.messagesService.showError('Something went wrong.');
          })
      }
    });
  }
  openGeneralDocumentDialog(generaldocument) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          document_id: generaldocument.id
        }
        this.judgeService.deleteCompanyGeneralDocument(this.api_token, data).subscribe((res: any) => {

          if (res.has_error == 0) {
            this.getGeneralDocumentList();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.messagesService.showError('Something went wrong.');
          })
      }
    });
  }
  singleDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.documentList.map((docItem) => {
        if (this.allDocumentData.indexOf(docItem.id) == -1) {
          if (docItem.id == Item.id) {
            this.allDocumentData.push(docItem.id);
            docItem.checked = true;
            this.documentDocs.push('assets/upload/category_documents/' + docItem.doc_name);
          }
        }
      })
      let documentPath = this.documentDocs.join('--');
      this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      if (this.documentList.length === this.documentDocs.length) {
        this.allDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allDocumentData.findIndex(itm => itm === Item.id);

      if (removeIndex !== -1) {
        this.allDocumentData.splice(removeIndex, 1);
        this.documentDocs.splice(removeIndex, 1);
      }
      if (this.documentDocs.length > 0) {
        let documentPath = this.documentDocs.join('--');
        this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      }
      if (this.documentList.length !== this.documentDocs.length) {
        this.allDocumentStatusShow = false;
      }
    }
  }
  SelectAllDocumentValue($event) {
    this.allDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.documentDocs = [];
      this.documentList.map((item) => {
        if (this.allDocumentData.indexOf(item.id) == -1) {
          this.allDocumentData.push(item.id);
          item.checked = true;
          this.documentDocs.push('assets/upload/category_documents/' + item.doc_name);
        }
      });
      this.allDocumentStatusShow = true;
      let documentPath = this.documentDocs.join('--');
      this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
    }
    else if ($event.srcElement.checked == false) {
      this.allDocumentData = [];
      this.documentDocs = [];
      this.documentList.map((item) => {
        item.checked = false;
      });
      this.allDocumentStatusShow = false;
    }
  }
  singleGeneralDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.generalDocumentList.map((generaldocItem) => {
        if (this.allGeneralDocumentData.indexOf(generaldocItem.id) == -1) {
          if (generaldocItem.id == Item.id) {
            this.allGeneralDocumentData.push(generaldocItem.id);
            generaldocItem.checked = true;
            this.generalDocumentDocs.push('assets/upload/comp_general_doc/' + generaldocItem.document_name);
          }
        }
      })
      let generalDocumentPath = this.generalDocumentDocs.join('--');
      this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      if (this.generalDocumentList.length == this.generalDocumentDocs.length) {
        this.allGeneralDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allGeneralDocumentData.findIndex(itm => itm === Item.id);

      if (removeIndex !== -1) {
        this.allGeneralDocumentData.splice(removeIndex, 1);
        this.generalDocumentDocs.splice(removeIndex, 1);
      }
      if (this.generalDocumentDocs.length > 0) {
        let generalDocumentPath = this.generalDocumentDocs.join('--');
        this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      }
      if (this.generalDocumentList.length !== this.generalDocumentDocs.length) {
        this.allGeneralDocumentStatusShow = false;
      }
    }
  }
  SelectAllGeneralDocumentValue($event) {
    this.allGeneralDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.generalDocumentDocs = [];
      this.generalDocumentList.map((item) => {
        if (this.allGeneralDocumentData.indexOf(item.id) == -1) {
          this.allGeneralDocumentData.push(item.id);
          item.checked = true;
          this.generalDocumentDocs.push('assets/upload/comp_general_doc/' + item.document_name);
        }
      });
      this.allGeneralDocumentStatusShow = true;
      let generalDocumentPath = this.generalDocumentDocs.join('--');
      this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
    }
    else if ($event.srcElement.checked == false) {
      this.allGeneralDocumentData = [];
      this.generalDocumentDocs = [];
      this.generalDocumentList.map((item) => {
        item.checked = false;
      });
      this.allGeneralDocumentStatusShow = false;
    }
  }
  openEvidenceDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Before submission please understand that this is a final submission. Once you proceed, It will forward the submission to the assessment team and you won' + 't be able to make any changes, add or delete further. Do you want to submit your documents?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.evidenceSendSubmission();
      }
    });
  }
  openGeneralDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Before submission please understand that this is a final submission. Once you proceed, It will forward the submission to the assessment team and you won' + 't be able to make any changes, add or delete further. Do you want to submit your documents?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.generalSendSubmission();
      }
    });
  }
  evidenceSendSubmission() {
    let documentIdArray = [];
    this.documentList.map((item) => {
      documentIdArray.push(item.id)
    })
    let data = {
      award_year_id: this.documentList[0].award_year_id,
      document_ids: documentIdArray,
      document_type: 'E'
    }
    this.judgeService.sendSubmission(this.api_token, data).subscribe((res: any) => {

      if (res.has_error == 0) {
        this.messagesService.showSuccess('Send Submission done successfully');
        this.getDocumentList();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  generalSendSubmission() {
    let generalDocumentIdArray = [];
    this.generalDocumentList.map((item) => {
      generalDocumentIdArray.push(item.id)
    })
    let data = {
      award_year_id: this.generalDocumentList[0].award_year_id,
      document_ids: generalDocumentIdArray,
      document_type: 'G'
    }
    this.judgeService.sendSubmission(this.api_token, data).subscribe((res: any) => {

      if (res.has_error == 0) {
        this.messagesService.showSuccess('Send Submission done successfully');
        this.getGeneralDocumentList();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
}

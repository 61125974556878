import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recursive-criteria',
  templateUrl: './recursive-criteria.component.html',
  styleUrls: ['./recursive-criteria.component.scss']
})
export class RecursiveCriteriaComponent implements OnInit {
  @Input() innerCriteriaListArray;
  user_token: any;
  api_token: any;
  submitted: boolean = false;
  processingText: boolean = false;
  CriteriaForm: FormGroup;
  errMsg1: any;
  errMsg2: any;
  closeResult: string;
  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder) {
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    this.CriteriaForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      point: ['', [Validators.required]],
      description: ['', [Validators.required]],
      rank: ['', [Validators.required]],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.CriteriaForm.controls; }

  toggleSubCriteria(subcriteriaitem, subcriteriaindex) {
    console.log(subcriteriaitem);
    this.CriteriaForm.patchValue({
      title: subcriteriaitem.title,
      point: subcriteriaitem.point,
      // action: action[add],
      rank: subcriteriaitem.rank,
      description: subcriteriaitem.description
    })
    subcriteriaitem.isOpen = !subcriteriaitem.isOpen;
    this.innerCriteriaList(subcriteriaitem.id);
  }
  innerCriteriaList(id) {
    this.dashboardService.innerCriteria(this.api_token, id).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.getChildNodes(res, id);
      }
      // console.log(this.innerCriteriaListArray);
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  getChildNodes(res, id) {
    let criteria = this.innerCriteriaListArray.find((item) => item.id == id);
    if (criteria != undefined) {
      // criteria['childArray'] = res.criteria_list;
      this.innerCriteriaListArray.find((item) => item.id == id)['childArray'] = res.criteria_list;
    }
    console.log('criteria', criteria);
    console.log('innerCriteriaListArray', this.innerCriteriaListArray);
  }

  open(content, className) {
    let modalClassName = className;
    // this.singleOfficeDetails = this.ScorePoint;
    // console.log(this.singleOfficeDetails)
    this.submitted = false;
    // this.approveForm.reset();
    // this.rejectForm.reset();
    this.processingText = false;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  addEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      resize_enabled: false,
      // toolbar: {
      // items: ['bold','italic','underline','|','strikethrough','subscript','superscript','|','bulletedList','numberedList','|','blockQuote','codeBlock','code','horizontalLine']
      // },
      language: 'en-gb',
      licenseKey: '',

    })
      .then(editor => {
        console.log(editor)
      })
      .catch(error => {
        console.error(error);
      });
  }
  updateCriteria(action) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.CriteriaForm.invalid) {
      return;
    }
    let data = {
      // criteria_id: ,
      // award_parent_id:,
      // rank_weight:,
      // title: this.CriteriaForm.value.title,
      // point:this.CriteriaForm.value.point,
      // action: action[add],
      // rank: this.CriteriaForm.value.rank,
      // description: this.CriteriaForm.value.description
    }
    this.dashboardService.addCriteria(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        // this.criteriaList();
        this.messagesService.showSuccess(res.msg);
      }
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { JudgeService } from '../../services/judge.service';
import { MessagesService } from '../../services/messages.service';
import { appConfig } from '../../app.config';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  leftbarselected: any;
  user_token: any;
  api_token: any;
  userDetails: any;
  lazyLoad: boolean = false;
  paymentArray: any = [];
  paymentDownloadPath: any;
  invoiceDownloadPath: any;
  constructor(private router: Router,
    private dataService: DataService,
    private judgeService: JudgeService,
    private messagesService: MessagesService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/payment-history')
          this.leftbarselected = 'payment history';
        this.dataService.leftPanelSelection('payment_history');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/payment-history`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.paymentList();
  }
  paymentList() {
    this.lazyLoad = true;
    this.judgeService.paymentlist(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.paymentArray = res.paymentList;
        this.paymentDownloadPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/company_payment_docs/';
        this.invoiceDownloadPath = appConfig.apiUrl + '/download-invoice?api_token=' + this.api_token + '&id=' ;
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }
}

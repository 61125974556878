import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from './layout/header/header.module';
import { FooterModule } from './layout/footer/footer.module';
import { LeftPanelModule } from './layout/left-panel/left-panel.module';
import { AppComponent } from './app.component';
import { LandingModule } from './landing/landing.module';
import { ThankYouModule } from './thank-you/thank-you.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AwardListModule } from './award-list/award-list.module';
import { AwardDetailsModule } from './award-details/award-details.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompanyRegisterModule } from './company-register/company-register.module';
import { AssessorRegisterModule } from './assessor-register/assessor-register.module';
import { LoginModule } from './login/login.module';
import { SponsorsListModule } from './sponsors-list/sponsors-list.module';
import { MediaGalleryModule } from './media-gallery/media-gallery.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ViewAllMediaModule } from './view-all-media/view-all-media.module';
import { OurJuriesModule } from './our-juries/our-juries.module';
import { FaqModule } from './faq/faq.module';
import { AssessorApplicationModule } from './assessor-application/assessor-application.module';
import { OurAssessorModule } from './our-assessor/our-assessor.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { AuthGuard } from '../_helpers/_guards/auth-guard.guard';
import { PublicGuard } from '../_helpers/_guards/public-guard.guard';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ActiveAccountModule } from './active-account/active-account.module';
import { AssessorDashboardModule } from './assessor-dashboard/assessor-dashboard.module';
import { TokenInterceptor } from '../_helpers/_guards/TokenInterceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialog } from './confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { JuryRegisterModule } from './jury-register/jury-register.module';
import { EditSelfAssessmentModule } from './assessor-dashboard/edit-self-assessment/edit-self-assessment.module';
import { AwardFremeworkModule } from './award-fremework/award-fremework.module';
import { LicenseModule } from './assessor-dashboard/license/license.module';
import { TestimonialsModule } from './testimonials/testimonials.module';
import { ContactModule } from './contact/contact.module';
import { AwardWinnerModule } from './award-winner/award-winner.module';
import { JudgeProfileModule } from './judge-dashboard/judge-profile/judge-profile.module';
import { JudgeDashboardModule } from './judge-dashboard/judge-dashboard.module';
import { CompanyProfileModule } from './company-dashboard/company-profile/company-profile.module';
import { CompanyDashboardModule } from './company-dashboard/company-dashboard.module';
import { CompanyDocumentModule } from './company-dashboard/company-document/company-document.module';
import { AwardOfficeDashboardModule } from './award-office-dashboard/award-office-dashboard.module';
import { AwardOfficeProfileModule } from './award-office-dashboard/award-office-profile/award-office-profile.module';
import { JuryAssessmentModule } from './judge-dashboard/jury-assessment/jury-assessment.module';
import { JudgeArchiveModule } from './judge-dashboard/judge-archive/judge-archive.module';
import { AssesorArchiveModule } from './assessor-dashboard/assesor-archive/assesor-archive.module';
import { AwardManagementModule } from './award-office-dashboard/award-management/award-management.module';
import { JudgeGroupDashboardModule } from './judge-dashboard/judge-group-dashboard/judge-group-dashboard.module';
import { OfficeArchiveModule } from './award-office-dashboard/office-archive/office-archive.module';
import { AwardApplyModule } from './company-dashboard/award-apply/award-apply.module';
import { CompanyAwardDetailsModule } from './company-dashboard/company-award-details/company-award-details.module';
import { OfficeCompanyDocumentsModule } from './award-office-dashboard/office-company-documents/office-company-documents.module';
import { OfficeCrteriaManagemnetModule } from './award-office-dashboard/office-crteria-managemnet/office-crteria-managemnet.module';
import { AwardOfficeMgmtModule } from './award-office-dashboard/award-office-mgmt/award-office-mgmt.module';
import { PaymentHistoryModule } from './company-dashboard/payment-history/payment-history.module';
import { AllParticipatedAwardModule } from './company-dashboard/all-participated-award/all-participated-award.module';
import { OfficeAssessmentModule } from './award-office-dashboard/office-assessment/office-assessment.module';
import { AssessorGroupModule } from './award-office-dashboard/assessor-group/assessor-group.module';
import { AssessorManagementModule } from './award-office-dashboard/assessor-management/assessor-management.module';
import { CompanyManagementModule } from './award-office-dashboard/company-management/company-management.module';
import { JuryGroupModule } from './award-office-dashboard/jury-group/jury-group.module';
import { JuryManagementModule } from './award-office-dashboard/jury-management/jury-management.module';
import { OfficeGroupMgmtModule } from './award-office-dashboard/office-group-mgmt/office-group-mgmt.module';
import { AwardStatisticsModule } from './award-office-dashboard/award-statistics/award-statistics.module';
import { AssessorAssignMgmtModule } from './award-office-dashboard/assessor-assign-mgmt/assessor-assign-mgmt.module';
import { JuryAssignMgmtModule } from './award-office-dashboard/jury-assign-mgmt/jury-assign-mgmt.module';
import { OfficeArchiveGroupModule } from './award-office-dashboard/office-archive-group/office-archive-group.module';
import { TermsConditionModule } from './terms-condition/terms-condition.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { CookiesPolicyModule } from './cookies-policy/cookies-policy.module';

@NgModule({
  declarations: [
    AppComponent, ConfirmationDialog
  ],
  entryComponents: [ConfirmationDialog],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    LeftPanelModule,
    LandingModule,
    HttpClientModule,
    AwardListModule,
    AwardDetailsModule,
    BrowserAnimationsModule,
    CompanyRegisterModule,
    AssessorRegisterModule,
    ThankYouModule,
    LoginModule,
    SponsorsListModule,
    ToastModule,
    MediaGalleryModule,
    ViewAllMediaModule,
    OurJuriesModule,
    FaqModule,
    TestimonialsModule,
    AwardFremeworkModule,
    AwardWinnerModule,
    LicenseModule,
    AssesorArchiveModule,
    JudgeProfileModule,
    JudgeDashboardModule,
    JudgeArchiveModule,
    JuryAssessmentModule,
    JudgeGroupDashboardModule,
    CompanyProfileModule,
    CompanyDashboardModule,
    CompanyDocumentModule,
    CompanyAwardDetailsModule,
    PaymentHistoryModule,
    AwardApplyModule,
    AllParticipatedAwardModule,
    AwardOfficeDashboardModule,
    AwardOfficeProfileModule,
    OfficeArchiveModule,
    AwardManagementModule,
    OfficeAssessmentModule,
    OfficeCrteriaManagemnetModule,
    OfficeCompanyDocumentsModule,
    AwardOfficeMgmtModule,
    AssessorGroupModule,
    AssessorManagementModule,
    OfficeArchiveGroupModule,
    CompanyManagementModule,
    JuryGroupModule,
    JuryManagementModule,
    OfficeGroupMgmtModule,
    AwardStatisticsModule,
    AssessorAssignMgmtModule,
    JuryAssignMgmtModule,
    AssessorApplicationModule,
    OurAssessorModule,
    // DashboardModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    ContactModule,
    ActiveAccountModule,
    AssessorDashboardModule,
    JuryRegisterModule,
    EditSelfAssessmentModule,
    MatDialogModule,
    MatButtonModule,
    TermsConditionModule,
    PrivacyPolicyModule,
    CookiesPolicyModule
  ],
  providers: [MessageService, AuthGuard, PublicGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }

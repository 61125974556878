import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JudgesComponent } from './judges.component';
import { WinnerService } from '../../services/winner.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MessagesService } from '../../services/messages.service';

@NgModule({
  declarations: [JudgesComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  providers: [
    WinnerService,
    MessagesService
  ],
  exports: [JudgesComponent]
})
export class JudgesModule { }

import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { DataService } from '../../services/data.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-final-assessment-point',
  templateUrl: './final-assessment-point.component.html',
  styleUrls: ['./final-assessment-point.component.scss']
})
export class FinalAssessmentPointComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  api_token: any;
  user_token: any;
  criteriaList: any = [];
  subcriteriaList: any = [];
  subcriteriaTitle: any;
  subcriteriaStatus: any;
  finaAassignmentDetails: any;
  breadcrumbSelected: any;
  IdParams: any;
  mainCriteraNumber: number = 1;
  CriteraNumber: any;
  strength: any;
  comment: any;
  selectPointType: any;
  selectPoint: any;
  criteraPoint: any;
  selectIndex: number = 0;
  critera_id: any;
  ScorePoint: any;
  awardMeasurmentsList: any = [];
  pointMeasurmentsList: any = [];
  rejetedData: any = [];
  send_approval_status: any;
  lazyLoad: boolean = false;
  processingtext: boolean = false;
  processingThirdLebeltext: boolean = false;
  criteriatype_name_Array: any = [];
  criteraNumber: any;
  thirdLebelStrength: any = [];
  thirdLebelComment: any = [];
  thirdLebelPointType: any = [];
  thirdLebelAllPoint: any = [];
  thirdLebelAllAssesorComment: any = [];
  errorMessageValue: any;
  thirdlevelSendApprovalStatus: boolean = false;
  assessor_comment: any;
  jury_approved_comment: any;
  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.IdParams = params;
    });
    console.log(this.IdParams)
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url.match('/final-assesment'))
          this.breadcrumbSelected = 'Add Final';
        this.dataService.leftPanelSelection('dashboard');
      }
    });
  }

  ngOnInit() {
    window.close()
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/final-assesment/` + this.IdParams.id + '/' + this.IdParams.id2 + '/' + this.IdParams.id3 + '/' + this.IdParams.id4]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.finalawardScoreBoard();
    this.addEditor();
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  finalawardScoreBoard() {
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'award_id': this.IdParams.id2,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'award_year_id': this.IdParams.id4
    }
    this.lazyLoad = true;
    this.dashboardService.finalAwardScoreBoard(this.api_token, data).subscribe((res: any) => {
      this.lazyLoad = false;
      this.finaAassignmentDetails = res;
      this.criteriatype_name_Array = res.criteriatype;
      this.criteriaList = res.criteria;
      this.subcriteriaList = res.get_all_criteria;
      this.subcriteriaTitle = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].title;
      this.critera_id = this.subcriteriaList[0].id;
      this.criteraPoint = this.subcriteriaList[0].point;
      // console.log(this.subcriteriaList)
      // for (let i = 0; i < this.criteriaList.length; i++) {
      //   this.criteriaList[i].each_point = this.criteriaList[i].each_point.toFixed(2);
      // }
      for (let i = 0; i < this.subcriteriaList.length; i++) {
        if (this.subcriteriaList[i].hasOwnProperty('score_data')) {
          if (this.subcriteriaList[i].score_data.length > 0) {
            this.subcriteriaList[i]['total_point'] = this.subcriteriaList[i].score_data[0].point_percent;
          }
          if (this.subcriteriaList[i].score_data.length == 0) {
            this.subcriteriaList[i]['total_point'] = 0;
          }
        }
        else if (!this.subcriteriaList[i].hasOwnProperty('score_data')) {
          this.subcriteriaList[i]['total_point'] = 0;
          for (let j = 0; j < this.criteriatype_name_Array.length; j++) {
            if (this.subcriteriaList[i].hasOwnProperty(j + 1)) {
              // console.log(this.subcriteriaList[i][j + 1])
              if (parseInt(this.subcriteriaList[i][j + 1].score_data[0].criteria_id) == this.subcriteriaList[i].id) {
                this.subcriteriaList[i]['total_point'] += parseFloat(this.subcriteriaList[i][j + 1].score_data[0].point_percent)
                // this.subcriteriaList[i]['total_point'] = Number(this.subcriteriaList[i]['total_point'])
                // this.subcriteriaList[i]['total_point'] = Math.round((this.subcriteriaList[i]['total_point'] + Number.EPSILON) * 100) / 100
              }
            }
          }
        }
      }

      if (this.subcriteriaList[0].score_data.length > 0) {
        if (this.subcriteriaList[0].hasOwnProperty('score_data')) {
          this.selectPointType = this.subcriteriaList[0].score_data[0].point_type;
          this.strength = this.subcriteriaList[0].score_data[0].strength;
          this.comment = this.subcriteriaList[0].score_data[0].comment;
          this.selectPoint = this.subcriteriaList[0].score_data[0].allopoint;
          if (this.subcriteriaList[0].score_data.length > 0) {
            this.jury_approved_comment = this.subcriteriaList[0].score_data[0].status_comment;
          }
          else if (this.subcriteriaList[0].score_data.length == 0) {
            this.jury_approved_comment = null;
          }
          if (this.subcriteriaList[0].score_data[0].allopoint == '0') {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('0')
          }
          else if ((this.subcriteriaList[0].score_data[0].allopoint == '5') || (this.subcriteriaList[0].score_data[0].allopoint == '10') || (this.subcriteriaList[0].score_data[0].allopoint == '15') || (this.subcriteriaList[0].score_data[0].allopoint == '20') || (this.subcriteriaList[0].score_data[0].allopoint == '25')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '30') || (this.subcriteriaList[0].score_data[0].allopoint == '35') || (this.subcriteriaList[0].score_data[0].allopoint == '40') || (this.subcriteriaList[0].score_data[0].allopoint == '45') || (this.subcriteriaList[0].score_data[0].allopoint == '50')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '55') || (this.subcriteriaList[0].score_data[0].allopoint == '60') || (this.subcriteriaList[0].score_data[0].allopoint == '65') || (this.subcriteriaList[0].score_data[0].allopoint == '70') || (this.subcriteriaList[0].score_data[0].allopoint == '75')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
          }

          else if ((this.subcriteriaList[0].score_data[0].allopoint == '80') || (this.subcriteriaList[0].score_data[0].allopoint == '85') || (this.subcriteriaList[0].score_data[0].allopoint == '90') || (this.subcriteriaList[0].score_data[0].allopoint == '95') || (this.subcriteriaList[0].score_data[0].allopoint == '100')) {
            this.pointMeasurmentsList = [];
            this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
          }
        }
        else if (!this.subcriteriaList[0].hasOwnProperty('score_data')) {
          this.selectPointType = '';
          this.strength = '';
          this.comment = '';
          this.selectPoint = '';
        }
      }
      for (let i = 0; i < this.criteriaList.length; i++) {
        if (i == 0) {
          this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          var key = Object.keys(this.finaAassignmentDetails.award_measurments)[i];
          this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
          this.pointMeasurmentsList.push('0')
        }
        if (i != 0) {
          if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          }
          else {
            this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
          }
        }
      }
      if (this.subcriteriaList[0].hire_order != 0) {
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + this.subcriteriaList[0].hire_order;
      }
      if (this.subcriteriaList[0].hire_order == 0) {
        this.CriteraNumber = this.criteriaList[0].criteria_number + '.' + 1;
      }
      this.ScorePoint = this.subcriteriaList[0];
      if (this.ScorePoint.score_data.length > 0) {
        this.selectPointType = this.ScorePoint.score_data[0].point_type;
        this.strength = this.ScorePoint.score_data[0].strength;
        this.comment = this.ScorePoint.score_data[0].comment;
        this.selectPoint = this.ScorePoint.score_data[0].allopoint;
        this.assessor_comment = this.ScorePoint.score_data[0].assessor_comment;
        if (this.ScorePoint.score_data.length > 0) {
          this.jury_approved_comment = this.ScorePoint.score_data[0].status_comment;
        }

        if (this.ScorePoint.score_data[0].allopoint == '0') {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('0')
        }
        else if ((this.ScorePoint.score_data[0].allopoint == '5') || (this.ScorePoint.score_data[0].allopoint == '10') || (this.ScorePoint.score_data[0].allopoint == '15') || (this.ScorePoint.score_data[0].allopoint == '20') || (this.ScorePoint.score_data[0].allopoint == '25')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '30') || (this.ScorePoint.score_data[0].allopoint == '35') || (this.ScorePoint.score_data[0].allopoint == '40') || (this.ScorePoint.score_data[0].allopoint == '45') || (this.ScorePoint.score_data[0].allopoint == '50')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '55') || (this.ScorePoint.score_data[0].allopoint == '60') || (this.ScorePoint.score_data[0].allopoint == '65') || (this.ScorePoint.score_data[0].allopoint == '70') || (this.ScorePoint.score_data[0].allopoint == '75')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '80') || (this.ScorePoint.score_data[0].allopoint == '85') || (this.ScorePoint.score_data[0].allopoint == '90') || (this.ScorePoint.score_data[0].allopoint == '95') || (this.ScorePoint.score_data[0].allopoint == '100')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
        }
      }
      else if (this.ScorePoint.score_data.length == 0) {
        this.selectPointType = '';
        this.strength = '';
        this.comment = '';
        this.selectPoint = '';
        this.assessor_comment = '';
        this.jury_approved_comment = null;
      }
      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data.length > 0) {
        this.subcriteriaStatus = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].score_data[0].approved_status;
      }

      if (this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data.length > 0) {
        this.rejetedData = this.subcriteriaList.filter(o => this.criteriaList.find(o2 => o.parent_papa === o2.id))[0].rejected_data;
        if (this.rejetedData.length > 0) {
          if (this.rejetedData.length > 0) {
            this.rejetedData.map((item) => {
              item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
            })
          }
        }
      }
    },
      err => {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      })
  }

  lineActive(subcriteria, criteraNumber, index) {
    this.selectIndex = index;
    this.criteraNumber = criteraNumber;
    if (this.criteraNumber != 3) {
      this.ScorePoint = subcriteria;
      console.log(this.ScorePoint)
      if (this.ScorePoint.hire_order != 0) {
        this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      }
      if (this.ScorePoint.hire_order == 0) {
        this.CriteraNumber = criteraNumber + '.' + 1;
      }
      console.log(this.CriteraNumber)
      this.subcriteriaTitle = this.ScorePoint.title;
      this.subcriteriaStatus = this.ScorePoint.score_data.length > 0 ? this.ScorePoint.score_data[0].approved_status : '';
      this.send_approval_status = this.ScorePoint.score_data.length > 0 ? this.ScorePoint.score_data[0].send_approval_status : '';

      this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
      if (this.rejetedData.length > 0) {
        this.rejetedData.map((item) => {
          item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
        })
      }

      var key = Object.keys(this.finaAassignmentDetails.award_measurments)[criteraNumber - 1];
      this.selectPointType = "";
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
      this.selectPoint = "";
      this.pointMeasurmentsList = [];
      this.pointMeasurmentsList.push('0')
      this.critera_id = this.ScorePoint.id;
      this.criteraPoint = this.ScorePoint.point;
      if (this.ScorePoint.score_data.length > 0) {
        this.selectPointType = this.ScorePoint.score_data[0].point_type;
        this.strength = this.ScorePoint.score_data[0].strength;
        this.comment = this.ScorePoint.score_data[0].comment;
        this.selectPoint = this.ScorePoint.score_data[0].allopoint;
        this.assessor_comment = this.ScorePoint.score_data[0].assessor_comment;
        if (this.ScorePoint.score_data.length > 0) {
          this.jury_approved_comment = this.ScorePoint.score_data[0].status_comment;
        }

        if (this.ScorePoint.score_data[0].allopoint == '0') {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('0')
        }
        else if ((this.ScorePoint.score_data[0].allopoint == '5') || (this.ScorePoint.score_data[0].allopoint == '10') || (this.ScorePoint.score_data[0].allopoint == '15') || (this.ScorePoint.score_data[0].allopoint == '20') || (this.ScorePoint.score_data[0].allopoint == '25')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '30') || (this.ScorePoint.score_data[0].allopoint == '35') || (this.ScorePoint.score_data[0].allopoint == '40') || (this.ScorePoint.score_data[0].allopoint == '45') || (this.ScorePoint.score_data[0].allopoint == '50')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '55') || (this.ScorePoint.score_data[0].allopoint == '60') || (this.ScorePoint.score_data[0].allopoint == '65') || (this.ScorePoint.score_data[0].allopoint == '70') || (this.ScorePoint.score_data[0].allopoint == '75')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
        }

        else if ((this.ScorePoint.score_data[0].allopoint == '80') || (this.ScorePoint.score_data[0].allopoint == '85') || (this.ScorePoint.score_data[0].allopoint == '90') || (this.ScorePoint.score_data[0].allopoint == '95') || (this.ScorePoint.score_data[0].allopoint == '100')) {
          this.pointMeasurmentsList = [];
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
        }
      }
      else if (this.ScorePoint.score_data.length == 0) {
        this.selectPointType = '';
        this.strength = '';
        this.comment = '';
        this.selectPoint = '';
        this.assessor_comment = '';
        this.jury_approved_comment = null;
      }
    }
    else if (this.criteraNumber == 3) {
      this.ScorePoint = subcriteria;
      console.log(this.ScorePoint);
      this.critera_id = this.ScorePoint.id;
      this.criteraPoint = this.ScorePoint.point;
      if (this.ScorePoint.hire_order != 0) {
        this.CriteraNumber = criteraNumber + '.' + this.ScorePoint.hire_order;
      }
      if (this.ScorePoint.hire_order == 0) {
        this.CriteraNumber = criteraNumber + '.' + 1;
      }
      this.subcriteriaTitle = this.ScorePoint.title;

      var key = Object.keys(this.finaAassignmentDetails.award_measurments)[criteraNumber - 1];
      this.awardMeasurmentsList = [];
      this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];

      for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

        if (this.ScorePoint.hasOwnProperty('score_data')) {
          if (this.ScorePoint.score_data.length == 0) {
            this.thirdlevelSendApprovalStatus = false;
            this.jury_approved_comment = null;

            console.log(this.jury_approved_comment);
            let blankdata = {
              'allopoint': '0',
              'point_type': '0',
              'strength': '',
              'comment': '',
              'assessor_comment': '',
            }
            if (this.criteriatype_name_Array[i - 1] != undefined) {
              this.criteriatype_name_Array[i - 1]['ScorePoint'] = blankdata;
              if (blankdata.allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
            }
          }
        }
        if (!this.ScorePoint.hasOwnProperty('score_data')) {
          if (this.ScorePoint.hasOwnProperty(i)) {
            if (this.criteriatype_name_Array[i - 1].criteria_type_id == this.ScorePoint[i].score_data[0].criteria_type_id) {

              this.criteriatype_name_Array[i - 1]['ScorePoint'] = this.ScorePoint[i].score_data[0];

                this.jury_approved_comment = this.ScorePoint[1].score_data[0].status_comment;

              console.log(this.jury_approved_comment);
              if (this.ScorePoint[i].score_data[0].send_approval_status == 'Y') {
                this.thirdlevelSendApprovalStatus = true;
              }
              if (this.ScorePoint[i].score_data[0].send_approval_status == 'N') {
                this.thirdlevelSendApprovalStatus = false;
              }
              if (this.ScorePoint[i].score_data[0].allopoint == '0') {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('0');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '5') || (this.ScorePoint[i].score_data[0].allopoint == '10') || (this.ScorePoint[i].score_data[0].allopoint == '15') || (this.ScorePoint[i].score_data[0].allopoint == '20') || (this.ScorePoint[i].score_data[0].allopoint == '25')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '30') || (this.ScorePoint[i].score_data[0].allopoint == '35') || (this.ScorePoint[i].score_data[0].allopoint == '40') || (this.ScorePoint[i].score_data[0].allopoint == '45') || (this.ScorePoint[i].score_data[0].allopoint == '50')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '55') || (this.ScorePoint[i].score_data[0].allopoint == '60') || (this.ScorePoint[i].score_data[0].allopoint == '65') || (this.ScorePoint[i].score_data[0].allopoint == '70') || (this.ScorePoint[i].score_data[0].allopoint == '75')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }

              else if ((this.ScorePoint[i].score_data[0].allopoint == '80') || (this.ScorePoint[i].score_data[0].allopoint == '85') || (this.ScorePoint[i].score_data[0].allopoint == '90') || (this.ScorePoint[i].score_data[0].allopoint == '95') || (this.ScorePoint[i].score_data[0].allopoint == '100')) {
                this.pointMeasurmentsList = [];
                this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
                this.criteriatype_name_Array[i - 1]['PointList'] = this.pointMeasurmentsList;
              }
            }
          }
        }
      }
      this.rejetedData = this.ScorePoint.rejected_data.length > 0 ? this.ScorePoint.rejected_data : [];
      if (this.rejetedData.length > 0) {
        this.rejetedData.map((item) => {
          item['point_name'] = this.awardMeasurmentsList.find(o => o.points === item.point_type).measurement_title
        })
      }
    }
  }
  onChange($event) {
    this.pointMeasurmentsList = [];
    if ($event == '0') {
      this.pointMeasurmentsList.push('0')
    }
    else if ($event == '25') {
      this.pointMeasurmentsList.push('5', '10', '15', '20', '25')
    }
    else if ($event == '50') {
      this.pointMeasurmentsList.push('30', '35', '40', '45', '50')
    }
    else if ($event == '75') {
      this.pointMeasurmentsList.push('55', '60', '65', '70', '75')
    }
    else if ($event == '100') {
      this.pointMeasurmentsList.push('80', '85', '90', '95', '100')
    }
  }
  onChangeThirdLebel($event, index1) {
    this.criteriatype_name_Array.forEach((element, index) => {
      if (index == index1) {
        this.pointMeasurmentsList = [];
        if ($event == '0') {
          this.pointMeasurmentsList.push('0');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '25') {
          this.pointMeasurmentsList.push('5', '10', '15', '20', '25');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '50') {
          this.pointMeasurmentsList.push('30', '35', '40', '45', '50');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '75') {
          this.pointMeasurmentsList.push('55', '60', '65', '70', '75');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
        else if ($event == '100') {
          this.pointMeasurmentsList.push('80', '85', '90', '95', '100');
          this.criteriatype_name_Array[index]['PointList'] = this.pointMeasurmentsList;
        }
      }
    });
  }
  scoreBoarddetails() {
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'award_id': this.IdParams.id2,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'award_year_id': this.IdParams.id4
    }
    this.dashboardService.finalAwardScoreBoard(this.api_token, data).subscribe((res: any) => {
      // this.finaAassignmentDetails = res;
      this.criteriaList = res.criteria;
      this.mainCriteraNumber = 1;
      for (let i = 0; i < this.criteriaList.length; i++) {
        if (i == 0) {
          this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          var key = Object.keys(this.finaAassignmentDetails.award_measurments)[i];
          this.awardMeasurmentsList = this.finaAassignmentDetails.award_measurments[key];
          // this.pointMeasurmentsList.push('0');
        }
        if (i != 0) {
          if (this.criteriaList[i - 1].id == this.criteriaList[i].id) {
            this.criteriaList[i].criteria_number = this.mainCriteraNumber;
          }
          else {
            this.criteriaList[i].criteria_number = ++this.mainCriteraNumber;
          }
        }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  // add score point
  addScorepoint() {
    if (this.criteraNumber != 3) {
      console.log(this.ScorePoint)
      if ((this.ScorePoint.score_data.length == 0) || (this.ScorePoint.score_data[0].approved_status == 'P') && (this.ScorePoint.score_data[0].send_approval_status == 'N')) {
        console.log(this.ScorePoint)
        if ((this.strength != '') && (this.comment != '') && (this.selectPointType != '') && (this.selectPoint != '')
          && (this.strength != null) && (this.comment != null) && (this.assessor_comment != null)) {
          console.log(this.ScorePoint)
          let data = {
            'user_token': this.user_token,
            'api_token': this.api_token,
            'award_id': this.IdParams.id2,
            'criteria_id': this.critera_id,
            'company_id': this.IdParams.id,
            'group_id': this.IdParams.id3,
            'point_type': this.selectPointType,
            'allopoint': this.selectPoint ? this.selectPoint : '',
            'criteria_point': this.criteraPoint,
            'strength': this.strength ? this.strength : '',
            'comment': this.comment ? this.comment : '',
            'assessor_comment': this.assessor_comment ? this.assessor_comment : '',
            'award_year_id': this.IdParams.id4
          }
          console.log(data);
          this.processingtext = true;
          this.dashboardService.addFinalScorePoint(this.api_token, data).subscribe((res: any) => {
            console.log(res);
            if (res.has_error == 0) {
              this.processingtext = false;
              this.scoreBoarddetails();
              this.messagesService.showSuccess('Score Data Added successfully.');
              this.subcriteriaList.map((item) => {
                if (item.id == this.critera_id) {
                  item['score_data'] = [];
                  item.score_data.push(res.score_point);
                  item['total_point'] = item.score_data[0].point_percent;
                }
              })
              console.log(this.subcriteriaList)
            }
            if (res.has_error == 1) {
              this.processingtext = false;
              for (let [key, value] of Object.entries(res.msg)) {
                // console.log(`${key}: ${value}`);
                this.errorMessageValue = value;
              }
              this.messagesService.showError(this.errorMessageValue);
            }
          },
            err => {
              this.processingtext = false;
              this.messagesService.showError('Something went wrong.');
              console.log(err);
            })
        }
        else if ((this.strength == '') || (this.comment == '') || (this.selectPointType == '') || (this.selectPoint == '') || (this.strength == null) || (this.comment == null) && (this.assessor_comment == null)) {
          this.messagesService.showError('Please Fillup All the Credentials');
          setTimeout(() => {
            this.messagesService.clear();
          }, 1500);
        }
      }
      else if ((this.ScorePoint.score_data[0].approved_status == 'P') && (this.ScorePoint.score_data[0].send_approval_status == 'Y')) {
        this.messagesService.showError('Approval already send.You cant update now!');
        setTimeout(() => {
          this.messagesService.clear();
        }, 1500);
      }
    }
    else if (this.criteraNumber == 3) {
      this.addThirdLebelScorePoint();
    }
  }
  // add third score point
  addThirdLebelScorePoint() {
    this.thirdLebelStrength = [];
    this.thirdLebelComment = [];
    this.thirdLebelAllPoint = [],
      this.thirdLebelPointType = [],
      this.thirdLebelAllAssesorComment = []
    for (let i = 0; i <= this.criteriatype_name_Array.length; i++) {

      if (this.criteriatype_name_Array[i - 1] != undefined) {

        this.thirdLebelStrength.push(this.criteriatype_name_Array[i - 1].ScorePoint.strength ? this.criteriatype_name_Array[i - 1].ScorePoint.strength : '');

        this.thirdLebelComment.push(this.criteriatype_name_Array[i - 1].ScorePoint.comment ? this.criteriatype_name_Array[i - 1].ScorePoint.comment : '');

        this.thirdLebelPointType.push(this.criteriatype_name_Array[i - 1].ScorePoint.point_type ? this.criteriatype_name_Array[i - 1].ScorePoint.point_type : '');

        this.thirdLebelAllPoint.push(this.criteriatype_name_Array[i - 1].ScorePoint.allopoint ? this.criteriatype_name_Array[i - 1].ScorePoint.allopoint : '');

        this.thirdLebelAllAssesorComment.push(this.criteriatype_name_Array[i - 1].ScorePoint.assessor_comment ? this.criteriatype_name_Array[i - 1].ScorePoint.assessor_comment : '');
      }
    }

    // if((this.thirdLebelStrength.length > 0) && (this.thirdLebelComment.length > 0) && (this.thirdLebelAllPoint.length > 0) && (this.thirdLebelPointType.length > 0)){
    let data = {
      'user_token': this.user_token,
      'api_token': this.api_token,
      'award_id': this.IdParams.id2,
      'criteria_id': this.critera_id,
      'company_id': this.IdParams.id,
      'group_id': this.IdParams.id3,
      'point_type': this.thirdLebelPointType,
      'allopoint': this.thirdLebelAllPoint,
      'criteria_point': this.criteraPoint,
      'strength': this.thirdLebelStrength,
      'comment': this.thirdLebelComment,
      'assessor_comment': this.thirdLebelAllAssesorComment,
      'award_year_id': this.IdParams.id4
    }
    console.log(data);
    if (!this.thirdlevelSendApprovalStatus) {
      this.processingThirdLebeltext = true;
      this.dashboardService.addThirdLebelFinalScorePoint(this.api_token, data).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingThirdLebeltext = false;
          this.scoreBoarddetails();
          this.messagesService.showSuccess('Score Data Updated successfully.');
          this.subcriteriaList.map((item) => {
            if (item.id == this.critera_id) {
              item['total_point'] = 0;
              for (let i = 1; i <= this.criteriatype_name_Array.length; i++) {
                if (!item.hasOwnProperty('score_data')) {
                  if (item.hasOwnProperty(i)) {
                    item[i].score_data[0] = res.score_data_new[i - 1];
                    item['total_point'] += parseFloat(item[i].score_data[0].point_percent);
                    // item['total_point'] = Number(item['total_point'])
                    // item['total_point'] = Math.round((item['total_point'] + Number.EPSILON) * 100) / 100
                  }
                }
                if (item.hasOwnProperty('score_data')) {
                  if (this.criteriatype_name_Array.length == i) {
                    delete item['score_data'];
                  }
                  item[i] = {
                    'score_data': []
                  }
                  item[i].score_data[0] = res.score_data_new[i - 1];
                  item['total_point'] += parseFloat(item[i].score_data[0].point_percent);
                  // item['total_point'] = Number(item['total_point'])
                  // item['total_point'] = Math.round((item['total_point'] + Number.EPSILON) * 100) / 100
                }
              }
            }
          })
          console.log(this.subcriteriaList)
        }
        if (res.has_error == 1) {
          this.processingThirdLebeltext = false;
          this.messagesService.showError(res.msg);
        }
      },
        err => {
          this.processingThirdLebeltext = false;
          this.messagesService.showError('Something went wrong.');
        })
    }
    else if (this.thirdlevelSendApprovalStatus) {
      this.messagesService.showError('Approval already send.You cant update now!');
    }

    // }
    // else if((this.thirdLebelStrength.length == 0) || (this.thirdLebelComment.length == 0) || (this.thirdLebelAllPoint.length == 0) || (this.thirdLebelPointType.length == 0)){
    //   this.messagesService.showError('Please Fillup Atleast one Credentials');
    //   setTimeout(() => {
    //     this.messagesService.clear();
    //   }, 1500);
    // }
  }
  addEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      resize_enabled: false,
      // toolbar: {
      // items: ['bold','italic','underline','|','strikethrough','subscript','superscript','|','bulletedList','numberedList','|','blockQuote','codeBlock','code','horizontalLine']
      // },
      language: 'en-gb',
      licenseKey: '',

    })
      .then(editor => {
        console.log(editor)
      })
      .catch(error => {
        console.error(error);
      });
  }
}

export const appConfig ={
    apiUrl: 'https://administrator.theawards.org/api',
    user_name: 'innovationAward2019', 
    password: 'innovationAward',
    company_logo_path: 'https://administrator.theawards.org/assets/upload/profile_pictures/',
    siteLogoLink: 'https://administrator.theawards.org/assets/images/',




     //apiUrl:'http://dev4.technoxponent.com/innovationaward/public/api',
     //user_name: 'innovationAward2019', 
     //password: 'innovationAward',
     //company_logo_path: 'http://dev4.technoxponent.com/innovationaward/public/assets/upload/profile_pictures/',
     //siteLogoLink: 'http://dev4.technoxponent.com/innovationaward/public/assets/images/',
}
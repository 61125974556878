import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwardBannerComponent } from './award-banner.component';
import { MessagesService } from '../../services/messages.service';

@NgModule({
  declarations: [AwardBannerComponent],
  imports: [
    CommonModule
  ],
  exports: [AwardBannerComponent],
  providers: [MessagesService]
})
export class AwardBannerModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { MediaGalleryComponent } from './media-gallery.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MessagesService } from '../services/messages.service';
import { MeadiaService } from '../services/meadia.service';
import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { FacebookModule } from 'ngx-facebook';
// intro routing
export const routes: Routes = [
  { path: '', component: MediaGalleryComponent },
];

@NgModule({
  declarations: [MediaGalleryComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CarouselModule,
    NgxTwitterTimelineModule,
    FacebookModule.forRoot()
  ],
  providers: [
    MessagesService,
    MeadiaService
  ]
})
export class MediaGalleryModule { }

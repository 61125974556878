import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CompanyDashboardComponent } from './company-dashboard.component';
import { CompanyDocumentModule } from './company-document/company-document.module';
import { CompanyProfileModule } from './company-profile/company-profile.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AwardApplyModule } from './award-apply/award-apply.module';
import { AwardDetailsModule } from '../award-details/award-details.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// intro routing
export const routes: Routes = [
  { path: '', component: CompanyDashboardComponent },
];

@NgModule({
  declarations: [CompanyDashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CompanyDocumentModule,
    CompanyProfileModule,
    AwardApplyModule,
    AwardDetailsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgbModule
  ]
})
export class CompanyDashboardModule { }

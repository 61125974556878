import { Component, OnInit } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { MeadiaService } from '../services/meadia.service';
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-assessor',
  templateUrl: './our-assessor.component.html',
  styleUrls: ['./our-assessor.component.scss']
})
export class OurAssessorComponent implements OnInit {
  value: number = new Date().getFullYear() - 5;
  highValue: number = new Date().getFullYear();
  options: Options = {
    floor: new Date().getFullYear() - 5,
    ceil: new Date().getFullYear(),
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        default:
          return '';
      }
    }
  };
  awardYearShow: boolean = false;
  awardNameShow: boolean = true;
  currentYear: any;
  yearList: any = [];
  user_token: any;
  profilePicturePath: any;
  awardListArray: any = [];
  selectedAwardArray: any = [];
  selectedIndex: any;
  show: boolean = false;
  assessorList1Array: any = [];
  assessorList2Array: any = [];
  pageNumber: number = 1;
  totalNumber: number;
  loadSearch: boolean = false;
  filterArray: any = [];
  lazyLoad: boolean = false;
  assessorSearch: boolean = false;
  assessorShow: boolean = false;
  windowScroll: boolean = false;
  WithoutPageReload: boolean = false;
  assessorTextDetails: any;
  constructor(private meadiaService: MeadiaService,
    private authService: AuthService,
    private messagesService: MessagesService,
    private router: Router) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.getuserToken();
    this.awardNameShow = true;
    this.awardYearShow = false;
    this.onScroll();
    this.userAwardlist();
    this.assessorText();
  }

  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  userAwardlist() {
    this.authService.userAwardList(this.user_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        // this.awardListArray = res.award_data.filter(x => x.status == "Y");
        this.awardListArray = res.award_data;
        console.log(this.awardListArray)
      }
    }, err => {

    })
  }

  assessorText() {
    this.meadiaService.ourAssesorText().subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.assessorTextDetails = res.data;
      }
    }, err => {

  })
}
  assessorlist() {
    if (this.pageNumber == 1) {
      if (this.WithoutPageReload == false) {
        this.lazyLoad = true;
      }
      if (this.WithoutPageReload == true) {
        this.assessorShow = true;
      }
      let per_page = 2;
      this.pageNumber = null;
      this.meadiaService.assessorList(this.user_token, this.pageNumber, per_page).subscribe((res: any) => {
        if (res.has_error == 0) {
          if(this.WithoutPageReload == false){
            this.lazyLoad = false;
          }
          if (this.WithoutPageReload == true) {
            this.assessorShow = false;
          }
          setTimeout(() => {
            this.activeLineColour('awardName');
          }, 200);
          this.assessorList1Array = res.assessorList1;
          this.assessorList2Array = res.assessorList2;
          this.profilePicturePath = res.company_logo_path;
          this.pageNumber = 2;
          this.filterArray = this.assessorList1Array.concat(this.assessorList2Array);
          this.totalNumber = res.totalCount;
        }
      },
        err => {
          if(this.WithoutPageReload == false){
            this.lazyLoad = false;
          }
          if (this.WithoutPageReload == true) {
            this.assessorShow = false;
          }
        })
    }
    else if (this.pageNumber > 1) {
      let per_page = 2;
      this.pageNumber++;
      let skip = this.pageNumber;
      this.windowScroll = true;
      this.meadiaService.assessorList(this.user_token, skip, per_page).subscribe((res: any) => {
        if (res.has_error == 0) {
          setTimeout(() => {
            this.activeLineColour('awardName');
          }, 200);
          this.assessorList1Array = res.assessorList1;
          res.assessorList2.map((item) => {
            this.assessorList2Array.push(item)
          })
          this.filterArray = this.assessorList1Array.concat(this.assessorList2Array);
          this.totalNumber = res.totalCount;
          this.windowScroll = false;
        }
      },
        err => {
          this.windowScroll = false;
        })
    }
  }

  assessorSerach(param) {
    if (param == 'search') {
      this.assessorList1Array = [];
      this.assessorList2Array = [];
      let data = {
        searchYearStart: this.value,
        searchYearEnd: this.highValue,
        searchAward: this.selectedAwardArray
      }
      this.pageNumber = null;
      let per_page = 2;
      this.loadSearch = true;
      this.assessorSearch = true;
      this.assessorShow = true;
      this.meadiaService.assessorSearch(this.user_token, this.pageNumber, per_page, data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.assessorList1Array = res.assessorList1;
          this.assessorList2Array = res.assessorList2;
          this.assessorShow = false;
          this.totalNumber = res.totalCount;
          this.filterArray = this.assessorList1Array.concat(this.assessorList2Array);
        }
      }, err => {
        this.assessorShow = false;
      })
    }
    else if (param = 'scroll') {
      this.pageNumber = 2;
      let per_page = 2;
      this.pageNumber++;
      let skip = this.pageNumber;
      let data = {
        searchYearStart: this.loadSearch ? this.value : '',
        searchYearEnd: this.loadSearch ? this.highValue : '',
        searchAward: this.loadSearch ? this.selectedAwardArray : []
      }
      this.assessorSearch = true;
      this.windowScroll = true;
      this.meadiaService.assessorSearch(this.user_token, skip, per_page, data).subscribe((response: any) => {
        // console.log('response', response);
        if (response.has_error == 0) {
          this.totalNumber = response.totalCount;
          this.assessorList1Array = response.assessorList1;
          response.assessorList2.map((item) => {
            this.assessorList2Array.push(item);
          });
          this.filterArray = this.assessorList1Array.concat(this.assessorList2Array);
          this.windowScroll = false;
        }
      }, error => {
        this.windowScroll = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800)
        // console.error(error);
      });

    }
  }


  onScroll() {
    console.log('scrolled!!');
    if (this.assessorSearch == false) {
      this.assessorlist();
    }
    else if (this.assessorSearch == true) {
      this.assessorSerach('scroll');
    }
  }

  reset(id) {
    this.pageNumber = 1;
    this.WithoutPageReload = true;
    this.assessorShow = false;
    this.loadSearch = false;
    this.assessorSearch = false;
    this.filterArray = [];
    this.selectedAwardArray = [];
    this.value = new Date().getFullYear() - 5;
    this.highValue = new Date().getFullYear();
    this.awardFilteration(id);
    this.assessorlist();
    this.userAwardlist();
  }

  selectAwardName($event: any, item) {
    if ($event.srcElement.checked == true) {
      this.selectedAwardArray.push(Number(item.id));
      console.log(this.selectedAwardArray);
    }
    else if ($event.srcElement.checked == false) {
      const index = this.selectedAwardArray.indexOf(item.id);
      this.selectedAwardArray.splice(index, 1);
      console.log(this.selectedAwardArray);
    }
  }

  awardFilteration(id) {
    if (id == 'awardName') {
      this.awardYearShow = false;
      this.awardNameShow = true;
    }
    else if (id == 'awardYear') {
      this.awardNameShow = false;
      this.awardYearShow = true;
    }
    else if (id == 'resetAward') {
      this.awardYearShow = false;
      this.awardNameShow = true;
    }
    this.activeLineColour(id);
  }

  activeLineColour(id) {
    let parentEl = document.getElementById(id).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('button').classList.remove('active');
    });
    document.getElementById(id).className += ' active';
  }
}

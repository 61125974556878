import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfAssessmentComponent } from './self-assessment.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: SelfAssessmentComponent },
];

@NgModule({
  declarations: [SelfAssessmentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    NgScrollbarModule
  ]
})
export class SelfAssessmentModule { }

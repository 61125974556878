import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/_helpers/_guards/custom.validator';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-award-office-mgmt',
  templateUrl: './award-office-mgmt.component.html',
  styleUrls: ['./award-office-mgmt.component.scss']
})
export class AwardOfficeMgmtComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  user_token: any;
  api_token: any;
  // panelExpanded2: boolean = false;
  showAddForm: boolean = false;
  show_permission_form: boolean = false;
  officeManagementForm: FormGroup;
  submitted: boolean = false;
  profile_pic_show: any;
  display_profile_photo: any;
  processingText: boolean = false;
  formAction: any;
  profilePicturErr: any;
  errorMessageValue: any;
  awardOfficelist: any = [];
  lazyLoad: boolean = false;
  loadingCount: number = 0;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  statusList: any = [];
  profileImagePath: any;
  profile_photo: any;
  singleOfficedetails: any;
  searchName: any = '';
  searchEmail: any = '';
  searchPhone: any = '';
  searchUsername: any = '';
  companymanagementListArray: any = [];
  menulist: any = [];
  allSelectedMenuData: any = [];
  menuErr: string;
  permissionProcessing: boolean = false;
  menuLoad: boolean = false;
  permissionMsg: any;
  permissionStatus: boolean = false;
  searchCompany: any;
  allSelectedMenuId: any = [];
  settings = {};

  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/award-office-MGMT')
          this.leftbarselected = 'Award Office MGMT';
        this.dataService.leftPanelSelection('award_office_mgmt');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-MGMT`]);
    }
    this.statusList = [
      {
        id: 1,
        name: 'Active',
        status: 'Y'
      },
      {
        id: 2,
        name: 'In-Active',
        status: 'N'
      },
      {
        id: 3,
        name: 'Approved',
        status: 'A'
      }, {
        id: 4,
        name: 'Blocked',
        status: 'B'
      }
    ]
    this.officeManagementForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      user_name: [null],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      office_company: ['', [Validators.required]],
      status: [''],
      password: [''],
      confirm_password: [''],
    }, {
      validator: MustMatch('password', 'confirm_password')
    });
    this.awardOfficememberlist();
    this.companymanagementList();

    this.settings = {
      singleSelection: false,
      text: "Select Menu",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  get f() { return this.officeManagementForm.controls; }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  openAddForm(action, officeItem) {
    this.formAction = action;
    this.showAddForm = true;
    if ((this.formAction == 'add') && (officeItem == null)) {
      this.singleOfficedetails = null;
      this.officeManagementForm.reset();
      this.profile_pic_show = null;
      this.profile_photo = null;
      this.display_profile_photo = null;
    }
    if ((this.formAction == 'edit') && (officeItem != null)) {
      this.singleOfficedetails = officeItem;
      console.log(this.singleOfficedetails);
      this.officeManagementForm.patchValue({
        first_name: this.singleOfficedetails.first_name,
        last_name: this.singleOfficedetails.last_name,
        user_name: this.singleOfficedetails.user_name,
        email: this.singleOfficedetails.email,
        phone: this.singleOfficedetails.phone,
        city: this.singleOfficedetails.city,
        address: this.singleOfficedetails.address,
        office_company: this.singleOfficedetails.office_company,
        status: this.singleOfficedetails.is_active,
        password: this.officeManagementForm.value.password
      });
      this.display_profile_photo = this.singleOfficedetails.profile_picture != null ? this.profileImagePath + this.singleOfficedetails.profile_picture : 'assets/images/default-user.png';
      this.profile_photo = this.singleOfficedetails.profile_picture;
    }
  }
  closeAddForm() {
    this.awardOfficememberlist();
    this.showAddForm = false;
    this.searchName = '';
    this.searchEmail = '';
    this.searchPhone = '';
    this.searchUsername = '';
    this.panelExpanded = false;
  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }
  openshow_permission_form(item) {
    this.show_permission_form = true;
    this.singleOfficedetails = item;
    this.panelExpanded = false;
    console.log(this.singleOfficedetails);
    this.permissionMenu();
  }
  closeshow_permission_form() {
    this.show_permission_form = false;
    this.allSelectedMenuData = [];
    this.menuErr = '';
    this.panelExpanded = false;
  }
  onSelectPhoto(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_pic_show = event.target.files[0];
      console.log(this.profile_pic_show);
      if (this.profile_pic_show != null) {
        this.profilePicturErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
        // console.log(this.display_profile_photo)
      }
    }
  }
  awardOfficememberlist() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }

    this.dashboardService.officeMemberList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.awardOfficelist = res.awardOfficeList;
        this.profileImagePath = res.profile_image_path;
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);

      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  searchOffice() {
    let data = {
      searchName: this.searchName,
      searchEmail: this.searchEmail,
      searchPhone: this.searchPhone,
      searchUsername: this.searchUsername,
      company: this.searchCompany
    }
    this.dashboardService.seachAwardOffice(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.awardOfficelist = res.awardOfficeList;
        // this.profileImagePath = res.profile_image_path;
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  updateOfficeManagement() {
    if (this.formAction == 'add') {
      this.submitted = true;
      if (this.profile_pic_show == null) {
        this.profilePicturErr = 'Profile Picture required';
      }
      // stop here if form is invalid
      if (this.officeManagementForm.invalid) {
        return;
      }
      console.log(this.officeManagementForm)
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('first_name', this.officeManagementForm.value.first_name),
        formData.append('last_name', this.officeManagementForm.value.last_name),
        formData.append('phone', this.officeManagementForm.value.phone),
        formData.append('city', this.officeManagementForm.value.city),
        formData.append('address', this.officeManagementForm.value.address),
        formData.append('office_company', this.officeManagementForm.value.office_company),
        formData.append('email', this.officeManagementForm.value.email),
        formData.append('password', this.officeManagementForm.value.password)
      if (this.profile_pic_show != null) {
        formData.append('profile_picture', this.profile_pic_show)
      }
      if (this.profile_pic_show != null) {
        this.processingText = true;
        this.dashboardService.addOfficeMember(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processingText = false;
            this.closeAddForm();
            this.awardOfficememberlist();
            this.profile_pic_show = null;
            this.submitted = false;
            this.officeManagementForm.reset();

            this.messagesService.showSuccess('Office Member Added Successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.processingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
      }
      else if (this.profile_pic_show == null) {
        this.profilePicturErr = 'Profile Picture required';
      }
    }
    else if (this.formAction == 'edit') {
      this.submitted = true;
      // stop here if form is invalid
      if (this.officeManagementForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('first_name', this.officeManagementForm.value.first_name),
        formData.append('last_name', this.officeManagementForm.value.last_name),
        formData.append('user_name', this.officeManagementForm.value.user_name),
        formData.append('phone', this.officeManagementForm.value.phone),
        formData.append('city', this.officeManagementForm.value.city),
        formData.append('address', this.officeManagementForm.value.address),
        formData.append('office_company', this.officeManagementForm.value.office_company),
        formData.append('email', this.officeManagementForm.value.email),
        formData.append('status', this.officeManagementForm.value.status),
        formData.append('password', this.officeManagementForm.value.password),
        formData.append('award_office_id', this.singleOfficedetails.id)
      if (this.profile_pic_show != null) {
        formData.append('profile_picture', this.profile_pic_show)
      }
      if (this.profile_pic_show == null) {
        formData.append('profile_picture', this.profile_photo)
      }
      this.processingText = true;
      this.dashboardService.editOfficeMember(this.api_token, formData).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingText = false;
          this.closeAddForm();
          this.awardOfficememberlist();
          this.profile_pic_show = null;
          this.submitted = false;
          this.panelExpanded = false;
          this.officeManagementForm.reset();

          this.messagesService.showSuccess('Office Member Updated Successfully.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
      },
        error => {
          this.processingText = false;
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        })

    }
  }
  permissionMenu() {
    this.menuLoad = true;
    this.dashboardService.menuList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.menuLoad = false;
        this.menulist = res.menuList;
        this.allSelectedMenuData = [];
        // console.log(this.menulist.filter(o => this.singleOfficedetails.user_menus.find(o2 => o.id === o2)));
        // this.singleOfficedetails.user_menus = this.menulist.filter(o => this.singleOfficedetails.user_menus.find(o2 => o.id === o2))
        // this.singleOfficedetails.user_menus.map((item) => {
        //   this.allSelectedMenuData.push(item.id);
        // })
        this.menulist.map((menuItem) => {
          menuItem['itemName'] = menuItem.menu_name ;
          this.singleOfficedetails.user_menus.map((item) => {
            if (menuItem.id == item) {
              // this.allSelectedMenuData.push(item);
              this.allSelectedMenuData.push(menuItem);
            }
          })
        })
        console.log(this.allSelectedMenuData);
      }
    }, err => {
      this.menuLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  onItemSelect(item: any) {
    console.log(item);
    // console.log(this.allSelectedMenuData);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    // console.log(this.allSelectedMenuData);
  }
  onSelectAll(items: any) {
    console.log(items);
    this.allSelectedMenuData = [];
    this.allSelectedMenuData = items;
    // console.log('allSelectedMenuData',this.allSelectedMenuData);
  }
  onDeSelectAll(items: any) {
    console.log(items);
    this.allSelectedMenuData = [];
    // console.log('allSelectedMenuData',this.allSelectedMenuData);
  }

  selectMenu($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.allSelectedMenuData.indexOf(Item.id) == -1) {
        this.allSelectedMenuData.push(Item.id);
      }
      // if (this.allSelectedMenuData.length > 0) {
      //   this.menuErr = '';
      // }
    }
    else if ($event.srcElement.checked == false) {
      this.allSelectedMenuData.splice(this.allSelectedMenuData.indexOf($event.srcElement.value * 1), 1);
      // if (this.allSelectedMenuData.length == 0) {
      //   this.menuErr = 'Please Select One Member';
      // }
    }
    console.log(this.allSelectedMenuData)
  }
  updatePermission() {
    this.allSelectedMenuId = [];
    for(let item of this.allSelectedMenuData){
      this.allSelectedMenuId.push(item.id)
    }
    // console.log(this.allSelectedMenuId)
    let data = {
      'user_token': this.user_token,
      'user_id': this.singleOfficedetails.id,
      'user_menu': this.allSelectedMenuId
    }
    // if (this.allSelectedMenuData.length > 0) {
    this.menuErr = '';
    this.permissionProcessing = true;
    this.dashboardService.givePermission(this.api_token, data).subscribe((res: any) => {
      // if (res.has_error == 0) {
      this.permissionProcessing = false;
      this.panelExpanded = false;
      this.closeshow_permission_form();
      this.awardOfficememberlist();
      this.allSelectedMenuId = [];

      this.messagesService.showSuccess(res.msg);
      setTimeout(() => {
        this.messagesService.clear();
      }, 4000);
      // }
      if (res.has_error == 1) {
        this.permissionProcessing = false;
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
    // }
    // else if (this.allSelectedMenuData.length == 0) {
    //   this.menuErr = 'Please Select One menu';
    // }
  }
}

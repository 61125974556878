import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd} from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-office-archive-group',
  templateUrl: './office-archive-group.component.html',
  styleUrls: ['./office-archive-group.component.scss']
})
export class OfficeArchiveGroupComponent implements OnInit {
  leftbarselected: any;
  lazyLoad: boolean = false;
  user_token: any;
  api_token: any;
  awardOfficeDetails: any = [];
  singleOfficeDetails: any;
  closeResult: string;
  licenseDownloadLink: any;
  company_logo_path: any;
  userDetails: any;
  assessorDocsData: any =[];
  juryyDocumentDownloadLink: any;
  juryUploadDocumentLink: any;
  companyGeneralDocumentDownloadLink: any;
  criteriaName: any;
  companymanagementListArray: any =[];
  permissionMsg: any;
  permissionStatus: boolean = false;
  award_logo_path: any;
  award_background_image_path: any;
  awardOfficeRes: any;

  constructor(private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dataService: DataService,
    private router: Router) {
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd == true) {
          if (val.url == '/archive-group')
            this.leftbarselected = 'Award Office Archive Group';
          this.dataService.leftPanelSelection('award_office_dashboard');
        }
      });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
     }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`archive-group`]);
    }
    this.archivelist();
    this.companymanagementList();
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  archivelist() {
    this.lazyLoad = true;
    this.dashboardService.archiveList(this.api_token).subscribe((res: any) => {
      console.log(res);
      // if (res.has_error == 0) {
        this.lazyLoad = false;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.awardOfficeRes = res;
        this.awardOfficeDetails = res.office_dashboard_details;
        this.award_logo_path = res.award_image_path;
        this.award_background_image_path = res.award_image_path;
        // console.log(this.awardOfficeDetails);
      // }
    if (res.has_error == 1) {
      this.lazyLoad = false;
      this.permissionStatus = true;
      this.permissionMsg = res.msg;
      this.router.navigate(['/award-office-dashboard']);
    }
    }, err => {
      this.lazyLoad = false;
      console.log(err);
        this.messagesService.showError('Something went wrong.');
    })
  }
  eachOfficeDashboard(officeItem){
    localStorage.setItem('eachOfficeDetails', JSON.stringify(officeItem.eachAwardAssessment));
    localStorage.setItem('uploadPath', this.awardOfficeRes.company_logo_path);
    this.router.navigate(['award-office-archive']);
  }
}

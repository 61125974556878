import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-office-crteria-managemnet',
  templateUrl: './office-crteria-managemnet.component.html',
  styleUrls: ['./office-crteria-managemnet.component.scss']
})
export class OfficeCrteriaManagemnetComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  user_token: any;
  api_token: any;
  show_edit_form: boolean = false;
  criteriaListArray: any = [];
  lazyLoad: boolean = false;
  innerCriteriaListArray: any = [];
  searchName: any = '';
  maincriteriaAddForm: boolean = false;
  companymanagementListArray: any = [];
  permissionMsg: any;
  permissionStatus: boolean = false;
  CriteriaForm: FormGroup;
  submitted: boolean = false;
  loadingCount: number = 0;
  processingText: boolean = false;
  singleCriteriaDetails: any;
  formAction: any;
  rankArray: any = [];
  innerCriteriaListCount: any = [];
  parent_criteria_point: any;

  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/criteria-management')
          this.leftbarselected = 'Criteria Management';
        this.dataService.leftPanelSelection('criteria_management');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/criteria-management`]);
    }
    this.CriteriaForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      point: ['', [Validators.required]],
      description: ['', [Validators.required]],
      rank: ['', [Validators.required]],
    });
    this.criteriaList();
    this.companymanagementList();
  }
  get f() { return this.CriteriaForm.controls; }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  openshow_edit_form() {
    this.show_edit_form = true;
  }
  closeshow_edit_form() {
    this.show_edit_form = false;
  }
  criteriaList() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.criterialist(this.api_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.loadingCount = 1;
        this.permissionStatus = false;
        this.permissionMsg = '';
        this.criteriaListArray = res.criteria_list;
      }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  toggle(item, blankdata) {
    this.submitted = false;
    // console.log(item);
    var toggleClass = document.getElementById('toggle_' + item.id).className;
    if (toggleClass == 'fa fa-plus') {
      this.addPlus(item, toggleClass);
    }
    if (toggleClass == 'fa fa-minus') {
      this.removePlus(item, toggleClass);
    }
    // this.maincriteriaAddForm = false;
  }
  addPlus(item, toggleClass) {
    document.getElementById('toggle_' + item.id).className = 'fa fa-minus';
    this.innerCriteriaList(item);
  }
  removePlus(item, toggleClass) {
    document.getElementById('toggle_' + item.id).className = 'fa fa-plus';
    let child = document.getElementById('toggle_' + item.id).parentElement.parentElement.querySelectorAll(".child_element");
    // console.log(child.length);
    for (let i = 0; i < child.length; i++) {
      child[i].remove();
    }
    this.CriteriaForm.reset();
    this.rankArray = [];
  }
  innerCriteriaList(item) {
    this.dashboardService.innerCriteria(this.api_token, item.id).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.innerCriteriaListArray = res.criteria_list;
        this.innerCriteriaListArray = this.innerCriteriaListArray.reverse();
        this.getChildNodes(res, item);
      }

    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }

  getChildNodes(res, item) {
    this.innerCriteriaListArray.map((subitem) => {
      // console.log(subitem)
      let middle_data = document.getElementById('criteria_' + item.id).insertAdjacentHTML("afterend",
        '<ul style="margin-left: 30px;" class="child_element"><li><div id ="criteria_' + subitem.id + '" class="accordion" ><i id ="toggle_' + subitem.id + '" class="fa fa-plus"></i> <span class="criteria_title" >' + subitem.title + '</span> <div class="action_panel"><font class="criteria_point">' + subitem.point + '</font><a href="javascript:void(0);" class="edit" id="edit_criteria_' + subitem.id + '"><span class="material-icons">edit </span></a><a href="javascript:void(0);" class="delete" (click)="openDialog(subitem)" id="delete_criteria_' + subitem.id + '"><span class="material-icons">close </span></a><a href="javascript:void(0);" class="add" id="add_criteria_' + subitem.id + '"><span class="material-icons">add </span></a></div></div></li></ul>');
      document.getElementById('toggle_' + subitem.id).addEventListener("click", this.toggle.bind(this, subitem, false));
      document.getElementById('delete_criteria_' + subitem.id).addEventListener("click", this.openDialog.bind(this, subitem, false));
      document.getElementById('edit_criteria_' + subitem.id).addEventListener("click", this.editMainCriteria.bind(this, subitem, 'edit', false));
      document.getElementById('add_criteria_' + subitem.id).addEventListener("click", this.addSubCriteria.bind(this, subitem, 'add', false));
    })
  }

  searchAward() {
    let data = {
      searchName: this.searchName
    }
    this.dashboardService.mainAwardSearch(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.criteriaListArray = res.criteria_list;
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  openDialog(item) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this criteria?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteCriteria(item);
      }
    });
  }
  deleteCriteria(item) {
    let data = {
      criteria_id: item.id
    }
    this.dashboardService.deleteCriteria(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        document.getElementById('toggle_' + item.id).className = 'fa fa-plus';
        let child = document.getElementById('toggle_' + item.id).parentElement.parentElement.querySelectorAll(".child_element");
        // console.log(child.length);
        for (let i = 0; i < child.length; i++) {
          child[i].remove();
        }
        document.getElementById('criteria_' + item.id).remove();
        this.CriteriaForm.reset();
        this.submitted = false;
        this.messagesService.showSuccess(res.msg);
      }
    })
  }
  closeForm() {
    this.maincriteriaAddForm = false
  }
  addMainCriteria(item, action) {
    this.singleCriteriaDetails = item;
    this.formAction = action;
    console.log(this.singleCriteriaDetails);
    this.getInnerCriterialist(item);
    this.CriteriaForm.reset();
    this.submitted = false;
    if (item.childnode == 3) {
      this.maincriteriaAddForm = false;
      this.messagesService.showError('Maximum number of criteria is added');
      setTimeout(() => {
        this.messagesService.clear();
      }, 2000);
    }
    if (item.childnode < 3) {
      this.maincriteriaAddForm = true;
      this.rankArray = [];
      this.rankArray.push(item.childnode + 1);
      // console.log(this.rankArray);
    }
  }
  addSubCriteria(item, action) {
    this.singleCriteriaDetails = item;
    this.formAction = action;
    console.log(this.singleCriteriaDetails);
    this.getInnerCriterialist(item);
    this.CriteriaForm.reset();
    this.submitted = false;
    this.maincriteriaAddForm = true;
  }
  editMainCriteria(item, action) {
    this.formAction = action;
    this.submitted = false;
    this.maincriteriaAddForm = true;
    this.dashboardService.getCriteria(this.api_token, item.id).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        this.singleCriteriaDetails = res.criteria_details;
        console.log(this.singleCriteriaDetails);
        this.getInnerCriterialist(item);
        this.getCriteriaList();
        this.getChildCriterialist(this.singleCriteriaDetails.parent_id, item.id);
        console.log(this.singleCriteriaDetails);
        this.rankArray = [];
        this.rankArray.push(this.singleCriteriaDetails.criteria_type);
        console.log(this.rankArray);
        this.CriteriaForm.patchValue({
          title: this.singleCriteriaDetails.title,
          point: this.singleCriteriaDetails.point,
          description: this.singleCriteriaDetails.description,
          rank: this.singleCriteriaDetails.criteria_type,
        });
      }

    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  updateCriteria() {
    if (this.formAction == 'add') {
      if (this.singleCriteriaDetails.parent_id == 0) {
        this.submitted = true;
        if (this.CriteriaForm.invalid) {
          return;
        }
        if ((this.singleCriteriaDetails.count_point > (Number(this.CriteriaForm.value.point) + (this.singleCriteriaDetails.child_total_point))) || ((this.singleCriteriaDetails.count_point) == (Number(this.CriteriaForm.value.point) + (this.singleCriteriaDetails.child_total_point)))) {
          let data = {
            criteria_id: 0,
            award_parent_id: this.singleCriteriaDetails.id,
            rank_weight: 0,
            title: this.CriteriaForm.value.title,
            point: this.CriteriaForm.value.point,
            action: this.formAction,
            rank: this.CriteriaForm.value.rank,
            description: this.CriteriaForm.value.description
          }
          this.processingText = true;
          this.dashboardService.addCriteria(this.api_token, data).subscribe((res: any) => {
            console.log(res);
            if (res.has_error == 0) {
              this.processingText = false;
              this.submitted = false;
              this.CriteriaForm.reset();
              // this.closeForm();
              this.rankArray = [];
              var toggleClass = document.getElementById('toggle_' + this.singleCriteriaDetails.id).className;
              if (toggleClass == 'fa fa-plus') {
                this.addPlus(this.singleCriteriaDetails, toggleClass);
              }
              if (toggleClass == 'fa fa-minus') {
                document.getElementById('criteria_' + this.singleCriteriaDetails.id).insertAdjacentHTML("afterend",
                  '<ul style="margin-left: 30px;" class="child_element"><li><div id ="criteria_' + res.detail.id + '" class="accordion" ><i id ="toggle_' + res.detail.id + '" class="fa fa-plus"></i> <span class="criteria_title" >' + res.detail.title + '</span> <div class="action_panel"><font class="criteria_point">' + res.detail.point + '</font><a href="javascript:void(0);" class="edit" id="edit_criteria_' + res.detail.id + '"><span class="material-icons">edit </span></a><a href="javascript:void(0);" class="delete" (click)="openDialog(res.detail)" id="delete_criteria_' + res.detail.id + '"><span class="material-icons">close </span></a><a href="javascript:void(0);" class="add" id="add_criteria_' + res.detail.id + '"><span class="material-icons">add </span></a></div></div></li></ul>');
                document.getElementById('toggle_' + res.detail.id).addEventListener("click", this.toggle.bind(this, res.detail, false));
                document.getElementById('delete_criteria_' + res.detail.id).addEventListener("click", this.openDialog.bind(this, res.detail, false));
                document.getElementById('edit_criteria_' + res.detail.id).addEventListener("click", this.editMainCriteria.bind(this, res.detail, 'edit', false));
                document.getElementById('add_criteria_' + res.detail.id).addEventListener("click", this.addSubCriteria.bind(this, res.detail, 'add', false));
                this.messagesService.showSuccess('Criteria Added successfuly');
              }
            }
            if (res.has_error == 1) {
              this.processingText = false;
            }
          }, err => {
            this.messagesService.showError('Something went wrong');
          })
        }
        else if (((this.singleCriteriaDetails.count_point) < (Number(this.CriteriaForm.value.point) + (this.singleCriteriaDetails.child_total_point)))) {
          this.submitted = false;
          this.messagesService.showError('Please Increase Parent criteria Point first');
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }
      if (this.singleCriteriaDetails.parent_id != 0) {
        this.submitted = true;
        if (this.CriteriaForm.invalid) {
          return;
        }
        if ((this.singleCriteriaDetails.point > (Number(this.CriteriaForm.value.point) + (this.singleCriteriaDetails.child_total_point))) || ((this.singleCriteriaDetails.point) == (Number(this.CriteriaForm.value.point) + (this.singleCriteriaDetails.child_total_point)))) {
          let data = {
            criteria_id: 0,
            award_parent_id: this.singleCriteriaDetails.id,
            rank_weight: this.CriteriaForm.value.rank,
            title: this.CriteriaForm.value.title,
            point: this.CriteriaForm.value.point,
            action: this.formAction,
            rank: 0,
            description: this.CriteriaForm.value.description
          }
          console.log(data);
          this.processingText = true;
          this.dashboardService.addCriteria(this.api_token, data).subscribe((res: any) => {
            console.log(res);
            if (res.has_error == 0) {
              this.processingText = false;
              this.submitted = false;
              this.CriteriaForm.reset();
              // this.closeForm();
              this.rankArray = [];
              var toggleClass = document.getElementById('toggle_' + this.singleCriteriaDetails.id).className;
              if (toggleClass == 'fa fa-plus') {
                this.addPlus(this.singleCriteriaDetails, toggleClass);
              }
              if (toggleClass == 'fa fa-minus') {
                document.getElementById('criteria_' + this.singleCriteriaDetails.id).insertAdjacentHTML("afterend",
                  '<ul style="margin-left: 30px;" class="child_element"><li><div id ="criteria_' + res.detail.id + '" class="accordion" ><i id ="toggle_' + res.detail.id + '" class="fa fa-plus"></i> <span class="criteria_title" >' + res.detail.title + '</span> <div class="action_panel"><font class="criteria_point">' + res.detail.point + '</font><a href="javascript:void(0);" class="edit" id="edit_criteria_' + res.detail.id + '"><span class="material-icons">edit </span></a><a href="javascript:void(0);" class="delete" (click)="openDialog(res.detail)" id="delete_criteria_' + res.detail.id + '"><span class="material-icons">close </span></a><a href="javascript:void(0);" class="add" id="add_criteria_' + res.detail.id + '"><span class="material-icons">add </span></a></div></div></li></ul>');
                document.getElementById('toggle_' + res.detail.id).addEventListener("click", this.toggle.bind(this, res.detail, false));
                document.getElementById('delete_criteria_' + res.detail.id).addEventListener("click", this.openDialog.bind(this, res.detail, false));
                document.getElementById('edit_criteria_' + res.detail.id).addEventListener("click", this.editMainCriteria.bind(this, res.detail, 'edit', false));
                document.getElementById('add_criteria_' + res.detail.id).addEventListener("click", this.addSubCriteria.bind(this, res.detail, 'add', false));
                this.messagesService.showSuccess('Criteria Added successfuly');
              }
            }
            if (res.has_error == 1) {
              this.processingText = false;
            }
          }, err => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong');
          })
        }
        else if (((this.singleCriteriaDetails.point) < (Number(this.CriteriaForm.value.point) + (this.singleCriteriaDetails.child_total_point)))) {
          this.submitted = false;
          this.messagesService.showError('Please Increase Parent criteria Point first');
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }
    }
    if (this.formAction == 'edit') {
      if (this.singleCriteriaDetails.parent_id != 0) {
        this.submitted = true;
        if (this.CriteriaForm.invalid) {
          return;
        }

        let data = {
          criteria_id: this.singleCriteriaDetails.id,
          award_parent_id: this.singleCriteriaDetails.parent_id,
          rank_weight: this.CriteriaForm.value.rank,
          title: this.CriteriaForm.value.title,
          point: this.CriteriaForm.value.point,
          action: this.formAction,
          rank: 0,
          description: this.CriteriaForm.value.description
        }
        if ((this.CriteriaForm.value.point >= this.singleCriteriaDetails.child_total_point) && (this.CriteriaForm.value.point <= (this.parent_criteria_point - this.singleCriteriaDetails.rest_child_total_point))) {
          this.processingText = true;
          this.dashboardService.addCriteria(this.api_token, data).subscribe((res: any) => {
            console.log(res);
            if (res.has_error == 0) {
              this.processingText = false;
              this.submitted = false;
              var x = document.getElementById('criteria_' + this.singleCriteriaDetails.id);
              x.querySelector(".criteria_title").innerHTML = res.detail.title;
              x.querySelector(".criteria_point").innerHTML = res.detail.point;
              this.messagesService.showSuccess('Criteria Updated successfully');
            }
            if (res.has_error == 1) {
              this.processingText = false;
            }
          }, err => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong');
          })
        }
        else if (this.CriteriaForm.value.point < this.singleCriteriaDetails.child_total_point) {
          this.messagesService.showError('You cannot decrease the score');
        }
        else if (this.CriteriaForm.value.point > (this.parent_criteria_point - this.singleCriteriaDetails.rest_child_total_point)) {
          this.messagesService.showError('Your score should be less than' + (this.parent_criteria_point - this.singleCriteriaDetails.rest_child_total_point));
        }
      }
    }
  }
  getInnerCriterialist(item) {
    this.dashboardService.innerCriteria(this.api_token, item.id).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.innerCriteriaListCount = res.criteria_list.length;
        if (this.formAction == 'add') {
          this.rankArray = [];
          this.rankArray.push(this.innerCriteriaListCount + 1);
          console.log(this.rankArray);
        }
        let innerCriteriaArray = [];
        innerCriteriaArray = res.criteria_list;
        this.singleCriteriaDetails.child_total_point = 0
        innerCriteriaArray.map((item) => {
          this.singleCriteriaDetails.child_total_point += Number(item.point);
        })
      }

    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  getCriteriaList() {
    this.dashboardService.criterialist(this.api_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        let criteriaArray = [];
        criteriaArray = res.criteria_list;
        let criteriaIdArray = [];
        criteriaArray.map((x) => {
          criteriaIdArray.push(x.id)
        })
        if (criteriaIdArray.includes(this.singleCriteriaDetails.parent_id)) {
          if (criteriaArray.length > 0) {
            this.parent_criteria_point = criteriaArray.find((x) => x.id === this.singleCriteriaDetails.parent_id).count_point;
            console.log(this.parent_criteria_point);
          }
        }
        else if (!criteriaIdArray.includes(this.singleCriteriaDetails.parent_id)) {
          this.dashboardService.getCriteria(this.api_token, this.singleCriteriaDetails.parent_id).subscribe((res: any) => {
            // console.log(res);
            if (res.has_error == 0) {
              this.parent_criteria_point = res.criteria_details.point;
              console.log(this.parent_criteria_point);
            }

          }, err => {
            this.messagesService.showError('Something went wrong');
          })
        }
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  getChildCriterialist(parent_id, child_id) {
    this.dashboardService.innerCriteria(this.api_token, parent_id).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        let childArray = [];
        childArray = res.criteria_list;
        // console.log(childArray);
        // console.log(child_id);
        if (childArray.length > 0) {
          console.log(childArray.filter((x) => x.id != child_id));
        }
        this.singleCriteriaDetails.rest_child_total_point = 0
        childArray.filter((x) => x.id != child_id).map((item) => {
          this.singleCriteriaDetails.rest_child_total_point += Number(item.point);
        })
        console.log(this.singleCriteriaDetails.rest_child_total_point);
      }

    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}

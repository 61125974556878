import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagesService } from '../services/messages.service';
import { DataService } from '../services/data.service';
import { appConfig } from '../app.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  user_token: any;
  AccountTypeList: any = [];
  show: boolean;
  errorMessageValue: any;
  processingText: boolean = false;
  rememberMe: boolean = false;
  user_data: any;
  upperCaseErrMsg: any = '';
  NumberErrMsg: any = '';
  lazyLoad: boolean = false;
  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private messagesService: MessagesService,
    private dataService: DataService) {
    this.user_token = localStorage.getItem('user_token');
    this.show = false;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      // password: ['', [Validators.required, Validators.minLength(8)]],
      user_type: ['', Validators.required]
    });
    this.getuserToken();
    this.getAccountTypes();
    if (JSON.parse(localStorage.getItem('RememberMe')) !== null) {
      this.user_data = JSON.parse(localStorage.getItem('user_data'));
      this.rememberMe = JSON.parse(localStorage.getItem('RememberMe'));
      this.loginForm.patchValue({
        email: this.user_data.email,
        password: this.user_data.password,
        user_type: this.user_data.user_type
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  getAccountTypes() {
    this.lazyLoad = true;
    this.authService.accountType(this.user_token).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.AccountTypeList = res.userTypes;
      }
    }, err => {
      this.lazyLoad = false;
    })
  }
  // click event function toggle
  password() {
    this.show = !this.show;
  }

  // onKey(event: any) { // without type info
  //   let upperCaseCharacters = /[A-Z]+/;
  //   let lowerCaseCharacters = /[a-z]+/g;
  //   let numberCharacters = /[0-9]+/;
  //   let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  //   if (event.target.value != '') {
  //     this.upperCaseErrMsg = 'Password must contain at least one uppercase letters';
  //     this.NumberErrMsg = 'Password must contain at least one number';
  //     if ((upperCaseCharacters.test(event.target.value) === false)) {
  //       this.upperCaseErrMsg = 'Password must contain at least one uppercase letters';

  //     }
  //     if ((upperCaseCharacters.test(event.target.value) === true)) {
  //       this.upperCaseErrMsg = '';
  //     }
  //     if ((numberCharacters.test(event.target.value) === false)) {
  //       this.NumberErrMsg = 'Password must contain at least one number';
  //     }
  //     if ((numberCharacters.test(event.target.value) === true)) {
  //       this.NumberErrMsg = '';
  //     }
  //   }
  //   else if (event.target.value == '') {
  //     this.NumberErrMsg = '';
  //     this.upperCaseErrMsg = '';
  //   }
  // }
  toggleVisibility(e) {
    this.rememberMe = e.target.checked;
  }

  LoginFormSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      user_type: this.loginForm.value.user_type,
    }
    // if ((this.NumberErrMsg == '') && (this.upperCaseErrMsg == '')) {
      this.processingText = true;
      this.authService.login(data).subscribe((res: any) => {
        // console.log(res)
        if (res.has_error == 0) {
          if (this.rememberMe == true) {
            localStorage.setItem('user_data', JSON.stringify({
              email: this.loginForm.value.email,
              password: this.loginForm.value.password,
              user_type: this.loginForm.value.user_type
            }));
            localStorage.setItem('RememberMe', JSON.stringify(this.rememberMe));
          }
          else if (this.rememberMe == false) {
            localStorage.removeItem('user_data');
            localStorage.removeItem('RememberMe');
          }
          localStorage.setItem('api_token', res.user.api_token);
          localStorage.setItem('userDetails', JSON.stringify(res.user));
          // this.dataService.userData(res.user);
          this.processingText = false;
          this.messagesService.showSuccess('You have loggedIn successfully');
          this.router.navigate(['/assesor-dashboard']);

          if (res.user.user_type == '6') {
            this.router.navigate([`/assesor-dashboard`]);
          }
          else if (res.user.user_type == '7') {
            this.router.navigate([`/jury-group-dashboard`]);
          }
          else if (res.user.user_type == '5') {
            this.router.navigate([`/company-dashboard`]);
          }

          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
        else if (res.has_error == 1) {
          this.processingText = false;
          this.errorMessageValue = res.error;
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }
      }, err => {
        this.processingText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 2000);
      })
    // }
    // else if (this.user_token == null) {
    //   this.messagesService.showError('User token is missing.');
    //   setTimeout(() => {
    //     this.messagesService.clear();
    //   }, 2000);
    // }
  }
}

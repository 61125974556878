import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AwardListComponent } from './award-list.component';
import { WinnerService } from '../services/winner.service';
import { MessagesService } from '../services/messages.service';
// intro routing
export const routes: Routes = [
  { path: '', component: AwardListComponent },
];

@NgModule({
  declarations: [AwardListComponent],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    RouterModule.forChild(routes)
  ],
  providers: [WinnerService, MessagesService]
})
export class AwardListModule { }

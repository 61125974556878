import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { OurJuriesComponent } from './our-juries.component';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule } from '@angular/forms';
import { MeadiaService } from '../services/meadia.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// intro routing
export const routes: Routes = [
  { path: '', component: OurJuriesComponent },
];

@NgModule({
  declarations: [OurJuriesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    Ng5SliderModule,
    FormsModule,
    InfiniteScrollModule
  ],
  providers:[MeadiaService]
})
export class OurJuriesModule { }

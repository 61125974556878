import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ViewAllMediaComponent } from './view-all-media.component';
import { MeadiaService } from '../services/meadia.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MessagesService } from '../services/messages.service';

// intro routing
export const routes: Routes = [
  { path: '', component: ViewAllMediaComponent },
];

@NgModule({
  declarations: [ViewAllMediaComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule
  ],
  providers: [MeadiaService , MessagesService]
})
export class ViewAllMediaModule { }

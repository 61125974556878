import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-award-banner',
  templateUrl: './award-banner.component.html',
  styleUrls: ['./award-banner.component.scss']
})
export class AwardBannerComponent implements OnInit {
  awardId: any;
  awardYearId: any;
  award_image_path: any;
  award_details: any =[];
  user_token: any;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private messagesService: MessagesService,
    private dataService: DataService) {
      this.activatedRoute.params.subscribe(params => {
        this.awardId = params.id;
        this.awardYearId = params.id1;
      });
      this.user_token = localStorage.getItem('user_token');
      setTimeout(() => {
      }, 500);
     }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.AwardDetails();
  }
  AwardDetails() {
    this.authService.awardDetails(this.user_token, this.awardId).subscribe((res: any) => {
      if (res.has_error == 0) {
        console.log(res);
        this.award_details =  res.awardDetails.filter(x => x.year_id === Number(this.awardYearId));
        console.log(this.award_details)
        this.award_image_path = res.award_image_path;
        this.dataService.awarddetails(this.award_details);
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      })
  }
}

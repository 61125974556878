import { Injectable } from '@angular/core';
import {MessageService} from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private messageService: MessageService) { }
  showSuccess(msessage: any) {
    // this.messageService.add({severity:'success', summary:msessage});
    this.messageService.add({severity:'success', summary:'Success Message', detail: msessage});
  }
  showError(msessage: any) {
      // this.messageService.add({severity:'error', summary:msessage});
      this.messageService.add({severity:'error', summary:'Error Message', detail: msessage});
  }
  showWarning(msessage: any) {
    // this.messageService.add({severity:'warning', summary:msessage});
    this.messageService.add({severity:'warn', summary:'Warn Message', detail: msessage});
  }
  clear() {
    this.messageService.clear();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSelfAssessmentComponent } from './edit-self-assessment.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// intro routing
export const routes: Routes = [
  { path: '', component: EditSelfAssessmentComponent },
];

@NgModule({
  declarations: [EditSelfAssessmentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgScrollbarModule,
    NgbModule,
    CKEditorModule
  ]
})
export class EditSelfAssessmentModule { }

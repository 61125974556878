import { Component, OnInit } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { MeadiaService } from '../services/meadia.service';
import { appConfig } from '../app.config';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages.service';
import { Router } from '@angular/router';
import { WinnerService } from '../services/winner.service';

@Component({
  selector: 'app-award-winner',
  templateUrl: './award-winner.component.html',
  styleUrls: ['./award-winner.component.scss']
})
export class AwardWinnerComponent implements OnInit {
  value: number = new Date().getFullYear() - 5;
  highValue: number = new Date().getFullYear();
  options: Options = {
    floor: new Date().getFullYear() - 5,
    ceil: new Date().getFullYear(),
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        default:
          return '';
      }
    }
  };
  awardYearShow: boolean = false;
  awardNameShow: boolean = true;
  currentYear: any;
  yearList: any = [];
  user_token: any;
  awardListArray: any = [];
  selectedAwardArray: any = [];
  selectedIndex: any;
  show: boolean = false;
  pageNumber: number = 1;
  totalNumber: number = 0;
  loadSearch: boolean = false;
  filterArray: any = [];
  lazyLoad: boolean = false;
  winnerSearch: boolean = false;
  winnerShow: boolean = false;
  windowScroll: boolean = false;
  WithoutPageReload: boolean = false;
  hidecheck: boolean = true;
  winnerListArray: any = [];
  companyLogoPath: any;
  award_image_path: any;
  winnersImagePath: any;
  constructor(private meadiaService: MeadiaService,
    private authService: AuthService,
    private messagesService: MessagesService,
    private router: Router,
    private winnerService: WinnerService) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    this.getuserToken();
    this.awardNameShow = true;
    this.awardYearShow = false;
    this.userAwardlist();
    this.winnerlist();
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }

  // open website url
  windowOpen(url) {
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    return window.open(url);
  }
  
  show_more()
  {
	  this.hidecheck=!this.hidecheck;
  }

  userAwardlist() {
    this.authService.userAwardList(this.user_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        // this.awardListArray = res.award_data.filter(x => x.status == "Y");
        this.awardListArray = res.award_data;
        console.log(this.awardListArray)
      }
    }, err => {

    })
  }

  // award list
  winnerlist() {
    if (this.WithoutPageReload == false) {
      this.lazyLoad = true;
    }
    if (this.WithoutPageReload == true) {
      this.winnerShow = true;
    }
    let per_page = 2;
    let page_no = this.pageNumber;
    this.winnerService.winnerList(this.user_token, this.pageNumber, per_page).subscribe((response: any) => {
      if (response.has_error == 0) {
        this.winnersImagePath = response.imagePath;
        this.award_image_path = response.award_image_path;
        this.companyLogoPath = response.company_logo_path;
        if(this.WithoutPageReload == false){
          this.lazyLoad = false;
        }
        if (this.WithoutPageReload == true) {
          this.winnerShow = false;
        }
        setTimeout(() => {
          this.activeLineColour('awardName');
        }, 200);
        this.winnerListArray = response.winnerList;
        this.filterArray = this.winnerListArray;
        this.totalNumber = response.totalCount;
      }
    }, error => {
      if(this.WithoutPageReload == false){
        this.lazyLoad = false;
      }
      if (this.WithoutPageReload == true) {
        this.winnerShow = false;
      }
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800)
      // console.error(error);
    });
  }
  // load more awards
  loadMoreWinners() {
    let per_page = 2;
    this.pageNumber++;
    let skip = this.pageNumber;
    this.windowScroll = true;
    this.winnerService.winnerList(this.user_token,skip, per_page).subscribe((response: any) => {
      // console.log('response', response);
      if (response.has_error == 0) {
        this.winnersImagePath = response.imagePath;
        response.winnerList.map((item) => {
          this.winnerListArray.push(item);
        })
        this.filterArray = this.winnerListArray;
        this.totalNumber = response.totalCount;
        this.windowScroll = false;
      }
    }, error => {
      this.windowScroll = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800)
      // console.error(error);
    });
  }

  winnerSerach(param) {
    if (param == 'search') {
      this.winnerListArray = [];
      let data = {
        searchYearStart: this.value,
        searchYearEnd: this.highValue,
        searchAward: this.selectedAwardArray
      }
      this.pageNumber = 1;
      let per_page = 2;
      this.loadSearch = true;
      this.winnerSearch = true;
      this.winnerShow = true;
      this.winnerService.winnerSearch(this.user_token, this.pageNumber, per_page, data).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.winnerListArray = res.winnerList;
          this.winnerShow = false;
          this.totalNumber = res.totalCount;
          this.filterArray = this.winnerListArray;
        }
      }, err => {
        this.winnerShow = false;
      })
    }
    else if (param = 'scroll') {
      let per_page = 2;
      this.pageNumber++;
      let skip = this.pageNumber;
      let data = {
        searchYearStart: this.loadSearch ? this.value : '',
        searchYearEnd: this.loadSearch ? this.highValue : '',
        searchAward: this.loadSearch ? this.selectedAwardArray : []
      }
      this.winnerSearch = true;
      this.windowScroll = true;
      this.winnerService.winnerSearch(this.user_token, skip, per_page, data).subscribe((response: any) => {
        // console.log('response', response);
        if (response.has_error == 0) {
          this.totalNumber = response.totalCount;
          response.winnerList.map((item) => {
            this.winnerListArray.push(item);
          });
          this.filterArray = this.winnerListArray;
          this.windowScroll = false;
        }
      }, error => {
        this.windowScroll = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800)
        // console.error(error);
      });

    }
  }


  onScroll() {
    console.log('scrolled!!');
    if (this.winnerSearch == false) {
      this.loadMoreWinners();
    }
    else if (this.winnerSearch == true) {
      this.winnerSerach('scroll');
    }
  }

  reset(id) {
    this.pageNumber = 1;
    this.WithoutPageReload = true;
    this.winnerShow = false;
    this.loadSearch = false;
    this.winnerSearch = false;
    this.filterArray = [];
    this.selectedAwardArray = [];
    this.value = new Date().getFullYear() - 5;
    this.highValue = new Date().getFullYear();
    this.awardFilteration(id);
    this.winnerlist();
    this.userAwardlist();
  }

  selectAwardName($event: any, item) {
    if ($event.srcElement.checked == true) {
      this.selectedAwardArray.push(Number(item.id));
      console.log(this.selectedAwardArray);
    }
    else if ($event.srcElement.checked == false) {
      const index = this.selectedAwardArray.indexOf(item.id);
      this.selectedAwardArray.splice(index, 1);
      console.log(this.selectedAwardArray);
    }
  }

  awardFilteration(id) {
    if (id == 'awardName') {
      this.awardYearShow = false;
      this.awardNameShow = true;
    }
    else if (id == 'awardYear') {
      this.awardNameShow = false;
      this.awardYearShow = true;
    }
    else if (id == 'resetAward') {
      this.awardYearShow = false;
      this.awardNameShow = true;
    }
    this.activeLineColour(id);
  }

  activeLineColour(id) {
    let parentEl = document.getElementById(id).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('button').classList.remove('active');
    });
    document.getElementById(id).className += ' active';
  }
}

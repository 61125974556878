import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/_helpers/_guards/custom.validator';
import { JudgeService } from '../../services/judge.service';
import { AuthService } from '../../services/auth.service';
// import { saveAs as fileSaverSave } from 'file-saver'
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  leftbarselected; any;
  show_personal_information: boolean = false;
  show_professional_information: boolean = false;
  show_industry_information: boolean = false;
  show_change_password: boolean = false;
  user_token: any;
  api_token: any;
  userDetails: any;
  profileUpdateOneForm: FormGroup;
  profileUpdateTwoForm: FormGroup;
  profileUpdateThreeForm: FormGroup;
  updatePasswordForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  submitted4: boolean = false;
  profileImagePath: any;
  gendersList: any = [];
  QualificationsList: any = [];
  AllCountryList: any = [];
  NationalityList: any = [];
  AgeGroupsList: any = [];
  YearsofWorkExperienceList: any = [];
  ProfessionalAssessmentExperienceList: any = [];
  AssessmentCompletesList: any = [];
  ExternalAwardsParticipatesList: any = [];
  InnovationHandsonExperiencesList: any = [];
  AreasofExpertisesList: any = [];
  ExternalAwardAssessmentTeamLeader_yearsList: any = [];
  ProfessionalUseLanguagesList: any = [];
  ConfirmationsList: any = [];
  EmploymentStatusesList: any = [];
  IndustryList: any = [];
  cityListArray: any = [];
  upperCaseNewErrMsg: string;
  NumberNewErrMsg: string;
  profile_CV: any;
  profile_photo: any;
  display_profile_photo: any;
  processingPasswordText: boolean = false;
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  namePrefixsList: any = [];
  ExternalAwardsParticipatedErr: any;
  HandsonExperiencesErr: any;
  AreasofExpertisesErr: any;
  lazyLoad: boolean = false;
  webSitePatternErr: any;
  processingOneText: boolean = false;
  processingTwoText: boolean = false;
  processingThreeText: boolean = false;
  award_id: any;
  errorMessageValue: any;
  loadingCount: number = 0;
  PrefixNameErr: any;
  profile_photo_show: null;
  profile_cv_show: null;
  ProfessionalCVErr: any;
  awardId: any = [];
  profileCVPath: any;
  ExternalAwards_participated_array: any = [];
  assesorAwardIdArray: any = [];
  handsonExperienceIdArray: any = [];
  Areas_of_ExpertiseId_Array: any = [];
  External_Awards_ParticipatedId_Array: any = [];
  profilePicErr: any;
  awardsParticipatedErr: any = '';
  areasofExpertisesErr: any = '';
  documentType: any;
  fileTypeText: any;

  settings1 = {};
  settings2 = {};
  settings3 = {};
  ExternalAwardsParticipatesSelectedItem: any = [];
  InnovationHandsonExperiencesSelectedItem: any = [];
  AreasofExpertisesSelectedItem: any = [];
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService,
    private authService: AuthService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/assesor-profile')
          this.leftbarselected = 'profile';
        this.dataService.leftPanelSelection('profile');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-profile`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.updatePasswordForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]
    }, {
      validator: MustMatch('new_password', 'confirm_password')
    });
    this.profileUpdateOneForm = this.formBuilder.group({
      namePrefix: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      city: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      country: ['', [Validators.required]],
      qualification: ['', [Validators.required]],
      mobile_no: ['', [Validators.required]],
      mobile_country_code: ['', [Validators.required]],
      whatsapp_no: ['', [Validators.required]],
      whatsapp_country_code: ['', [Validators.required]],
      graduatedIn: ['', [Validators.required]],
      work_email: ['', [Validators.required]],
      personal_email: ['', [Validators.required]],
      age_group: ['', [Validators.required]],
      license_code: ['']
    });

    this.profileUpdateTwoForm = this.formBuilder.group({
      attended_award_training: ['', [Validators.required]],
      participated_award_training: ['', [Validators.required]],
      professional_assessment_language: ['', [Validators.required]],
      report_writing_language: ['', [Validators.required]],
      employment_status: ['', [Validators.required]],
      current_employer: ['', [Validators.required]],
      job_title: ['', [Validators.required]],
      Industry: ['', [Validators.required]],
      LinkedIn_profile_link: ['', [Validators.required]],
      // annual_leave: ['', [Validators.required]],
      Business_Travel_leave: ['', [Validators.required]],
      interested_participate_award_year: ['', [Validators.required]]
    });
    this.profileUpdateThreeForm = this.formBuilder.group({
      total_work_experience: ['', [Validators.required]],
      professional_assessment_experience: ['', [Validators.required]],
      professional_certifications: ['', [Validators.required]],
      Internal_assessment_completed: ['', [Validators.required]],
      External_assessment_completed: ['', [Validators.required]],
      external_award_assessment_completed: ['', [Validators.required]],
      external_awards_teamLeader: ['', [Validators.required]],
      no_of_years_assessment_team_leader: ['', [Validators.required]],
      // External Awards Participated array
    });

    this.getUserDetails();
    this.callingCodes();
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';

    this.settings1 = {
      singleSelection: false,
      text: "Select Hands-on Experience",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settings2 = {
      singleSelection: false,
      text: "Select Areas of Expertise",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settings3 = {
      singleSelection: false,
      text: "Select External Awards Participated",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }
  get f() { return this.updatePasswordForm.controls; }

  get f1() { return this.profileUpdateOneForm.controls; }
  get f2() { return this.profileUpdateTwoForm.controls; }
  get f3() { return this.profileUpdateThreeForm.controls; }
  openshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  closeshow_personal_information() {
    this.show_personal_information = !this.show_personal_information;
  }
  openshow_professional_information() {
    this.show_professional_information = !this.show_professional_information;
  }
  closeshow_professional_information() {
    this.show_professional_information = !this.show_professional_information;
  }
  openshow_industry_information() {
    this.show_industry_information = !this.show_industry_information;
  }
  closeshow_industry_information() {
    this.show_industry_information = !this.show_industry_information;
  }
  openshow_change_password() {
    this.show_change_password = !this.show_change_password;
  }
  closeshow_change_password() {
    this.show_change_password = !this.show_change_password;
    this.submitted1 = false;
    this.updatePasswordForm.reset();
    this.upperCaseNewErrMsg = '';
    this.NumberNewErrMsg = '';
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  getUserDetails() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.getUserDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.loadingCount = 1;
        if (this.loadingCount == 1) {
          this.dataService.userdetails(res);
          this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
          this.userDetails.name = res.user_details.user.name;
          this.userDetails.profile_picture = res.user_details.user.profile_picture;
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        }
        this.userDetails = res.user_details;
        // this.profileCVPath = res.cv_path;
        this.profileCVPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/Profile_CV/';
        this.awardId = [];
        this.assesorAwardIdArray = [];
        res.awardUser.map((item) => {
          this.assesorAwardIdArray.push(item.year_id)
        })

        this.namePrefixsList = res.NamePrefixs;
        this.gendersList = res.genders;
        this.QualificationsList = res.Qualifications;
        this.AgeGroupsList = res.AgeGroups;
        this.NationalityList = res.Nationalities;
        this.AllCountryList = res.all_country;
        this.YearsofWorkExperienceList = res.YearsofWorkExperiences;
        this.ProfessionalAssessmentExperienceList = res.ProfessionalAssessmentExperiences;
        this.AssessmentCompletesList = res.AssessmentCompletes;
        this.ExternalAwardsParticipatesList = res.ExternalAwardsParticipates;
        this.InnovationHandsonExperiencesList = res.InnovationHandsonExperiences;
        this.AreasofExpertisesList = res.AreasofExpertises;
        this.InnovationHandsonExperiencesList.map((item) => {
          item['itemName'] = item.trans_data;
          item['handson_experiences_id'] = Number(item.innovation_handson_experiences_id);
        })
        this.ExternalAwardsParticipatesList.map((item) => {
          item['itemName'] = item.trans_data;
          item['awards_participateds_id'] = Number(item.external_awards_participateds_id);
        });
        this.AreasofExpertisesList.map((item) => {
          item['itemName'] = item.trans_data;
          item['expertises_id'] = Number(item.areasof_expertises_id);
        });

        this.External_Awards_ParticipatedId_Array = [];
        this.ExternalAwardsParticipatesSelectedItem = [];
        this.handsonExperienceIdArray = [];
        this.InnovationHandsonExperiencesSelectedItem = [];
        this.Areas_of_ExpertiseId_Array = [];
        this.AreasofExpertisesSelectedItem = [];

        if ((this.userDetails.Areas_of_Expertise === false) || (this.userDetails.Areas_of_Expertise === null)) {
          this.Areas_of_ExpertiseId_Array = [];
        }
        else if (this.userDetails.Areas_of_Expertise.length > 0) {
          this.userDetails.Areas_of_Expertise.map((item) => {
            this.AreasofExpertisesList.map((item1) => {
              if (item1.areasof_expertises_id == item) {
                this.AreasofExpertisesSelectedItem.push(item1);
              }
            })

            // if (this.Areas_of_ExpertiseId_Array.indexOf(Number(item)) == -1) {
            //   this.Areas_of_ExpertiseId_Array.push(Number(item))
            // }
          });
        }
        if ((this.userDetails.Innovation_Hands_on_Experience == false) || (this.userDetails.Innovation_Hands_on_Experience === null)) {
          this.handsonExperienceIdArray = [];
        }
        else if (this.userDetails.Innovation_Hands_on_Experience.length > 0) {
          this.userDetails.Innovation_Hands_on_Experience.map((item) => {
            this.InnovationHandsonExperiencesList.map((item2) => {
              if (item2.innovation_handson_experiences_id == item) {
                this.InnovationHandsonExperiencesSelectedItem.push(item2);
              }
            })

            // if (this.handsonExperienceIdArray.indexOf(Number(item)) == -1) {
            //   this.handsonExperienceIdArray.push(Number(item))
            // }
          });
        }
        if ((this.userDetails.external_awards_participateds === false) || (this.userDetails.external_awards_participateds === null)) {
          this.External_Awards_ParticipatedId_Array = [];
        }
        else if (this.userDetails.external_awards_participateds.length > 0) {
          this.userDetails.external_awards_participateds.map((item) => {
            this.ExternalAwardsParticipatesList.map((item3) => {
              if (item3.external_awards_participateds_id == item) {
                this.ExternalAwardsParticipatesSelectedItem.push(item3);
              }
            })
            // if (this.External_Awards_ParticipatedId_Array.indexOf(Number(item)) == -1) {
            //   this.External_Awards_ParticipatedId_Array.push(Number(item))
            // }
          });
        }

        // this.AreasofExpertisesList = res.AreasofExpertises;
        this.ExternalAwardAssessmentTeamLeader_yearsList = res.ExternalAwardAssessmentTeamLeader_years;
        this.ConfirmationsList = res.Confirmations;
        this.ProfessionalUseLanguagesList = res.ProfessionalUseLanguages;
        this.EmploymentStatusesList = res.EmploymentStatuses;
        this.IndustryList = res.Industries;
        this.profileImagePath = res.imagePath;

        this.profileUpdateOneForm.patchValue({
          namePrefix: this.userDetails.NamePrefix_id,
          first_name: this.userDetails.user.first_name,
          last_name: this.userDetails.user.last_name,
          gender: this.userDetails.Gender_id,
          city: this.userDetails.cityof_residences_id,
          nationality: this.userDetails.nationalities_id,
          country: this.userDetails.user.country_id,
          qualification: this.userDetails.qualifications_id,
          mobile_no: this.userDetails.mobile_no,
          mobile_country_code: this.userDetails.Country_code,
          whatsapp_no: this.userDetails.WhatsApp_mobile_no,
          whatsapp_country_code: this.userDetails.WhatsApp_Country_code,
          graduatedIn: this.userDetails.Graduated_In,
          work_email: this.userDetails.Work_E_mail,
          personal_email: this.userDetails.user.email,
          age_group: this.userDetails.age_groups_id,
          license_code: this.userDetails.license_code,
        });
        this.profileUpdateTwoForm.patchValue({
          attended_award_training: this.userDetails.Confirmation_Award_Assessor_Training,
          participated_award_training: this.userDetails.Confirmation_Award_2017_Assessment,
          // Innovation Hands-on Experience
          professional_assessment_language: this.userDetails.professional_language_id,
          report_writing_language: this.userDetails.Report_Writing_professional_language_id,
          employment_status: this.userDetails.employment_statuses_id,
          current_employer: this.userDetails.Current_Employer,
          job_title: this.userDetails.Job_Title,
          Industry: this.userDetails.industries_id,
          LinkedIn_profile_link: this.userDetails.LinkedIn_Profile_Link,
          // Professional Profile/ CV
          Business_Travel_leave: this.userDetails.Business_Travel_Leave,
          interested_participate_award_year: this.userDetails.your_company_interested_participate_the_UAE_Innovation_Award
        })
        this.profileUpdateThreeForm.patchValue({
          total_work_experience: this.userDetails.yearsof_work_experiences_id,
          professional_assessment_experience: this.userDetails.professional_assessment_experiences_id,
          professional_certifications: this.userDetails.Professional_Certifications,
          Internal_assessment_completed: this.userDetails.AssessmentComplete_id,
          External_assessment_completed: this.userDetails.External_AssessmentComplete_id,
          external_award_assessment_completed: this.userDetails.external_awards_Assessment_id,
          external_awards_teamLeader: this.userDetails.Team_Leader_name,
          no_of_years_assessment_team_leader: this.userDetails.external_award_assessment_team_leader_year_id,
        });
        // step 1
        this.display_profile_photo = this.userDetails.user.profile_picture != null ? this.profileImagePath + this.userDetails.user.profile_picture : 'assets/images/default-user.png';
        this.profile_photo = this.userDetails.Profile_pic;
        this.profile_CV = this.userDetails.Profile_CV;
        this.userDetails.gender_name = this.gendersList.length > 0 ? this.gendersList.find(x => x.gender_id == this.userDetails.Gender_id).trans_data : 'NA';

        this.userDetails.qualifications_name = this.QualificationsList.length > 0 ? this.QualificationsList.find((x) => x.qualifications_id === this.userDetails.qualifications_id).trans_data : 'NA';

        this.userDetails.agegroup_name = this.AgeGroupsList.length > 0 ? this.AgeGroupsList.find((x) => x.age_groups_id === this.userDetails.age_groups_id).trans_data : 'NA';

        this.userDetails.nationalities_name = this.NationalityList.length > 0 ? this.NationalityList.find((x) => x.nationalities_id === this.userDetails.nationalities_id).trans_data : 'NA';

        this.userDetails.country_name = this.AllCountryList.length > 0 ? this.AllCountryList.find((x) => x.nationalities_id === this.userDetails.user.country_id).trans_data : 'NA';


        // step 2
        this.userDetails.years_of_work_experience_name = this.YearsofWorkExperienceList.length > 0 ? this.YearsofWorkExperienceList.find((x) => x.yearsof_work_experiences_id === this.userDetails.yearsof_work_experiences_id).trans_data : 'NA';

        this.userDetails.Professional_assessment_experience_name = this.ProfessionalAssessmentExperienceList.length > 0 ? this.ProfessionalAssessmentExperienceList.find((x) => x.professional_assessment_experiences_id === this.userDetails.professional_assessment_experiences_id).trans_data : 'NA';

        this.userDetails.Internal_Assessment_completes_name = this.AssessmentCompletesList.length > 0 ? this.AssessmentCompletesList.find((x) => x.id === Number(this.userDetails.AssessmentComplete_id)).data : 'NA';

        this.userDetails.External_Assessment_completes_name = this.AssessmentCompletesList.length > 0 ? this.AssessmentCompletesList.find((x) => x.id === Number(this.userDetails.External_AssessmentComplete_id)).data : 'NA';

        if ((this.userDetails.external_awards_participateds === false) || (this.userDetails.external_awards_participateds === null)) {
          this.userDetails.External_Awards_Participates_names = [];
        }
        else if (this.userDetails.external_awards_participateds.length > 0) {
          this.userDetails.External_Awards_Participates_names = this.ExternalAwardsParticipatesList.filter(o => this.userDetails.external_awards_participateds.find(o2 => o.external_awards_participateds_id === Number(o2)))
        }

        this.userDetails.External_Award_Assessment_completes_name = this.ExternalAwardAssessmentTeamLeader_yearsList.length > 0 ? this.ExternalAwardAssessmentTeamLeader_yearsList.find((x) => x.external_award_assessment_team_leaders_id === this.userDetails.external_awards_Assessment_id).trans_data : 'NA';

        this.userDetails.externalAwardAssessmentTeamLeader_year_name = this.ExternalAwardAssessmentTeamLeader_yearsList.length > 0 ? this.ExternalAwardAssessmentTeamLeader_yearsList.find((x) => x.external_award_assessment_team_leaders_id === this.userDetails.external_award_assessment_team_leader_year_id).trans_data : 'NA';

        // step 3
        this.userDetails.award_assessor_training_name = this.ConfirmationsList.length > 0 ? this.ConfirmationsList.find((x) => x.confirmations_id === this.userDetails.Confirmation_Award_Assessor_Training).trans_data : 'NA';

        this.userDetails.participated_award_2017_Assessment_name = this.ConfirmationsList.length > 0 ? this.ConfirmationsList.find((x) => x.confirmations_id === this.userDetails.Confirmation_Award_2017_Assessment).trans_data : 'NA';
        this.userDetails.company_interested_participate__award = this.ConfirmationsList.length > 0 ? this.ConfirmationsList.find((x) => x.confirmations_id === this.userDetails.your_company_interested_participate_the_UAE_Innovation_Award).trans_data : 'NA';

        this.userDetails.Professional_language = this.ProfessionalUseLanguagesList.length > 0 ? this.ProfessionalUseLanguagesList.find((x) => x.professional_language_id === this.userDetails.professional_language_id).trans_data : 'NA';

        this.userDetails.report_writing_language = this.ProfessionalUseLanguagesList.length > 0 ? this.ProfessionalUseLanguagesList.find((x) => x.professional_language_id === this.userDetails.Report_Writing_professional_language_id).trans_data : 'NA';

        this.userDetails.employmentStatus = this.EmploymentStatusesList.length > 0 ? this.EmploymentStatusesList.find((x) => x.employment_statuses_id === this.userDetails.employment_statuses_id).trans_data : 'NA';
        this.userDetails.industry_name = this.IndustryList.length > 0 ? this.IndustryList.find((x) => x.industries_id === this.userDetails.industries_id).trans_data : 'NA';

        if ((this.userDetails.Areas_of_Expertise === false) || (this.userDetails.Areas_of_Expertise === null)) {
          this.userDetails.Areas_of_Expertise_names = [];
        }
        else if (this.userDetails.Areas_of_Expertise.length > 0) {
          this.userDetails.Areas_of_Expertise_names = this.AreasofExpertisesList.filter(o => this.userDetails.Areas_of_Expertise.find(o2 => o.areasof_expertises_id === Number(o2)))
        }
        if ((this.userDetails.Innovation_Hands_on_Experience === false) || (this.userDetails.Innovation_Hands_on_Experience === null)) {
          this.userDetails.Innovation_Hands_on_Experience_names = [];
        }
        else if (this.userDetails.Innovation_Hands_on_Experience.length > 0) {
          this.userDetails.Innovation_Hands_on_Experience_names = this.InnovationHandsonExperiencesList.filter(o => this.userDetails.Innovation_Hands_on_Experience.find(o2 => o.innovation_handson_experiences_id === Number(o2)))
        }


        this.dataService.userData(res);
      }
    }, err => {
      if (err.error.message == "Unauthenticated.") {
      }
      if (err.error.message != "Unauthenticated.") {
        this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  awardsParticipatedValue($event, participatesItem) {
    if ($event.srcElement.checked == true) {
      if (this.External_Awards_ParticipatedId_Array.indexOf(Number(participatesItem.awards_participateds_id)) == -1) {
        this.External_Awards_ParticipatedId_Array.push(Number(participatesItem.awards_participateds_id));
      }
      if (this.External_Awards_ParticipatedId_Array.length > 0) {
        this.awardsParticipatedErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.External_Awards_ParticipatedId_Array.splice(this.External_Awards_ParticipatedId_Array.indexOf($event.srcElement.value * 1), 1);
      if (this.External_Awards_ParticipatedId_Array.length == 0) {
        this.awardsParticipatedErr = 'External Awards Participated is required';
      }
    }
    console.log(this.External_Awards_ParticipatedId_Array)
  }
  areasofExpertisesValue($event, areasofExpertisesItem) {
    if ($event.srcElement.checked == true) {
      if (this.Areas_of_ExpertiseId_Array.indexOf(Number(areasofExpertisesItem.expertises_id)) == -1) {
        this.Areas_of_ExpertiseId_Array.push(Number(areasofExpertisesItem.expertises_id));
      }
      if (this.Areas_of_ExpertiseId_Array.length > 0) {
        this.areasofExpertisesErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.Areas_of_ExpertiseId_Array.splice(this.Areas_of_ExpertiseId_Array.indexOf($event.srcElement.value * 1), 1);
      if (this.Areas_of_ExpertiseId_Array.length == 0) {
        this.areasofExpertisesErr = 'Areas of Expertises is required';
      }
    }
    console.log(this.Areas_of_ExpertiseId_Array)
  }
  handsonExperiencesValue($event, handsonExperiencesItem) {
    if ($event.srcElement.checked == true) {
      if (this.handsonExperienceIdArray.indexOf(Number(handsonExperiencesItem.innovation_handson_experiences_id)) == -1) {
        this.handsonExperienceIdArray.push(Number(handsonExperiencesItem.innovation_handson_experiences_id));
      }
      if (this.handsonExperienceIdArray.length > 0) {
        this.HandsonExperiencesErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.handsonExperienceIdArray.splice(this.handsonExperienceIdArray.indexOf($event.srcElement.value * 1), 1);
      if (this.handsonExperienceIdArray.length == 0) {
        this.HandsonExperiencesErr = 'External Awards Participated is required';
      }
    }
    console.log(this.handsonExperienceIdArray);
    console.log(this.InnovationHandsonExperiencesList)
  }
  onItemSelect1(item: any) {
    // console.log(this.InnovationHandsonExperiencesSelectedItem);
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length > 0) {
      this.HandsonExperiencesErr = '';
    }
  }
  OnItemDeSelect1(item: any) {
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length > 0) {
      this.HandsonExperiencesErr = '';
    }
  }
  onSelectAll1(items: any) {
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length > 0) {
      this.HandsonExperiencesErr = '';
    }
  }
  onDeSelectAll1(items: any) {
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      this.HandsonExperiencesErr = 'Handson Experiences is require';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length > 0) {
      this.HandsonExperiencesErr = '';
    }
  }
  onItemSelect2(item: any) {
    // console.log(this.AreasofExpertisesSelectedItem);
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if (this.AreasofExpertisesSelectedItem.length > 0) {
      this.areasofExpertisesErr = '';
    }
  }
  OnItemDeSelect2(item: any) {
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if (this.AreasofExpertisesSelectedItem.length > 0) {
      this.areasofExpertisesErr = '';
    }
  }
  onSelectAll2(items: any) {
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if (this.AreasofExpertisesSelectedItem.length > 0) {
      this.areasofExpertisesErr = '';
    }
  }
  onDeSelectAll2(items: any) {
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if (this.AreasofExpertisesSelectedItem.length > 0) {
      this.areasofExpertisesErr = '';
    }
  }
  onItemSelect3(item: any) {
    // console.log(this.ExternalAwardsParticipatesSelectedItem);
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length > 0) {
      this.awardsParticipatedErr = '';
    }
  }
  OnItemDeSelect3(item: any) {
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length > 0) {
      this.awardsParticipatedErr = '';
    }
  }
  onSelectAll3(items: any) {
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length > 0) {
      this.awardsParticipatedErr = '';
    }
  }
  onDeSelectAll3(items: any) {
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length > 0) {
      this.awardsParticipatedErr = '';
    }
  }
  onChangeMobileCode(event) {
    if (event == '') {
      // this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      // this.mobileCountryCodeeErr = '';
    }
  }
  onChangeWhatappCode(event) {
    if (event == '') {
      // this.WhatsAppCountryErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      // this.WhatsAppCountryErr = '';
    }
  }
  onChangePrefix(event) {
    if (event == '') {
      this.PrefixNameErr = 'Assessor Prefix Name is required';
    }
    if (event != '') {
      this.PrefixNameErr = '';
    }
  }
  // get city list
  cityList(NationalityId) {
    let data = {
      nationalities_id: NationalityId
    }
    this.authService.getCityList(data).subscribe((response: any) => {
      this.cityListArray = response.data;
      this.userDetails.cityof_residence_name = this.cityListArray.find(x => x.cityof_residences_id_translate_id == this.userDetails.cityof_residences_id).trans_data;
    }, error => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    });
  }
  onKeyNewPassword(event: any) { // without type info
    let upperCaseCharacters = /[A-Z]+/;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/;
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (event.target.value != '') {
      this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';
      this.NumberNewErrMsg = 'New Password must contain at least one number';
      if ((upperCaseCharacters.test(event.target.value) === false)) {
        this.upperCaseNewErrMsg = 'New Password must contain at least one uppercase letters';

      }
      if ((upperCaseCharacters.test(event.target.value) === true)) {
        this.upperCaseNewErrMsg = '';
      }
      if ((numberCharacters.test(event.target.value) === false)) {
        this.NumberNewErrMsg = 'New Password must contain at least one number';
      }
      if ((numberCharacters.test(event.target.value) === true)) {
        this.NumberNewErrMsg = '';
      }
    }
    else if (event.target.value == '') {
      this.NumberNewErrMsg = '';
      this.upperCaseNewErrMsg = '';
    }
  }
  onSelectCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_cv_show = event.target.files[0];
      if (this.profile_cv_show == null) {
        this.ProfessionalCVErr = 'please select a document';
      }
      if (this.profile_cv_show != null) {
        this.ProfessionalCVErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  onSelectPhoto(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_photo_show = event.target.files[0];
      // console.log(this.profile_photo)
      if (this.profile_photo_show == null) {
        this.profilePicErr = 'please select a your profile photo';
      }
      if (this.profile_photo_show != null) {
        this.profilePicErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
      }
    }
  }
  updatepassword() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }
    let data = {
      user_token: this.user_token,
      current_password: this.updatePasswordForm.value.current_password,
      new_password: this.updatePasswordForm.value.new_password,
    }
    if ((this.NumberNewErrMsg == '') && (this.upperCaseNewErrMsg == '')) {
      this.processingPasswordText = true;
      this.judgeService.updatePaasword(this.api_token, data).subscribe((res: any) => {
        console.log(res)
        if (res.has_error == 0) {
          this.processingPasswordText = false;
          this.messagesService.showSuccess(res.msg);
          this.submitted1 = false;
          console.log(this.updatePasswordForm.value);
          this.show_change_password = false;
          this.updatePasswordForm.reset();
          this.upperCaseNewErrMsg = '';
          this.NumberNewErrMsg = '';
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingPasswordText = false;
        }
      },
        err => {
          this.processingPasswordText = false;
          this.messagesService.showError('Something went wrong.');
        });
    }
  }
  updateprofileOne() {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.profileUpdateOneForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.assesorAwardIdArray),
      formData.append('NamePrefix_id', this.profileUpdateOneForm.value.namePrefix),
      formData.append('first_name', this.profileUpdateOneForm.value.first_name),
      formData.append('last_name', this.profileUpdateOneForm.value.last_name),
      formData.append('Gender_id', this.profileUpdateOneForm.value.gender),
      formData.append('cityof_residences_id', this.profileUpdateOneForm.value.city),
      formData.append('nationalities_id', this.profileUpdateOneForm.value.nationality),
      formData.append('qualifications_id', this.profileUpdateOneForm.value.qualification),
      formData.append('Country_code', this.profileUpdateOneForm.value.mobile_country_code),
      formData.append('mobile_no', this.profileUpdateOneForm.value.mobile_no),
      formData.append('WhatsApp_Country_code', this.profileUpdateOneForm.value.whatsapp_country_code),
      formData.append('WhatsApp_mobile_no', this.profileUpdateOneForm.value.whatsapp_no),
      formData.append('Graduated_In', this.profileUpdateOneForm.value.graduatedIn),
      formData.append('Work_E_mail', this.profileUpdateOneForm.value.work_email),
      formData.append('email', this.profileUpdateOneForm.value.personal_email),
      formData.append('country_id', this.profileUpdateOneForm.value.country),
      formData.append('age_groups_id', this.profileUpdateOneForm.value.age_group)
    formData.append('license_code', this.profileUpdateOneForm.value.license_code)

    if (this.profile_photo_show != null) {
      formData.append('Profile_pic', this.profile_photo_show)
    }
    if (this.profile_photo_show == null) {
      formData.append('Profile_pic', this.profile_photo)
    }
    if ((this.profile_photo != null) || (this.profile_photo_show != null)) {
      this.processingOneText = true;
      this.profilePicErr = '';
      this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingOneText = false;
          this.getUserDetails();
          this.show_personal_information = false;
          this.messagesService.showSuccess('Profile Updated successfully.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
        else if (res.has_error == 1) {
          this.processingOneText = false;
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
      },
        error => {
          this.processingOneText = false;
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        })
    }
    else if ((this.profile_photo == null) && (this.profile_photo_show == null)) {
      this.profilePicErr = 'Please upload your profile picture.'
    }
  }
  updateprofileTwo() {
    this.submitted3 = true;

    this.handsonExperienceIdArray = [];
    this.Areas_of_ExpertiseId_Array = [];

    this.InnovationHandsonExperiencesSelectedItem.map((item2) => {
      this.handsonExperienceIdArray.push(item2.innovation_handson_experiences_id);
    });
    this.AreasofExpertisesSelectedItem.map((item3) => {
      this.Areas_of_ExpertiseId_Array.push(item3.areasof_expertises_id);
    });

    // stop here if form is invalid
    if (this.profileUpdateTwoForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token)
    formData.append('award_id', this.assesorAwardIdArray),
      formData.append('Confirmation_Award_Assessor_Training', this.profileUpdateTwoForm.value.attended_award_training),
      formData.append('Confirmation_Award_2017_Assessment', this.profileUpdateTwoForm.value.participated_award_training),
      formData.append('professional_language_id', this.profileUpdateTwoForm.value.professional_assessment_language),
      formData.append('Report_Writing_professional_language_id', this.profileUpdateTwoForm.value.report_writing_language),
      formData.append('Areas_of_Expertise', this.Areas_of_ExpertiseId_Array),
      formData.append('Innovation_Hands_on_Experience', this.handsonExperienceIdArray),
      formData.append('employment_statuses_id', this.profileUpdateTwoForm.value.employment_status),
      formData.append('Current_Employer', this.profileUpdateTwoForm.value.current_employer),
      formData.append('Job_Title', this.profileUpdateTwoForm.value.job_title),
      formData.append('industries_id', this.profileUpdateTwoForm.value.Industry),
      formData.append('LinkedIn_Profile_Link', this.profileUpdateTwoForm.value.LinkedIn_profile_link),
      formData.append('Business_Travel_Leave', this.profileUpdateTwoForm.value.Business_Travel_leave),
      formData.append('your_company_interested_participate_the_UAE_Innovation_Award', this.profileUpdateTwoForm.value.interested_participate_award_year)
    formData.append('email', this.profileUpdateOneForm.value.personal_email)

    if (this.profile_cv_show != null) {
      formData.append('Profile_CV', this.profile_cv_show)
    }
    if (this.profile_photo_show == null) {
      formData.append('Profile_CV', this.profile_CV)
    }
    if (this.profile_CV != null || this.profile_cv_show != null) {
      this.ProfessionalCVErr = '';
      this.processingTwoText = true;
      this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
        if (res.has_error == 0) {
          this.processingTwoText = false;
          this.getUserDetails();
          this.show_professional_information = false;
          this.profile_cv_show = null;
          this.messagesService.showSuccess('Profile Updated successfully.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
        else if (res.has_error == 1) {
          this.processingTwoText = false;
          for (let [key, value] of Object.entries(res.error)) {
            this.errorMessageValue = value;
          }
          this.messagesService.showError(this.errorMessageValue);
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        }
      },
        error => {
          this.processingTwoText = false;
          this.messagesService.showError('Something went wrong.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
        })
    }
    else if (this.profile_cv_show == null && this.profile_CV == null) {
      this.ProfessionalCVErr = 'please select a document'
    }
  }
  updateprofileThree() {
    this.submitted4 = true;

    this.External_Awards_ParticipatedId_Array = [];
    this.ExternalAwardsParticipatesSelectedItem.map((item) => {
      this.External_Awards_ParticipatedId_Array.push(item.external_awards_participateds_id);
    });

    // stop here if form is invalid
    if (this.profileUpdateThreeForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_id', this.assesorAwardIdArray),
      formData.append('yearsof_work_experiences_id', this.profileUpdateThreeForm.value.total_work_experience),
      formData.append('Professional_Certifications', this.profileUpdateThreeForm.value.professional_certifications),
      formData.append('professional_assessment_experiences_id', this.profileUpdateThreeForm.value.professional_assessment_experience),
      formData.append('AssessmentComplete_id', this.profileUpdateThreeForm.value.Internal_assessment_completed),
      formData.append('External_AssessmentComplete_id', this.profileUpdateThreeForm.value.External_assessment_completed),
      formData.append('external_awards_Assessment_id', this.profileUpdateThreeForm.value.external_award_assessment_completed),
      formData.append('Team_Leader_name', this.profileUpdateThreeForm.value.external_awards_teamLeader),
      formData.append('external_award_assessment_team_leader_year_id', this.profileUpdateThreeForm.value.no_of_years_assessment_team_leader),
      formData.append('email', this.profileUpdateOneForm.value.personal_email)
    formData.append('external_awards_participateds', this.External_Awards_ParticipatedId_Array)
    // if (this.External_Awards_ParticipatedId_Array.length > 0) {
    this.processingThreeText = true;
    this.judgeService.updateProfile(this.api_token, formData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.processingThreeText = false;
        this.getUserDetails();
        this.show_industry_information = false;
        this.messagesService.showSuccess('Profile Updated successfully.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
      else if (res.has_error == 1) {
        this.processingThreeText = false;
        for (let [key, value] of Object.entries(res.error)) {
          this.errorMessageValue = value;
        }
        this.messagesService.showError(this.errorMessageValue);
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      }
    },
      error => {
        this.processingThreeText = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 4000);
      })
    // }
    // else if (this.External_Awards_ParticipatedId_Array.length == 0) {
    //   this.awardsParticipatedErr = 'External Awards Participated is required';
    // }
  }
  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
}

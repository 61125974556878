import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AssesorArchiveComponent } from './assesor-archive.component';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { DataService } from '../../services/data.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { AuthService } from '../../services/auth.service';
import { ExcelService } from '../../services/excel.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import { SharedModule } from 'src/_helpers/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

// intro routing
export const routes: Routes = [
  { path: '', component: AssesorArchiveComponent },
]; 

@NgModule({
  declarations: [AssesorArchiveComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    SharedModule,
    NgScrollbarModule
  ]
})
export class AssesorArchiveModule { }

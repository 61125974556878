import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  constructor(private http: HttpClient) { }
  // user details
  getUserDetails(user_token, api_token) {
    return this.http.get(`${appConfig.apiUrl}/user-profile-detail?user_token=${user_token}&api_token=${api_token}`, { headers: this.headers })
    // return this.http.get(`${appConfig.apiUrl}/user-details?user_token=${user_token}&api_token=${api_token}`, { headers: this.headers })
  }
  // dashboard details
  dashboardDetails(user_token, api_token) {
    return this.http.get(`${appConfig.apiUrl}/dashboard?user_token=${user_token}&api_token=${api_token}`, { headers: this.headers })
  }
  // 
  filterAwardOfficeDashboard(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/dashboard?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // award score board
  getAwardScore(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/award-score-board?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // show shared data
  showSharedData(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/show-shared-data?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // send for approval to judge
  sendApproval(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/send-for-approval-to-judge?api_token=${api_token}`, $obj, { headers: this.headers });
  }
  // upload assesor document
  uploadDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/upload-assesor-document?api_token=${api_token}`, $obj)
  }
  // upload assesor document
  getDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-document-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // delete Document
  deleteDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/delete-assessor-document?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // site visit request
  siteVisitrequest(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/site-visit-request?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // award Filteration
  awardFilteration(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/dashboard?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  shareScorePoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/share-score-point?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  addScorePoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-score-point?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // final award score board
  finalAwardScoreBoard(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/final-award-score-board?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // add final score point
  addFinalScorePoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-final-score-point?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // add Third Lebel ScorePoint
  addThirdLebelScorePoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-score-point-mul?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // add Third Lebel FinalScorePoint
  addThirdLebelFinalScorePoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-final-score-point-mul?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // add self assessment Third Lebel ScorePoint
  addThirdLebelSelfScorePoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-self-assesment-process-mul?api_token=${api_token}`, $obj)
  }
  // self assessment
  selfAssessment(user_token, api_token) {
    return this.http.get(`${appConfig.apiUrl}/self-assessment?user_token=${user_token}&api_token=${api_token}`, { headers: this.headers })
  }
  // add self assessment
  addSelfAssessment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-self-assessment?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  editSelfAssesment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-self-assesment?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  updateSelfAssessment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-self-assesment?api_token=${api_token}`, $obj)
  }
  // chart data
  getChart(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/chart-data?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // download-chart-report
  downloadChartReport(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/download-chart-report?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // get License
  getLicense(user_token, api_token) {
    return this.http.get(`${appConfig.apiUrl}/get-license?user_token=${user_token}&api_token=${api_token}`, { headers: this.headers })
  }
  // get-license-price
  getLicensePrice(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/get-license-price?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // create License
  createLicense(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/get-license?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // assessment-license
  assessmentLicense(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assessment-license?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // view selfassessment docs  
  getSelfassessmentDocs(api_token) {
    return this.http.get(`${appConfig.apiUrl}/view-selfassessment-docs?api_token=${api_token}`, { headers: this.headers })
  }
  deleteSelfAssessmentDocument(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/delete-selfassessment-doc?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // share all score point
  shareAllScorePpoint(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/share-all-score-point?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // get share status
  getShareStatus(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/get-share-all-status?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // make archive
  createArchive(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/make-archive?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // archive-list
  archiveList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/archive-list?api_token=${api_token}`, { headers: this.headers })
  }
  // add judge comment
  judgeComment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-judge-comment?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // company payment
  companyPayment(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/company-payment?api_token=${api_token}`, $obj)
  }
  // show final award score offc
  officeScore(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/show-final-award-score-offc?api_token=${api_token}`, $obj)
  }
  // office assesment status change
  officeAssesmentStatusChange(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assesment-status-change-award-office?api_token=${api_token}`, $obj)
  }
  // assessor-management-list
  assessorManagementList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/assessor-management-list?api_token=${api_token}`, { headers: this.headers })
  }
  // jury-management-list
  filterAssessorManagmentList(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-management-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // 
  filterCompanyManagementList(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/company-management-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // jury-management-list
  juryManagmentList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/judge-management-list?api_token=${api_token}`, { headers: this.headers })
  }
  // jury-management-list
  filterJuryManagmentList(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/judge-management-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // search jury
  jurySearch(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/judge-management-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // add-awardoffice-member
  addOfficeMember(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-awardoffice-member?api_token=${api_token}`, $obj)
  }
  // edit-awardoffice-member
  editOfficeMember(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-awardoffice-member?api_token=${api_token}`, $obj)
  }
  // award-office-list
  officeMemberList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/award-office-list?api_token=${api_token}`, { headers: this.headers })
  }
  // award-office-list
  officeMemberGroupList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/award-office-group-list?api_token=${api_token}`, { headers: this.headers })
  }
  // award-office-list
  seachAwardOfficeGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/award-office-group-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // edit-office-group
  editOfficeGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-office-group?api_token=${api_token}`, $obj)
  }
  // edit-award
  editAward(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-award-data?api_token=${api_token}`, $obj)
  }
  // show-final-reports
  showFinalReports(api_token, award_id) {
    return this.http.get(`${appConfig.apiUrl}/show-final-reports?api_token=${api_token}&award_id=${award_id}`, { headers: this.headers })
  }
  // award-measurments
  awardMeasurements(api_token, $obj) {
    return this.http.get(`${appConfig.apiUrl}/award-measurments?api_token=${api_token}&award_id=${$obj.award_id}&criteria_order_id=${$obj.criteria_order_id}`, { headers: this.headers })
  }
  // edit-award-measurments
  editawardMeasurement(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/award-measurments?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // award-star
  awardStar(api_token, $obj) {
    return this.http.get(`${appConfig.apiUrl}/award-star?api_token=${api_token}&award_id=${$obj.award_id}&award_year_id=${$obj.award_year_id}`, { headers: this.headers })
  }
  // edit-award-measurments
  editAwardStar(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/award-star?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // award-office-list
  seachAwardOffice(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/award-office-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // form-list-data
  formList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/form-list-data?api_token=${api_token}`, { headers: this.headers })
  }
  // add-jury
  addJuryProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-jury?api_token=${api_token}`, $obj)
  }
  // edit-jury-profile
  editJuryProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-jury-profile?api_token=${api_token}`, $obj)
  }
  // add-assessor
  addAssessorProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-assessor?api_token=${api_token}`, $obj)
  }
  // edit-assessor-profile
  ediAssessorProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-assessor-profile?api_token=${api_token}`, $obj)
  }
  // add-company
  addCompanyProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-company?api_token=${api_token}`, $obj)
  }
  // edit-company-profile
  editCompanyProfile(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/edit-company-profile?api_token=${api_token}`, $obj)
  }
  // criteria-list
  criterialist(api_token) {
    return this.http.get(`${appConfig.apiUrl}/criteria-list?api_token=${api_token}`, { headers: this.headers })
  }
  // get-inner-criteria
  innerCriteria(api_token, id) {
    return this.http.get(`${appConfig.apiUrl}/get-inner-criteria?api_token=${api_token}&id=${id}`, { headers: this.headers })
  }
  // get-criteria
  getCriteria(api_token, id) {
    return this.http.get(`${appConfig.apiUrl}/get-criteria?api_token=${api_token}&id=${id}`, { headers: this.headers })
  }
  // add-edit-criteria
  addCriteria(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/add-edit-criteria?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // delete-criteria
  deleteCriteria(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/delete-criteria?api_token=${api_token}`, $obj, { headers: this.headers })
  }

  mainAwardSearch(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/criteria-list?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // company-management-list
  companyManagementList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/company-management-list?api_token=${api_token}`, { headers: this.headers })
  }
  // generate-invoice
  invoiceGenerate(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/generate-invoice?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // /change-star-rating
  changeRating(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/change-star-rating?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // dashboard-statics
  staticsDetails(api_token) {
    return this.http.get(`${appConfig.apiUrl}/dashboard-statics?api_token=${api_token}`, { headers: this.headers })
  }

  // update-payment-status
  paymentUpdate(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/update-payment-status?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // approve-award-process
  approveAward(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/approve-award-process?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // approve-award
  approveAwardList(api_token, user_id) {
    return this.http.get(`${appConfig.apiUrl}/approve-award?api_token=${api_token}&user_id=${user_id}`, { headers: this.headers })
  }
  // find-company
  findCompanyList(api_token, year_id) {
    return this.http.get(`${appConfig.apiUrl}/find-company?api_token=${api_token}&year_id=${year_id}`, { headers: this.headers })
  }
  // find-assessor-group
  findAssessorGroupList(api_token, year_id) {
    return this.http.get(`${appConfig.apiUrl}/find-assessor-group?api_token=${api_token}&year_id=${year_id}`, { headers: this.headers })
  }
  // find-judge-group
  findJudgeGroupList(api_token, year_id) {
    return this.http.get(`${appConfig.apiUrl}/find-judge-group?api_token=${api_token}&year_id=${year_id}`, { headers: this.headers })
  }
  // assigned-assessor-group-list
  assesorAssignedGroupList(api_token, user_type) {
    return this.http.get(`${appConfig.apiUrl}/assigned-assessor-group-list?api_token=${api_token}&user_type=${user_type}`, { headers: this.headers })
  }
  // assigned-jury-group-list
  juryAssignedGroupList(api_token, user_type) {
    return this.http.get(`${appConfig.apiUrl}/assigned-jury-group-list?api_token=${api_token}&user_type=${user_type}`, { headers: this.headers })
  }
  // assessor-assign-group
  addAssignAssessorGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-assign-group?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // judge-assign-group
  addAssignJuryGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/judge-assign-group?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // group-creation-data-list
  groupCreationList(api_token, year_id) {
    return this.http.get(`${appConfig.apiUrl}/group-creation-data-list?api_token=${api_token}&year_id=${year_id}`, { headers: this.headers })
  }
  // assessor-group-create
  addAssessorGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-group-create?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // assessor-group-edit
  editAssessorGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/assessor-group-edit?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // judge-group-create
  addJuryGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/judge-group-create?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // judge-group-edit
  editJuryGroup(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/judge-group-edit?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // menu-list
  menuList(api_token) {
    return this.http.get(`${appConfig.apiUrl}/menu-list?api_token=${api_token}`, { headers: this.headers })
  }
  // give-permission
  givePermission(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/give-permission?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // assessor-group-list
  assessorGrouplist(api_token) {
    return this.http.get(`${appConfig.apiUrl}/assessor-group-list?api_token=${api_token}`, { headers: this.headers })
  }
  // judge-group-list
  juryGrouplist(api_token) {
    return this.http.get(`${appConfig.apiUrl}/judge-group-list?api_token=${api_token}`, { headers: this.headers })
  }
  searchAssignedAssessorGroupList(api_token, user_type, award_year_id, company_id) {
    return this.http.get(`${appConfig.apiUrl}/assigned-assessor-group-list?api_token=${api_token}&user_type=${user_type}&award_year_id=${award_year_id}&company_id=${company_id}`, { headers: this.headers })
  }
  searchAssignedJuryGroupList(api_token, user_type, award_year_id, company_id) {
    return this.http.get(`${appConfig.apiUrl}/assigned-jury-group-list?api_token=${api_token}&user_type=${user_type}&award_year_id=${award_year_id}&company_id=${company_id}`, { headers: this.headers })
  }
  // chart data
  getOfficeChart(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/final-chart-data?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // download-chart-report
  downloadOfficeChartReport(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/final-chart-report?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // user-status-change
  statusChange(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/user-status-change?api_token=${api_token}`, $obj, { headers: this.headers })
  }
  // menu-details
  menuDetails(api_token) {
    return this.http.get(`${appConfig.apiUrl}/menu-details?api_token=${api_token}`, { headers: this.headers })
  }
  // change-dates
  changeDate(api_token, $obj) {
    return this.http.post(`${appConfig.apiUrl}/change-dates?api_token=${api_token}`, $obj, { headers: this.headers })
  }
}

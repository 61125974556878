import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from 'src/app/services/dashboard.service';


@Component({
  selector: 'app-judge-group-dashboard',
  templateUrl: './judge-group-dashboard.component.html',
  styleUrls: ['./judge-group-dashboard.component.scss']
})
export class JudgeGroupDashboardComponent implements OnInit {
  leftbarselected; any;
  user_token: any;
  api_token: any;
  juryGroupDetails: any = [];
  lazyLoad: boolean = false;
  award_logo_path: any;
  award_background_image_path: any;
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService) {
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd == true) {
          if (val.url == '/jury-group-dashboard')
            this.leftbarselected = 'Jury Group Dashboard';
          this.dataService.leftPanelSelection('judge-dashboard');
        }
      });
      this.user_token = localStorage.getItem('user_token');
      this.api_token = localStorage.getItem('api_token');
     }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/jury-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getJuryGroupdDeails();
  }
  getJuryGroupdDeails() {
    this.lazyLoad = true;
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.juryGroupDetails = res.jury_details;
        this.award_logo_path = res.award_image_path;
        this.award_background_image_path = res.award_image_path;
        console.log(this.juryGroupDetails);
      }
    }, err => {
      this.lazyLoad = false;
      console.log(err);
      if (err.error.message != "Unauthenticated.") {
        // this.messagesService.showError('Something went wrong.');
      }
    })
  }
 eachjuryDashboard(juryItem){
    localStorage.setItem('eachJuryDetails', JSON.stringify(juryItem.eachAwardAssessment));
    this.router.navigate(['jury-dashboard']);
    // this.router.navigate(['judge-dashboard', juryItem.group_id]);
  }
}

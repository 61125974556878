import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { appConfig } from '../../app.config';

@Component({
  selector: 'app-assessor-management',
  templateUrl: './assessor-management.component.html',
  styleUrls: ['./assessor-management.component.scss']
})
export class AssessorManagementComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  user_token: any;
  api_token: any;
  panelExpanded2: boolean = false;
  showAddForm: boolean = false;
  show_approveaward_form: boolean = false;
  show_activiltylog: boolean = false;
  show_license: boolean = false;
  assessorList: any = [];
  lazyLoad: boolean = false;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  assessorManagementForm: FormGroup;
  submitted: boolean = false;
  processingText: boolean = false;

  profileImagePath: any;
  gendersList: any = [];
  QualificationsList: any = [];
  AllCountryList: any = [];
  NationalityList: any = [];
  AgeGroupsList: any = [];
  YearsofWorkExperienceList: any = [];
  ProfessionalAssessmentExperienceList: any = [];
  AssessmentCompletesList: any = [];
  ExternalAwardsParticipatesList: any = [];
  InnovationHandsonExperiencesList: any = [];
  AreasofExpertisesList: any = [];
  ExternalAwardAssessmentTeamLeader_yearsList: any = [];
  ProfessionalUseLanguagesList: any = [];
  ConfirmationsList: any = [];
  EmploymentStatusesList: any = [];
  IndustryList: any = [];
  cityListArray: any = [];
  upperCaseNewErrMsg: string;
  NumberNewErrMsg: string;
  profile_CV: any;
  profile_photo: any;
  display_profile_photo: any;
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  namePrefixsList: any = [];
  ExternalAwardsParticipatedErr: any;
  HandsonExperiencesErr: any;
  AreasofExpertisesErr: any;
  webSitePatternErr: any;
  errorMessageValue: any;
  loadingCount: number = 0;
  PrefixNameErr: any;
  profile_photo_show: any = null;
  profile_cv_show: any = null;
  ProfessionalCVErr: any;
  profileCVPath: any;
  ExternalAwards_participated_array: any = [];
  assesorAwardIdArray: any = [];
  handsonExperienceIdArray: any = [];
  Areas_of_ExpertiseId_Array: any = [];
  External_Awards_ParticipatedId_Array: any = [];
  profilePicErr: any;
  awardsParticipatedErr: any = '';
  areasofExpertisesErr: any = '';
  documentType: any;
  mobileCountryCodeeErr: string;
  WhatsAppCountryErr: string;
  formAction: any;
  companymanagementListArray: any = [];
  searchName: any;
  searchEmail: any;
  searchPhoneNumber: any;
  searchUserName: any;
  approveList: any = [];
  singleAssessorDetails: any;
  approveAwardIdArray: any = [];
  awardErr: any;
  approveProcessingText: boolean = false;
  show_assessordetails_display_form: boolean = false;
  approveAwardListLoad: boolean = false;
  permissionMsg: any;
  permissionStatus: boolean = false;
  statusArray: any = [];
  fileTypeText: any;

  settings1 = {};
  settings2 = {};
  settings3 = {};
  ExternalAwardsParticipatesSelectedItem: any = [];
  InnovationHandsonExperiencesSelectedItem: any = [];
  AreasofExpertisesSelectedItem: any = [];
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private authService: AuthService,) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/assessor-management')
          this.leftbarselected = 'Assessor Management';
        this.dataService.leftPanelSelection('assessor_management');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/assessor-management`]);
    }
    this.assessorManagementForm = this.formBuilder.group({
      namePrefix: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      city: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      country: ['', [Validators.required]],
      qualification: ['', [Validators.required]],
      mobile_no: ['', [Validators.required]],
      mobile_country_code: ['', [Validators.required]],
      whatsapp_no: ['', [Validators.required]],
      whatsapp_country_code: ['', [Validators.required]],
      graduatedIn: ['', [Validators.required]],
      work_email: ['', [Validators.required]],
      personal_email: ['', [Validators.required]],
      age_group: ['', [Validators.required]],
      license_code: [''],

      attended_award_training: ['', [Validators.required]],
      participated_award_training: ['', [Validators.required]],
      professional_assessment_language: ['', [Validators.required]],
      report_writing_language: ['', [Validators.required]],
      employment_status: ['', [Validators.required]],
      current_employer: ['', [Validators.required]],
      job_title: ['', [Validators.required]],
      Industry: ['', [Validators.required]],
      LinkedIn_profile_link: ['', [Validators.required]],
      // annual_leave: ['', [Validators.required]],
      Business_Travel_leave: ['', [Validators.required]],
      interested_participate_award_year: ['', [Validators.required]],
      total_work_experience: ['', [Validators.required]],
      professional_assessment_experience: ['', [Validators.required]],
      professional_certifications: ['', [Validators.required]],
      Internal_assessment_completed: ['', [Validators.required]],
      External_assessment_completed: ['', [Validators.required]],
      external_award_assessment_completed: ['', [Validators.required]],
      external_awards_teamLeader: ['', [Validators.required]],
      no_of_years_assessment_team_leader: ['', [Validators.required]],
      // External Awards Participated array
    });

    this.formlistData();
    this.callingCodes();
    this.assessorManagementlist();
    this.companymanagementList();
    this.statusArray = [
      {
        name: 'Active',
        status: 'Y'
      },
      {
        name: 'In-Active',
        status: 'N'
      },
      {
        name: 'Approved',
        status: 'A'
      },
      {
        name: 'Blocked',
        status: 'B'
      }
    ]
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';

    this.settings1 = {
      singleSelection: false,
      text: "Select Hands-on Experience",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settings2 = {
      singleSelection: false,
      text: "Select Areas of Expertise",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
    this.settings3 = {
      singleSelection: false,
      text: "Select External Awards Participated",
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableSearchFilter: true,
      badgeShowLimit: 3
    };
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  get f() { return this.assessorManagementForm.controls; }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  formlistData() {
    this.dashboardService.formList(this.api_token).subscribe((res: any) => {
      console.log(res);
      this.profileCVPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/Profile_CV/';
      this.profileImagePath = res.profile_image_path;
      this.namePrefixsList = res.NamePrefixs;
      this.gendersList = res.genders;
      this.QualificationsList = res.Qualifications;
      this.AgeGroupsList = res.AgeGroups;
      this.NationalityList = res.Nationalities;
      this.AllCountryList = res.all_country;
      this.YearsofWorkExperienceList = res.YearsofWorkExperiences;
      this.ProfessionalAssessmentExperienceList = res.ProfessionalAssessmentExperiences;
      this.AssessmentCompletesList = res.AssessmentCompletes;
      this.ExternalAwardsParticipatesList = res.ExternalAwardsParticipates;
      this.InnovationHandsonExperiencesList = res.InnovationHandsonExperiences;
      this.AreasofExpertisesList = res.AreasofExpertises;
      this.ExternalAwardAssessmentTeamLeader_yearsList = res.ExternalAwardAssessmentTeamLeader_years;
      this.ConfirmationsList = res.Confirmations;
      this.ProfessionalUseLanguagesList = res.ProfessionalUseLanguages;
      this.EmploymentStatusesList = res.EmploymentStatuses;
      this.IndustryList = res.Industries;
      this.ExternalAwardsParticipatesList.map((item) => {
        item['itemName'] = item.trans_data;
      });
      this.InnovationHandsonExperiencesList.map((item) => {
        item['itemName'] = item.trans_data;
      });
      this.AreasofExpertisesList.map((item) => {
        item['itemName'] = item.trans_data;
      });

    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  onChangeStatus($event, assessorItem) {
    let status = $event;
    let data = {
      'user_id': assessorItem.assessor.user_id,
      'status': status
    }
    this.dashboardService.statusChange(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.messagesService.showSuccess('Status Changes Successfully');
        this.assessorManagementlist();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  assessorManagementlist() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.assessorManagementList(this.api_token).subscribe((res: any) => {
      // if (res.has_error == 0) {
      this.assessorList = res.assessor_list;
      this.assessorList = this.assessorList.filter((thing, index, self) => index === self.findIndex((t) => (t.assessor.user_id === thing.assessor.user_id)))
      console.log(this.assessorList);
      this.lazyLoad = false;
      this.loadingCount = 1;
      this.permissionStatus = false;
      this.permissionMsg = '';
      // }
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }
  openshow_AssessorDetails_form(item) {
    this.show_assessordetails_display_form = true;
    this.singleAssessorDetails = item;
    this.panelExpanded = false;
    console.log(this.singleAssessorDetails);
    // step 1
    this.display_profile_photo = this.singleAssessorDetails.profile_picture != null ? this.profileImagePath + this.singleAssessorDetails.profile_picture : 'assets/images/default-user.png';
    this.profile_photo = this.singleAssessorDetails.profile_picture;

    this.profile_photo = this.singleAssessorDetails.profile_picture;
    this.profile_CV = this.singleAssessorDetails.assessor.Profile_CV;

    this.singleAssessorDetails.gender_name = this.gendersList.length > 0 ? this.gendersList.find(x => x.gender_id == this.singleAssessorDetails.assessor.Gender_id).trans_data : 'NA';

    this.singleAssessorDetails.qualifications_name = this.QualificationsList.length > 0 ? this.QualificationsList.find((x) => x.qualifications_id === this.singleAssessorDetails.assessor.qualifications_id).trans_data : 'NA';

    this.singleAssessorDetails.agegroup_name = this.AgeGroupsList.length > 0 ? this.AgeGroupsList.find((x) => x.age_groups_id === this.singleAssessorDetails.assessor.age_groups_id).trans_data : 'NA';

    this.singleAssessorDetails.nationalities_name = this.NationalityList.length > 0 ? this.NationalityList.find((x) => x.nationalities_id === this.singleAssessorDetails.assessor.nationalities_id).trans_data : 'NA';

    this.singleAssessorDetails.country_name = this.AllCountryList.length > 0 ? this.AllCountryList.find((x) => x.nationalities_id === this.singleAssessorDetails.country_id).trans_data : 'NA';

    this.singleAssessorDetails.years_of_work_experience_name = this.YearsofWorkExperienceList.length > 0 ? this.YearsofWorkExperienceList.find((x) => x.yearsof_work_experiences_id === this.singleAssessorDetails.assessor.yearsof_work_experiences_id).trans_data : 'NA';

    this.singleAssessorDetails.Professional_assessment_experience_name = this.ProfessionalAssessmentExperienceList.length > 0 ? this.ProfessionalAssessmentExperienceList.find((x) => x.professional_assessment_experiences_id === this.singleAssessorDetails.assessor.professional_assessment_experiences_id).trans_data : 'NA';

    this.singleAssessorDetails.Internal_Assessment_completes_name = this.AssessmentCompletesList.length > 0 ? this.AssessmentCompletesList.find((x) => x.id === Number(this.singleAssessorDetails.assessor.AssessmentComplete_id)).data : 'NA';

    this.singleAssessorDetails.External_Assessment_completes_name = this.AssessmentCompletesList.length > 0 ? this.AssessmentCompletesList.find((x) => x.id === Number(this.singleAssessorDetails.assessor.External_AssessmentComplete_id)).data : 'NA';

    if ((this.singleAssessorDetails.assessor.external_awards_participateds === false) || (this.singleAssessorDetails.assessor.external_awards_participateds === null)) {
      this.singleAssessorDetails.External_Awards_Participates_names = [];
    }
    else if (this.singleAssessorDetails.assessor.external_awards_participateds.length > 0) {
      this.singleAssessorDetails.External_Awards_Participates_names = this.ExternalAwardsParticipatesList.filter(o => this.singleAssessorDetails.assessor.external_awards_participateds.find(o2 => o.external_awards_participateds_id === Number(o2)))
    }

    this.singleAssessorDetails.External_Award_Assessment_completes_name = this.ExternalAwardAssessmentTeamLeader_yearsList.length > 0 ? this.ExternalAwardAssessmentTeamLeader_yearsList.find((x) => x.external_award_assessment_team_leaders_id === this.singleAssessorDetails.assessor.external_awards_Assessment_id).trans_data : 'NA';

    this.singleAssessorDetails.externalAwardAssessmentTeamLeader_year_name = this.ExternalAwardAssessmentTeamLeader_yearsList.length > 0 ? this.ExternalAwardAssessmentTeamLeader_yearsList.find((x) => x.external_award_assessment_team_leaders_id === this.singleAssessorDetails.assessor.external_award_assessment_team_leader_year_id).trans_data : 'NA';

    // step 3
    this.singleAssessorDetails.award_assessor_training_name = this.ConfirmationsList.length > 0 ? this.ConfirmationsList.find((x) => x.confirmations_id === this.singleAssessorDetails.assessor.Confirmation_Award_Assessor_Training).trans_data : 'NA';

    this.singleAssessorDetails.participated_award_2017_Assessment_name = this.ConfirmationsList.length > 0 ? this.ConfirmationsList.find((x) => x.confirmations_id === this.singleAssessorDetails.assessor.Confirmation_Award_2017_Assessment).trans_data : 'NA';

    this.singleAssessorDetails.company_interested_participate__award = this.ConfirmationsList.length > 0 ? this.ConfirmationsList.find((x) => x.confirmations_id === this.singleAssessorDetails.assessor.your_company_interested_participate_the_UAE_Innovation_Award).trans_data : 'NA';

    this.singleAssessorDetails.Professional_language = this.ProfessionalUseLanguagesList.length > 0 ? this.ProfessionalUseLanguagesList.find((x) => x.professional_language_id === this.singleAssessorDetails.assessor.professional_language_id).trans_data : 'NA';

    this.singleAssessorDetails.report_writing_language = this.ProfessionalUseLanguagesList.length > 0 ? this.ProfessionalUseLanguagesList.find((x) => x.professional_language_id === this.singleAssessorDetails.assessor.Report_Writing_professional_language_id).trans_data : 'NA';

    this.singleAssessorDetails.employmentStatus = this.EmploymentStatusesList.length > 0 ? this.EmploymentStatusesList.find((x) => x.employment_statuses_id === this.singleAssessorDetails.assessor.employment_statuses_id).trans_data : 'NA';

    this.singleAssessorDetails.industry_name = this.IndustryList.length > 0 ? this.IndustryList.find((x) => x.industries_id === this.singleAssessorDetails.assessor.industries_id).trans_data : 'NA';

    if ((this.singleAssessorDetails.assessor.Areas_of_Expertise === false) || (this.singleAssessorDetails.assessor.Areas_of_Expertise === null)) {
      this.singleAssessorDetails.Areas_of_Expertise_names = [];
    }
    else if (this.singleAssessorDetails.assessor.Areas_of_Expertise.length > 0) {
      this.singleAssessorDetails.Areas_of_Expertise_names = this.AreasofExpertisesList.filter(o => this.singleAssessorDetails.assessor.Areas_of_Expertise.find(o2 => o.areasof_expertises_id === Number(o2)))
    }
    if ((this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience === false) || (this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience === null)) {
      this.singleAssessorDetails.Innovation_Hands_on_Experience_names = [];
    }
    else if (this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience.length > 0) {
      this.singleAssessorDetails.Innovation_Hands_on_Experience_names = this.InnovationHandsonExperiencesList.filter(o => this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience.find(o2 => o.innovation_handson_experiences_id === Number(o2)))
    }

    console.log(this.singleAssessorDetails)
  }
  closeshow_AssessorDetails_form() {
    this.show_assessordetails_display_form = false;
    this.panelExpanded = false;
  }
  searchAssessor() {
    let data = {
      searchName: this.searchName,
      searchEmail: this.searchEmail,
      searchPhone: this.searchPhoneNumber,
      searchUsername: this.searchUserName
    }
    this.dashboardService.filterAssessorManagmentList(this.api_token, data).subscribe((res: any) => {
      this.assessorList = res.assessor_list;
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  onSelectCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_cv_show = event.target.files[0];
      if (this.profile_cv_show == null) {
        this.ProfessionalCVErr = 'please select a document';
      }
      if (this.profile_cv_show != null) {
        this.ProfessionalCVErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  onSelectPhoto(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_photo_show = event.target.files[0];
      if (this.profile_photo_show == null) {
        this.profilePicErr = 'please select a your profile photo';
      }
      if (this.profile_photo_show != null) {
        this.profilePicErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
      }
    }
  }
  onItemSelect1(item: any) {
    // console.log(this.InnovationHandsonExperiencesSelectedItem);
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.HandsonExperiencesErr = '';
    }
  }
  OnItemDeSelect1(item: any) {
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.HandsonExperiencesErr = '';
    }
  }
  onSelectAll1(items: any) {
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.HandsonExperiencesErr = '';
    }
  }
  onDeSelectAll1(items: any) {
    if(this.InnovationHandsonExperiencesSelectedItem.length == 0){
      this.HandsonExperiencesErr = 'Handson Experiences is require';
    }
    if(this.InnovationHandsonExperiencesSelectedItem.length > 0){
      this.HandsonExperiencesErr = '';
    }
  }
  onItemSelect2(item: any) {
    // console.log(this.AreasofExpertisesSelectedItem);
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.areasofExpertisesErr = '';
    }
  }
  OnItemDeSelect2(item: any) {
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.areasofExpertisesErr = '';
    }
  }
  onSelectAll2(items: any) {
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.areasofExpertisesErr = '';
    }
  }
  onDeSelectAll2(items: any) {
    if(this.AreasofExpertisesSelectedItem.length == 0){
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if(this.AreasofExpertisesSelectedItem.length > 0){
      this.areasofExpertisesErr = '';
    }
  }
  onItemSelect3(item: any) {
    // console.log(this.ExternalAwardsParticipatesSelectedItem);
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.awardsParticipatedErr = '';
    }
  }
  OnItemDeSelect3(item: any) {
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.awardsParticipatedErr = '';
    }
  }
  onSelectAll3(items: any) {
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.awardsParticipatedErr = '';
    }
  }
  onDeSelectAll3(items: any) {
    if(this.ExternalAwardsParticipatesSelectedItem.length == 0){
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if(this.ExternalAwardsParticipatesSelectedItem.length > 0){
      this.awardsParticipatedErr = '';
    }
  }
  awardsParticipatedValue($event, participatesItem) {
    if ($event.srcElement.checked == true) {
      if (this.External_Awards_ParticipatedId_Array.indexOf(Number(participatesItem.external_awards_participateds_id)) == -1) {
        this.External_Awards_ParticipatedId_Array.push(Number(participatesItem.external_awards_participateds_id));
      }
      if (this.External_Awards_ParticipatedId_Array.length > 0) {
        this.awardsParticipatedErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.External_Awards_ParticipatedId_Array.splice(this.External_Awards_ParticipatedId_Array.indexOf($event.srcElement.value * 1), 1);
      if (this.External_Awards_ParticipatedId_Array.length == 0) {
        this.awardsParticipatedErr = 'External Awards Participated is required';
      }
    }
    console.log(this.External_Awards_ParticipatedId_Array)
  }
  areasofExpertisesValue($event, areasofExpertisesItem) {
    console.log(areasofExpertisesItem);
    if ($event.srcElement.checked == true) {
      if (this.Areas_of_ExpertiseId_Array.indexOf(Number(areasofExpertisesItem.areasof_expertises_id)) == -1) {
        this.Areas_of_ExpertiseId_Array.push(Number(areasofExpertisesItem.areasof_expertises_id));
      }
      if (this.Areas_of_ExpertiseId_Array.length > 0) {
        this.areasofExpertisesErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.Areas_of_ExpertiseId_Array.splice(this.Areas_of_ExpertiseId_Array.indexOf($event.srcElement.value * 1), 1);
      if (this.Areas_of_ExpertiseId_Array.length == 0) {
        this.areasofExpertisesErr = 'Areas of Expertises is required';
      }
    }
    console.log(this.Areas_of_ExpertiseId_Array)
  }
  handsonExperiencesValue($event, handsonExperiencesItem) {
    if ($event.srcElement.checked == true) {
      if (this.handsonExperienceIdArray.indexOf(Number(handsonExperiencesItem.innovation_handson_experiences_id)) == -1) {
        this.handsonExperienceIdArray.push(Number(handsonExperiencesItem.innovation_handson_experiences_id));
      }
      if (this.handsonExperienceIdArray.length > 0) {
        this.HandsonExperiencesErr = '';
      }
    }
    else if ($event.srcElement.checked == false) {
      this.handsonExperienceIdArray.splice(this.handsonExperienceIdArray.indexOf($event.srcElement.value * 1), 1);
      if (this.handsonExperienceIdArray.length == 0) {
        this.HandsonExperiencesErr = 'External Awards Participated is required';
      }
    }
    console.log(this.handsonExperienceIdArray);
    console.log(this.InnovationHandsonExperiencesList)
  }
  onChangeMobileCode(event) {
    if (event == '') {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.mobileCountryCodeeErr = '';
    }
  }
  onChangeWhatappCode(event) {
    if (event == '') {
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    if (event != '') {
      this.WhatsAppCountryErr = '';
    }
  }
  onChangePrefix(event) {
    if (event == '') {
      this.PrefixNameErr = 'Assessor Prefix Name is required';
    }
    if (event != '') {
      this.PrefixNameErr = '';
    }
  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }

  openAddForm(action, assessorItem) {
    document.getElementById("form_open").style.display = "block";
    document.getElementById("form_close").style.display = "none";
    this.showAddForm = true;
    this.formAction = action;
    this.submitted = false;
    this.panelExpanded = false;
    if ((this.formAction == 'add') && (assessorItem == null)) {
      this.singleAssessorDetails = null;
      this.display_profile_photo = '';
      // this.profile_photo_show = null;
      // this.profile_cv_show = null;
      this.profile_CV = null;
      this.profile_photo = null;
      this.ProfessionalCVErr = '';
      this.profilePicErr = '';
      this.assessorManagementForm.reset();
      this.External_Awards_ParticipatedId_Array.length = 0;
      this.awardsParticipatedErr = '';
      this.Areas_of_ExpertiseId_Array.length = 0;
      this.areasofExpertisesErr = '';
      this.handsonExperienceIdArray.length = 0;
      this.HandsonExperiencesErr = '';
      this.PrefixNameErr = '';
      this.WhatsAppCountryErr = '';
      this.mobileCountryCodeeErr = '';

      this.External_Awards_ParticipatedId_Array = [];
      this.ExternalAwardsParticipatesSelectedItem = [];
      this.handsonExperienceIdArray = [];
      this.InnovationHandsonExperiencesSelectedItem = [];
      this.Areas_of_ExpertiseId_Array = [];
      this.AreasofExpertisesSelectedItem = [];
    }
    if ((this.formAction == 'edit') && (assessorItem != null)) {
      this.singleAssessorDetails = assessorItem;
      this.profile_photo = null;
      this.profile_cv_show = null;
      this.ProfessionalCVErr = '';
      this.profilePicErr = '';
      this.PrefixNameErr = '';
      this.WhatsAppCountryErr = '';
      this.mobileCountryCodeeErr = '';

      console.log(this.singleAssessorDetails);
      // this.InnovationHandsonExperiencesList.map((item) => {
      //   item['handson_experiences_id'] = Number(item.innovation_handson_experiences_id);
      // })
      // this.ExternalAwardsParticipatesList.map((item) => {
      //   item['awards_participateds_id'] = Number(item.external_awards_participateds_id);
      // });
      // this.AreasofExpertisesList.map((item) => {
      //   item['expertises_id'] = Number(item.areasof_expertises_id);
      // });
      this.External_Awards_ParticipatedId_Array = [];
      this.ExternalAwardsParticipatesSelectedItem = [];
      this.handsonExperienceIdArray = [];
      this.InnovationHandsonExperiencesSelectedItem = [];
      this.Areas_of_ExpertiseId_Array = [];
      this.AreasofExpertisesSelectedItem = [];

      if ((this.singleAssessorDetails.assessor.Areas_of_Expertise === false) || (this.singleAssessorDetails.assessor.Areas_of_Expertise === null)) {
        this.Areas_of_ExpertiseId_Array = [];
      }
      else if (this.singleAssessorDetails.assessor.Areas_of_Expertise.length > 0) {
        this.singleAssessorDetails.assessor.Areas_of_Expertise.map((item) => {

          this.AreasofExpertisesList.map((item1) => {
            if (item1.areasof_expertises_id == item) {
              this.AreasofExpertisesSelectedItem.push(item1);
            }
          })
          // if (this.Areas_of_ExpertiseId_Array.indexOf(Number(item)) == -1) {
          //   this.Areas_of_ExpertiseId_Array.push(Number(item))
          // }
        });
      }
      console.log(this.Areas_of_ExpertiseId_Array);
      if ((this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience == false) || (this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience === null)) {
        this.handsonExperienceIdArray = [];
        this.InnovationHandsonExperiencesSelectedItem = [];
      }
      else if (this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience.length > 0) {
        this.singleAssessorDetails.assessor.Innovation_Hands_on_Experience.map((item) => {
          this.InnovationHandsonExperiencesList.map((item2) => {
            if (item2.innovation_handson_experiences_id == item) {
              this.InnovationHandsonExperiencesSelectedItem.push(item2);
            }
          })
          // if (this.handsonExperienceIdArray.indexOf(Number(item)) == -1) {
          //   this.handsonExperienceIdArray.push(Number(item))
          // }
        });
      }
      if ((this.singleAssessorDetails.assessor.external_awards_participateds === false) || (this.singleAssessorDetails.assessor.external_awards_participateds === null)) {
        this.External_Awards_ParticipatedId_Array = [];
      }
      else if (this.singleAssessorDetails.assessor.external_awards_participateds.length > 0) {
        this.singleAssessorDetails.assessor.external_awards_participateds.map((item) => {
          this.ExternalAwardsParticipatesList.map((item3) => {
            if (item3.external_awards_participateds_id == item) {
              this.ExternalAwardsParticipatesSelectedItem.push(item3);
            }
          })
          // if (this.External_Awards_ParticipatedId_Array.indexOf(Number(item)) == -1) {
          //   this.External_Awards_ParticipatedId_Array.push(Number(item))
          // }
        });
      }


      this.assessorManagementForm.patchValue({
        namePrefix: this.singleAssessorDetails.assessor.NamePrefix_id,
        first_name: this.singleAssessorDetails.assessor.first_name,
        last_name: this.singleAssessorDetails.assessor.last_name,
        gender: this.singleAssessorDetails.assessor.Gender_id,
        city: this.singleAssessorDetails.assessor.cityof_residences_id,
        nationality: this.singleAssessorDetails.assessor.nationalities_id,
        country: this.singleAssessorDetails.country_id,
        qualification: this.singleAssessorDetails.assessor.qualifications_id,
        mobile_no: this.singleAssessorDetails.assessor.mobile_no,
        mobile_country_code: this.singleAssessorDetails.assessor.Country_code,
        whatsapp_no: this.singleAssessorDetails.assessor.WhatsApp_mobile_no,
        whatsapp_country_code: this.singleAssessorDetails.assessor.WhatsApp_Country_code,
        graduatedIn: this.singleAssessorDetails.assessor.Graduated_In,
        work_email: this.singleAssessorDetails.assessor.Work_E_mail,
        personal_email: this.singleAssessorDetails.email,
        age_group: this.singleAssessorDetails.assessor.age_groups_id,
        license_code: this.singleAssessorDetails.assessor.license_code,

        attended_award_training: this.singleAssessorDetails.assessor.Confirmation_Award_Assessor_Training,
        participated_award_training: this.singleAssessorDetails.assessor.Confirmation_Award_2017_Assessment,
        // Innovation Hands-on Experience
        professional_assessment_language: this.singleAssessorDetails.assessor.professional_language_id,
        report_writing_language: this.singleAssessorDetails.assessor.Report_Writing_professional_language_id,
        employment_status: this.singleAssessorDetails.assessor.employment_statuses_id,
        current_employer: this.singleAssessorDetails.assessor.Current_Employer,
        job_title: this.singleAssessorDetails.assessor.Job_Title,
        Industry: this.singleAssessorDetails.assessor.industries_id,
        LinkedIn_profile_link: this.singleAssessorDetails.assessor.LinkedIn_Profile_Link,
        // Professional Profile/ CV
        Business_Travel_leave: this.singleAssessorDetails.assessor.Business_Travel_Leave,
        interested_participate_award_year: this.singleAssessorDetails.assessor.your_company_interested_participate_the_UAE_Innovation_Award,

        total_work_experience: this.singleAssessorDetails.assessor.yearsof_work_experiences_id,
        professional_assessment_experience: this.singleAssessorDetails.assessor.professional_assessment_experiences_id,
        professional_certifications: this.singleAssessorDetails.assessor.Professional_Certifications,
        Internal_assessment_completed: this.singleAssessorDetails.assessor.AssessmentComplete_id,
        External_assessment_completed: this.singleAssessorDetails.assessor.External_AssessmentComplete_id,
        external_award_assessment_completed: this.singleAssessorDetails.assessor.external_awards_Assessment_id,
        external_awards_teamLeader: this.singleAssessorDetails.assessor.Team_Leader_name,
        no_of_years_assessment_team_leader: this.singleAssessorDetails.assessor.external_award_assessment_team_leader_year_id,

      });
      // console.log(this.profileImagePath);
      // console.log(this.singleAssessorDetails.profile_picture);
      this.display_profile_photo = this.singleAssessorDetails.profile_picture != null ? this.profileImagePath + this.singleAssessorDetails.profile_picture : 'assets/images/default-user.png';
      this.profile_photo = this.singleAssessorDetails.profile_picture;

      this.profile_photo = this.singleAssessorDetails.profile_picture;
      this.profile_CV = this.singleAssessorDetails.assessor.Profile_CV;

    }
  }
  closeAddForm() {
    this.showAddForm = false;
    document.getElementById("form_open").style.display = "none";
    document.getElementById("form_close").style.display = "block";
    this.panelExpanded = false;
    this.submitted = false;
  }
  approveAwardList(assessorItem) {
    this.approveAwardListLoad = true;
    this.dashboardService.approveAwardList(this.api_token, assessorItem.assessor.user_id).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.approveAwardListLoad = false;
        this.approveList = res.award_data;
        this.approveAwardIdArray = [];
        this.approveList.map((item) => {
          if (item.status == 'Y') {
            this.approveAwardIdArray.push(item.award_year.id);
          }
        })
        // console.log(this.approveAwardIdArray);
      }
    }, err => {
      this.approveAwardListLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  approveAwardSelectedValue($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.approveAwardIdArray.indexOf(Item.award_year.id) == -1) {
        this.approveAwardIdArray.push(Item.award_year.id);
      }
      // if (this.approveAwardIdArray.length > 0) {
      //   this.awardErr = '';
      // }
    }
    else if ($event.srcElement.checked == false) {
      this.approveAwardIdArray.splice(this.approveAwardIdArray.indexOf($event.srcElement.value * 1), 1);
      // if (this.approveAwardIdArray.length == 0) {
      //   this.awardErr = 'Please Select One Award';
      // }
    }
    console.log(this.approveAwardIdArray)
  }
  approveAward() {
    let data = {
      award_year_id: this.approveAwardIdArray,
      user_id: this.singleAssessorDetails.assessor.user_id
    }
    console.log(data)
    this.approveProcessingText = true;
    // if (this.approveAwardIdArray.length > 0) {
    this.awardErr = '';
    this.dashboardService.approveAward(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.approveProcessingText = false;
        this.messagesService.showSuccess(res.msg);
        this.assessorManagementlist();
        this.closeshow_approveaward_form();
        this.panelExpanded = false;
      }
      if (res.has_error == 1) {
        this.approveProcessingText = false;
        this.messagesService.showError(res.msg);
      }
    }, err => {
      this.approveProcessingText = false;
      this.messagesService.showError('Something went wrong');
    })
    // }
    // else if (this.approveAwardIdArray.length == 0) {
    //   this.awardErr = 'Please Select One Award';
    // }
  }
  openshow_approveaward_form(assessorItem) {
    this.singleAssessorDetails = assessorItem;
    this.show_approveaward_form = true;
    this.panelExpanded = false;
    this.approveAwardList(assessorItem);
  }
  closeshow_approveaward_form() {
    this.show_approveaward_form = false;
  }

  openshow_activiltylog_form() {
    this.show_activiltylog = true;
  }
  closeshow_activiltylog_form() {
    this.show_activiltylog = false;
  }
  openshow_license_form() {
    this.show_license = true;
  }
  closeshow_license_form() {
    this.show_license = false;
  }
  updateprofile() {
    this.submitted = true;
    this.External_Awards_ParticipatedId_Array = [];
    this.handsonExperienceIdArray = [];
    this.Areas_of_ExpertiseId_Array = [];

    this.ExternalAwardsParticipatesSelectedItem.map((item1) => {
      this.External_Awards_ParticipatedId_Array.push(item1.external_awards_participateds_id);
    });
    this.InnovationHandsonExperiencesSelectedItem.map((item2) => {
      this.handsonExperienceIdArray.push(item2.innovation_handson_experiences_id);
    });
    this.AreasofExpertisesSelectedItem.map((item3) => {
      this.Areas_of_ExpertiseId_Array.push(item3.areasof_expertises_id);
    });

    if ((this.profile_CV == null) && (this.profile_cv_show == null)) {
      this.ProfessionalCVErr = 'please select a document';
    }
    if ((this.profile_photo == null) && (this.profile_photo_show == null)) {
      this.profilePicErr = 'please upload profile picture';
    }
    if ((this.assessorManagementForm.value.namePrefix == null) && (this.assessorManagementForm.value.first_name != null)) {
      this.PrefixNameErr = 'Assessor Prefix Name is required';
    }
    if ((this.assessorManagementForm.value.whatsapp_country_code == null) && (this.assessorManagementForm.value.whatsapp_no != null)) {
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    if ((this.assessorManagementForm.value.mobile_country_code == null) && (this.assessorManagementForm.value.mobile_no != null)) {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
    // if (this.External_Awards_ParticipatedId_Array.length == 0) {
    //   this.awardsParticipatedErr = 'External Awards Participated is required';
    // }
    // if (this.Areas_of_ExpertiseId_Array.length == 0) {
    //   this.areasofExpertisesErr = 'Areas of Expertises is required';
    // }
    // if (this.handsonExperienceIdArray.length == 0) {
    //   this.HandsonExperiencesErr = 'External Awards Participated is required';
    // }
    console.log('External_Awards_ParticipatedId_Array', this.External_Awards_ParticipatedId_Array)
    console.log('Areas_of_ExpertiseId_Array', this.Areas_of_ExpertiseId_Array)
    console.log('handsonExperienceIdArray', this.handsonExperienceIdArray)
    // stop here if form is invalid
    if (this.assessorManagementForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      // formData.append('award_id', this.assesorAwardIdArray),

      formData.append('NamePrefix_id', this.assessorManagementForm.value.namePrefix),
      formData.append('first_name', this.assessorManagementForm.value.first_name),
      formData.append('last_name', this.assessorManagementForm.value.last_name),
      formData.append('Gender_id', this.assessorManagementForm.value.gender),
      formData.append('cityof_residences_id', this.assessorManagementForm.value.city),
      formData.append('nationalities_id', this.assessorManagementForm.value.nationality),
      formData.append('qualifications_id', this.assessorManagementForm.value.qualification),
      formData.append('Country_code', this.assessorManagementForm.value.mobile_country_code),
      formData.append('mobile_no', this.assessorManagementForm.value.mobile_no),
      formData.append('WhatsApp_Country_code', this.assessorManagementForm.value.whatsapp_country_code),
      formData.append('WhatsApp_mobile_no', this.assessorManagementForm.value.whatsapp_no),
      formData.append('Graduated_In', this.assessorManagementForm.value.graduatedIn),
      formData.append('Work_E_mail', this.assessorManagementForm.value.work_email),
      formData.append('email', this.assessorManagementForm.value.personal_email),
      formData.append('country_id', this.assessorManagementForm.value.country),
      formData.append('age_groups_id', this.assessorManagementForm.value.age_group),
      formData.append('license_code', this.assessorManagementForm.value.license_code)

    if (this.profile_photo_show != null) {
      formData.append('Profile_pic', this.profile_photo_show)
    }
    if (this.profile_photo_show == null) {
      formData.append('Profile_pic', this.profile_photo)
    }

    formData.append('yearsof_work_experiences_id', this.assessorManagementForm.value.total_work_experience),
      formData.append('Professional_Certifications', this.assessorManagementForm.value.professional_certifications),
      formData.append('professional_assessment_experiences_id', this.assessorManagementForm.value.professional_assessment_experience),
      formData.append('AssessmentComplete_id', this.assessorManagementForm.value.Internal_assessment_completed),
      formData.append('External_AssessmentComplete_id', this.assessorManagementForm.value.External_assessment_completed),
      formData.append('external_awards_Assessment_id', this.assessorManagementForm.value.external_award_assessment_completed),
      formData.append('Team_Leader_name', this.assessorManagementForm.value.external_awards_teamLeader),
      formData.append('external_award_assessment_team_leader_year_id', this.assessorManagementForm.value.no_of_years_assessment_team_leader),
      formData.append('external_awards_participateds', this.External_Awards_ParticipatedId_Array),

      formData.append('Confirmation_Award_Assessor_Training', this.assessorManagementForm.value.attended_award_training),
      formData.append('Confirmation_Award_2017_Assessment', this.assessorManagementForm.value.participated_award_training),
      formData.append('professional_language_id', this.assessorManagementForm.value.professional_assessment_language),
      formData.append('Report_Writing_professional_language_id', this.assessorManagementForm.value.report_writing_language),
      formData.append('Areas_of_Expertise', this.Areas_of_ExpertiseId_Array),
      formData.append('Innovation_Hands_on_Experience', this.handsonExperienceIdArray),
      formData.append('employment_statuses_id', this.assessorManagementForm.value.employment_status),
      formData.append('Current_Employer', this.assessorManagementForm.value.current_employer),
      formData.append('Job_Title', this.assessorManagementForm.value.job_title),
      formData.append('industries_id', this.assessorManagementForm.value.Industry),
      formData.append('LinkedIn_Profile_Link', this.assessorManagementForm.value.LinkedIn_profile_link),
      formData.append('Business_Travel_Leave', this.assessorManagementForm.value.Business_Travel_leave),
      formData.append('your_company_interested_participate_the_UAE_Innovation_Award', this.assessorManagementForm.value.interested_participate_award_year)

    if (this.profile_cv_show != null) {
      formData.append('Profile_CV', this.profile_cv_show)
    }
    if (this.profile_photo_show == null) {
      formData.append('Profile_CV', this.profile_CV)
    }
    if (this.formAction == 'edit') {
      formData.append('assessor_id', this.singleAssessorDetails.id)
    }
    if ((this.profile_CV != null || this.profile_cv_show != null)
      && (this.profile_photo != null || this.profile_photo_show != null) && (this.assessorManagementForm.value.namePrefix != '') && (this.assessorManagementForm.value.WhatsApp_Country_code != '') && (this.assessorManagementForm.value.mobile_country_code != '') && (this.External_Awards_ParticipatedId_Array.length > 0) && (this.Areas_of_ExpertiseId_Array.length > 0) && (this.handsonExperienceIdArray.length > 0)) {
      this.processingText = true;
      this.ProfessionalCVErr = '';
      this.profilePicErr = '';
      this.mobileCountryCodeeErr = '';
      if (this.formAction == 'add') {
        this.dashboardService.addAssessorProfile(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processingText = false;
            this.panelExpanded = false;
            this.assessorManagementlist();
            this.closeAddForm();
            this.messagesService.showSuccess('Assessor added successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.processingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
        // }
        // else if (this.External_Awards_ParticipatedId_Array.length == 0) {
        //   this.awardsParticipatedErr = 'External Awards Participated is required';
        // }
      }
      if (this.formAction == 'edit') {
        this.dashboardService.ediAssessorProfile(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processingText = false;
            this.assessorManagementlist();
            this.panelExpanded = false;
            this.closeAddForm();
            this.messagesService.showSuccess('Profile Updated successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.processingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
      }
    }

    if ((this.profile_CV == null) && (this.profile_cv_show == null)) {
      this.ProfessionalCVErr = 'please select a document';
    }
    if ((this.profile_photo != null) && (this.profile_photo_show == null)) {
      this.profilePicErr = 'please upload profile picture';
    }
    if ((this.assessorManagementForm.value.namePrefix == null) && (this.assessorManagementForm.value.first_name != null)) {
      this.PrefixNameErr = 'Assessor Prefix Name is required';
    }
    if ((this.assessorManagementForm.value.whatsapp_country_code == null) && (this.assessorManagementForm.value.whatsapp_no != null)) {
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    if ((this.assessorManagementForm.value.mobile_country_code == null) && (this.assessorManagementForm.value.mobile_no != null)) {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (this.ExternalAwardsParticipatesSelectedItem.length == 0) {
      this.awardsParticipatedErr = 'External Awards Participated is required';
    }
    if (this.AreasofExpertisesSelectedItem.length == 0) {
      this.areasofExpertisesErr = 'Areas of Expertises is required';
    }
    if (this.InnovationHandsonExperiencesSelectedItem.length == 0) {
      this.HandsonExperiencesErr = 'Handson Experiences is required';
    }
  }
}

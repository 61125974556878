import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MeadiaService } from '../services/meadia.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessagesService } from '../services/messages.service';
import { FacebookService, InitParams } from 'ngx-facebook';
import { appConfig } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

declare var FB: any;
@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 3
      },
      1025: {
        items: 3
      }
      // 1025: {
      //   items: 6
      // }
    },
    nav: false,
    // video:true
  }
  user_token: any;
  mediaImageArray: any = [];
  mediaVideoArray: any = [];
  year: any;
  yearList: any = [];
  mediaImagePath: any;
  safeSrc: SafeResourceUrl;
  lazyLoad: boolean = false;
  galleryShow: boolean = false;
  selectedIndex: any;
  youtube_video_thumnail: any;
  currentYear: any;
  newYearArray: any = [];
  mediaArray: any = [];
  videoArray: any = [];
  videoShow: boolean = false;
  videoPath: any;
  videothumbnailPath: any;
  htmlVideoDuration: any;
  vimeoVideoThumbnail: any;
  videoEl: any;
  isYoutubeVideo: boolean = true;
  isVimeoVideo: boolean = true;
  viewAllYear: any;
  constructor(private meadiaService: MeadiaService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private messagesService: MessagesService,
    private fb: FacebookService,
    private http: HttpClient,
    private router: Router) {
    this.user_token = localStorage.getItem('user_token');
  }

  ngOnInit() {
    // (window as any).fbAsyncInit = function () {
    //   FB.init({
    //     appId: '816831178771778',
    //     cookie: true,
    //     xfbml: true,
    //     version: 'v3.1'
    //   });
    //   FB.AppEvents.logPageView();
    // };

    // (function (d, s, id) {
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) { return; }
    //   js = d.createElement(s); js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
    this.getuserToken();
    var d = new Date();
    this.currentYear = d.getFullYear();
    for (let i = this.currentYear; i > this.currentYear - 4; i--) {
      this.yearList.push(i);
    }
    this.newYearArray = [this.currentYear];
    window.scroll(0, 0);
    this.getMediaList();
  }

  getuserToken(){
    let userData ={
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res:any)=>{
      if(res.has_error == 0){
        this.user_token = res.user_token;
        localStorage.setItem('user_token',this.user_token)
      }
      },
      err =>{
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }
  
  yearFilteration(year, index) {
    this.viewAllYear = year;
    this.selectedIndex = null;
    let yearId = 'year' + index;
    let parentEl = document.getElementById(yearId).parentNode.parentNode;
    parentEl.querySelectorAll('li').forEach((item) => {
      item.querySelector('a').classList.remove('active');
    });
    document.getElementById(yearId).className += ' active';
    if (!this.newYearArray.includes(year)) {
      this.newYearArray.push(year);
      this.year = year;
      this.galleryShow = true;
      let pageNumber: number = 1;
      let per_page = 20;
      this.mediaImageArray = [];
      this.mediaVideoArray = [];
      this.meadiaService.mediaList(this.user_token, this.year, pageNumber,per_page).subscribe((res: any) => {
        // console.log(res);
        if (res.has_error == 0) {
          this.galleryShow = false;
          this.mediaImageArray = res.imageData;
          if (res.videoData.length > 0) {
            this.mediaVideoArray = res.videoData;
            this.mediaVideoArray.map((item) => {
              this.videoArray.push(item);
            })
          }
          this.mediaImagePath = res.imagePath;
          this.videothumbnailPath = res.video_thumb_path;
          this.mediaImageArray.map((item) => {
            this.mediaArray.push(item);
          })
          if (this.mediaVideoArray.length > 0) {
            this.mediaVideoArray.map((item) => {
              if (item.video_type == "F") {
                item['videoSrc'] = item.video;
              }
              else if (item.video_type == "L") {
                if (item.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
                  // ?rel=0&amp;autoplay=1&mute=1
                  this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(item.video + '?autoplay=1');
                  item['videoSrc'] = this.safeSrc;
                  var youtube_video_id = item.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
                  this.youtube_video_thumnail = 'http://img.youtube.com/vi/' + youtube_video_id + '/hqdefault.jpg';
                  item['videoThumbnail'] = this.youtube_video_thumnail;
                  item['videoType'] = 'youtube';
                }
                // else if (item.video.match(/http:\/\/(?:www\.)?youtube.*watch\?v=([a-zA-Z0-9\-_]+)/)) {
                //   this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(item.video + '?autoplay=1');
                //   item['videoSrc'] = this.safeSrc;
                //   var youtube_video_id = item.video.match(/http:\/\/(?:www\.)?youtube.*watch\?v=([a-zA-Z0-9\-_]+)/).pop();
                //   this.youtube_video_thumnail = 'http://img.youtube.com/vi/' + youtube_video_id + '/hqdefault.jpg';
                //   item['videoThumbnail'] = this.youtube_video_thumnail;
                // }
                else if (item.videodata.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
                  var match = item.videodata.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/);
                  var vimeo_video_id = match[2];
                  var vimeoVideo = 'https://player.vimeo.com/video/' + match[2];
                  this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(vimeoVideo + '?autoplay=1');
                  item['videoSrc'] = this.safeSrc;
                  this.http.get('https://vimeo.com/api/v2/video/' + vimeo_video_id + '.json')
                    .subscribe((response: any) => {
                      this.vimeoVideoThumbnail = response[0].thumbnail_large;
                      item['videoThumbnail'] = this.vimeoVideoThumbnail;
                    });
                    item['videoType'] = 'vimeo';
                }
              }
            })
            console.log('mediaVideoArray', this.mediaVideoArray)
          }
        }
      }, err => {
        this.galleryShow = false;
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800)
      })
    }
    else if (this.newYearArray.includes(year)) {
      let a = year.toString();
      this.mediaImageArray = this.mediaArray.filter(x => x.year == a);
      this.mediaVideoArray = this.videoArray.filter(x => x.year == a);
    }
  }
  getMediaList() {
    this.lazyLoad = true;
    let pageNumber: number = 1;
    let per_page = 20;
    this.viewAllYear = this.currentYear;
    if (this.year == undefined) this.year = this.currentYear;
    this.meadiaService.mediaList(this.user_token, this.year, pageNumber,per_page).subscribe((res: any) => {
      // console.log(res);
      if (res.has_error == 0) {
        this.lazyLoad = false;
        this.videoPath = res.imagePath;
        this.mediaImageArray = res.imageData;
        this.mediaVideoArray = res.videoData;
        this.mediaImagePath = res.imagePath;
        this.videothumbnailPath = res.video_thumb_path;
        this.mediaImageArray.map((item) => {
          this.mediaArray.push(item);
        })
        this.mediaVideoArray.map((item) => {
          this.videoArray.push(item);
        })
        this.mediaVideoArray.map((item) => {
          if (item.video_type == "F") {
            item['videoSrc'] = item.video;
          }
          else if (item.video_type == "L") {
            // item.video ="https://vimeo.com/376789571"
            if (item.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
              this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(item.video + '?autoplay=1');
              item['videoSrc'] = this.safeSrc;
              var youtube_video_id = item.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
              this.youtube_video_thumnail = 'http://img.youtube.com/vi/' + youtube_video_id + '/hqdefault.jpg';
              item['videoThumbnail'] = this.youtube_video_thumnail;
              item['videoType'] = 'youtube';
            }
            // else if (item.video.match(/http:\/\/(?:www\.)?youtube.*watch\?v=([a-zA-Z0-9\-_]+)/)) {
            //   this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(item.video + '?autoplay=1');
            //   item['videoSrc'] = this.safeSrc;
            //   var youtube_video_id = item.video.match(/http:\/\/(?:www\.)?youtube.*watch\?v=([a-zA-Z0-9\-_]+)/).pop();
            //   this.youtube_video_thumnail = 'http://img.youtube.com/vi/' + youtube_video_id + '/hqdefault.jpg';
            //   item['videoThumbnail'] = this.youtube_video_thumnail;
            // }
            else if (item.videodata.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
              var match = item.videodata.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/);
              var vimeo_video_id = match[2];
              var vimeoVideo = 'https://player.vimeo.com/video/' + match[2];
              this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(vimeoVideo + '?autoplay=1');
              item['videoSrc'] = this.safeSrc;
              this.http.get('http://vimeo.com/api/v2/video/' + vimeo_video_id + '.json')
                .subscribe((response: any) => {
                  this.vimeoVideoThumbnail = response[0].thumbnail_large;
                  item['videoThumbnail'] = this.vimeoVideoThumbnail;
                });
                item['videoType'] = 'vimeo';
            }
          }
        })
        console.log('mediaVideoArray', this.mediaVideoArray)
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800)
    })
  }
  videoPlay(index) {
    this.videoShow = true;
    this.selectedIndex = index;
    setTimeout(() => {
      this.videoShow = false;
    }, 2000);
  }
  viewAll(){
    this.router.navigate(['/view-all-media',this.viewAllYear]);
  }
}

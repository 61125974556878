import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { appConfig } from '../app.config';
import { MessagesService } from '../services/messages.service';
import { DataService } from '../services/data.service';
import { DashboardService } from '../services/dashboard.service';


@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss']
})
export class CompanyRegisterComponent implements OnInit {
  formOneShow: boolean = true;
  formTwoShow: boolean = false;
  // formThreeShow: boolean = false;

  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  stepThreeForm: FormGroup;
  stepFourForm: FormGroup;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4 = false;

  showOpenAward: boolean = false;
  showOpenCountry: boolean = false;
  allAwardList: any = [];
  allAwardSelected: any = [];
  allAwardCountryList: any = [];
  allAwardCountrySelected: any = [];
  allAwardCountrySelectedString: any = "";
  allAwardSelectedString: any = "";

  award_image_path: any;
  awardIdParam: any;
  awardId: any = [];
  user_token: any;
  countryCodeArray: any = [];
  countryCallingCodes: any = [];
  IndustryList: any = [];
  NamePrefixsList: any = [];
  NationalitiesList: any = [];
  branchesList: any = [];
  noofStaffList: any = [];
  sectorsList: any = [];
  yearsinUaeList: any = [];
  NationalityId: any = [];
  cityListArray: any = [];
  awardList: any = [];
  newAwardIdArray: any = [];
  awardErr: any;
  mobileCountryCodeErr: any;
  NationalityErr: any;
  showCountryborder: boolean = false;
  showAwardborder: boolean = false;
  BusinessLicense_Show: any;
  Company_Logo_Show: any;
  Business_LicenseErr: any;
  CompanyLogoErr: any;
  Business_License: any;
  Company_Logo: any;
  AuthorizedPrefixNameErr: any;
  CeoPrefixNameErr: any;
  contactCountryCodeErr: any;
  mandetoryShow: boolean = true;
  delegate1prefixErr: any;
  delegate2prefixErr: any;
  errorMessageValue: any;
  processingText: boolean = false;
  uniqueEmailShow: boolean = false;
  uniqueEmailErr: any;
  lazyLoad: boolean = false;
  // emailProsessingtext: boolean = false;
  uniqueEmailVerified: boolean = false;
  webSitePatternErr: boolean = false;
  allCountryList: any = [];
  payment_ReceiptErr: any;
  paymentReceipt_Show: any = null;
  award_year_id: any = [];
  awardDetails: any;
  awardYearIdParam: any;
  processingAddPayment: boolean;
  compnayRes: any;
  paymentProcessing: boolean = false;
  paymentInfo: any;
  fileTypeText: any;
  company_logo_text: any;

  constructor(private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private messagesService: MessagesService,
    private dataService: DataService,
    private dashboardService: DashboardService) {
    localStorage.removeItem('awardId');
    localStorage.removeItem('award_year_Id');
    this.activatedRoute.params.subscribe(params => {
      this.awardIdParam = Number(params['id']);
      this.awardYearIdParam = Number(params['id1']);
      this.awardId.push(Number(params['id']));
      this.award_year_id.push(Number(params['id1']));
    });
    localStorage.setItem('awardId', this.awardIdParam);
    localStorage.setItem('award_year_Id', this.awardYearIdParam);
    this.user_token = localStorage.getItem('user_token');
    console.log(this.award_year_id)
  }

  ngOnInit() {
    this.dataService.awardDetailsData.subscribe((res: any) => {
      console.log(res);
      if ((res != undefined) && (res != '')) {
        this.awardDetails = res.filter(x => x.year_id === this.awardYearIdParam);
        console.log(this.awardDetails)
      }
    })
    window.scrollTo(0, 0);
    this.getuserToken();
    this.callingCodes();
    this.getCompanyDetails(this.awardId);
    this.stepOneForm = this.formBuilder.group({
      award_type: [this.awardYearIdParam, Validators.required],
      award_country: [''],
      company_name: ['', Validators.required],
      Industry: ['', Validators.required],
      sector: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      number_of_staff: ['', Validators.required],
      years_in_operation: ['', Validators.required],
      postal_code: ['', Validators.required],
      work_email: ['', [Validators.required, Validators.email]],
      website: ['', Validators.required],
      Branches: ['', Validators.required],
      contact_Country_code: ['', Validators.required],
      contact_number: ['', Validators.required],
    });
    this.stepTwoForm = this.formBuilder.group({
      ceo_prefix: ['', Validators.required],
      ceo_first_name: ['', Validators.required],
      ceo_last_name: ['', Validators.required],
      authorized_representative_prefix: ['', Validators.required],
      authorized_representative: ['', Validators.required],
      representative_job_title: ['', Validators.required],
      mobile_Country_code: ['', Validators.required],
      mobile_number: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      company_profile_description: ['', Validators.required],
      head_office_address: ['', Validators.required]
    });
    this.stepThreeForm = this.formBuilder.group({
      delegate_prefix: ['', Validators.required],
      delegate_first_name: ['', Validators.required],
      delegate_last_name: ['', Validators.required],
      delegate2_prefix: ['', Validators.required],
      delegate2_first_name: ['', Validators.required],
      delegate2_last_name: ['', Validators.required],
    });
    this.stepFourForm = this.formBuilder.group({
      receipt_name: ['']
    });
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
    this.company_logo_text = "Company Logo resolution should be 295px X 225px";
  }
  getuserToken() {
    let userData = {
      user_name: appConfig.user_name,
      password: appConfig.password
    }
    this.authService.getUserToken(userData).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.user_token = res.user_token;
        localStorage.setItem('user_token', this.user_token)
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
        setTimeout(() => {
          this.messagesService.clear();
        }, 800);
      });
  }
  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    })
  }
  // get city list
  cityList(NationalityId) {
    let data = {
      nationalities_id: NationalityId
    }
    this.authService.getCityList(data).subscribe((response: any) => {
      this.cityListArray = response.data;
    }, error => {
    });
  }
  onChangeContactCode(event) {
    if (event == '') {
      this.contactCountryCodeErr = 'Country code of Contact Number is required';
    }
    if (event != '') {
      this.contactCountryCodeErr = '';
    }
  }
  onChangeMobileCode(event) {
    if (event == '') {
      this.mobileCountryCodeErr = 'Mobile code of Contact Number is required';
    }
    if (event != '') {
      this.mobileCountryCodeErr = '';
    }
  }
  onChangeDelegate1prefix(event) {
    if (event == '') {
      this.delegate1prefixErr = 'Delegate prefix is required';
    }
    if (event != '') {
      this.delegate1prefixErr = '';
    }
  }
  onChangeDelegate2prefix(event) {
    if (event == '') {
      this.delegate2prefixErr = 'Delegate2 prefix is required';
    }
    if (event != '') {
      this.delegate2prefixErr = '';
    }
  }
  onSelectFileBusinessLicense(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      this.BusinessLicense_Show = event.target.files[0];
      if (this.BusinessLicense_Show != '') {
        this.Business_LicenseErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.Business_License = event.target.result;
      }
    }
  }
  onSelectFileCompanyLogo(event) {
    if (event.target.files && event.target.files[0]) {
      this.Company_Logo_Show = event.target.files[0];
      if (this.Company_Logo_Show != '') {
        this.CompanyLogoErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.Company_Logo = event.target.result;

      }
    }
  }
  onSelectFilePayment(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      this.paymentReceipt_Show = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.Business_License = event.target.result;
      }
    }
  }
  // get assessor details
  getCompanyDetails(awardId) {
    this.lazyLoad = true;
    let data = {
      user_token: this.user_token,
      award_id: awardId
    }
    this.authService.companyDetails(data).subscribe((response: any) => {
      this.lazyLoad = false;
      this.IndustryList = response.Industries;
      this.NamePrefixsList = response.NamePrefixs;
      this.NationalitiesList = response.Nationalities;
      this.branchesList = response.branches;
      this.noofStaffList = response.no_of_staff;
      this.sectorsList = response.sectors;
      this.yearsinUaeList = response.years_in_uae;
      this.awardList = response.award_list;
      this.allCountryList = response.all_country;
      this.paymentInfo = this.awardList.filter(x => x.year_id === this.awardYearIdParam);
      console.log('paymentInfo',this.paymentInfo)
      this.stepOneForm.patchValue({
        award_country: this.awardList.find(x => x.id === this.awardIdParam).country_id
      });

    },
      err => {
        this.lazyLoad = false;
      })
  }
  onChangeAuthorizedPrefix(event) {
    if (event == '') {
      this.AuthorizedPrefixNameErr = 'Authorized Representative Prefix Name is required';
    }
    if (event != '') {
      this.AuthorizedPrefixNameErr = '';
    }
  }
  onChangeCeoPrefix(event) {
    if (event == '') {
      this.CeoPrefixNameErr = 'Authorized Representative Prefix Name is required';
    }
    if (event != '') {
      this.CeoPrefixNameErr = '';
    }
  }
  addProp1(e) {
    if (e.target.checked == true) {
      this.mandetoryShow = false;
    }
    else if (e.target.checked == false) {
      this.mandetoryShow = true;
      this.submitted3 = false;
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.stepOneForm.controls; }

  get f2() { return this.stepTwoForm.controls; }

  get f3() { return this.stepThreeForm.controls; }

  get f4() { return this.stepFourForm.controls; }
  uniqueEmailCheck() {
    // if (this.stepTwoForm.value.email != '') {
    let data = {
      email: this.stepTwoForm.value.email
    }
    // this.emailProsessingtext = true;
    this.processingText = true;
    this.authService.uniqueEmail(data).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.uniqueEmailShow = true;
        // this.emailProsessingtext = false;
        this.uniqueEmailVerified = false;
        this.uniqueEmailErr = '';
      }
      else if (res.has_error == 1) {
        this.uniqueEmailShow = false;
        // this.emailProsessingtext = false;
        this.processingText = false;
        window.scroll({
          top: 500,
          left: 0,
          behavior: 'smooth'
        });
        this.uniqueEmailVerified = true;
        this.uniqueEmailErr = 'Email is already registered';
      }
    }, err => {
      this.uniqueEmailErr = 'Something went wrong.';
      this.uniqueEmailShow = false;
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
      // this.emailProsessingtext = false;
      this.processingText = false;
      this.uniqueEmailVerified = false;
    })
    // }
  }
  onKey(event: any) { // without type info
    if (event.target.value == '') {
      this.uniqueEmailVerified = false;
    }
  }
  onChangeAward(event) {
    this.allCountryList = [];
    this.allAwardCountrySelected = [];
    this.allAwardCountrySelectedString = '';
    // this.NationalityId = [];
    this.submitted1 = false;
    this.IndustryList = [];
    this.NamePrefixsList = [];
    this.NationalitiesList = [];
    this.branchesList = [];
    this.noofStaffList = [];
    this.sectorsList = [];
    this.yearsinUaeList = [];

    this.stepOneForm.get('Industry').reset();
    this.stepOneForm.get('sector').reset();
    this.stepOneForm.get('country').reset();
    this.stepOneForm.get('number_of_staff').reset();
    this.stepOneForm.get('years_in_operation').reset();
    this.stepOneForm.get('Branches').reset();

    this.awardYearIdParam = event;
    this.award_year_id = [];
    this.award_year_id.push(Number(event));
    this.awardId = [];
    this.awardId.push(this.awardList.find(x => x.year_id === Number(this.awardYearIdParam)).id);
    this.awardIdParam = this.awardList.find(x => x.year_id === Number(this.awardYearIdParam)).id;
    console.log(this.award_year_id);
    localStorage.setItem('awardId', this.awardIdParam);
    localStorage.setItem('award_year_Id', this.awardYearIdParam);
    this.awardCompanyDetails(this.awardId);
  }
  formOneSubmit() {
    this.submitted1 = true;
    if ((this.stepOneForm.value.years_in_operation == '') || (this.stepOneForm.value.postal_code == '')
      || (this.stepOneForm.value.work_email == '') || (this.stepOneForm.value.website == '')
      || (this.stepOneForm.value.Branches == '') || (this.stepOneForm.value.contact_number == '')) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.contact_Country_code == '') && (this.stepOneForm.value.contact_number != '')) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
      this.contactCountryCodeErr = 'Country code of Contact Number is required';
    }
    if ((this.stepOneForm.value.country == '') || (this.stepOneForm.value.number_of_staff == '')) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.sector == '') || (this.stepOneForm.value.city == null)) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepOneForm.value.company_name == '') || (this.stepOneForm.value.Industry == '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    console.log(this.stepOneForm.value)
    // if (this.NationalityId.length == 0) {
    //   window.scroll({
    //     top: 300,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    //   this.NationalityErr = 'Award Country is required';
    //   this.showCountryborder = true;
    // }

    // stop here if form is invalid
    if (this.stepOneForm.invalid) {
      return;
    }
    // if (this.NationalityId.length == 0) {
    //   window.scroll({
    //     top: 300,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }
    if (this.webSitePatternErr == true) {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    else if (this.webSitePatternErr == false) {
      this.formOneShow = false;
      this.formTwoShow = true;
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
    }
    // else if ((this.NationalityId.length > 0) && (this.webSitePatternErr == false)) {
    //   this.formOneShow = false;
    //   this.formTwoShow = true;
    //   window.scroll({
    //     top: 200,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }

  }

  formTwoSubmit() {
    this.submitted2 = true;
    if (this.stepTwoForm.value.head_office_address == '') {
      window.scroll({
        top: 900,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.stepTwoForm.value.company_profile_description == '') {
      window.scroll({
        top: 800,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (this.BusinessLicense_Show == null) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.Business_LicenseErr = 'Business License is required';
    }
    else {
      this.Business_LicenseErr = '';
    }
    if (this.Company_Logo_Show == null) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
      this.CompanyLogoErr = 'Company Logo is required';
    }
    else {
      this.CompanyLogoErr = '';
    }
    if ((this.stepTwoForm.value.mobile_Country_code == '') && (this.stepTwoForm.value.mobile_number != '')) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
      this.mobileCountryCodeErr = 'Country code of Mobile Number is required';
    }
    if ((this.stepTwoForm.value.mobile_number == '') || (this.stepTwoForm.value.email == '')) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepTwoForm.value.authorized_representative == '') || (this.stepTwoForm.value.representative_job_title == '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepTwoForm.value.authorized_representative_prefix == '') && (this.stepTwoForm.value.authorized_representative != '')) {
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
      this.AuthorizedPrefixNameErr = 'Authorized Representative Prefix Name is required';
    }
    if ((this.stepTwoForm.value.ceo_first_name == '') || (this.stepTwoForm.value.ceo_last_name == '')) {
      window.scroll({
        top: 300,
        left: 0,
        behavior: 'smooth'
      });
    }
    if ((this.stepTwoForm.value.ceo_prefix == '') && (this.stepTwoForm.value.ceo_first_name != '')) {
      window.scroll({
        top: 300,
        left: 0,
        behavior: 'smooth'
      });
      this.CeoPrefixNameErr = 'CEO Prefix Name is required';
    }
    // stop here if form is invalid
    if (this.stepTwoForm.invalid) {
      return;
    }
    if ((this.BusinessLicense_Show != null) && (this.Company_Logo_Show != null)) {
      if (this.stepTwoForm.value.email != '') {
        this.uniqueEmailCheck();
      }
    }
    else if ((this.BusinessLicense_Show == null) && (this.Company_Logo_Show == null)) {
      window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
      });
    }
    setTimeout(() => {
      if (this.uniqueEmailShow == true) {
        this.stepOneForm.value.Industry = Number(this.stepOneForm.value.Industry);
        this.stepOneForm.value.sector = Number(this.stepOneForm.value.sector);
        this.stepOneForm.value.country = Number(this.stepOneForm.value.country);
        this.stepOneForm.value.number_of_staff = Number(this.stepOneForm.value.number_of_staff);
        this.stepOneForm.value.years_in_operation = Number(this.stepOneForm.value.years_in_operation);
        this.stepOneForm.value.Branches = Number(this.stepOneForm.value.Branches);
        this.stepOneForm.value.contact_Country_code = parseInt(this.stepOneForm.value.contact_Country_code);

        this.stepTwoForm.value.ceo_prefix = Number(this.stepTwoForm.value.ceo_prefix);
        this.stepTwoForm.value.authorized_representative_prefix = Number(this.stepTwoForm.value.authorized_representative_prefix);
        this.stepTwoForm.value.mobile_Country_code = parseInt(this.stepTwoForm.value.mobile_Country_code);

        let formData: FormData = new FormData();
        formData.append('user_token', this.user_token),
          // formData.append('award_id', this.awardId),
          formData.append('award_id', this.award_year_id),
          // formData.append('country_user', this.NationalityId),
          formData.append('company_name', this.stepOneForm.value.company_name),
          formData.append('industry_id', this.stepOneForm.value.Industry),
          formData.append('sector_id', this.stepOneForm.value.sector),
          formData.append('city', this.stepOneForm.value.city),
          formData.append('country_id', this.stepOneForm.value.country),

          formData.append('no_of_staff', this.stepOneForm.value.number_of_staff),
          formData.append('po_box', this.stepOneForm.value.postal_code),
          formData.append('years_in_uae', this.stepOneForm.value.years_in_operation),
          formData.append('website', this.stepOneForm.value.website),
          formData.append('no_of_branch', this.stepOneForm.value.Branches),
          formData.append('phone', this.stepOneForm.value.contact_number),
          formData.append('country_code', this.stepOneForm.value.contact_Country_code),
          formData.append('Work_E_mail', this.stepOneForm.value.work_email),

          formData.append('ceo_prefix', this.stepTwoForm.value.ceo_prefix),
          formData.append('ceo_firstname', this.stepTwoForm.value.ceo_first_name),
          formData.append('ceo_lastname', this.stepTwoForm.value.ceo_last_name),
          formData.append('representative_prefix', this.stepTwoForm.value.authorized_representative_prefix),
          formData.append('representative_firstname', this.stepTwoForm.value.authorized_representative),
          formData.append('job_title', this.stepTwoForm.value.representative_job_title),
          formData.append('mob_country_code', this.stepTwoForm.value.mobile_Country_code),
          formData.append('mob_phone', this.stepTwoForm.value.mobile_number),
          formData.append('email', this.stepTwoForm.value.email),
          formData.append('business_license', this.BusinessLicense_Show),
          formData.append('company_logo', this.Company_Logo_Show),
          formData.append('description', this.stepTwoForm.value.company_profile_description),
          formData.append('head_office_addr', this.stepTwoForm.value.head_office_address),

          formData.append('delegate1_prefix', null),
          formData.append('delegate1_firstname', ''),
          formData.append('delegate1_lastname', ''),
          formData.append('delegate2_prefix', null),
          formData.append('delegate2_firstname', ''),
          formData.append('delegate2_lastname', '');
          console.log(this.stepOneForm.value);
        if (this.user_token != null) {
          console.log(this.stepOneForm.value);
          this.processingText = true;
          this.authService.companyRegister(formData).subscribe((res: any) => {
            if (res.has_error == 0) {
              // this.compnayRes = res.user_details.api_token;
              this.processingText = false;
              // if (this.awardDetails[0].is_payble == 'Y') {
              //   this.formOneShow = false;
              //   this.formTwoShow = false;
              //   this.formThreeShow = true;
              //   window.scrollTo(0, 0);
              // }
              // if (this.awardDetails[0].is_payble == 'N') {
                // this.processingText = false;
                window.scrollTo(0, 0);
                this.messagesService.showSuccess('Registration is successfully done.Please check your email for an account activation link.');
                setTimeout(() => {
                  this.messagesService.clear();
                }, 4000);
                this.router.navigate(['/thank-you']);
              // }
            }
            else if (res.has_error == 1) {
              this.processingText = false;
              window.scroll({
                top: 300,
                left: 0,
                behavior: 'smooth'
              });
              for (let [key, value] of Object.entries(res.error)) {
                this.errorMessageValue = value;
              }
              this.messagesService.showError(this.errorMessageValue);
              setTimeout(() => {
                this.messagesService.clear();
              }, 4000);
            }
          },
            error => {
              this.processingText = false;
              window.scroll({
                top: 300,
                left: 0,
                behavior: 'smooth'
              });
              this.messagesService.showError('Something went wrong.');
              setTimeout(() => {
                this.messagesService.clear();
              }, 4000);
            })
        }
        else if (this.user_token == null) {
          window.scroll({
            top: 300,
            left: 0,
            behavior: 'smooth'
          });
          this.messagesService.showError('User token is missing.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 2000);
        }

      }
    }, 2000);
  }
  addPayment() {
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_year_id', this.awardYearIdParam),
      formData.append('receipt_name', this.stepFourForm.value.receipt_name)
    if (this.paymentReceipt_Show != null) {
      formData.append('payment_document', this.paymentReceipt_Show)
    }
    if (this.paymentReceipt_Show != null) {
      formData.append('payment_document', null)
    }
    if ((this.stepFourForm.value.receipt_name != '') && (this.paymentReceipt_Show != null)) {

      this.paymentProcessing = true;
      this.dashboardService.companyPayment(this.compnayRes, formData).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.paymentProcessing = false;
          window.scrollTo(0, 0);
          this.messagesService.showSuccess('Registration is successfully done.Please check your email for an account activation link.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
          this.router.navigate(['/thank-you']);
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.paymentProcessing = false;
        }
      },
        err => {
          this.paymentProcessing = false;
          this.messagesService.showError('Something went wrong.');
        })
    }
    else if ((this.stepFourForm.value.receipt_name == '') || (this.paymentReceipt_Show == null)) {
          window.scrollTo(0, 0);
          this.messagesService.showSuccess('Registration is successfully done.Please check your email for an account activation link.');
          setTimeout(() => {
            this.messagesService.clear();
          }, 4000);
          this.router.navigate(['/thank-you']);
    }
  }
  awardCompanyDetails(AwardId) {
    let data = {
      user_token: this.user_token,
      award_id: AwardId
    }
    this.authService.companyDetails(data).subscribe((response: any) => {
      this.IndustryList = response.Industries;
      this.NamePrefixsList = response.NamePrefixs;
      this.NationalitiesList = response.Nationalities;
      this.branchesList = response.branches;
      this.noofStaffList = response.no_of_staff;
      this.sectorsList = response.sectors;
      this.yearsinUaeList = response.years_in_uae;
      this.allCountryList = response.all_country;
      console.log(this.awardList.find(x => x.id === this.awardIdParam).country_id)
      this.stepOneForm.patchValue({
        award_country: this.awardList.find(x => x.id === this.awardIdParam).country_id
      });
    },
      err => {
      })
  }

  // checkAwardCountry(item) {
  //   if (!this.allAwardCountrySelected.some((allAwardCountrySelecteditem) => allAwardCountrySelecteditem.id == item.id)) {
  //     // this.NationalityId.push(item.id);
  //     this.submitted1 = false;
  //     this.cityListArray = [];
  //     this.stepOneForm.get('city').reset();
  //     // this.cityList(this.NationalityId);
  //     this.allAwardCountrySelected.push(item);
  //     // if (this.NationalityId.length > 0) {
  //     //   this.NationalityErr = '';
  //     //   this.showCountryborder = false;
  //     // }
  //   }
  //   else {
  //     const index: number = this.allAwardCountrySelected.indexOf(item);
  //     if (index !== -1) {
  //       this.allAwardCountrySelected.splice(index, 1);
  //       // this.NationalityId.splice(index, 1);
  //       this.submitted1 = false;
  //       this.cityListArray = [];
  //       this.stepOneForm.get('city').reset();
  //       // this.cityList(this.NationalityId);
  //       // if (this.NationalityId.length == 0) {
  //       //   this.NationalityErr = 'Award Country is required';
  //       //   this.showCountryborder = true;
  //       // }
  //     }
  //   }
  //   this.allAwardCountrySelectedString = "";
  //   if (this.allAwardCountrySelected.length > 3) {
  //     this.allAwardCountrySelectedString = this.allAwardCountrySelected.length + " items selected";

  //   }
  //   else {
  //     for (let itemlist of this.allAwardCountrySelected) {
  //       this.allAwardCountrySelectedString += itemlist.trans_data + ',';

  //     }
  //   }
  // }

  openClosePopup() {
    this.showOpenAward = !this.showOpenAward;
  }

  openClosePopupCountry() {
    this.showOpenCountry = !this.showOpenCountry;
  }
  onClickedOutside(e: Event) {
    this.showOpenCountry = false;
  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  // number Only
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onChangeCountry($event) {
    // this.cityListArray = [];
    // this.stepOneForm.get('city').reset();
    // this.cityList($event);
  }
}

import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WinnerService } from 'src/app/services/winner.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss'],
  providers: [NgbRatingConfig]
})
export class WinnersComponent implements OnInit {
  user_token: any;
  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,
    dots: false,
    nav: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
      // 0: {
      //   items: 1
      // },
      // 575: {
      //   items: 2
      // },
      // 767: {
      //   items: 2
      // },
      // 1000: {
      //   items: 3
      // }
    }
  }
  winnerListArray: any = [];
  companyLogoPath: any;
  award_image_path: any;
  currentRate = 3.14;

  constructor(private winnerService: WinnerService,
    config: NgbRatingConfig) {
    this.user_token = localStorage.getItem('user_token');
    // config.max = 5;
    // config.readonly = true;
  }

  ngOnInit() {
    this.winnerlist();
  }
  winnerlist() {
    let page_no = null;
    let per_page = null
    this.winnerService.winnerList(this.user_token, page_no, per_page).subscribe((res: any) => {
      // console.log(res)
      if (res.has_error == 0) {
        this.winnerListArray = res.winnerList;
        this.companyLogoPath = res.company_logo_path;
        this.award_image_path = res.award_image_path;
        // console.log(this.winnerListArray)
      }
    }, err => {

    })
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { AwardListComponent } from './award-list/award-list.component';
import { AwardDetailsComponent } from './award-details/award-details.component';
import { CompanyRegisterComponent } from './company-register/company-register.component';
import { AssessorRegisterComponent } from './assessor-register/assessor-register.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { LoginComponent } from './login/login.component';
import { SponsorsListComponent } from './sponsors-list/sponsors-list.component';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { ViewAllMediaComponent } from './view-all-media/view-all-media.component';
import { OurJuriesComponent } from './our-juries/our-juries.component';
import { FaqComponent } from './faq/faq.component';
import { AssessorApplicationComponent } from './assessor-application/assessor-application.component';
import { OurAssessorComponent } from './our-assessor/our-assessor.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from '../_helpers/_guards/auth-guard.guard';
import { PublicGuard } from '../_helpers/_guards/public-guard.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActiveAccountComponent } from './active-account/active-account.component';
import { AssessorDashboardComponent } from './assessor-dashboard/assessor-dashboard.component';
import { AssignmentPointComponent } from './assessor-dashboard/assignment-point/assignment-point.component';
import { ProfileComponent } from './assessor-dashboard/profile/profile.component';
import { FinalAssessmentPointComponent } from './assessor-dashboard/final-assessment-point/final-assessment-point.component';
import { JuryRegisterComponent } from './jury-register/jury-register.component';
import { SelfAssessmentComponent } from './assessor-dashboard/self-assessment/self-assessment.component';
import { EditSelfAssessmentComponent } from './assessor-dashboard/edit-self-assessment/edit-self-assessment.component';
import { AwardFremeworkComponent } from './award-fremework/award-fremework.component';
import { LicenseComponent } from './assessor-dashboard/license/license.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ContactComponent } from './contact/contact.component';
import { AwardWinnerComponent } from './award-winner/award-winner.component';
import { JudgeDashboardComponent } from './judge-dashboard/judge-dashboard.component';
import { JudgeProfileComponent } from './judge-dashboard/judge-profile/judge-profile.component';

import { CompanyDocumentComponent } from './company-dashboard/company-document/company-document.component';
import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { CompanyProfileComponent } from './company-dashboard/company-profile/company-profile.component';
import { AwardOfficeProfileComponent } from './award-office-dashboard/award-office-profile/award-office-profile.component';
import { AwardOfficeDashboardComponent } from './award-office-dashboard/award-office-dashboard.component';
import { JuryAssessmentComponent } from './judge-dashboard/jury-assessment/jury-assessment.component';
import { JudgeArchiveComponent } from './judge-dashboard/judge-archive/judge-archive.component';
import { AssesorArchiveComponent } from './assessor-dashboard/assesor-archive/assesor-archive.component';
import { AwardManagementComponent } from './award-office-dashboard/award-management/award-management.component';
import { JudgeGroupDashboardComponent } from './judge-dashboard/judge-group-dashboard/judge-group-dashboard.component';
import { OfficeArchiveComponent } from './award-office-dashboard/office-archive/office-archive.component';
import { AwardApplyComponent } from './company-dashboard/award-apply/award-apply.component';
import { CompanyAwardDetailsComponent } from './company-dashboard/company-award-details/company-award-details.component';
import { AwardOfficeMgmtComponent } from './award-office-dashboard/award-office-mgmt/award-office-mgmt.component';
import { OfficeCompanyDocumentsComponent } from './award-office-dashboard/office-company-documents/office-company-documents.component';
import { OfficeCrteriaManagemnetComponent } from './award-office-dashboard/office-crteria-managemnet/office-crteria-managemnet.component';
import { PaymentHistoryComponent } from './company-dashboard/payment-history/payment-history.component';
import { AllParticipatedAwardComponent } from './company-dashboard/all-participated-award/all-participated-award.component';
import { OfficeAssessmentComponent } from './award-office-dashboard/office-assessment/office-assessment.component';
import { AssessorGroupComponent } from './award-office-dashboard/assessor-group/assessor-group.component';
import { AssessorManagementComponent } from './award-office-dashboard/assessor-management/assessor-management.component';
import { CompanyManagementComponent } from './award-office-dashboard/company-management/company-management.component';
import { JuryGroupComponent } from './award-office-dashboard/jury-group/jury-group.component';
import { JuryManagementComponent } from './award-office-dashboard/jury-management/jury-management.component';
import { OfficeGroupMgmtComponent } from './award-office-dashboard/office-group-mgmt/office-group-mgmt.component';
import { AwardStatisticsComponent } from './award-office-dashboard/award-statistics/award-statistics.component';
import { JuryAssignMgmtComponent } from './award-office-dashboard/jury-assign-mgmt/jury-assign-mgmt.component';
import { AssessorAssignMgmtComponent } from './award-office-dashboard/assessor-assign-mgmt/assessor-assign-mgmt.component';
import { OfficeArchiveGroupComponent } from './award-office-dashboard/office-archive-group/office-archive-group.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';

const routes: Routes = [
  // {
  //   path: '', redirectTo: '/home', pathMatch: 'full'
  // },
  {
    path: '',
    component: LandingComponent,
    // redirectTo: '/home',
    pathMatch: 'full',
    canActivate: [PublicGuard]
  },
  {
    path: 'award-list',
    component: AwardListComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'award-details/:id/:id1',
    component: AwardDetailsComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'company-register/:id/:id1',
    component: CompanyRegisterComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'assessor-register/:id/:id1',
    component: AssessorRegisterComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'sponsors-list',
    component: SponsorsListComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'media-gallery',
    component: MediaGalleryComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'view-all-media/:id',
    component: ViewAllMediaComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'view-all-media',
    component: ViewAllMediaComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'our-jury',
    component: OurJuriesComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'assessor-appliaction',
    component: AssessorApplicationComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'jury-appliaction',
    component: JuryRegisterComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'our-assessors',
    component: OurAssessorComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'award-framework',
    component: AwardFremeworkComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'award-details-presentation/:id/:id1',
    component: AwardFremeworkComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'active-account/:id',
    component: ActiveAccountComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'reset-password/:id',
    component: ResetPasswordComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'testimonials',
    component: TestimonialsComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'contact-us',
    component: ContactComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent,
    canActivate: [PublicGuard]
  },  
  {
    path: 'terms-and-condition',
    component: TermsConditionComponent,
    canActivate: [PublicGuard]
  },  
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'award-winner',
    component: AwardWinnerComponent,
    canActivate: [PublicGuard]
  },
  {
    path: 'assesor-dashboard',
    component: AssessorDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assesor-assignment/:id/:id2/:id3/:id4',
    component: AssignmentPointComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'final-assesment/:id/:id2/:id3/:id4',
    component: FinalAssessmentPointComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assesor-profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'self-assesment',
    component: SelfAssessmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-self-assesment/:id',
    component: EditSelfAssessmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assesor-license',
    component: LicenseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assesor-archive',
    component: AssesorArchiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-group-dashboard',
    component: JudgeGroupDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-dashboard',
    component: JudgeDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-profile',
    component: JudgeProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-assessment/:id/:id2/:id3/:id4/:id5',
    component: JuryAssessmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-archive',
    component: JudgeArchiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-dashboard',
    component: CompanyDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-profile',
    component: CompanyProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-document',
    component: CompanyDocumentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-history',
    component: PaymentHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'all-participated-award',
    component: AllParticipatedAwardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-award-apply',
    component: AwardApplyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-award-details/:id/:id1',
    component: CompanyAwardDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-office-profile',
    component: AwardOfficeProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-office-dashboard',
    component: AwardOfficeDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-office-archive',
    component: OfficeArchiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-management',
    component: AwardManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-office-assessment/:id',
    component: OfficeAssessmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-documents',
    component: OfficeCompanyDocumentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'criteria-management',
    component: OfficeCrteriaManagemnetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-office-MGMT',
    component: AwardOfficeMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-office-group-MGMT',
    component: OfficeGroupMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assessor-management',
    component: AssessorManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assessor-group',
    component: AssessorGroupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-management',
    component: JuryManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jury-group',
    component: JuryGroupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-management',
    component: CompanyManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-statistics',
    component: AwardStatisticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assign-assessor-management',
    component: AssessorAssignMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assign-jury-management',
    component: JuryAssignMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'archive-group',
    component: OfficeArchiveGroupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: './layout/header/header.module#HeaderModule',
  },
  {
    path: '',
    loadChildren: './layout/footer/footer.module#FooterModule',
  },
  {
    path: '',
    loadChildren: './layout/left-panel/left-panel.module#LeftPanelModule',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

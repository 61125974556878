import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { appConfig } from '../../app.config';
import { JudgeService } from '../../services/judge.service';


@Component({
  selector: 'app-jury-management',
  templateUrl: './jury-management.component.html',
  styleUrls: ['./jury-management.component.scss']
})
export class JuryManagementComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected: any;
  user_token: any;
  api_token: any;
  panelExpanded2: boolean = false;
  showAddForm: boolean = false;
  show_approveaward_form: boolean = false;
  show_activiltylog: boolean = false;
  juryList: any = [];
  lazyLoad: boolean = false;
  selectedIndex: any = -1;
  panelExpanded: boolean = false;
  juryManagementForm: FormGroup;
  submitted: boolean = false;
  PrefixNameErr: any;
  loadingCount: number = 0;
  userDetails: any;
  profileCVPath: any = [];
  namePrefixsList: any = [];
  gendersList: any = [];
  QualificationsList: any = [];
  nationalityList: any = [];
  AllCountryList: any = [];
  YearsofWorkExperienceList: any = [];
  ProfessionalUseLanguagesList: any = [];
  IndustryList: any = [];
  profileImagePath: any;
  display_profile_photo: any;
  profile_photo: any;
  profile_CV: any;
  countryCallingCodes: any = [];
  countryCodeArray: any = [];
  ProfessionalCVErr: any;
  processingText: boolean = false;
  errorMessageValue: any;
  Profile_Cv_Show: any = null;
  profile_photo_show: any = null;
  formAction: any;
  singleJurydetails: any;
  profilePicErr: any;
  mobileCountryCodeeErr: string;
  WhatsAppCountryErr: string;
  aboutusList: any = [];
  companymanagementListArray: any = [];
  approveList: any = [];
  searchName: any;
  searchUserName: any;
  searchEmail: any;
  searchPhoneNumber: any;
  approveProcessingText: boolean;
  awardErr: string;
  approveAwardIdArray: any = [];
  webSitePatternErr: boolean;
  approveAwardListLoad: boolean = false;
  permissionMsg: any;
  permissionStatus: boolean = false;
  statusArray: any = [];
  fileTypeText: any;
  
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private judgeService: JudgeService) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/jury-management')
          this.leftbarselected = 'Jury Management';
        this.dataService.leftPanelSelection('jury_management');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    this.aboutusList.push('Award website', 'Google Search', 'Referred by someone', 'Social Media', 'Others')
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/jury-management`]);
    }
    this.juryManagementForm = this.formBuilder.group({
      namePrefix: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      employer: ['', Validators.required],
      job_title: ['', Validators.required],
      Country_code: ['', Validators.required],
      mobile_no: ['', Validators.required],
      WhatsApp_Country_code: ['', Validators.required],
      WhatsApp_mobile_no: ['', Validators.required],
      workEmail: ['', [Validators.required, Validators.email]],
      personalEmail: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zip_code: ['', Validators.required],
      country: ['', Validators.required],
      qualification: ['', Validators.required],
      yearsofWorkExperience: ['', Validators.required],
      industry: ['', Validators.required],
      professional_language: ['', Validators.required],
      linkedIn_profile_link: ['', Validators.required],
      about_us: ['', Validators.required],
      interest_description: ['', Validators.required],
      experience_description: ['', Validators.required],
      confict_description: ['', Validators.required],
      recomended_email: [''],
      notes: ['']
    });
    this.juryManagementlist();
    this.callingCodes();
    this.formlistData();
    this.companymanagementList();
    this.statusArray = [
      {
        name: 'Active',
        status: 'Y'
      },
      {
        name: 'In-Active',
        status: 'N'
      },
      {
        name: 'Approved',
        status: 'A'
      },
      {
        name: 'Blocked',
        status: 'B'
      }
    ]
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
  }
  companymanagementList() {
    this.dashboardService.companyManagementList(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {

        this.companymanagementListArray = res.company_list;
        console.log(res);
        if (this.companymanagementListArray.length > 0) {
          this.companymanagementListArray.map((companyItem) => {
            if ((companyItem.is_invoice_generated == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N') && (companyItem.payment_document == null) && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'U') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.status == 'N') && (companyItem.award_year.is_payble == 'N')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }
            if ((companyItem.is_invoice_generated == 'Y') && (companyItem.payment_status == 'N' && companyItem.payment_document != null) && (companyItem.status == 'N') && (companyItem.award_year.is_payble == 'Y')) {
              companyItem['blink_status'] = 'Y';
              this.dataService.blinkTab(companyItem);
            }

            if ((companyItem.assessor_group_assign_status == 'N' || companyItem.judge_group_assign_status == 'N')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'Y';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else if ((companyItem.assessor_group_assign_status == 'Y' && companyItem.judge_group_assign_status == 'Y')) {
              if (companyItem.company_details.company_general_documents.length > 0) {
                if (companyItem.company_details.company_general_documents[0].status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
              else if (companyItem.company_details.company_documents.length > 0) {
                if (companyItem.company_details.company_documents.status == 'Y') {
                  companyItem['blink_status'] = 'N';
                  this.dataService.blinkTab(companyItem);
                }
              }
            }
            else {
              companyItem['blink_status'] = 'N';
              this.dataService.blinkTab(companyItem);
            }
          })
        }
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  get f() { return this.juryManagementForm.controls; }

  formlistData() {
    this.dashboardService.formList(this.api_token).subscribe((res: any) => {
      console.log(res);
      this.profileCVPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/Profile_CV/';
      this.namePrefixsList = res.NamePrefixs;
      this.gendersList = res.genders;
      this.QualificationsList = res.Qualifications;
      this.nationalityList = res.Nationalities
      this.AllCountryList = res.all_country;
      this.YearsofWorkExperienceList = res.YearsofWorkExperiences;
      this.ProfessionalUseLanguagesList = res.ProfessionalUseLanguages;
      this.IndustryList = res.Industries;
      this.profileImagePath = res.profile_image_path;

    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }

  juryManagementlist() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.juryManagmentList(this.api_token).subscribe((res: any) => {
      this.loadingCount = 1;
      this.juryList = res.jury_list;
      this.juryList = this.juryList.filter((thing, index, self) => index === self.findIndex((t) => (t.jury.user_id === thing.jury.user_id)))
      console.log(this.juryList);
      this.lazyLoad = false;
      this.permissionStatus = false;
      this.permissionMsg = '';
      if (res.has_error == 1) {
        this.lazyLoad = false;
        this.permissionStatus = true;
        this.permissionMsg = res.msg;
        this.router.navigate(['/award-office-dashboard']);
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong.')
    })
  }
  searchJury() {
    let data = {
      searchName: this.searchName,
      searchEmail: this.searchEmail,
      searchPhone: this.searchPhoneNumber,
      searchUsername: this.searchUserName
    }
    this.dashboardService.filterJuryManagmentList(this.api_token, data).subscribe((res: any) => {
      this.juryList = res.jury_list;
    }, err => {
      this.messagesService.showError('Something went wrong.')
    })
  }
  onChangeStatus($event, juryItem) {
    let status = $event;
    let data = {
      'user_id': juryItem.jury.user_id,
      'status': status
    }
    this.dashboardService.statusChange(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.messagesService.showSuccess('Status Changes Successfully');
        this.juryManagementlist();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  openPanelExpanded(index) {
    this.selectedIndex = index;
    this.panelExpanded = !this.panelExpanded;
  }
  onSelectCV(event) {
    if (event.target.files && event.target.files[0]) {
      this.Profile_Cv_Show = event.target.files[0];
      if (this.Profile_Cv_Show == null) {
        this.ProfessionalCVErr = 'please select a document';
      }
      if (this.Profile_Cv_Show != null) {
        this.ProfessionalCVErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  onSelectPhoto(event) {
    if (event.target.files && event.target.files[0]) {
      this.profile_photo_show = event.target.files[0];
      if (this.profile_photo_show == null) {
        this.profilePicErr = 'please select profile picture';
      }
      if (this.profile_photo_show != null) {
        this.profilePicErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        this.display_profile_photo = event.target.result;
      }
    }
  }
  openAddForm(action, juryItem) {
    document.getElementById("form_open").style.display = "block";
    document.getElementById("form_close").style.display = "none";
    this.showAddForm = true;
    this.formAction = action;
    this.submitted = false;
    this.panelExpanded = false;
    if ((this.formAction == 'add') && (juryItem == null)) {
      this.singleJurydetails = null;
      this.display_profile_photo = '';
      this.profile_photo = null;
      this.profile_CV = null;
      this.juryManagementForm.reset();
      this.profile_photo_show = null;
      this.Profile_Cv_Show = null;
      this.ProfessionalCVErr = '';
      this.profilePicErr = '';
    }
    if ((this.formAction == 'edit') && (juryItem != null)) {
      this.singleJurydetails = juryItem;
      this.profile_photo_show = null;
      this.Profile_Cv_Show = null;
      this.ProfessionalCVErr = '';
      this.profilePicErr = '';
      console.log(this.singleJurydetails);
      this.juryManagementForm.patchValue({
        namePrefix: this.singleJurydetails.jury.NamePrefix_id,
        firstName: this.singleJurydetails.jury.first_name,
        lastName: this.singleJurydetails.jury.last_name,
        gender: this.singleJurydetails.jury.Gender_id,
        nationality: this.singleJurydetails.jury.nationalities_id,
        employer: this.singleJurydetails.jury.Current_Employer,
        job_title: this.singleJurydetails.jury.Job_Title,
        Country_code: this.singleJurydetails.jury.Country_code,
        mobile_no: this.singleJurydetails.jury.mobile_no,
        WhatsApp_Country_code: this.singleJurydetails.jury.WhatsApp_Country_code,
        WhatsApp_mobile_no: this.singleJurydetails.jury.WhatsApp_mobile_no,
        workEmail: this.singleJurydetails.jury.Work_E_mail,
        personalEmail: this.singleJurydetails.email,
        address: this.singleJurydetails.address,
        city: this.singleJurydetails.jury.cityof_residences_id,
        zip_code: this.singleJurydetails.jury.zip_code,
        country: this.singleJurydetails.country_id,
        qualification: this.singleJurydetails.jury.qualifications_id,
        yearsofWorkExperience: this.singleJurydetails.jury.yearsof_work_experiences_id,
        industry: this.singleJurydetails.jury.industries_id,
        professional_language: this.singleJurydetails.jury.professional_language_id,
        linkedIn_profile_link: this.singleJurydetails.jury.LinkedIn_Profile_Link,
        about_us: this.singleJurydetails.jury.how_did_you_hear_about,
        interest_description: this.singleJurydetails.jury.interest_description,
        experience_description: this.singleJurydetails.jury.experience_description,
        confict_description: this.singleJurydetails.jury.confict_description,
        recomended_email: this.singleJurydetails.jury.recomended_email,
        notes: this.singleJurydetails.jury.notes
      });
      this.display_profile_photo = this.singleJurydetails.profile_picture != null ? this.profileImagePath + this.singleJurydetails.profile_picture : 'assets/images/default-user.png';

      this.profile_photo = this.singleJurydetails.profile_picture;
      this.profile_CV = this.singleJurydetails.jury.Profile_CV;

    }
  }

  closeAddForm() {
    this.showAddForm = false;
    document.getElementById("form_open").style.display = "none";
    document.getElementById("form_close").style.display = "block";
  }
  approveAwardList(juryItem) {
    this.approveAwardListLoad = true;
    this.dashboardService.approveAwardList(this.api_token, juryItem.jury.user_id).subscribe((res: any) => {
      if (res.has_error == 0) {
        this.approveAwardListLoad = false;
        this.approveList = res.award_data;
        this.approveAwardIdArray = [];
        this.approveList.map((item) => {
          if (item.status == 'Y') {
            this.approveAwardIdArray.push(item.award_year.id);
          }
        })
        console.log(this.approveAwardIdArray);
      }
    }, err => {
      this.approveAwardListLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }

  approveAwardSelectedValue($event, Item) {
    if ($event.srcElement.checked == true) {
      if (this.approveAwardIdArray.indexOf(Item.award_year.id) == -1) {
        this.approveAwardIdArray.push(Item.award_year.id);
      }
      // if (this.approveAwardIdArray.length > 0) {
      //   this.awardErr = '';
      // }
    }
    else if ($event.srcElement.checked == false) {
      this.approveAwardIdArray.splice(this.approveAwardIdArray.indexOf($event.srcElement.value * 1), 1);
      // if (this.approveAwardIdArray.length == 0) {
      //   this.awardErr = 'Please Select One Award';
      // }
    }
    console.log(this.approveAwardIdArray)
  }
  approveAward() {
    let data = {
      award_year_id: this.approveAwardIdArray,
      user_id: this.singleJurydetails.jury.user_id
    }
    console.log(data)
    this.approveProcessingText = true;
    // if (this.approveAwardIdArray.length > 0) {
    this.awardErr = '';
    this.dashboardService.approveAward(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.approveProcessingText = false;
        this.messagesService.showSuccess(res.msg);
        this.juryManagementlist();
        this.closeshow_approveaward_form();
        this.panelExpanded = false;
      }
      if (res.has_error == 1) {
        this.approveProcessingText = false;
        this.messagesService.showError(res.msg);
      }
    }, err => {
      this.approveProcessingText = false;
      this.messagesService.showError('Something went wrong');
    })
    // }
    // else if (this.approveAwardIdArray.length == 0) {
    //   this.awardErr = 'Please Select One Award';
    // }
  }

  openshow_approveaward_form(juryItem) {
    this.singleJurydetails = juryItem;
    this.show_approveaward_form = true;
    this.panelExpanded = false;
    this.approveAwardList(juryItem);

  }
  closeshow_approveaward_form() {
    this.show_approveaward_form = false;
  }

  openshow_activiltylog_form() {
    this.show_activiltylog = true;
  }
  closeshow_activiltylog_form() {
    this.show_activiltylog = false;
  }

  callingCodes() {
    this.authService.countryCodeList().subscribe((res) => {
      this.countryCodeArray = res;
      this.countryCodeArray.map((item) => {
        if (item.callingCodes[0] != "") {
          this.countryCallingCodes.push(item.callingCodes[0])
        }
      });
      this.countryCallingCodes.sort();
      this.countryCallingCodes = this.countryCallingCodes.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t === thing
        ))
      )
    }, err => {
      this.messagesService.showError('Something went wrong.');
      setTimeout(() => {
        this.messagesService.clear();
      }, 800);
    })
  }

  onChangeMobileCode(event) {
    if (event == '') {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.mobileCountryCodeeErr = '';
    }
  }
  onChangeWhatappCode(event) {
    if (event == '') {
      this.WhatsAppCountryErr = 'Country code of Mobile Number is required';
    }
    if (event != '') {
      this.WhatsAppCountryErr = '';
    }
  }
  onChangePrefix(event) {
    if (event == '') {
      this.PrefixNameErr = 'Jury Prefix Name is required';
    }
    if (event != '') {
      this.PrefixNameErr = '';
    }
  }
  // validate website url
  CheckURL(fieldId) {
    var url = fieldId.target.value;
    if (url !== "") {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null) {
        this.webSitePatternErr = false;
        return true;

      }

      else {
        this.webSitePatternErr = true;
        // fieldId.focus();
        return false;
      }
    }
    else if (url === "") {
      this.webSitePatternErr = false;
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  updateprofile() {
    this.submitted = true;

    if ((this.profile_CV == null) && (this.Profile_Cv_Show == null)) {
      this.ProfessionalCVErr = 'please select a document';
    }
    if ((this.profile_photo == null) && (this.profile_photo_show == null)) {
      this.profilePicErr = 'please upload profile picture';
    }
    if ((this.juryManagementForm.value.namePrefix == null) && (this.juryManagementForm.value.firstName != null)) {
      this.PrefixNameErr = 'Jury Prefix Name is required';
    }
    if ((this.juryManagementForm.value.WhatsApp_Country_code == null) && (this.juryManagementForm.value.WhatsApp_mobile_no != null)) {
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    if ((this.juryManagementForm.value.Country_code == null) && (this.juryManagementForm.value.mobile_no != null)) {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }


    // stop here if form is invalid
    if (this.juryManagementForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      // formData.append('award_id', this.juryAwardIdArray),
      formData.append('NamePrefix_id', this.juryManagementForm.value.namePrefix),
      formData.append('first_name', this.juryManagementForm.value.firstName),
      formData.append('last_name', this.juryManagementForm.value.lastName),
      formData.append('email', this.juryManagementForm.value.personalEmail),
      formData.append('Gender_id', this.juryManagementForm.value.gender),
      formData.append('nationalities_id', this.juryManagementForm.value.nationality),
      formData.append('Current_Employer', this.juryManagementForm.value.employer),
      formData.append('Job_Title', this.juryManagementForm.value.job_title),
      formData.append('address', this.juryManagementForm.value.address),
      formData.append('Country_code', this.juryManagementForm.value.Country_code),
      formData.append('mobile_no', this.juryManagementForm.value.mobile_no),
      formData.append('WhatsApp_Country_code', this.juryManagementForm.value.WhatsApp_Country_code),
      formData.append('WhatsApp_mobile_no', this.juryManagementForm.value.WhatsApp_mobile_no),
      formData.append('Work_E_mail', this.juryManagementForm.value.workEmail),
      formData.append('cityof_residences_id', this.juryManagementForm.value.city),
      formData.append('country_id', this.juryManagementForm.value.country),
      formData.append('zip_code', this.juryManagementForm.value.zip_code)

    formData.append('industries_id', this.juryManagementForm.value.industry),
      formData.append('qualifications_id', this.juryManagementForm.value.qualification),
      formData.append('professional_language_id', this.juryManagementForm.value.professional_language),
      formData.append('yearsof_work_experiences_id', this.juryManagementForm.value.yearsofWorkExperience),
      formData.append('LinkedIn_Profile_Link', this.juryManagementForm.value.linkedIn_profile_link),
      formData.append('how_did_you_hear_about', this.juryManagementForm.value.about_us)

    formData.append('interest_description', this.juryManagementForm.value.interest_description),
      formData.append('experience_description', this.juryManagementForm.value.experience_description),
      formData.append('confict_description', this.juryManagementForm.value.confict_description),
      formData.append('recomended_email', this.juryManagementForm.value.recomended_email ? this.juryManagementForm.value.recomended_email : ''),
      formData.append('notes', this.juryManagementForm.value.notes ? this.juryManagementForm.value.notes : '')

    if (this.Profile_Cv_Show != null) {
      formData.append('Profile_CV', this.Profile_Cv_Show)
    }
    if (this.Profile_Cv_Show == null) {
      formData.append('Profile_CV', this.profile_CV)
    }
    if (this.profile_photo_show != null) {
      formData.append('Profile_pic', this.profile_photo_show)
    }
    if (this.profile_photo_show == null) {
      formData.append('Profile_pic', this.profile_photo)
    }
    if (this.formAction == 'edit') {
      formData.append('jury_id', this.singleJurydetails.id)
    }

    if ((this.profile_CV != null || this.Profile_Cv_Show != null)
      && (this.profile_photo != null || this.profile_photo_show != null) && (this.juryManagementForm.value.namePrefix != '') && (this.juryManagementForm.value.WhatsApp_Country_code != '') && (this.juryManagementForm.value.Country_code != '')) {
      console.log(this.formAction)
      this.ProfessionalCVErr = '';
      this.profilePicErr = '';
      this.processingText = true;
      if (this.formAction == 'add') {
        this.ProfessionalCVErr = '';
        this.profilePicErr = '';
        this.dashboardService.addJuryProfile(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processingText = false;
            this.panelExpanded = false;
            this.closeAddForm();
            this.juryManagementlist();
            this.Profile_Cv_Show = null;
            this.profile_photo_show = null;
            this.messagesService.showSuccess('Profile Updated successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.processingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
      }
      else if (this.formAction == 'edit') {
        this.dashboardService.editJuryProfile(this.api_token, formData).subscribe((res: any) => {
          if (res.has_error == 0) {
            this.processingText = false;
            this.panelExpanded = false;
            this.closeAddForm();
            this.juryManagementlist();
            this.Profile_Cv_Show = null;
            this.profile_photo_show = null;
            this.messagesService.showSuccess('Profile Updated successfully.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
          else if (res.has_error == 1) {
            this.processingText = false;
            for (let [key, value] of Object.entries(res.error)) {
              this.errorMessageValue = value;
            }
            this.messagesService.showError(this.errorMessageValue);
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          }
        },
          error => {
            this.processingText = false;
            this.messagesService.showError('Something went wrong.');
            setTimeout(() => {
              this.messagesService.clear();
            }, 4000);
          })
      }
    }
    else if ((this.profile_CV == null) && (this.Profile_Cv_Show == null)) {
      this.ProfessionalCVErr = 'please select a document';
    }
    else if ((this.profile_photo == null) && (this.profile_photo_show == null)) {
      this.profilePicErr = 'please upload profile picture';
    }
    else if ((this.juryManagementForm.value.namePrefix == null) && (this.juryManagementForm.value.firstName != null)) {
      this.PrefixNameErr = 'Jury Prefix Name is required';
    }
    else if ((this.juryManagementForm.value.WhatsApp_Country_code == null) && (this.juryManagementForm.value.WhatsApp_mobile_no != null)) {
      this.WhatsAppCountryErr = 'Country code of WhatsApp Number is required';
    }
    else if ((this.juryManagementForm.value.Country_code == null) && (this.juryManagementForm.value.mobile_no != null)) {
      this.mobileCountryCodeeErr = 'Country code of Mobile Number is required';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../services/data.service';
import { MessagesService } from '../services/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JudgeService } from '../services/judge.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { last } from 'rxjs/operators';
import { appConfig } from '../app.config';
import { DashboardService } from '../services/dashboard.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss'],
  providers: [NgbRatingConfig]
})
export class CompanyDashboardComponent implements OnInit {
  leftbarselected; any;
  show_doc_list: boolean = false;
  show_general__list: boolean = false;
  show_document_popup: any = [];
  view_doc_status: boolean = false;
  view_general_doc_status: boolean = false;
  user_token: any;
  api_token: any;
  addDocmentForm: FormGroup;
  addGeneralDocmentForm: FormGroup;
  addPaymentDocmentForm: FormGroup;
  submitted1: boolean = false;
  submitted2: boolean = false;
  submitted3: boolean = false;
  criteraList: any = [];
  document: any = [];
  general_document: any;
  documentErr: any;
  generalDocumentErr: any;
  processingAddDocument: boolean = false;
  processingAddGeneralDocument: boolean = false;
  documentList: any = [];
  generalDocumentList: any = [];
  documentImagePath: any;
  generalDocumentImagePath: any;
  final_document: any;
  final_general_document: any;
  lazyLoad: boolean = false;
  documentLoad: boolean = false;
  generaDocumentLoad: boolean = false;
  documentType: any;
  generalDocumentAction: any;
  generalDocumentId: any;
  document_name: any;
  NoDatamsg: any;
  loadingCount: number = 0;
  generalDocumentErrmsg: any;
  documentres: any;
  allDocumentData: any = [];
  allGeneralDocumentData: any = [];
  documentDocs: any = [];
  generalDocumentDocs: any = [];
  allDocumentStatusShow: boolean = false;
  allGeneralDocumentStatusShow: boolean = false;
  company_doc_text: any;
  generalDocumentDownloadPath: any;
  documentDownloadPath: any;
  siteLogoLink: any;
  payment_document: any;
  paymentErr: any;
  processingAddPayment: boolean = false;
  award_image_path: any;
  paymentDocumentpath: any;
  generalDocumentRes: any;
  documentAwardId: any;
  documentAwardYear: any;
  generalDocumentAwardId: any;
  generalDocumentAwardYear: any;
  documentFilterationYearList: any = [];
  generalDocumentFilterationYearList: any = [];
  documentFilterationAwardList: any = [];
  generalDocumentFilterationAwardList: any = [];
  displayPaymentDocumentName: any;
  companyDetails: any = [];
  copmanyDocumentAwardId: any;
  generalCopmanyDocumentAwardId: any;
  dashboardRes: any;
  singleCompanyDetails: any;
  singleGeneralDocument: any;
  displayCriteriaNumber: any;
  criteriaArray: any = [];
  changesIndex: number;
  documentIdArray: any = [];
  generalDocumentIdArray: any = [];
  documentSubmissionStatus: boolean = true;
  generalDocumentSubmissionStatus: boolean = true;
  invoiceDownloadPath: any;
  fileTypeText: any;
  constructor(private router: Router,
    private dataService: DataService,
    private messagesService: MessagesService,
    private formBuilder: FormBuilder,
    private judgeService: JudgeService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    config: NgbRatingConfig) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/company-dashboard')
          this.leftbarselected = 'dashboard';
        this.dataService.leftPanelSelection('company_dashboard');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
    this.siteLogoLink = appConfig.siteLogoLink;
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/assesor-dashboard`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    // this.getDocumentList();
    // this.getGeneralDocumentList();
    this.addDocmentForm = this.formBuilder.group({
      criteria: ['', [Validators.required]]
    });
    this.addGeneralDocmentForm = this.formBuilder.group({
      document_name: ['', [Validators.required]],
    });
    this.addPaymentDocmentForm = this.formBuilder.group({
      receipt_name: ['', [Validators.required]],
    });
    this.getDashboardDeails();
    this.fileTypeText = 'Max File size is 20 MB & File type should be in between pdf, docx, png, jpg, jpeg,ppx,pptx';
  }
  get f1() { return this.addDocmentForm.controls; }

  get f2() { return this.addGeneralDocmentForm.controls; }

  get f3() { return this.addPaymentDocmentForm.controls; }

  getDashboardDeails() {
    if (this.loadingCount == 0) {
      this.lazyLoad = true;
    }
    this.dashboardService.dashboardDetails(this.user_token, this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.lazyLoad = false;
        this.dashboardRes = res;
        this.companyDetails = res.company_data;
        this.award_image_path = res.award_image_path;
        this.paymentDocumentpath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/company_payment_docs/';

        this.generalDocumentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';

        this.documentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
      }
    }, err => {
      this.lazyLoad = false;
      this.messagesService.showError('Something went wrong');
    })
  }
  open_add_document(companyItem) {
    this.show_document_popup = 1;
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });

    this.displayCriteriaNumber = '';
    this.criteriaArray = [];
    this.NoDatamsg == '';
    this.singleCompanyDetails = companyItem;


    if (this.singleCompanyDetails.criteria.length > 0) {
      for (let i = 0; i < this.singleCompanyDetails.criteria.length; i++) {
        this.singleCompanyDetails.criteria[i].criteria_number = i + 1;
      }
    }
    console.log(this.singleCompanyDetails.criteria);
    this.copmanyDocumentAwardId = this.singleCompanyDetails.year_id;
    console.log(companyItem);
    this.criteraList = [];
    this.NoDatamsg = '';
    this.criteraList.push({
      'key': 'loop-' + this.criteraList.length,
      'option': this.singleCompanyDetails.criteria
    });
    // this.lazyLoad = false;
    console.log(this.criteraList)
    // this.show_doc_list = false;
    // this.show_general__list = false;
  }
  open_general_add_document(generalDocument, action, generalCompanyItem) {
    this.show_document_popup = 2;
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
    this.NoDatamsg == '';
    this.singleCompanyDetails = generalCompanyItem;
    console.log(this.singleCompanyDetails)
    if (this.singleCompanyDetails != null) {
      this.generalCopmanyDocumentAwardId = this.singleCompanyDetails.year_id;
    }
    else if (this.singleCompanyDetails == null) {
      this.generalCopmanyDocumentAwardId = generalDocument.award_year_id;
    }
    // this.show_doc_list = false;
    // this.show_general__list = false;
    this.generalDocumentAction = action;
    this.singleGeneralDocument = generalDocument;
    console.log(this.singleGeneralDocument);
    if (generalDocument != null) {
      this.generalDocumentId = generalDocument.id;
      this.addGeneralDocmentForm.patchValue({
        document_name: generalDocument.name
      })
    }
  }
  close_document() {
    this.show_document_popup = 0;
    this.submitted1 = false;
    this.submitted2 = false;
    this.submitted3 = false;
    this.addDocmentForm.reset();
    this.addGeneralDocmentForm.reset();
    this.addPaymentDocmentForm.reset();
    this.displayCriteriaNumber = '';
    this.criteriaArray = [];
    this.documentIdArray = [];
    this.generalDocumentIdArray = [];
    this.documentErr = '';
    this.generalDocumentErr = '';
    this.NoDatamsg == '';
    this.document = [];
    this.general_document = null;
    this.criteraList = [];
    this.payment_document = null;
    this.paymentErr = '';
    this.documentDocs = [];
    this.generalDocumentDocs = [];
    this.allGeneralDocumentStatusShow = false;
    this.allGeneralDocumentData = [];
    this.allDocumentStatusShow = false;
    this.allDocumentData = [];
    this.documentAwardYear = '';
    this.documentAwardId = '';
    this.documentFilterationAwardList = [];
    this.documentFilterationYearList = [];
    this.generalDocumentFilterationAwardList = [];
    this.generalDocumentFilterationYearList = [];
    this.generalDocumentAwardYear = '';
    this.generalDocumentAwardId = '';
    // this.criteraList.push({
    //   'key': 'loop-' + this.singleCompanyDetails.criteria.length,
    //   'option': this.singleCompanyDetails.criteria
    // });
  }
  open_view_document() {
    this.view_doc_status = true;
  }
  // close_view_document() {
  //   this.view_doc_status = false;
  // }
  close_view_document1() {
    this.view_doc_status = false;
  }
  close_view_document2() {
    this.view_general_doc_status = false;
  }
  doc_list_status() {

    this.show_doc_list = !this.show_doc_list;
    if (this.show_doc_list == true) {
      this.show_general__list = false;
    }
    this.documentDocs = [];
    this.generalDocumentDocs = [];
    this.allDocumentData = [];
    this.allGeneralDocumentData = [];
    this.allDocumentStatusShow = false;
    this.allGeneralDocumentStatusShow = false;
  }
  general_doc_status() {

    this.show_general__list = !this.show_general__list;
    if (this.show_general__list == true) {
      this.show_doc_list = false;
    }
    this.documentDocs = [];
    this.generalDocumentDocs = [];
    this.allDocumentData = [];
    this.allGeneralDocumentData = [];
    this.allDocumentStatusShow = false;
    this.allGeneralDocumentStatusShow = false;
  }
  hide_doc_status() {
    this.show_doc_list = false;
    this.show_general__list = false;
  }
  open_view_document1(document1) {
    this.documentType = document1.doc_name.split('.').pop();
    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png') || (this.documentType == 'JPEG')) {
      this.view_doc_status = true;
      this.documentLoad = true;
      this.final_document = this.documentImagePath + document1.doc_name;
      this.documentLoad = false;
    }
    else if (this.documentType == 'pdf') {
      this.view_doc_status = true;
      this.documentLoad = true;
      this.final_document = this.documentImagePath + document1.doc_name;
      this.final_document = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentImagePath + document1.doc_name);
      this.documentLoad = false;
    }
    else if (this.documentType == 'docx') {
      this.view_doc_status = false;
      this.final_document = this.documentImagePath + document1.doc_name;
      const downloadLink = document.createElement("a");
      const fileName = document1.document_name;

      downloadLink.href = this.final_document;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  open_view_document2(generalDocument) {
    this.documentType = generalDocument.document_name.split('.').pop();
    if ((this.documentType == 'jpg') || (this.documentType == 'jpeg') || (this.documentType == 'png') || (this.documentType == 'JPEG')) {
      this.view_general_doc_status = true;
      this.generaDocumentLoad = true;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      this.generaDocumentLoad = false;
    }
    else if (this.documentType == 'pdf') {
      this.view_general_doc_status = true;
      this.generaDocumentLoad = true;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      this.final_general_document = this.sanitizer.bypassSecurityTrustResourceUrl(this.generalDocumentImagePath + generalDocument.document_name);
      this.generaDocumentLoad = false;
    }
    else if (this.documentType == 'docx') {
      this.view_general_doc_status = false;
      this.final_general_document = this.generalDocumentImagePath + generalDocument.document_name;
      const downloadLink = document.createElement("a");
      const fileName = generalDocument.document_name;

      downloadLink.href = this.final_general_document;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  onSelectFileAddDocument(event) {
    let files = event.target.files;
    console.log(files);
    if (files) {
      for (let file of files) {
        this.document.push(file)
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //     }
        //     reader.readAsDataURL(file);
      }
    }
    if (this.document.length > 0) {
      this.documentErr = '';
    }
    console.log(this.document);
  }
  onSelectFileAddGeneralDocument(event) {
    if (event.target.files && event.target.files[0]) {
      this.general_document = event.target.files[0];
      console.log(this.general_document);
      if (this.general_document != '') {
        this.generalDocumentErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
        // this.display_profile_photo = event.target.result;
      }
    }
  }
  onChange(item, keyname, $event) {
    this.NoDatamsg = '';
    this.changesIndex = this.criteraList.findIndex(x => x.key == keyname);
    for (let a of item.option) {
      if (a.id == $event.target.value) {
        if (this.criteriaArray.filter(x => x.keyIndex == this.changesIndex).length > 0) {
          let _obj = this.criteriaArray.find(x => x.keyIndex == this.changesIndex)
          _obj.criteria_number = a.criteria_number
        } else {
          this.criteriaArray.push({
            keyIndex: this.changesIndex,
            criteria_number: a.criteria_number
          });
        }
      }
    }
    // console.log('criteriaArray', this.criteriaArray);
    if (this.criteraList.length > this.changesIndex + 1) {
      let criLen = this.criteraList.length
      this.criteraList.splice(this.changesIndex + 1, criLen - (this.changesIndex + 1));
      this.criteriaArray.splice(this.changesIndex + 1, criLen - (this.changesIndex + 1));
      // this.criteriaArray.splice(changesIndex + 1, criLen - (changesIndex + 1));
      // let lastObj = this.criteraList[this.criteraList.length - 1]
      // lastObj.option = []
    }
    let CriteriaNumberArray = [];
    this.criteriaArray.map((item) => {
      CriteriaNumberArray.push(item.criteria_number);
    })
    // console.log(CriteriaNumberArray);
    this.displayCriteriaNumber = CriteriaNumberArray.join('.');
    // console.log(this.displayCriteriaNumber);
    this.getCriteria($event.target.value);
  }
  deleteCriteria(keyname) {
    this.NoDatamsg = '';
    let changesIndex = this.criteraList.findIndex(x => x.key == keyname);
    if (this.criteraList.length > changesIndex + 1) {
      let criLen = this.criteraList.length
      this.criteraList.splice(changesIndex + 1, criLen - (changesIndex + 1));
      // let lastObj = this.criteraList[this.criteraList.length - 1]
      // lastObj.option = []
    }
  }
  getCriteria(parentId) {
    let data = {
      parent_id: parentId
    }
    this.judgeService.getCritera(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        if (res.allcriteria.length > 0) {
          for (let i = 0; i < res.allcriteria.length; i++) {
            res.allcriteria[i].criteria_number = i + 1;
          }
        }
        console.log(res.allcriteria);

        if (res.allcriteria.length > 0) {
          this.criteraList.push({
            'key': 'loop-' + this.criteraList.length,
            'option': res.allcriteria
          });
          console.log(this.criteraList)
        }
        if (res.allcriteria.length == 0) {
          this.NoDatamsg = 'This is the last subcriteria';
          this.generalDocumentErrmsg = '';
        }
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  getDocumentList() {
    // if (this.loadingCount == 0) {
    //   this.lazyLoad = true;
    // }
    this.judgeService.documentlist(this.api_token, this.documentAwardYear, this.documentAwardId).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        // this.loadingCount = 1;
        this.documentres = res;
        // this.award_image_path = res.award_image_path;
        // this.paymentDocumentpath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/company_payment_docs/';
        // if (this.documentres.currentAwardUserData.receipt_name != null) {
        //   this.addPaymentDocmentForm.patchValue({
        //     receipt_name: this.documentres.currentAwardUserData.receipt_name,
        //   })
        // }
        // else if (this.documentres.currentAwardUserData.receipt_name == null) {
        //   this.addPaymentDocmentForm.patchValue({
        //     receipt_name: '',
        //   })
        // }

        // if (this.documentres.currentAwardUserData.payment_document != null) {
        //   this.displayPaymentDocumentName = this.documentres.currentAwardUserData.payment_document;
        // }
        // else if (this.documentres.currentAwardUserData.payment_document == null) {
        //   this.displayPaymentDocumentName = '';
        // }
        // this.documentFilterationAwardList = res.filerationAwards;
        // // Step 1. Get all the object keys.    
        // // Step 1. Get all the object values.
        // let evilResponseProps = Object.values(this.documentres.filerationYears);
        // this.documentFilterationYearList = [];
        // for (let item of evilResponseProps) {
        //   this.documentFilterationYearList.push(item)
        // }
        // console.log(this.documentFilterationYearList);
        // this.documentList = res.company_doc;
        // this.documentList.map((item) => {
        //   item.checked = false;
        // })
        // console.log(this.documentList)
        // this.company_doc_text = res.company_doc_text;
        // this.criteraList = [];
        // this.NoDatamsg = '';
        // this.criteraList.push({
        //   'key': 'loop-' + this.criteraList.length,
        //   'option': res.criteria
        // });
        // this.lazyLoad = false;
        // console.log(this.criteraList)
        // this.documentImagePath = res.image_path;
        // this.documentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
      }
    },
      err => {
        // this.lazyLoad = false;
        this.messagesService.showError('Something went wrong.');
      })
  }
  getGeneralDocumentList() {
    this.judgeService.generalDocumentlist(this.api_token, this.generalDocumentAwardYear, this.generalDocumentAwardId).subscribe((res: any) => {
      console.log(res);
      this.generalDocumentRes = res;
      this.generalDocumentFilterationAwardList = res.filerationAwards;
      let evilResponseProps = Object.values(this.generalDocumentRes.filerationYears);
      this.generalDocumentFilterationYearList = [];
      for (let item of evilResponseProps) {
        this.generalDocumentFilterationYearList.push(item)
      }
      this.generalDocumentList = res.general_document;
      this.company_doc_text = res.company_doc_text;
      // this.generalDocumentImagePath = res.image_path;
      this.generalDocumentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  filterDocument() {
    console.log(this.documentAwardYear)
    console.log(this.documentAwardId)
    this.judgeService.documentlist(this.api_token, this.documentAwardYear, this.documentAwardId).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.loadingCount = 1;
        this.documentres = res;
        this.documentList = res.company_doc;
        this.documentList.map((item) => {
          item.checked = false;
        })
        console.log(this.documentList)
        this.company_doc_text = res.company_doc_text;
        this.criteraList = [];
        this.NoDatamsg = '';
        this.criteraList.push({
          'key': 'loop-' + this.criteraList.length,
          'option': res.criteria
        });
        // this.documentImagePath = res.image_path;
        this.documentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/category_documents/';
      }
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  filterGeneralDocument() {
    this.judgeService.generalDocumentlist(this.api_token, this.generalDocumentAwardYear, this.generalDocumentAwardId).subscribe((res: any) => {
      console.log(res);
      this.generalDocumentRes = res;
      this.generalDocumentList = res.general_document;
      this.company_doc_text = res.company_doc_text;
      this.generalDocumentImagePath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/comp_general_doc/';
      // this.generalDocumentImagePath = res.image_path;
    },
      err => {
        this.messagesService.showError('Something went wrong.');
      })
  }
  onChangeDocumentAward(value) {
    this.documentAwardId = value;
    console.log(this.documentAwardId);

  }
  onChangeGeneralDocumentAward(value) {
    this.generalDocumentAwardId = value;
    console.log(this.generalDocumentAwardId);
  }
  onChangeDocumentYear(value) {
    this.documentAwardYear = value;
  }
  onChangeGeneralDocumentYear(value) {
    this.generalDocumentAwardYear = value;
  }
  addDocment() {
    this.submitted1 = true;
    if (this.document.length == 0) {
      this.documentErr = 'Document is required';
    }
    // stop here if form is invalid
    if (this.addDocmentForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token)
    formData.append('criteria_id', this.addDocmentForm.value.criteria),
      formData.append('criteria_number', this.displayCriteriaNumber),
      formData.append('award_year_id', this.copmanyDocumentAwardId),
      formData.append('file_count', this.document.length)
    for (let i = 0; i < this.document.length; i++) {
      formData.append("comp_excel_upload_" + i, this.document[i]);
    }
    if (this.document.length == 0) {
      this.documentErr = 'Document is required';
    }
    if (this.document.length > 0) {
      this.documentErr = '';
      this.generalDocumentErrmsg = '';
      // if (this.NoDatamsg != '') {
      this.processingAddDocument = true;
      this.judgeService.addDocument(this.api_token, formData).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingAddDocument = false;
          // let a = this.criteraList[0]
          // this.criteraList = [];
          // this.criteraList.push(a);
          // console.log(this.criteraList)
          this.getDashboardDeails();
          this.close_document();
          this.messagesService.showSuccess(res.msg);
        }
        if (res.has_error == 1) {
          this.processingAddDocument = false;
          this.messagesService.showError(res.msg);
        }
      },
        err => {
          this.processingAddDocument = false;
          this.messagesService.showError('Something went wrong.');
        })
      // }
    }
    else if (this.NoDatamsg == '') {
      this.generalDocumentErrmsg = 'Please wait there is some other criteria';
    }
  }
  addGeneralDocment() {
    this.submitted2 = true;
    if (this.generalDocumentAction == 'add') {
      if ((this.general_document == null) || (this.general_document == undefined)) {
        this.generalDocumentErr = 'General Document is required';
      }
      // stop here if form is invalid
      if (this.addGeneralDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('action', this.generalDocumentAction),
        formData.append('name', this.addGeneralDocmentForm.value.document_name),
        formData.append('award_year_id', this.generalCopmanyDocumentAwardId),
        formData.append('document_name', this.general_document)

      if ((this.general_document == null) || (this.general_document == undefined)) {
        this.generalDocumentErr = 'General Document is required';
      }
      if ((this.general_document != null) || (this.general_document != undefined)) {
        this.processingAddGeneralDocument = true;
        this.judgeService.addGeneralDocument(this.api_token, formData).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.processingAddGeneralDocument = false;
            this.close_document();
            this.getDashboardDeails();
            this.messagesService.showSuccess('General Document Added Successfully');
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
            this.processingAddGeneralDocument = false;
          }
        },
          err => {
            this.processingAddGeneralDocument = false;
            this.messagesService.showError('Something went wrong.');
          })
      }
    }
    else if (this.generalDocumentAction == 'edit') {
      // stop here if form is invalid
      if (this.addGeneralDocmentForm.invalid) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append('user_token', this.user_token),
        formData.append('action', this.generalDocumentAction),
        formData.append('name', this.addGeneralDocmentForm.value.document_name),
        formData.append('doc_id', this.generalDocumentId),
        formData.append('award_year_id', this.generalCopmanyDocumentAwardId)
      this.processingAddGeneralDocument = true;
      this.judgeService.addGeneralDocument(this.api_token, formData).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingAddGeneralDocument = false;
          this.getDashboardDeails();
          this.close_document();

          this.messagesService.showSuccess('General Document Updated Successfully');
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingAddGeneralDocument = false;
        }
      },
        err => {
          this.processingAddGeneralDocument = false;
          this.messagesService.showError('Something went wrong.');
        })
    }
  }
  openDocumentDialog(document) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          document_id: document.id
        }
        this.judgeService.deleteCompanyDocument(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            // this.close_document();
            this.documentList = this.documentList.filter((x => x.id !== document.id))
            // console.log(this.documentList)
            this.getDashboardDeails();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.messagesService.showError('Something went wrong.');
          })
        // const a = document.createElement('a');
        // a.click();
        // a.remove();
      }
    });
  }
  openGeneralDocumentDialog(generaldocument) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure you want to delete this document?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          document_id: generaldocument.id
        }
        this.judgeService.deleteCompanyGeneralDocument(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            // this.close_document();
            this.generalDocumentList = this.generalDocumentList.filter((x => x.id !== generaldocument.id))
            console.log(this.generalDocumentList)
            this.getDashboardDeails();
            this.messagesService.showSuccess(res.msg);
          }
          if (res.has_error == 1) {
            this.messagesService.showError(res.msg);
          }
        },
          err => {
            this.messagesService.showError('Something went wrong.');
          })
      }
    });
  }
  singleDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.documentList.map((docItem) => {
        if (this.allDocumentData.indexOf(docItem.id) == -1) {
          if (docItem.id == Item.id) {
            this.allDocumentData.push(docItem.id);
            docItem.checked = true;
            this.documentDocs.push('assets/upload/category_documents/' + docItem.doc_name);
          }
        }
      })
      let documentPath = this.documentDocs.join('--');
      this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      if (this.documentList.length === this.documentDocs.length) {
        this.allDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allDocumentData.findIndex(itm => itm === Item.id);
      // console.log(removeIndex)
      if (removeIndex !== -1) {
        this.allDocumentData.splice(removeIndex, 1);
        this.documentDocs.splice(removeIndex, 1);
      }
      if (this.documentDocs.length > 0) {
        let documentPath = this.documentDocs.join('--');
        this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      }
      if (this.documentList.length !== this.documentDocs.length) {
        this.allDocumentStatusShow = false;
      }
    }
    console.log(this.allDocumentData);
    console.log(this.documentDocs);
  }
  SelectAllDocumentValue($event) {
    this.allDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.documentDocs = [];
      this.documentList.map((item) => {
        if (this.allDocumentData.indexOf(item.id) == -1) {
          this.allDocumentData.push(item.id);
          item.checked = true;
          this.documentDocs.push('assets/upload/category_documents/' + item.doc_name);
        }
      });
      this.allDocumentStatusShow = true;
      let documentPath = this.documentDocs.join('--');
      this.documentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + documentPath;
      this.allDocumentStatusShow = true;
    }
    else if ($event.srcElement.checked == false) {
      this.allDocumentData = [];
      this.documentDocs = [];
      this.documentList.map((item) => {
        item.checked = false;
      });
      this.allDocumentStatusShow = false;
    }
    console.log(this.documentDocs)
    console.log(this.allDocumentData)
  }
  singleGeneralDocumentValue($event, Item) {
    if ($event.srcElement.checked == true) {
      this.generalDocumentList.map((generaldocItem) => {
        if (this.allGeneralDocumentData.indexOf(generaldocItem.id) == -1) {
          if (generaldocItem.id == Item.id) {
            this.allGeneralDocumentData.push(generaldocItem.id);
            generaldocItem.checked = true;
            this.generalDocumentDocs.push('assets/upload/comp_general_doc/' + generaldocItem.document_name);
          }
        }
      })
      let generalDocumentPath = this.generalDocumentDocs.join('--');
      this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      if (this.generalDocumentList.length == this.generalDocumentDocs.length) {
        this.allGeneralDocumentStatusShow = true;
      }
    }
    else if ($event.srcElement.checked == false) {
      let removeIndex = this.allGeneralDocumentData.findIndex(itm => itm === Item.id);
      // console.log(removeIndex)
      if (removeIndex !== -1) {
        this.allGeneralDocumentData.splice(removeIndex, 1);
        this.generalDocumentDocs.splice(removeIndex, 1);
      }
      if (this.generalDocumentDocs.length > 0) {
        let generalDocumentPath = this.generalDocumentDocs.join('--');
        this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      }
      if (this.generalDocumentList.length !== this.generalDocumentDocs.length) {
        this.allGeneralDocumentStatusShow = false;
      }
    }
    console.log(this.allGeneralDocumentData);
    console.log(this.generalDocumentDocs);
  }
  SelectAllGeneralDocumentValue($event) {
    this.allGeneralDocumentData = [];
    if ($event.srcElement.checked == true) {
      this.generalDocumentDocs = [];
      this.generalDocumentList.map((item) => {
        if (this.allGeneralDocumentData.indexOf(item.id) == -1) {
          this.allGeneralDocumentData.push(item.id);
          item.checked = true;
          this.generalDocumentDocs.push('assets/upload/comp_general_doc/' + item.document_name);
        }
      });
      this.allGeneralDocumentStatusShow = true;
      let generalDocumentPath = this.generalDocumentDocs.join('--');
      this.generalDocumentDownloadPath = appConfig.apiUrl + '/download-zip?api_token=' + this.api_token + '&pathArray=' + generalDocumentPath;
      this.allDocumentStatusShow = true;
    }
    else if ($event.srcElement.checked == false) {
      this.allGeneralDocumentData = [];
      this.generalDocumentDocs = [];
      this.generalDocumentList.map((item) => {
        item.checked = false;
      });
      this.allGeneralDocumentStatusShow = false;
    }
    console.log(this.generalDocumentDocs);
    console.log(this.allGeneralDocumentData);
  }
  open_add_payment(companyItem) {
    this.show_document_popup = 3;
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
    this.NoDatamsg == '';
    this.submitted3 = false;
    this.singleCompanyDetails = companyItem;
    this.invoiceDownloadPath = appConfig.apiUrl + '/download-invoice?api_token=' + this.api_token + '&id=';
    this.copmanyDocumentAwardId = this.singleCompanyDetails.year_id;
    // console.log(this.copmanyDocumentAwardId);
    // console.log(this.singleCompanyDetails);
    // this.show_doc_list = false;
    // this.show_general__list = false;
    if (this.singleCompanyDetails.receipt_name != null) {
      this.addPaymentDocmentForm.patchValue({
        receipt_name: this.singleCompanyDetails.receipt_name,
      })
    }
    else if (this.singleCompanyDetails.receipt_name == null) {
      this.addPaymentDocmentForm.patchValue({
        receipt_name: '',
      })
    }

    if (this.singleCompanyDetails.payment_document != null) {
      this.displayPaymentDocumentName = this.singleCompanyDetails.payment_document;
    }
    else if (this.singleCompanyDetails.payment_document == null) {
      this.displayPaymentDocumentName = '';
    }
  }
  close_add_payment() {
    this.show_document_popup = 0;
    this.submitted3 = false;
    this.payment_document = null;
    this.paymentErr = '';
  }
  onSelectFilepayment(event) {
    if (event.target.files && event.target.files[0]) {
      this.payment_document = event.target.files[0];
      this.displayPaymentDocumentName = this.payment_document.name;
      console.log(this.displayPaymentDocumentName);
      if (this.payment_document != '') {
        this.paymentErr = '';
      }
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event: any) => {
      }
    }
  }
  addPayment() {
    this.submitted3 = true;

    // if((this.documentres.currentAwardUserData.payment_document == null) || (this.payment_document == null) || (this.payment_document == undefined)) {
    //   this.paymentErr = 'Payment Document is required';
    // }
    if ((this.displayPaymentDocumentName == null) || (this.displayPaymentDocumentName == '')) {
      this.paymentErr = 'Payment Document is required';
    }
    // stop here if form is invalid
    if (this.addPaymentDocmentForm.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('user_token', this.user_token),
      formData.append('award_year_id', this.copmanyDocumentAwardId),
      formData.append('receipt_name', this.addPaymentDocmentForm.value.receipt_name)

    // if((this.documentres.currentAwardUserData.payment_document == null) || (this.payment_document == null) || (this.payment_document == undefined)) {
    //   this.paymentErr = 'Payment Document is required';
    // }

    if ((this.payment_document == null) || (this.payment_document == undefined) &&
      (this.singleCompanyDetails.payment_document != null)) {
      formData.append('payment_document', this.displayPaymentDocumentName)
    }
    if ((this.payment_document != null) || (this.payment_document != undefined)) {
      formData.append('payment_document', this.payment_document)
    }
    if ((this.payment_document != null) || (this.singleCompanyDetails.payment_document != null)) {
      this.processingAddPayment = true;
      // console.log(this.payment_document);
      // console.log(this.singleCompanyDetails.payment_document);
      // console.log(this.copmanyDocumentAwardId);
      this.dashboardService.companyPayment(this.api_token, formData).subscribe((res: any) => {
        console.log(res);
        if (res.has_error == 0) {
          this.processingAddPayment = false;
          console.log(this.criteraList);
          this.close_add_payment();
          this.getDashboardDeails();
          this.messagesService.showSuccess('Payment Successfully done.');
        }
        if (res.has_error == 1) {
          this.messagesService.showError(res.msg);
          this.processingAddPayment = false;
        }
      },
        err => {
          this.processingAddPayment = false;
          this.messagesService.showError('Something went wrong.');
        })
    }
  }
  open_view_company(companyItem) {
    this.show_document_popup = 4;
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
    this.NoDatamsg == '';
    this.singleCompanyDetails = companyItem;
    console.log(this.singleCompanyDetails);

    // if((this.singleCompanyDetails.company_doc.length > 0) && (this.singleCompanyDetails.company_doc[0].status == 'Y')){
    //   this.documentSubmissionStatus = false;
    // }
    // else if ((this.singleCompanyDetails.company_doc.length > 0) && (this.singleCompanyDetails.company_doc[0].status == 'N')){
    //   this.documentSubmissionStatus = true;
    // }
    // else if (this.singleCompanyDetails.company_doc.length == 0){
    //   this.documentSubmissionStatus = true;
    // }
    console.log('approve_status', this.singleCompanyDetails.status);
    if (this.singleCompanyDetails.awardDetails.is_payble == 'Y') {
      if ((this.singleCompanyDetails.payment_status == 'Y') && (this.singleCompanyDetails.company_doc.length > 0) && (this.singleCompanyDetails.company_doc[0].status == 'N') && (this.singleCompanyDetails.status == 'Y')) {
        this.documentSubmissionStatus = true;
      }
      else if (this.singleCompanyDetails.payment_status != 'Y') {
        this.documentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.company_doc.length == 0) {
        this.documentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.company_doc[0].status == 'Y') {
        this.documentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.status == 'N') {
        this.documentSubmissionStatus = false;
      }
    }
    else if (this.singleCompanyDetails.awardDetails.is_payble == 'N') {
      if ((this.singleCompanyDetails.company_doc.length > 0) && (this.singleCompanyDetails.company_doc[0].status == 'N') && (this.singleCompanyDetails.status == 'Y')) {
        this.documentSubmissionStatus = true;
      }
      else if (this.singleCompanyDetails.company_doc.length == 0) {
        this.documentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.company_doc[0].status == 'Y') {
        this.documentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.status == 'N') {
        this.documentSubmissionStatus = false;
      }
    }
    this.documentList = this.singleCompanyDetails.company_doc;
    this.documentList.map((item) => {
      item.checked = false;
    })
    console.log(this.documentList);
    this.documentFilterationAwardList = this.dashboardRes.filerationAwards;

    // Step 1. Get all the object keys.    
    // Step 1. Get all the object values.
    let evilResponseProps = Object.values(this.dashboardRes.filerationYears);
    this.documentFilterationYearList = [];
    for (let item of evilResponseProps) {
      this.documentFilterationYearList.push(item)
    }
    // this.documentFilterationYearList = this.dashboardRes.filerationYears;
    console.log(this.documentFilterationAwardList);
    console.log(this.documentFilterationYearList);
  }
  open_view_general_company(companyItem) {
    this.show_document_popup = 5;
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
    this.NoDatamsg == '';
    this.singleCompanyDetails = companyItem;


    // if((this.singleCompanyDetails.general_document.length > 0) && (this.singleCompanyDetails.general_document[0].status == 'Y')){
    //   this.generalDocumentSubmissionStatus = false;
    // }
    // else if ((this.singleCompanyDetails.general_document.length > 0) && (this.singleCompanyDetails.general_document[0].status == 'N')){
    //   this.generalDocumentSubmissionStatus = true;
    // }
    // else if (this.singleCompanyDetails.general_document.length == 0){
    //   this.generalDocumentSubmissionStatus = true;
    // }
    console.log('approve_status', this.singleCompanyDetails.status);
    if (this.singleCompanyDetails.awardDetails.is_payble == 'Y') {
      if ((this.singleCompanyDetails.payment_status == 'Y') && (this.singleCompanyDetails.general_document.length > 0) && (this.singleCompanyDetails.general_document[0].status == 'N') && (this.singleCompanyDetails.status == 'Y')) {
        this.generalDocumentSubmissionStatus = true;
      }
      else if (this.singleCompanyDetails.payment_status != 'Y') {
        this.generalDocumentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.general_document.length == 0) {
        this.generalDocumentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.general_document[0].status == 'Y') {
        this.generalDocumentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.status == 'N') {
        this.generalDocumentSubmissionStatus = false;
      }
    }
    else if (this.singleCompanyDetails.awardDetails.is_payble == 'N') {
      if ((this.singleCompanyDetails.general_document.length > 0) && (this.singleCompanyDetails.general_document[0].status == 'N') && (this.singleCompanyDetails.status == 'Y')) {
        this.generalDocumentSubmissionStatus = true;
      }
      else if (this.singleCompanyDetails.general_document.length == 0) {
        this.generalDocumentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.general_document[0].status == 'Y') {
        this.generalDocumentSubmissionStatus = false;
      }
      else if (this.singleCompanyDetails.status == 'N') {
        this.generalDocumentSubmissionStatus = false;
      }
    }

    console.log(this.singleCompanyDetails);
    this.generalDocumentList = this.singleCompanyDetails.general_document;
    this.generalDocumentList.map((item) => {
      item.checked = false;
    })
    this.generalDocumentFilterationAwardList = this.dashboardRes.filerationAwards;
    let evilResponseProps = Object.values(this.dashboardRes.filerationYears);
    this.generalDocumentFilterationYearList = [];
    for (let item of evilResponseProps) {
      this.generalDocumentFilterationYearList.push(item)
    }
    // this.generalDocumentFilterationYearList = this.dashboardRes.filerationYears;
  }
  openEvidenceDialog(document) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Before submission please understand that this is a final submission. Once you proceed, It will forward the submission to the assessment team and you won'+'t be able to make any changes, add or delete further. Do you want to submit your documents?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.evidenceSendSubmission();
      }
    });
  }
  openGeneralDialog(document) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Before submission please understand that this is a final submission. Once you proceed, It will forward the submission to the assessment team and you won'+'t be able to make any changes, add or delete further. Do you want to submit your documents?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.generalSendSubmission();
      }
    });
  }
  evidenceSendSubmission() {
    console.log(this.singleCompanyDetails);
    console.log(this.documentList);

    this.documentList.map((item) => {
      this.documentIdArray.push(item.id)
    })
    let data = {
      award_year_id: this.singleCompanyDetails.year_id,
      document_ids: this.documentIdArray,
      document_type: 'E'
    }
    console.log(data);
    this.judgeService.sendSubmission(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.getDashboardDeails();
        this.close_document();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
  generalSendSubmission() {
    console.log(this.singleCompanyDetails);
    console.log(this.generalDocumentList)

    this.generalDocumentList.map((item) => {
      this.generalDocumentIdArray.push(item.id)
    })
    let data = {
      award_year_id: this.singleCompanyDetails.year_id,
      document_ids: this.generalDocumentIdArray,
      document_type: 'G'
    }
    console.log(data);
    this.judgeService.sendSubmission(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.getDashboardDeails();
        this.close_document();
      }
    }, err => {
      this.messagesService.showError('Something went wrong');
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessagesService } from 'src/app/services/messages.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { appConfig } from '../../app.config';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-self-assessment',
  templateUrl: './self-assessment.component.html',
  styleUrls: ['./self-assessment.component.scss']
})
export class SelfAssessmentComponent implements OnInit {
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';

  leftbarselected; any;
  user_token: any;
  api_token: any;
  selfAssessmentdetails: any;
  assessor_company_details: any = [];
  filteredAwardArray: any = [];
  closeResult: string;
  singleCompnaydetails: any;
  addSelfAssessmentForm: FormGroup;
  submitted: boolean = false;
  processingText: boolean = false;
  errMsg: any;
  lazyLoad: boolean = false;
  chartCriteriaList: any = [];
  selectIndex: any;
  processigntext: boolean = false;
  addProcessigntext: boolean = false;
  licenseErrMsg: any;
  allSelfassessmentDocs = [];
  allSelfassessmentPath: any;
  public fullChartArray: Array<any> = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: 'Your chart title',
      fontColor: 'black',  // chart title color (can be hexadecimal too)
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Criteria'
        },
        gridLines: {
          display: false,
        }
      }], yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Scores'
        },
        gridLines: {
          drawBorder: false,
        },
        barThickness: 10
      }]
    },
  };

  chartData = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];

  chartLabels = [];

  public barChartColors = [
    { backgroundColor: '#4fb5ef' },
    { backgroundColor: '#a931a9' },
  ]

  chartImageArray: any = [];
  constructor(private router: Router,
    private dataService: DataService,
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private authService: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dialog: MatDialog) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd == true) {
        if (val.url == '/self-assesment')
          this.leftbarselected = 'self assessment';
        this.dataService.leftPanelSelection('selfassessment');
      }
    });
    this.user_token = localStorage.getItem('user_token');
    this.api_token = localStorage.getItem('api_token');
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    if (userInfo.user_type == '7') {
      this.router.navigate([`/judge-group-dashboard`]);
    }
    else if (userInfo.user_type == '6') {
      this.router.navigate([`/self-assesment`]);
    }
    else if (userInfo.user_type == '5') {
      this.router.navigate([`/company-dashboard`]);
    }
    else if (userInfo.user_type == '2') {
      this.router.navigate([`/award-office-dashboard`]);
    }
    this.getSelfAssessmentdetails();
    // this.viewSelfassessmentDocs();
    this.addSelfAssessmentForm = this.formBuilder.group({
      company_name: ['', Validators.required],
      code: ['', Validators.required],
      license_code: ['']
    });
  }
  
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
  viewSelfassessmentDocs() {
    this.dashboardService.getSelfassessmentDocs(this.api_token).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.allSelfassessmentDocs = res.assesments;
        // this.allSelfassessmentPath = res.criteriaFilePath;
        this.allSelfassessmentPath = appConfig.apiUrl + '/download-file?api_token=' + this.api_token + '&document_path=assets/upload/self_criteria_file/';

        this.assessor_company_details.map((item) => {
          this.allSelfassessmentDocs.map((docItem) => {
            if (item.id == docItem.id) {
              console.log('1')
              item['assessment_docs'] = docItem;
            }
          })
        });
        console.log('assessor_company_details',this.assessor_company_details);
      }
    }, err => {
      this.messagesService.showError('Something went wrong!');
    })
  }
  getSelfAssessmentdetails() {
    this.lazyLoad = true;
    this.dashboardService.selfAssessment(this.user_token, this.api_token).subscribe((res: any) => {
      // console.log(res)
      if (res.has_error == 0) {
        this.viewSelfassessmentDocs();
        this.lazyLoad = false;
        this.assessor_company_details = res.assessor_company_details;
        this.assessor_company_details.map((item) => {
          // item.totalGetPoint = item.totalGetPoint.toFixed(1);
          // item.totalPoint = item.totalPoint.toFixed(1);
          item['total_bar_percent'] = 0;
          item.totalGetPoint = Math.round(item.totalGetPoint)
          item.criteria.map((criteriaItem) => {
            criteriaItem.bar_percent = criteriaItem.bar_percent ? criteriaItem.bar_percent : 0
            criteriaItem['bar_percent_status'] = criteriaItem.bar_percent;
            criteriaItem['bar_percent'] = criteriaItem.bar_percent + '%';
            // let a  = criteriaItem.get_point.toFixed(1);
            // criteriaItem.get_point = a;
            // criteriaItem.point = Number(criteriaItem.point).toFixed(1);
            item['total_bar_percent'] += criteriaItem.bar_percent_status;
          })
          item['criteria_count'] = item.criteria.length * 100;
          // this.allSelfassessmentDocs.map((docItem) => {
          //   if (item.id == docItem.id) {
          //     console.log('1')
          //     item['assessment_docs'] = docItem;
          //   }
          // })
        });
        // console.log('assessor_company_details',this.assessor_company_details);
      }
    }, err => {
      this.lazyLoad = false;
      if (err.error.message == "Unauthenticated.") {
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.messagesService.showError('Something went wrong.');
      }
    })
  }

  open(content, compnayItem, className) {
    this.singleCompnaydetails = compnayItem;
    console.log(this.singleCompnaydetails);
    let modalClassName = className;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder' , centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openForAdd(content, className) {
    this.submitted = false;
    this.addSelfAssessmentForm.get('company_name').reset();
    this.addSelfAssessmentForm.get('code').reset();
    this.addSelfAssessmentForm.get('license_code').reset();
    this.licenseErrMsg = '';
    let modalClassName = className;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: modalClassName + ' commonModalClass animated slideInUp modal-holder', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // sponsor registration popup open dismiss
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.addSelfAssessmentForm.controls; }

  formSubmit() {
    this.submitted = true;
    if (this.addSelfAssessmentForm.invalid) {
      return;
    }

    let data = {
      'user_token': this.user_token,
      'company_name': this.addSelfAssessmentForm.value.company_name,
      'code': this.addSelfAssessmentForm.value.code,
      'license_code': this.addSelfAssessmentForm.value.license_code,
    }
    this.addProcessigntext = true;
    this.dashboardService.assessmentLicense(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.modalService.dismissAll();
        this.messagesService.showSuccess('Self Assessment Added Successfully.');
        this.router.navigate(['/edit-self-assesment/', res.assessorAssesmentID]);
      }
      if (res.has_error == 1) {
        this.licenseErrMsg = res.msg;
        setTimeout(() => {
          this.licenseErrMsg = '';
        }, 3000);
      }
      this.addProcessigntext = false;
    }, err => {
      console.log(err)
      if (err.error.message == "Unauthenticated.") {
        this.addProcessigntext = false;
        this.modalService.dismissAll();
      }
      if (err.error.message != "Unauthenticated.") {
        this.addProcessigntext = false;
        this.messagesService.showError('Something went wrong.');
      }
    })
  }
  openDialogReport(compnayItem, index) {
    this.selectIndex = index;
    if (compnayItem.criteria_count != compnayItem.total_bar_percent) {
      this.processigntext = false;
      this.messagesService.showWarning('Please fillup all criteria');
    }
    if (compnayItem.criteria_count == compnayItem.total_bar_percent) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to generate report?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      },
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let data = {
          'api_token': this.api_token,
          'award_id': compnayItem.awardID,
          'assessment_id': compnayItem.id
        }
        this.processigntext = true;
        this.dashboardService.getChart(this.api_token, data).subscribe((res: any) => {
          console.log(res);
          if (res.has_error == 0) {
            this.fullChartArray = [];
            this.chartImageArray = [];
            let count = 1
            res.criteria.map((item) => {
              item['critera_number'] = count;
              count++;
            })
            for (let item of res.criteria) {
              let chartLabels = [];
              let lineChartTotalScore = [];
              let lineChartTotalPoint = [];
    
              // let chartLabels
              item.getTotalScoreValue.map((item1) => {
                // var str = item1.title;
                // var res = str.substring(0, 4);
                item1.title = item1.title.length > 30 ? item1.title.substring(0, 30) + '..' : item1.title;
                item['chartLabels'] = chartLabels.push(item1.title);
                item['total_score'] = lineChartTotalScore.push(item1.total_score);
                item['point'] = lineChartTotalPoint.push(item1.point);
              })
              // let chartData = [
              //   { data: lineChartTotalScore, label: 'Scores Achieved for Critera '},
              //   { data: lineChartTotalPoint, label: '' }
              // ];
              let chartData = [
                { data: lineChartTotalScore, label: '' },
                { data: lineChartTotalPoint, label: '' }
              ];
              this.barChartOptions = {
                responsive: true,
                barDatasetSpacing: 20,
                title: {
                  display: true,
                  text: 'Scores Achieved for Critera' + item.critera_number,
                  fontColor: 'black',  // chart title color (can be hexadecimal too)
                },
                scales: {
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Criteria'
                    },
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      fontFamily: "'Open Sans Bold', sans-serif",
                      fontSize: 11
                    },
                    // barThickness: 10,
                    barPercentage: 0.9,
                    // categorySpacing: 100
                    categoryPercentage: 0.9,
                  }], yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Scores'
                    },
                    gridLines: {
                      drawBorder: false,
                    },
                    barThickness: 10,
                    ticks: {
                      fontFamily: "'Open Sans Bold', sans-serif",
                      fontSize: 11
                    },
                    // categorySpacing: 100
                  }]
                },
              }
              this.fullChartArray.push({ chartData: chartData, chartLabels: chartLabels, id: item.id, barChartOptions: this.barChartOptions })
              setTimeout(() => {
                var canvas = <HTMLCanvasElement>document.getElementById('canvasChart' + item.id);
                let a = canvas.toDataURL();
                this.chartImageArray.push(a);
                // console.log(this.chartImageArray);
                if (this.chartImageArray.length == this.fullChartArray.length) {
                  this.getPdfLink(compnayItem.awardID, compnayItem.id)
                }
                // console.log(a);
              }, 1000);
              // this.processigntext = false;
            }
            // console.log(this.fullChartArray);
          }
        }, err => {
          this.processigntext = false;
          this.messagesService.showError('Something went wrong!');
        })
      }
    });
  }
  }
  downloadReport(compnayItem, index) {
    this.selectIndex = index;

    this.processigntext = true;

    console.log(compnayItem);
    let data = {
      'api_token': this.api_token,
      'award_id': compnayItem.awardID,
      'assessment_id': compnayItem.id
    }

    if (compnayItem.criteria_count != compnayItem.total_bar_percent) {
      this.processigntext = false;
      this.messagesService.showWarning('Please fillup all criteria');
    }
    if (compnayItem.criteria_count == compnayItem.total_bar_percent) {
    this.dashboardService.getChart(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.fullChartArray = [];
        this.chartImageArray = [];
        let count = 1
        res.criteria.map((item) => {
          item['critera_number'] = count;
          count++;
        })
        for (let item of res.criteria) {
          let chartLabels = [];
          let lineChartTotalScore = [];
          let lineChartTotalPoint = [];

          // let chartLabels
          item.getTotalScoreValue.map((item1) => {
            // var str = item1.title;
            // var res = str.substring(0, 4);
            item1.title = item1.title.length > 30 ? item1.title.substring(0, 30) + '..' : item1.title;
            item['chartLabels'] = chartLabels.push(item1.title);
            item['total_score'] = lineChartTotalScore.push(item1.total_score);
            item['point'] = lineChartTotalPoint.push(item1.point);
          })
          // let chartData = [
          //   { data: lineChartTotalScore, label: 'Scores Achieved for Critera '},
          //   { data: lineChartTotalPoint, label: '' }
          // ];
          let chartData = [
            { data: lineChartTotalScore, label: '' },
            { data: lineChartTotalPoint, label: '' }
          ];
          this.barChartOptions = {
            responsive: true,
            barDatasetSpacing: 20,
            title: {
              display: true,
              text: 'Scores Achieved for Critera' + item.critera_number,
              fontColor: 'black',  // chart title color (can be hexadecimal too)
            },
            scales: {
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Criteria'
                },
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontFamily: "'Open Sans Bold', sans-serif",
                  fontSize: 11
                },
                // barThickness: 10,
                barPercentage: 0.9,
                // categorySpacing: 100
                categoryPercentage: 0.9,
              }], yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Scores'
                },
                gridLines: {
                  drawBorder: false,
                },
                barThickness: 10,
                ticks: {
                  fontFamily: "'Open Sans Bold', sans-serif",
                  fontSize: 11
                },
                // categorySpacing: 100
              }]
            },
          }
          this.fullChartArray.push({ chartData: chartData, chartLabels: chartLabels, id: item.id, barChartOptions: this.barChartOptions })
          setTimeout(() => {
            var canvas = <HTMLCanvasElement>document.getElementById('canvasChart' + item.id);
            let a = canvas.toDataURL();
            this.chartImageArray.push(a);
            // console.log(this.chartImageArray);
            if (this.chartImageArray.length == this.fullChartArray.length) {
              this.getPdfLink(compnayItem.awardID, compnayItem.id)
            }
            // console.log(a);
          }, 1000);
          this.processigntext = false;
        }
        // console.log(this.fullChartArray);
      }
    }, err => {
      this.messagesService.showError('Something went wrong!');
    })
  }
  }

  getPdfLink(award_id, assessorAssesmen_id) {
    let data = {
      'api_token': this.api_token,
      'award_id': award_id,
      'assessment_id': assessorAssesmen_id,
      'base64Code': this.chartImageArray
    }
    this.dashboardService.downloadChartReport(this.api_token, data).subscribe((res: any) => {
      console.log(res);
      if (res.has_error == 0) {
        this.processigntext = false;
        window.open(res.next_url);
      }
    }, err => {
      this.messagesService.showError('Something went wrong!');
    })
  }

  onChartClick(event) {
  }

}
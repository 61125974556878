import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule } from '@angular/forms';
import { MeadiaService } from '../services/meadia.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AwardWinnerComponent } from './award-winner.component';
import { WinnerService } from '../services/winner.service';

// intro routing
export const routes: Routes = [
  { path: '', component: AwardWinnerComponent },
];

@NgModule({
  declarations: [AwardWinnerComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    Ng5SliderModule,
    FormsModule,
    InfiniteScrollModule
  ], providers: [MeadiaService,WinnerService]
})
export class AwardWinnerModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { ClickOutsideModule } from 'ng-click-outside';
// intro routing
export const routes: Routes = [
  { path: '', component: HeaderComponent },
];

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ClickOutsideModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }
